import styled from 'styled-components';

const StyledCustomHeader = styled.div`
    display: flex;
    margin: 0 1rem;
    .customized-button{
        margin-right: 0.7rem
    }
`;

export default StyledCustomHeader;
