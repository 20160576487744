import React from 'react';
import PropTypes from 'prop-types';
import styled, { css } from 'styled-components';

import { typographyBody1, typographyBody2 } from './../../core';
import { INPUT_SIZES } from '../constants';

const StyledBaseInputPrefixSuffix = styled.span.withConfig({
  shouldForwardProp: (prop) => !['isDisabled', 'size'].includes(prop),
})`
  ${({ theme }) => typographyBody1(theme)}
  align-items: center;
  box-sizing: border-box;
  color: ${({ isDisabled, theme }) =>
    isDisabled ? theme.color.text.light.disabled.value : theme.color.text.light.primary.value};
  display: flex;
  flex-shrink: 0;
  overflow: hidden;
  ${({ size, theme }) =>
    size === INPUT_SIZES.LARGE &&
    css`
      ${typographyBody2(theme)}
      color: ${theme.color.text.light.primary.value};
    `}
`;

const BaseInputPrefixSuffix = ({ isDisabled = false, size = INPUT_SIZES.STANDARD, ...props }) => (
  <StyledBaseInputPrefixSuffix {...{ isDisabled, size }} {...props} />
);

BaseInputPrefixSuffix.propTypes = {
  /** If true, changes visual appearance */
  isDisabled: PropTypes.bool,
  /** Set the size of the input */
  size: PropTypes.oneOf(Object.values(INPUT_SIZES)),
};

export { BaseInputPrefixSuffix };
