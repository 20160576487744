import { useRef, useLayoutEffect } from 'react';
import { useIntl } from 'react-intl';
import { IconCancelOutlined } from 'cdk-radial';
import StyledExpandableChips from './styled';
import { ExpandableChipsProps } from './types';
import { messages } from './messages';

const ExpandableChips = ({
  data,
  keysArray,
  rowIndex,
  field,
  isExpanded,
  setRowFieldExpansion,
  setRowHeight,
  handleFieldRemoval,
  handleRemoveAllData
}: ExpandableChipsProps): JSX.Element => {
  const containerRef = useRef<HTMLDivElement>(null);
  const intl = useIntl();

  const handleExpandCollapseClick = (isExpandedLocal: boolean) => {
    setRowFieldExpansion(rowIndex, field, isExpandedLocal);
  };

  useLayoutEffect(() => {
    if (data && data.length && containerRef && containerRef.current) {
      setRowHeight(rowIndex, containerRef.current.clientHeight, field);
    }
  }, [data, rowIndex, containerRef, setRowHeight, field]);

  const dataToShow = !isExpanded && data.length > 2 ? data.slice(0, 2) : data;

  return (
    <StyledExpandableChips
      ref={containerRef}
      data-testid="Expandablechips-container"
    >
      {dataToShow.map((item, itemIndex) => (
        <div
          className="expand-chips-container"
          key={JSON.stringify(item)}
          data-testid="expandablechip"
        >
          {
            // eslint-disable-next-line @typescript-eslint/no-unsafe-return
            keysArray.reduce((acc, curr) => acc[curr], item)
          }
          <span
            role="button"
            tabIndex={0}
            style={{ cursor: 'pointer' }}
            data-testid="remove-chip"
            onClick={() => handleFieldRemoval(itemIndex, rowIndex, field)}
            onKeyDown={() => handleFieldRemoval(itemIndex, rowIndex, field)}
          >
            <IconCancelOutlined />
          </span>
        </div>
      ))}
      {(field === 'roles' || field === 'stores') &&
        data.length > 1 &&
        handleRemoveAllData && (
          <div
            role="button"
            tabIndex={0}
            className="see-text remove-all-button"
            onClick={() => handleRemoveAllData(rowIndex, field)}
            onKeyDown={() => handleRemoveAllData(rowIndex, field)}
            data-testid="remove-all"
          >
            {intl.formatMessage(messages.removeAll)}
          </div>
        )}
      {isExpanded && data.length && data.length > 2 ? (
        <div
          role="button"
          tabIndex={0}
          className="see-text"
          onClick={() => handleExpandCollapseClick(false)}
          onKeyDown={() => handleExpandCollapseClick(false)}
          data-testid="see-less"
        >
          {intl.formatMessage(messages.seeLess)}
        </div>
      ) : null}
      {!isExpanded && data.length && data.length > 2 ? (
        <div
          role="button"
          tabIndex={0}
          className="see-text"
          onClick={() => handleExpandCollapseClick(true)}
          onKeyDown={() => handleExpandCollapseClick(true)}
          data-testid="see-more"
        >
          {intl.formatMessage(messages.seeMore)}
        </div>
      ) : null}
      {!data || !data.length ? '-' : null}
    </StyledExpandableChips>
  );
};

export default ExpandableChips;
