import React from 'react';
import PropTypes from 'prop-types';
import styled, { css } from 'styled-components';

import { BREAKPOINTS, truncateText, typographyBody1, typographyBody2 } from '../../../core';
import { LIST_SIZES } from '../../constants';

const StyledListItemPrimaryText = styled.span`
  ${truncateText};
  ${({ theme }) => typographyBody2(theme)};
  box-sizing: border-box;
  color: ${({ isDisabled, theme }) => isDisabled && theme.color.text.light.disabled.value};
  pointer-events: none;
  width: 100%;
  ${({ isDisabled, size, theme }) =>
    size === LIST_SIZES.STANDARD &&
    css`
      @media ${BREAKPOINTS.L} {
        ${typographyBody1(theme)}
        color: ${isDisabled && theme.color.text.light.disabled.value};
      }
    `}
`;

const ListItemPrimaryText = ({ children, isDisabled = false, size = LIST_SIZES.STANDARD, ...other }) => (
  <StyledListItemPrimaryText {...{ children, isDisabled, size }} {...other} />
);

ListItemPrimaryText.propTypes = {
  /** children - included to make propTypes happy. hiddenProps array below removes this from displaying in Storybook. */
  children: PropTypes.node,
  /** Changes appearance to look non-interactable */
  isDisabled: PropTypes.bool,
  /** Changes appearance on different sizes */
  size: PropTypes.oneOf(Object.values(LIST_SIZES)),
};

export { ListItemPrimaryText };
