import { Menu, MenuListItem } from 'cdk-radial';
import styled from 'styled-components';

export const StyledSingleSelectFilter = styled.div``;

export const StyledMenu = styled(Menu)`
  pointer-events: all !important;
  z-index: 51;
`;

export const StyledMenuListItem = styled(MenuListItem)`
  z-index: 51;
`;
