import { defineMessages } from 'react-intl';

export const messages = defineMessages({
  automapRolesButton: {
    id: 'NEWUSERIMPORT.STEP1.TITLE.AUTOMAPROLESBUTTON',
    defaultMessage: 'Automap Roles'
  },
  automapToastForRole: {
    id: 'NEWUSERIMPORT.AUTOMAPROLE.DIALOG.TOAST.MAPPING',
    defaultMessage: 'DMS role will be mapped to the selected CDK Role.'
  },
  automapToastForRoles: {
    id: 'NEWUSERIMPORT.AUTOMAPROLES.DIALOG.TOAST.MAPPINGS',
    defaultMessage: 'DMS roles will be mapped to the selected CDK Roles.'
  },
  selectValue: {
    id: 'NEWUSERIMPORT.STEP1.CNUMBER.HELPER',
    defaultMessage: 'Please select a value'
  },
  selectCNumber: {
    id: 'NEWUSERIMPORT.STEP2.TABLE.FILTER.CNUMBER',
    defaultMessage: 'CNumber'
  },
  importFromDMSTitle: {
    id: 'NEWUSERIMPORT.TITLE.IMPORTFROMDMS',
    defaultMessage: 'Import From DMS'
  }
});
