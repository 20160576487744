import styled from 'styled-components';

import { inputStateOutlineStyles } from '../../input';
import { EXPAND_BEHAVIORS } from '../constants';

const SearchInputContainer = styled.div.withConfig({
  shouldForwardProp: (prop) => !['expandBehavior', 'isExpanded'].includes(prop),
})`
  align-items: center;
  display: flex;
  justify-content: ${({ expandBehavior }) =>
    expandBehavior === EXPAND_BEHAVIORS.SLIDE_LEFT ? 'flex-end' : 'flex-start'};
  position: relative;
  width: 100%;
  & :focus {
    ${inputStateOutlineStyles.focus};
    :hover {
      background-color: ${({ isExpanded, theme }) => !isExpanded && theme.color.state.default.hover.value};
    }
    :active {
      background-color: ${({ isExpanded, theme }) => !isExpanded && theme.color.state.default.pressed.value};
    }
  }
`;

export { SearchInputContainer };
