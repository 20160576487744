import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import { getRem, typographyCaption, wordBreak } from './../../core';
import { IconErrorOutlined } from './../../icons';

const paddingSize = getRem('12px');
const iconSize = getRem('16px');

const StyledInputErrorText = styled.span`
  ${({ theme }) => typographyCaption(theme)}
  ${wordBreak}
    align-items: center;
  box-sizing: border-box;
  color: ${({ theme }) => theme.color.system.negative[500].value};
  display: flex;
  margin-top: ${({ theme }) => theme.size.spacing.small.value};
  padding-left: ${paddingSize};
  padding-right: ${paddingSize};
`;

const StyledInputErrorTextContent = styled.span`
  min-width: 0;
  width: 100%;
`;

const StyledOutlinedErrorIcon = styled(IconErrorOutlined)`
  align-self: flex-start;
  display: flex;
  fill: ${({ theme }) => theme.color.system.negative[500].value};
  flex-shrink: 0;
  height: ${iconSize};
  margin-right: ${({ theme }) => theme.size.spacing.small.value};
  width: ${iconSize};
`;

const InputErrorText = ({ dataTestId = undefined, id, text, ...other }) => (
  <StyledInputErrorText id={id} data-testid={dataTestId} role="alert" {...other}>
    <StyledOutlinedErrorIcon data-testid={dataTestId ? `${dataTestId}-icon` : undefined} />
    <StyledInputErrorTextContent data-testid={dataTestId ? `${dataTestId}-text` : undefined}>
      {text}
    </StyledInputErrorTextContent>
  </StyledInputErrorText>
);

InputErrorText.propTypes = {
  /** provides data-testid for functional tests */
  dataTestId: PropTypes.string,
  /** Unique identificator. Necessary to have for linking with other components */
  id: PropTypes.string.isRequired,
  /** Displays provided text */
  text: PropTypes.node.isRequired,
};

export { InputErrorText };
