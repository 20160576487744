/* eslint-disable @typescript-eslint/no-unsafe-call,
@typescript-eslint/explicit-module-boundary-types */
import { CELL_RENDERERS, CELL_CLASSES } from 'cdk-radial';
import React, { useRef } from 'react';
import { useIntl } from 'react-intl';
import MappedRoleDropDown from './mappedRoleDropDown';
import { messages } from './messages';

type JobRole = {
  value: string;
  label: string;
  roleGuid: string;
  id: string;
};

const RenderCustomDropdown = (gridParams: any): JSX.Element => {
  const { rowIndex, api, value, data, context } = gridParams;

  const updateJobProfiles = (jobProfile: JobRole) => {
    const updatedRole = {
      enterpriseId: data.enterpriseId,
      id: data.id,
      jobprofileName: data.jobprofileName,
      roleGuid: jobProfile.roleGuid
    };
    context.updateRoleField(updatedRole);
  };

  const dropdownColumnId = 'dropdown';
  const dropdownRefs = useRef<(HTMLDivElement | null)[]>([]);
  const intialRoleGuid = data?.roleGuid;
  const options = value;
  const initialRole = options.filter((v: any) => v.roleGuid === intialRoleGuid);

  return (
    <MappedRoleDropDown
      ref={(el: HTMLDivElement | null) => {
        dropdownRefs.current[rowIndex] = el;
      }}
      onMenuClose={() => {
        console.log('onMenuClose');
      }}
      onMenuOpen={() => {
        const focusedCell = api.getFocusedCell();
        const focusedCellIndex = focusedCell && focusedCell.rowIndex;
        const focusedCellColumnId = focusedCell && focusedCell.column.colId;
        const isDropdownCellFocused =
          focusedCellIndex === rowIndex &&
          focusedCellColumnId === dropdownColumnId;
        if (!isDropdownCellFocused) {
          api.setFocusedCell(rowIndex, dropdownColumnId);
        }
      }}
      onSpaceKeyClick={() => {
        api.ensureIndexVisible(rowIndex);
        api.ensureColumnVisible(dropdownColumnId);
      }}
      options={options}
      initialRole={initialRole[0]}
      updateRole={selectedValue => updateJobProfiles(selectedValue)}
    />
  );
};

const useColumns = (): Array<any> => {
  const intl = useIntl();
  const columns = [
    {
      cellRenderer: CELL_RENDERERS.TEXT,
      field: 'id',
      headerName: intl.formatMessage(messages.profileId),
      minWidth: 150,
      sortable: true
    },
    {
      cellRenderer: CELL_RENDERERS.TEXT,
      field: 'jobprofileName',
      headerName: intl.formatMessage(messages.dmsJobProfile),
      minWidth: 200,
      sortable: true
    },
    {
      cellClass: CELL_CLASSES.DROPDOWN,
      cellRendererFramework: RenderCustomDropdown,
      field: 'roles',
      headerName: intl.formatMessage(messages.mappedRole),
      minWidth: 350
    }
  ];
  return [columns];
};
export default useColumns;
