import React from 'react';
import PropTypes from 'prop-types';
import InputMask from 'react-input-mask-3.0';

import { DATE_INPUT_MASKS } from './constants';
import { DATE_FORMATS } from '../../constants';

const ReactDateInputMask = ({ dateFormat, inputRef = undefined, maxLength = undefined, ...other }) => {
  // By default, on focus new value becomes dateFormat. For this case we want to set it back to an empty string
  const beforeMaskedStateChange = ({ nextState }) => {
    const value = nextState.value === dateFormat ? '' : nextState.value;

    return {
      ...nextState,
      value,
    };
  };

  return (
    <InputMask
      beforeMaskedStateChange={beforeMaskedStateChange}
      maskPlaceholder={dateFormat}
      mask={DATE_INPUT_MASKS[dateFormat]}
      {...other}
      placeholder={dateFormat}
      ref={inputRef}
    />
  );
};

ReactDateInputMask.propTypes = {
  /** Defines in what format date input should format value (e.g. YYYY/MM/DD, MM/DD/YYYY, DD/MM/YYYY) */
  dateFormat: PropTypes.oneOf(Object.values(DATE_FORMATS)).isRequired,
  /** Ref that should be passed to input component */
  inputRef: PropTypes.oneOfType([PropTypes.func, PropTypes.shape({ current: PropTypes.instanceOf(Element) })]),
  /** This prop is not used by component. We destructure it from props because
   * it should not be supplied to InputMask component */
  maxLength: PropTypes.number,
};

export { ReactDateInputMask };
