import styled from 'styled-components';

const StyledRevokeImpersonationDialog = styled.div`
.footer-button-group{
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    margin-top: 0.75rem;
}
.revoke-impersonation-text{
    margin-top: 0.5rem;
    margin-bottom: 1rem;
}
`;

export default StyledRevokeImpersonationDialog;
