import moment from 'moment';
import { useIntl } from 'react-intl';
import TableFilterType from '../../../helpers/types/tableFilterType';
import { messages } from './messages';
import { getProfileAccounts, getStores } from './utils';

export const UID = {
  PROFILES: 'profiles',
  STORES: 'stores',
  JOB_PROFILES: 'jobProfiles',
  CNUMBER: 'cNumber'
};

const useFilters = (): TableFilterType[] => {
  const intl = useIntl();

  const filters: TableFilterType[] = [
    {
      label: intl.formatMessage(messages.lastLogin),
      uid: 'log_date',
      typecastTo: moment,
      type: 'range',
      multi: false,
      options: [
        {
          id: 'currentweek',
          label: intl.formatMessage(messages.currentWeek),
          value: { min: moment().startOf('week'), max: moment().endOf('week') },
          selectionLabel: '',
          disabled: false,
          showDivider: false
        },
        {
          id: 'previousweek',
          label: intl.formatMessage(messages.previousWeek),
          value: {
            min: moment().startOf('week').subtract('7', 'd'),
            max: moment().endOf('week').subtract('7', 'd')
          },
          selectionLabel: '',
          disabled: false,
          showDivider: false
        },
        {
          id: 'currentmonth',
          label: intl.formatMessage(messages.currentMonth),
          value: {
            min: moment().startOf('month'),
            max: moment().endOf('month')
          },
          selectionLabel: '',
          disabled: false,
          showDivider: false
        },
        {
          id: 'previousmonth',
          label: intl.formatMessage(messages.previousMonth),
          value: {
            min: moment().startOf('month').subtract(1, 'M'),
            max: moment().endOf('month').subtract(1, 'M')
          },
          selectionLabel: '',
          disabled: false,
          showDivider: false
        },
        {
          id: 'past6months',
          label: intl.formatMessage(messages.past6Months),
          value: { min: moment().subtract(6, 'M'), max: moment() },
          selectionLabel: '',
          disabled: false,
          showDivider: false
        },
        {
          id: 'pastyear',
          label: intl.formatMessage(messages.pastYear),
          value: { min: moment().subtract(1, 'y'), max: moment() },
          selectionLabel: '',
          disabled: false,
          showDivider: false
        }
      ]
    },
    {
      label: intl.formatMessage(messages.cnumber),
      uid: UID.CNUMBER,
      typecastTo: null,
      type: 'equality',
      multi: true,
      options: []
    },
    {
      label: intl.formatMessage(messages.dmsProfile),
      uid: UID.JOB_PROFILES,
      typecastTo: null,
      type: 'includes',
      multi: true,
      options: []
    },
    {
      label: intl.formatMessage(messages.dmsAccounts),
      uid: UID.PROFILES,
      typecastTo: null,
      typecastGridDataTo: getProfileAccounts,
      type: 'includes',
      multi: true,
      options: []
    },
    {
      label: intl.formatMessage(messages.stores),
      uid: UID.STORES,
      typecastTo: null,
      typecastGridDataTo: getStores,
      type: 'includes',
      multi: true,
      options: []
    }
  ];
  return filters;
};

export default useFilters;
