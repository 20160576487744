import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import { getRem } from '../../core';
import { tablePaginationTextStyles } from '../shared-styles';

const StyledPageSummaryText = styled.span`
  ${tablePaginationTextStyles}
  margin: 0 ${getRem(6)};
`;

const PageSummaryText = ({ id, text, ...other }) => (
  <StyledPageSummaryText id={id} {...other}>
    {text}
  </StyledPageSummaryText>
);

PageSummaryText.propTypes = {
  /** Id of pagination page summary label */
  id: PropTypes.string.isRequired,
  /** Sets page summary text */
  text: PropTypes.node.isRequired,
};

export { PageSummaryText };
