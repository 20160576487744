import { AuthState } from '@okta/okta-auth-js';
import { includes } from 'lodash';
import { EnterpriseCDKAccessDetailsType } from 'redux/services/authz-service/types';
import { FullUser } from 'redux/services/identity-service/types';

const defaultUserData = {} as FullUser;

export const isCDKUser = (userData: FullUser = defaultUserData): boolean => {
  if (userData?.fullUser) {
    const {
      fullUser: { enterprise, user }
    } = userData;
    return (
      enterprise?.selfId.toUpperCase() === 'E000000' &&
      user?.loginId.toLowerCase().includes('cdk.com')
    );
  }
  return false;
};

export const isEnterpriseChanged = (pathname: string): boolean => {
  const paths = pathname.split('/');
  if (
    paths[1] === 'enterprise' &&
    paths[2].startsWith('E') &&
    localStorage.getItem('enterpriseId') !== paths[2]
  ) {
    return true;
  }
  return false;
};

export const getFullName = (userData: FullUser = defaultUserData): string => {
  const { firstName, lastName } = userData?.fullUser?.user || {};
  if (firstName && lastName) {
    return `${firstName} ${lastName}`;
  }
  return '';
};

export const hasCDKAccessPermission = (
  enterpriseCDKAccessDetails: EnterpriseCDKAccessDetailsType | undefined
): boolean => {
  if (enterpriseCDKAccessDetails) {
    return !!enterpriseCDKAccessDetails?.isEditAccessEnabled;
  }
  return true;
};

export const hasLoggedInAsCDKUser = (authState: AuthState | null): boolean => {
  const loggedInUser = (authState?.accessToken?.claims.cdksid as string) || '';
  return loggedInUser?.toLowerCase().includes('cdk.com');
};

const hasPermission = (
  permissionsAssignedToUser: Record<string, any>,
  code: string
) => includes(permissionsAssignedToUser, code);

export const hasImpersonationPermission = (
  userInfo: FullUser,
  permissionsAssignedToUser: Record<string, any>,
  userEnterpriseId: string
): boolean =>
  userInfo?.fullUser?.user?.loginId?.toLowerCase()?.includes('cdk.com') &&
  hasPermission(permissionsAssignedToUser, 'MNGUSRIMPRSN') &&
  userEnterpriseId.toLowerCase() !== 'e000000';
