import React from 'react';

import { NormalizedButton } from 'lib/button';
import { IconVisibility, IconVisibilityOff } from 'lib/icons';
import PropTypes from 'prop-types';
import styled from 'styled-components';

const StyledSuffixButton = styled(NormalizedButton)`
  display: inline-flex;
  padding: 0;
`;

const ShowHideValueToggleButton = ({
  isDisabled = false,
  isHidden = false,
  onToggleShowHide,
  toggleHideLabel = 'Show value',
  toggleShowLabel = 'Hide value',
  ...other
}) => {
  const Icon = isHidden ? IconVisibility : IconVisibilityOff;
  const toggleLabel = isHidden ? toggleHideLabel : toggleShowLabel;

  return (
    <StyledSuffixButton
      aria-label={toggleLabel}
      disabled={isDisabled}
      onClick={onToggleShowHide}
      type="button"
      {...other}
    >
      <Icon />
    </StyledSuffixButton>
  );
};

ShowHideValueToggleButton.propTypes = {
  /** If true, button is disabled and value cannot be shown */
  isDisabled: PropTypes.bool,
  /** Current state in which component should be rendered */
  isHidden: PropTypes.bool,
  /** Callback which is called on toggle */
  onToggleShowHide: PropTypes.func.isRequired,
  /** Label shown when component's state is hidden */
  toggleHideLabel: PropTypes.node,
  /** Label shown when component's state is not hidden */
  toggleShowLabel: PropTypes.node,
};

export { ShowHideValueToggleButton };
