import React from 'react';
import { THEMES } from 'lib/utilities';
import PropTypes from 'prop-types';
import { animated, useSpring } from 'react-spring/web.cjs';
import styled, { css } from 'styled-components';

import { getHexToRgb, getRem, transitionAnimation, typographyCaption, wordBreak } from '../../core';

const getTooltipThemeCss = (theme, tooltipTheme) => {
  switch (tooltipTheme) {
    case THEMES.DARK:
    case THEMES.PRIMARY:
      return css`
        background-color: rgba(${getHexToRgb(theme.color.additional.light.value, 0.9)});
        color: ${theme.color.additional.dark.value};
      `;
    case THEMES.LIGHT:
    default:
      return css`
        background-color: ${theme.color.additional.dark.value};
        color: ${theme.color.additional.light.value};
      `;
  }
};

const StyledAnimatedTooltip = styled(animated.span).withConfig({
  shouldForwardProp: (prop) => !['tooltipTheme'].includes(prop),
})`
  ${({ theme }) => typographyCaption(theme)};
  ${transitionAnimation('opacity')}
  ${wordBreak}
    border-radius: ${({ theme }) => theme.size.borderRadius.large.value};
  box-sizing: border-box;
  display: inline-flex;
  flex-wrap: wrap;
  justify-content: center;
  margin: 0;
  max-width: ${getRem('192px')};
  padding: ${({ theme }) => theme.size.spacing.medium.value} ${getRem('12px')};
  position: fixed;
  top: 0;
  user-select: none;
  z-index: ${({ theme }) => theme.zIndex.tooltip.value};
  ${({ theme, tooltipTheme }) => getTooltipThemeCss(theme, tooltipTheme)}
`;

const Tooltip = React.forwardRef(({ delay = 500, id, style = {}, text, theme = THEMES.LIGHT, ...other }, ref) => {
  const animationProps = useSpring({
    config: { duration: 200 },
    delay: delay,
    from: { opacity: 0 },
    opacity: 1,
  });

  return (
    <StyledAnimatedTooltip
      id={id}
      role="tooltip"
      style={{ ...animationProps, ...style }}
      tooltipTheme={theme}
      {...other}
      ref={ref}
    >
      {text}
    </StyledAnimatedTooltip>
  );
});

Tooltip.propTypes = {
  /** Delay in ms before tooltip appears on hover */
  delay: PropTypes.number,
  /** Unique identifier of the component */
  id: PropTypes.string.isRequired,
  /** Inline styles applied to main component wrapper */
  style: PropTypes.shape({}),
  /** Component content */
  text: PropTypes.node.isRequired,
  /** Changes style depending on theme */
  theme: PropTypes.oneOf(Object.values(THEMES)),
};

export { Tooltip };
