import React, { useCallback, useRef } from 'react';
import {
  KEY_VALUES,
  useKeyDownEventListener,
  useOutsideClickEventListener,
  useTabOutEventListener,
  useShareForwardedRef,
} from 'lib/utilities';
import PropTypes from 'prop-types';
import styled from 'styled-components';

const StyledPopup = styled.div`
  color: ${({ theme }) => theme.color.additional.dark.value};
  display: inline-block;
  max-width: 100%;
  outline: 0;
  position: relative;
`;

const Popup = React.forwardRef(
  (
    {
      children,
      closePopupOnOutsideClick = true,
      closePopupOnTabOut = true,
      dataTestId = '',
      isOpen,
      onClose,
      onOpen,
      wrappedComponentContent,
      wrappedComponentRef = null,
      ...other
    },
    ref
  ) => {
    const wrapperRef = useShareForwardedRef(ref);
    const popupContentRef = useRef();

    const handleClose = () => {
      if (isOpen) {
        onClose();
      }
    };

    const handleEscapeKeyDown = useCallback(() => {
      const isFocused = wrapperRef.current.contains(document.activeElement);

      if (isOpen && isFocused) {
        onClose();
      }
    }, [isOpen, onClose, wrapperRef]);

    const handleTabKeyDown = useCallback(
      (event) => {
        const isFocused = popupContentRef.current && popupContentRef.current.contains(document.activeElement);
        const isShiftPressed = event.shiftKey;

        if (!isFocused && isOpen && isShiftPressed) {
          onClose();
        }
      },
      [isOpen, onClose]
    );

    const handleKeyDown = useCallback(() => {
      const isFocused = wrapperRef.current.contains(document.activeElement);

      if (!isOpen && isFocused) {
        onOpen();
      }
    }, [isOpen, onOpen, wrapperRef]);

    useKeyDownEventListener(KEY_VALUES.ARROW_DOWN, handleKeyDown, wrapperRef.current);
    useKeyDownEventListener(KEY_VALUES.ARROW_UP, handleKeyDown, wrapperRef.current);
    useKeyDownEventListener(KEY_VALUES.ESCAPE, handleEscapeKeyDown, wrapperRef.current);
    useKeyDownEventListener(KEY_VALUES.TAB, handleTabKeyDown, wrapperRef.current);

    useOutsideClickEventListener(wrapperRef, closePopupOnOutsideClick ? handleClose : null);
    useTabOutEventListener(popupContentRef, closePopupOnTabOut ? handleClose : null);

    return (
      <StyledPopup data-testid={dataTestId} ref={wrapperRef} {...other}>
        <div ref={wrappedComponentRef}>
          {React.cloneElement(wrappedComponentContent, {
            'aria-expanded': isOpen,
            'aria-haspopup': true,
          })}
        </div>
        {isOpen && (
          <div
            aria-hidden={!isOpen}
            data-testid={dataTestId && `${dataTestId}-listbox`}
            ref={popupContentRef}
            role="listbox"
          >
            {children}
          </div>
        )}
      </StyledPopup>
    );
  }
);

Popup.propTypes = {
  /** Any content inserted between component tags */
  children: PropTypes.node.isRequired,
  /** If true, closes the popup content on outside click */
  closePopupOnOutsideClick: PropTypes.bool,
  /** If true, closes the popup content on tabbing out of the popup content */
  closePopupOnTabOut: PropTypes.bool,
  /** Id value used for testing */
  dataTestId: PropTypes.string,
  /** If true, shows popup menu */
  isOpen: PropTypes.bool.isRequired,
  /** Callback to be called when popup menu is being closed */
  onClose: PropTypes.func.isRequired,
  /** Callback to be called when popup menu is getting opened */
  onOpen: PropTypes.func.isRequired,
  /** Content which is shown when popup is open */
  wrappedComponentContent: PropTypes.node.isRequired,
  /** A reference of the component wrapped with the Popup */
  wrappedComponentRef: PropTypes.oneOfType([PropTypes.func, PropTypes.shape({ current: PropTypes.any })]),
};

export { Popup };
