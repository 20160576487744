import styled from 'styled-components';

const StyledStepOne = styled.div`
display: block;
.selectCNumber {
    min-height: 8.5rem;
    max-width: 17.5rem;
    width: 100%;
}
`;

export default StyledStepOne;
