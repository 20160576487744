import styled from 'styled-components';

export const StyledStepThree = styled.div`
  margin: 1rem 0;
  width: 100%;
  height: -webkit-fill-available;
`;

export const StyledStepThreeFilterContainer = styled.div`
  display: flex;
  & > div:first-child {
    flex: 2;
  }
  & > div:last-child {
    flex: 1;
  }
`;
