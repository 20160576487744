/* eslint-disable @typescript-eslint/restrict-template-expressions */
import { createApi } from '@reduxjs/toolkit/query/react';
import baseQuery from './baseQuery';
import {
  SearchAllEnterpriseResponseType,
  SearchAllEnterprisePayloadType,
  SearchAllEnterpriseResponseTypeLegacy,
  MergeEnterprisePayload,
  SearchEntityResponseType,
  SearchEntityPayloadType,
  SearchMoveStoreResponseTypeLegacy,
  CreateDMSInfo,
  CreateEnterpriseDetailsBody,
  CreateIdentifiersBody,
  CreateStoreDetailsBody,
  DeleteIdentifiersBody,
  PutStoreVisibilityBody,
  UpdateDepartmentByStoreId,
  MoveStore
} from '../types/enterpriseInfo-service-types';
import apiEndPoints from './apiEndPoints';
import { CreateDepartmentDetailsBody } from '../types/store-service-types';

export const enterpriseInfoServiceAPI = createApi({
  reducerPath: 'enterpriseInfoService',
  baseQuery,
  endpoints: builder => ({
    searchAllEnterpriseLegacy: builder.query<
      SearchAllEnterpriseResponseType,
      SearchAllEnterprisePayloadType
    >({
      query: ({ searchValue }) =>
        `${apiEndPoints.getSearchAllEnterprise}?input=${searchValue}&limit=500000&offset=0`,
      transformResponse: (response: SearchAllEnterpriseResponseTypeLegacy) => ({
        enterprises: response?.results,
        metaData: {
          totalItemCount: response?.metaData?.resultsCount
        }
      })
    }),

    getEntitiesLegacy: builder.query<
      SearchEntityResponseType,
      SearchEntityPayloadType
    >({
      query: ({ searchValue }) =>
        `${apiEndPoints.getSearchEntities}?input=${searchValue}&limit=500000&offset=0`,
      transformResponse: (response: SearchMoveStoreResponseTypeLegacy) => ({
        stores: response?.results,
        metaData: {
          totalItemCount: response?.metaData?.resultsCount
        }
      })
    }),
    postMergeEnterprise: builder.mutation<void, MergeEnterprisePayload>({
      query: payload => ({
        url: `${apiEndPoints.mergeEnterprise}`,
        method: 'POST',
        body: payload
      })
    }),

    putDepartmentAssignUnassign: builder.mutation<
      void,
      UpdateDepartmentByStoreId
    >({
      query: body => ({
        url: apiEndPoints.putDepartmentAssignUnassign(
          body.storeId,
          body.updateType
        ),
        method: 'PUT',
        body: body.payload
      })
    }),
    postDMSInformationDepartmentDetails: builder.mutation<void, CreateDMSInfo>({
      query: body => ({
        url: apiEndPoints.postCreateDMSInfoDetails(
          body.dmsValue,
          body.ipaddress
        ),
        method: 'POST',
        body: body.payload
      })
    }),
    putDeleteAlternateDepartmentDetails: builder.mutation<
      void,
      DeleteIdentifiersBody
    >({
      query: body => ({
        url: apiEndPoints.putDeleteAlternateDetails(
          body.dmsValue,
          body.ipaddress,
          body.storeId
        ),
        method: 'Delete'
      })
    }),
    putStoreVisibility: builder.mutation<void, PutStoreVisibilityBody>({
      query: body => ({
        url: apiEndPoints.putStoreVisibility(
          body.storeId,
          body.visibility ? 'true' : 'false'
        ),
        method: 'PUT'
      })
    }),
    putCreateStoreDetails: builder.mutation<void, CreateStoreDetailsBody>({
      query: body => ({
        url: apiEndPoints.putCreateStoreDetails(body.storeId),
        method: 'PUT',
        body: body.payload
      })
    }),
    putCreateDepartmentDetails: builder.mutation<
      void,
      CreateDepartmentDetailsBody
    >({
      query: body => ({
        url: apiEndPoints.putCreateDepartmentDetails(body.departmentId),
        method: 'PUT',
        body: body.payload
      })
    }),
    putCreateEnterpriseDetails: builder.mutation<
      void,
      CreateEnterpriseDetailsBody
    >({
      query: body => ({
        url: apiEndPoints.putCreateEnterpriseDetails(body.enterpriseId),
        method: 'PUT',
        body: body.payload
      })
    }),
    putCreateAlternateDetails: builder.mutation<void, CreateIdentifiersBody>({
      query: body => ({
        url: apiEndPoints.putCreateAlternateDetails(
          body.storeId,
          body.identifierName
        ),
        method: 'PUT',
        body: body.payload
      })
    }),
    putCreateAlternateDepartmentDetails: builder.mutation<
      void,
      CreateIdentifiersBody
    >({
      query: body => ({
        url: apiEndPoints.putCreateAlternateDepartmentDetails(
          body.storeId,
          body.identifierName
        ),
        method: 'PUT',
        body: body.payload
      })
    }),
    putCreateEnterpriseAlternateDetails: builder.mutation<
      void,
      CreateIdentifiersBody
    >({
      query: body => ({
        url: apiEndPoints.putCreateEnterpriseAlternateDetails(
          body.storeId,
          body.identifierName
        ),
        method: 'PUT',
        body: body.payload
      })
    }),
    putDeleteAlternateDetails: builder.mutation<void, DeleteIdentifiersBody>({
      query: body => ({
        url: apiEndPoints.putDeleteAlternateDetails(
          body.dmsValue,
          body.ipaddress,
          body.storeId
        ),
        method: 'Delete'
      })
    }),
    postDMSInformationDetails: builder.mutation<void, CreateDMSInfo>({
      query: body => ({
        url: apiEndPoints.postCreateDMSInfoDetails(
          body.dmsValue,
          body.ipaddress
        ),
        method: 'POST',
        body: body.payload
      })
    }),

    postMoveStore: builder.mutation<void, MoveStore>({
      query: (body: MoveStore) => ({
        url: apiEndPoints.putMoveStore(body.storeId),
        method: 'PUT',
        body: body.payload
      })
    })
  })
});

export const {
  useSearchAllEnterpriseLegacyQuery,
  useGetEntitiesLegacyQuery,
  usePostMergeEnterpriseMutation,
  usePutDepartmentAssignUnassignMutation,
  usePutDeleteAlternateDepartmentDetailsMutation,
  usePostDMSInformationDepartmentDetailsMutation,
  usePutCreateAlternateDetailsMutation,
  usePutCreateAlternateDepartmentDetailsMutation,
  usePutCreateEnterpriseAlternateDetailsMutation,
  usePutCreateEnterpriseDetailsMutation,
  usePutCreateStoreDetailsMutation,
  usePutCreateDepartmentDetailsMutation,
  usePutDeleteAlternateDetailsMutation,
  usePutStoreVisibilityMutation,
  usePostDMSInformationDetailsMutation,
  usePostMoveStoreMutation
} = enterpriseInfoServiceAPI;
