import { FlexFooterBlock, ToggleButton } from 'cdk-radial';
import StyledFlexFooterContainer from './styled';

type FooeterProps = {
  isCollapsed: boolean;
  handleExpandCollapse: (isCollapsed: boolean) => void;
};

const Footer = ({
  isCollapsed,
  handleExpandCollapse
}: FooeterProps): JSX.Element => (
  <StyledFlexFooterContainer
    hasDivider
    marginAutoOff
    isCollapsed={isCollapsed}
    data-testid="footer-container"
  >
    <FlexFooterBlock isCollapsed={isCollapsed}>
      <ToggleButton
        collapsedToggleLabel="Collapse navigation"
        expandedToggleLabel="Expand navigation"
        iconClassName="toggle-button-icon-classname"
        isCollapsed={isCollapsed}
        onCollapse={() => handleExpandCollapse(true)}
        onExpand={() => handleExpandCollapse(false)}
        data-testid="toggle-button"
      />
    </FlexFooterBlock>
  </StyledFlexFooterContainer>
);

export default Footer;
