export const AMP_EVENT_TYPES = {
  SELECT_INTERFACE: 'Select Interface Experience',
  SELECT_LEGACY_SETTINGS: 'Select Legacy Settings',
  SELECT_IMPORT_USERS: 'Select Manage User Imports',
  SELECT_ALL_USERS: 'Select All Users',
  SELECT_MERGE_USERS: 'Select Merge User',
  SELECT_EMAIL_NOTIFICATIONS: 'Select Email Notifications',
  SELECT_ALL_ROLES: 'Select All Roles',
  SELECT_STORES: 'Select Stores',
  SELECT_GROUPS: 'Select Groups',
  SELECT_LOGS_AND_REPORTS: 'Select Logs And Reports',
  SELECT_SETTINGS: 'Select Settings',
  SELECT_CREATE_USER: 'Select New User',
  SELECT_COPY_USER: 'Select Copy User',
  SELECT_DELETE_USER: 'Select Delete User',
  SELECT_UPDATE_USER: 'Select Update User',
  IMPORT_USER_SOURCE: 'Import User Source',
  SELECT_CREATE_ROLE: 'Select Create Role',
  SELECT_UPDATE_ROLE: ' Select Save Role Changes',
  SELECT_ROLE_ASSIGNMENT: 'Role Assignment',
  SELECT_STORE_ASSIGNMENT: 'Store Assignment',
  SELECT_DMS_ACCESS_TAB: 'Select DMS Access Tab',
  SELECT_UPDATE_STORE: 'Select Save Store Changes',
  SELECT_UPDATE_DEPARTMENT: ' Select Save Department Changes',
  DOWNLOAD_LAST_LOGIN_REPORT: 'Download Last Login Report',
  DOWNLOAD_USER_ACTIVITY_REPORT: 'Download User Activity Report',
  DOWNLOAD_USER_LIST_REPORT: 'Download User List Report',
  DOWNLOAD_STORE_ASSIGNMENT_REPORT: 'Download Store Assignment Report',
  DOWNLOAD_ROLE_ASSIGN_REPORT: 'Download Role Assignment Report'
};

export const ENTERPRISE_ID = 'E000000';
