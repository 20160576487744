/* istanbul ignore file */
import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import { Provider } from 'react-redux';
import AppProvider from './providers/AppProvider';
import store from './redux/store';
import reportWebVitals from './reportWebVitals';

function renderApplication() {
  ReactDOM.render(
    <React.StrictMode>
      <Provider store={store}>
        <AppProvider />
      </Provider>
    </React.StrictMode>,
    document.getElementById('root')
  );
}

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
renderApplication();
