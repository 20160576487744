import React from 'react';

import { Button, BUTTON_BACKGROUND_APPEARANCES, BUTTON_ICON_COLORS, BUTTON_SIZES, BUTTON_VARIANTS } from 'lib/button';
import { ICON_SIZE } from 'lib/icons';
import PropTypes from 'prop-types';
import styled, { css } from 'styled-components';

export const StyledDialogFooterButton = styled(Button)`
  margin-left: ${({ theme }) => theme.size.spacing.medium.value};
  ${({ isLeftAligned }) =>
    isLeftAligned &&
    css`
      margin-left: 0;
      margin-right: auto;
    `};
`;

const DialogFooterButton = ({
  backgroundAppearance = BUTTON_BACKGROUND_APPEARANCES.LIGHT,
  customTagElement = '',
  hideText = false,
  href = undefined,
  icon = undefined,
  iconClassName = '',
  iconColor = BUTTON_ICON_COLORS.PRIMARY,
  iconSize = ICON_SIZE.DEFAULT,
  id,
  isDisabled = false,
  isLoading = false,
  loaderText = 'Loading...',
  onClick = () => {},
  onKeyDown = () => {},
  preserveClickableArea = false,
  size = BUTTON_SIZES.STANDARD,
  text,
  type = '',
  variant = BUTTON_VARIANTS.PRIMARY,
  ...other
}) => {
  const buttonVariant = variant === BUTTON_VARIANTS.PRIMARY ? variant : BUTTON_VARIANTS.TEXT;
  const handleClick = (event) => {
    onClick(event, id);
  };

  return (
    <StyledDialogFooterButton
      backgroundAppearance={backgroundAppearance}
      customTagElement={customTagElement}
      hideText={hideText}
      href={href}
      icon={icon}
      iconClassName={iconClassName}
      iconColor={iconColor}
      iconSize={iconSize}
      id={id}
      isDisabled={isDisabled}
      isLoading={isLoading}
      key={text}
      onClick={handleClick}
      onKeyDown={onKeyDown}
      preserveClickableArea={preserveClickableArea}
      size={size}
      text={text}
      type={type}
      variant={buttonVariant}
      {...other}
    />
  );
};

DialogFooterButton.propTypes = {
  /** @ignore */
  backgroundAppearance: PropTypes.oneOf(Object.values(BUTTON_BACKGROUND_APPEARANCES)),
  /** Replaces default tag: button or anchor with new value */
  customTagElement: PropTypes.string,
  /** If true, will visually hide text */
  hideText: PropTypes.bool,
  /** When URL is provided, element changes from button to hyperlink <a> */
  href: PropTypes.string,
  /** If not loading, will render specified icon before text */
  icon: PropTypes.node,
  /** Adds new class for icon element */
  iconClassName: PropTypes.string,
  /** Will change icon color */
  iconColor: PropTypes.oneOf(Object.values(BUTTON_ICON_COLORS)),
  /** Will set size of the icon */
  iconSize: PropTypes.oneOf(Object.values(ICON_SIZE)),
  /** Id of component */
  id: PropTypes.string.isRequired,
  /** Disallows user to interact with the button and adjusts appearance */
  isDisabled: PropTypes.bool,
  /** If true, will display loader and adjust width to better accommodate spinner */
  isLoading: PropTypes.bool,
  /** Loader text which will be read for screen reader users */
  loaderText: PropTypes.node,
  /** Callback that is called on click */
  onClick: PropTypes.func,
  /** Callback that is called on key down */
  onKeyDown: PropTypes.func,
  /** If true, will add vertical margins to the component */
  preserveClickableArea: PropTypes.bool,
  /** Changes button height */
  size: PropTypes.oneOf(Object.values(BUTTON_SIZES)),
  /** Will display text inside button */
  text: PropTypes.node.isRequired,
  /** Default html button 'type' attribute values when button component is used */
  type: PropTypes.string,
  /** Changes button style depending on variant. Default 'primary' */
  variant: PropTypes.oneOf(Object.values(BUTTON_VARIANTS)),
};

export { DialogFooterButton };
