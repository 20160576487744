import { defineMessages } from 'react-intl';

export const messages = defineMessages({
  stepFourScheduleTitle: {
    id: 'NEWUSERIMPORT.STEP4.TITLE',
    defaultMessage: 'Schedule Import'
  },
  stepFourSelectDate: {
    id: 'NEWUSERIMPORT.STEP4.SUBTITLE',
    defaultMessage:
      'Select a date and time when you want to complete the import process'
  }
});
