import UsserDetailsType, {
  SectionType,
  FieldNameType,
  ComponentTypes
} from './types';

const userDetailsObject: UsserDetailsType = {
  [SectionType.basicDetails]: {
    [FieldNameType.firstName]: {
      uid: FieldNameType.firstName,
      isRequired: true,
      errorMessage: '',
      hasError: false,
      value: ''
    },
    [FieldNameType.middleName]: {
      uid: FieldNameType.middleName,
      isRequired: false,
      errorMessage: '',
      hasError: false,
      value: ''
    },
    [FieldNameType.lastName]: {
      uid: FieldNameType.lastName,
      isRequired: true,
      errorMessage: '',
      hasError: false,
      value: ''
    },
    [FieldNameType.suffix]: {
      uid: FieldNameType.suffix,
      isRequired: false,
      errorMessage: '',
      hasError: false,
      options: [
        { label: 'Sr', value: 'Sr' },
        { label: 'Jr', value: 'Jr' },
        { label: 'I', value: 'I' },
        { label: 'II', value: 'II' },
        { label: 'III', value: 'III' }
      ],
      value: { label: '', value: '' }
    },
    [FieldNameType.nickName]: {
      uid: FieldNameType.nickName,
      isRequired: false,
      errorMessage: '',
      hasError: false,
      value: ''
    },
    [FieldNameType.locale]: {
      uid: FieldNameType.locale,
      isRequired: false,
      errorMessage: '',
      hasError: false,
      options: [
        { label: 'English (United States)', value: 'en_US' },
        { label: 'English (Canada)', value: 'en_CA' },
        { label: 'English (French)', value: 'fr_CA' }
      ],
      value: { label: '', value: '' }
    },
    [FieldNameType.externallyFederated]: {
      uid: FieldNameType.externallyFederated,
      isRequired: false,
      errorMessage: '',
      hasError: false,
      value: false
    },
    [FieldNameType.uniquePrincipalName]: {
      uid: FieldNameType.uniquePrincipalName,
      isRequired: false,
      errorMessage: '',
      hasError: false,
      value: ''
    }
  },
  [SectionType.identificationDetails]: {
    [FieldNameType.employeeId]: {
      uid: FieldNameType.employeeId,
      isRequired: false,
      errorMessage: '',
      hasError: false,
      value: ''
    },
    [FieldNameType.jobTitle]: {
      uid: FieldNameType.jobTitle,
      isRequired: false,
      errorMessage: '',
      hasError: false,
      options: [],
      value: { label: '', value: '' }
    }
  },
  [SectionType.passwordDetails]: {
    [FieldNameType.cdkSimpleId]: {
      uid: FieldNameType.cdkSimpleId,
      isRequired: true,
      type: ComponentTypes.email,
      errorMessage: '',
      hasError: false,
      value: ''
    },
    [FieldNameType.newPassword]: {
      uid: FieldNameType.newPassword,
      isRequired: true,
      type: ComponentTypes.password,
      errorMessage: '',
      hasError: false,
      value: ''
    },
    [FieldNameType.retypePassword]: {
      uid: FieldNameType.retypePassword,
      isRequired: true,
      type: ComponentTypes.password,
      errorMessage: '',
      hasError: false,
      value: ''
    }
  },
  [SectionType.contactDetails]: {
    [FieldNameType.primaryEmail]: {
      uid: FieldNameType.primaryEmail,
      type: ComponentTypes.email,
      isRequired: false,
      errorMessage: '',
      hasError: false,
      value: ''
    },
    [FieldNameType.primaryPhone]: {
      uid: FieldNameType.primaryPhone,
      type: ComponentTypes.phoneNumber,
      isRequired: false,
      errorMessage: '',
      hasError: false,
      value: ''
    },
    [FieldNameType.secondaryEmail]: {
      uid: FieldNameType.secondaryEmail,
      type: ComponentTypes.email,
      isRequired: false,
      errorMessage: '',
      hasError: false,
      value: ''
    },
    [FieldNameType.secondaryPhone]: {
      uid: FieldNameType.secondaryPhone,
      type: ComponentTypes.phoneNumber,
      isRequired: false,
      errorMessage: '',
      hasError: false,
      value: ''
    }
  },
  [SectionType.roles]: [],
  [SectionType.stores]: [],
  [SectionType.copyDMSProfile]: {
    [FieldNameType.isCopyDMSProfileStarted]: {
      uid: FieldNameType.isCopyDMSProfileStarted,
      isRequired: false,
      errorMessage: '',
      hasError: false,
      value: ''
    }
  }
};

export default userDetailsObject;
