import { useState } from 'react';
import {
  Column,
  Grid,
  Row,
  Radio,
  Button,
  BUTTON_VARIANTS,
  IconFileDownload,
} from 'cdk-radial';
import StyledTemplateSelectCard from './styled';
import TemplateInfoDialog from './TemplateInfoDialog';

type TemplateSelectCardProps = {
  uid:string,
  mainHeading: string,
  mainDescription: string,
  columnNamesDesc: string,
  TemplateRule: { label: string,
    content: {
      title: string,
      rules: string[]
    } },
  downloadSampleFile: { label: string, sampleLink: string },
  handleRadioChange: (event: React.ChangeEvent<HTMLInputElement>) => void,
  radioValue: string,
};

const TemplateSelectCard = ({
  uid, mainHeading, mainDescription, columnNamesDesc, TemplateRule, downloadSampleFile,
  handleRadioChange, radioValue,
}: TemplateSelectCardProps):JSX.Element => {
  const [isTemplateInfoOpen, setIsTemplateInfoOpen] = useState<boolean>(false);

  const handleTemplateInfoClick = () => {
    setIsTemplateInfoOpen(true);
  };

  return (
    <StyledTemplateSelectCard data-testid="template-select-card-container">
      <Grid>
        <Row>
          <Column className="radio-container-column" data-testid="radio-container-column">
            <Radio
              dataTestId="radio"
              id={`${uid}-radio`}
              label=""
              name={`${uid}-radio`}
              onChange={handleRadioChange}
              value={uid}
              hideLabel="true"
              checked={radioValue === uid}
            />
          </Column>
          <Column data-testid="content-container-column">
            <p className="main-heading">{mainHeading}</p>
            <p className="main-description">{mainDescription}</p>
            <p className="column-desc">{columnNamesDesc}</p>
            <div className="btn-container">
              <Button text={TemplateRule.label} variant={BUTTON_VARIANTS.TEXT} className="custom-btns" data-testid="templateinfo-btn" onClick={handleTemplateInfoClick} />
              <a href={downloadSampleFile.sampleLink} download className="custom-anchor">
                <Button
                  text={downloadSampleFile.label}
                  icon={<IconFileDownload />}
                  variant={BUTTON_VARIANTS.TEXT}
                  className="custom-btns"
                />
              </a>
            </div>
          </Column>
        </Row>
      </Grid>
      <TemplateInfoDialog
        isModalOpen={isTemplateInfoOpen}
        closeModal={setIsTemplateInfoOpen}
        content={TemplateRule.content}
      />
    </StyledTemplateSelectCard>
  );
};

export default TemplateSelectCard;
