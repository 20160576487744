import React from 'react';
import PropTypes from 'prop-types';

import {
  CustomIndicator,
  DefaultIndicator,
  InformationIndicator,
  NegativeIndicator,
  PositiveIndicator,
  WarningIndicator,
} from '../blocks';
import { STATUS_INDICATOR_VARIANTS } from '../constants';

const StatusIndicator = React.forwardRef(
  (
    {
      ariaLabel = 'Status Indicator',
      backgroundColor = '',
      borderColor = '',
      enableStateStyles,
      icon = undefined,
      iconColor = '',
      isDisabled,
      isFullWidth = undefined,
      label,
      renderBeforeContent = undefined,
      showIcon = false,
      textColor = '',
      variant = STATUS_INDICATOR_VARIANTS.DEFAULT,
      ...other
    },
    ref
  ) => {
    const props = {
      ...{
        ariaLabel,
        backgroundColor,
        borderColor,
        enableStateStyles,
        icon,
        iconColor,
        isDisabled,
        isFullWidth,
        label,
        renderBeforeContent,
        showIcon,
        textColor,
        variant,
      },
    };
    switch (variant) {
      case STATUS_INDICATOR_VARIANTS.INFORMATION:
        return <InformationIndicator {...props} {...other} ref={ref} />;
      case STATUS_INDICATOR_VARIANTS.NEGATIVE:
        return <NegativeIndicator {...props} {...other} ref={ref} />;
      case STATUS_INDICATOR_VARIANTS.POSITIVE:
        return <PositiveIndicator {...props} {...other} ref={ref} />;
      case STATUS_INDICATOR_VARIANTS.WARNING:
        return <WarningIndicator {...props} {...other} ref={ref} />;
      case STATUS_INDICATOR_VARIANTS.CUSTOM:
        return <CustomIndicator {...props} {...other} />;
      default:
        return <DefaultIndicator {...props} {...other} ref={ref} />;
    }
  }
);

StatusIndicator.propTypes = {
  /** Informs screen reader users what actions they should take */
  ariaLabel: PropTypes.node,
  /** Adds custom background color to status indicator */
  backgroundColor: PropTypes.string,
  /** Adds custom border color to status indicator */
  borderColor: PropTypes.string,
  /** enables hover, focus, active, disabled states on status indicator */
  enableStateStyles: PropTypes.bool,
  /** Shows icon inside the indicator. Use icon component from the library */
  icon: PropTypes.node,
  /** Changes icon fill color inside the indicator */
  iconColor: PropTypes.string,
  /** Changes status indicator to disabled state */
  isDisabled: PropTypes.bool,
  /** If true, status indicator takes full width of container */
  isFullWidth: PropTypes.bool,
  /** Status indicator label */
  label: PropTypes.node.isRequired,
  /** Renders custom content before main content group */
  renderBeforeContent: PropTypes.func,
  /** Shows icon inside the indicator */
  showIcon: PropTypes.bool,
  /** Adds custom label text color to status indicator */
  textColor: PropTypes.string,
  /** Changes status indicator style depending on variant. */
  variant: PropTypes.oneOf(Object.values(STATUS_INDICATOR_VARIANTS)),
};

export { StatusIndicator };
