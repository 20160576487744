import React from 'react';
import PropTypes from 'prop-types';
import styled, { css } from 'styled-components';

import { truncateText, visuallyHidden } from './../../core';

const StyledButtonText = styled.span.withConfig({
  shouldForwardProp: (prop) => !['isHidden'].includes(prop),
})`
  ${truncateText}
  color: inherit;
  display: inline-block;
  pointer-events: none;
  z-index: ${({ theme }) => theme.zIndex.buttonContent.value};

  ${({ isHidden }) =>
    isHidden &&
    css`
      ${visuallyHidden}

      && {
        position: absolute;
      }
    `}
`;

const ButtonText = ({ isHidden = false, ...props }) => <StyledButtonText isHidden={isHidden} {...props} />;

ButtonText.propTypes = {
  /** If true, visually hides text */
  isHidden: PropTypes.bool,
};

export { ButtonText };
