/* eslint-disable @typescript-eslint/restrict-template-expressions */
/* eslint-disable @typescript-eslint/no-unsafe-call */
import { ChangeEvent, useEffect, useRef, useState } from 'react';
import {
  Autocomplete,
  IconArrowDropDown,
  IconArrowDropUp,
  Input,
  KEY_VALUES
} from 'cdk-radial';
import moment from 'moment';
import { StyledMenuListItemAutoComplete } from 'pages/MoveStores/Components/styled';
import { AutocompleteProps, Option } from './types';

export const AutocompleteDropdown = ({
  options: defaultOptions,
  inputProps,
  autoCompleteProps,
  value,
  enableCustomValidation,
  errorMessage,
  hasError,
  isRequired,
  autoCompleteWidth = 224,
  isAutoComplete,
  selectedDate,
  onChange = () => {},
  onInputClear = () => {}
}: AutocompleteProps): JSX.Element => {
  const targetRef = useRef<HTMLElement>(null);
  const dimensionRef = useRef<HTMLElement>(null);

  const [optionsList, setOptionsList] = useState(defaultOptions);

  const [isOpen, setIsOpen] = useState(false);
  const [selectedOption, setSelectedOption] = useState(value);
  const [inputValue, setInputValue] = useState(value?.label || '');
  const { BACKSPACE, ENTER, SPACE, TAB } = KEY_VALUES;
  const id = `AutocompleteDropdown`;

  useEffect(() => {
    setOptionsList(defaultOptions);
  }, [defaultOptions]);

  const resetOptions = () => {
    setInputValue('');
    setSelectedOption({
      label: '',
      value: '',
      alias: ''
    });
    setOptionsList(defaultOptions);
    onInputClear();
  };

  useEffect(() => {
    if (
      selectedOption &&
      selectedOption.label === 'Now' &&
      selectedDate &&
      selectedDate.format('MM/DD/yy') !== moment().format('MM/DD/yy')
    )
      resetOptions();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedDate]);

  const handleOpen = () => {
    setIsOpen(true);
  };

  const handleClose = () => {
    setIsOpen(false);
  };

  const handleSelect = (option: Option) => {
    setSelectedOption(option);
    setInputValue(option.label);
    setOptionsList(defaultOptions);
    handleClose();
    onChange(option);

    targetRef?.current?.focus();
  };

  const findOptions = (event: ChangeEvent<HTMLInputElement>) => {
    const inputText = event.target.value.toLowerCase();
    setInputValue(inputText);
    setIsOpen(true);
    const textLength = inputText.length;
    const results = defaultOptions.filter(
      opt => inputText === opt.label.substring(0, textLength).toLowerCase()
    );
    if (results.length > 0) {
      setOptionsList(results);
    } else if (results.length === 0) {
      setOptionsList(defaultOptions);
    } else {
      setOptionsList([
        {
          label: 'No Results',
          value: 'No Results',
          alias: 'No Results',
          disabled: true
        }
      ]);
    }
  };

  const handleClear = (event: React.MouseEvent<HTMLButtonElement>) => {
    event.preventDefault();
    resetOptions();
  };

  const handleMenu = () => {
    setIsOpen(prevIsOpen => !prevIsOpen);
  };

  const handleKeyDown = (e: React.KeyboardEvent<HTMLElement>) => {
    if (e.key === ENTER) {
      if (
        inputValue.length > 0 &&
        optionsList.length > 0 &&
        !optionsList[0].disabled
      ) {
        setSelectedOption(optionsList[0]);
        setInputValue(optionsList[0].label);
        onChange(optionsList[0]);

        handleMenu();
      }
    }
    if (e.key === SPACE) {
      handleMenu();
    }
    if (e.key === BACKSPACE && selectedOption?.label !== '') {
      resetOptions();
    }
    if (e.key === TAB && selectedOption?.label === '') {
      setInputValue('');
      setOptionsList(defaultOptions);
    }
  };

  return (
    <>
      <div
        style={{ width: `${autoCompleteWidth}px` }}
        data-testid="autocomplete-menu"
      >
        <Input
          autoComplete="off"
          dataTestId="autocomplete-menu-input"
          hasClearButton={selectedOption?.label !== ''}
          helperText="Helper text"
          icon={isOpen ? <IconArrowDropUp /> : <IconArrowDropDown />}
          id={id}
          labelRef={dimensionRef}
          name="autocomplete-dropdown"
          onChange={findOptions}
          onClick={handleMenu}
          onInputClear={handleClear}
          onKeyDown={handleKeyDown}
          placeholder="Search"
          ref={targetRef}
          value={inputValue}
          enableCustomValidation={enableCustomValidation}
          hasError={hasError}
          errorMessage={errorMessage}
          isRequired={isRequired}
          // eslint-disable-next-line react/jsx-props-no-spreading
          {...inputProps}
        />
      </div>
      <Autocomplete
        dataTestId="autocomplete-menu"
        dimensionRef={dimensionRef}
        labelRef={targetRef}
        style={{
          zIndex: '100',
          width: dimensionRef?.current?.offsetWidth,
          maxWidth: dimensionRef?.current?.offsetWidth
        }}
        onClose={handleClose}
        onChange={findOptions}
        onOpen={handleOpen}
        isOpen={isOpen}
        onUnselect={selectedOption?.label === ''}
        // eslint-disable-next-line react/jsx-props-no-spreading
        {...autoCompleteProps}
        isAuto={isAutoComplete}
      >
        {optionsList.map((optionRow, ind) => (
          <StyledMenuListItemAutoComplete
            dataTestId={`autocomplete-menu-list-item-${ind}`}
            key={`${id}-${JSON.stringify(optionRow)}`}
            isSelected={selectedOption?.label === optionRow.label}
            onClick={() => handleSelect(optionRow)}
            isDisabled={optionRow.disabled}
            style={{
              zIndex: '100',
              width: dimensionRef?.current?.offsetWidth,
              maxWidth: dimensionRef?.current?.offsetWidth
            }}
          >
            {optionRow.label}
          </StyledMenuListItemAutoComplete>
        ))}
      </Autocomplete>
    </>
  );
};
