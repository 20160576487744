import {
  IconEnFlag,
  IconFrCaFlag,
  Menu,
  MenuListItem,
  MenuSwitcherButton,
  MENU_PLACEMENT
} from 'cdk-radial';
import { useRef, useState } from 'react';
import { useCookies } from 'react-cookie';
import { LocaleOption } from './types';
import LanguageSwitcherWrapper from './styled';

const LocaleToggle = (): JSX.Element => {
  const options = [
    {
      id: 'en',
      label: 'EN',
      value: 'en',
      icon: <IconEnFlag />
    },
    {
      id: 'fr',
      label: 'FR',
      value: 'fr',
      icon: <IconFrCaFlag />
    }
  ];

  const localeCookieDurationDays = 30;

  const [cookie, setCookie] = useCookies(['locale']);
  const currentLocale = cookie.locale ? cookie.locale : 'en';
  const currentLocaleOption = options.find(v => currentLocale === v.value);
  const [isOpen, setIsOpen] = useState(false);
  const [selectedView, setSelectedView] = useState(currentLocaleOption);
  const menuContainerRef = useRef();
  const targetRef = useRef<HTMLInputElement>(null);

  const handleClose = () => {
    setIsOpen(false);
    targetRef.current?.focus();
  };

  const handleOpen = () => {
    setIsOpen(true);
  };

  const setLocaleCookie = (val: string) => {
    const expirationDate = new Date();
    expirationDate.setDate(expirationDate.getDate() + localeCookieDurationDays);
    setCookie('locale', val, {
      path: '/',
      expires: expirationDate,
      domain: '.connectcdk.com'
    });
  };

  const handleSelect = (option: LocaleOption) => {
    setSelectedView(option);
    setLocaleCookie(option.value);
    setIsOpen(false);
    targetRef.current?.focus();
  };

  return (
    <LanguageSwitcherWrapper>
      <MenuSwitcherButton
        dataTestId="menu-switcher-button"
        id="language-menu-switcher-button"
        icon={selectedView?.icon}
        isOpen={isOpen}
        label={selectedView?.label}
        ref={targetRef}
      />
      <Menu
        dataTestId="language-select-menu-switcher"
        placement={MENU_PLACEMENT.RIGHT_START}
        labelRef={targetRef}
        onClose={handleClose}
        onOpen={handleOpen}
        isOpen={isOpen}
        ref={menuContainerRef}
      >
        {options.map((option, ind) => (
          <MenuListItem
            dataTestId={`menu-item-${ind}`}
            isSelected={selectedView?.value === option.value}
            key={option.id}
            onClick={() => handleSelect(option)}
            isOpen={isOpen}
            hideSelectedItemCheckmark={false}
          >
            {option.label}
          </MenuListItem>
        ))}
      </Menu>
    </LanguageSwitcherWrapper>
  );
};

export default LocaleToggle;
