import { useEffect, useState } from 'react';

export const getStaticPosition = (position, isOpen) => {
  const [showMenu, setShowMenu] = useState(false);

  useEffect(() => {
    if (isOpen) {
      setShowMenu(true);
    } else {
      setShowMenu(false);
    }
  }, [isOpen]);

  return [position, showMenu];
};
