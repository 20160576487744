import { UserApp } from './types';

const STANDALONE_APP_SUFFIX = '/a/';
const NON_STANDALONE_SUFFIX = '/#/';

const suffix = (app: UserApp) =>
  app.standaloneApp ? STANDALONE_APP_SUFFIX : NON_STANDALONE_SUFFIX;

const getLegacyURL = (app: UserApp) => {
  const url = process.env.REACT_APP_OLD_APP_URL || '';
  const domainUrl = url.split('/a/')[0];
  return `${domainUrl}${suffix(app)}${app.name}`;
};

export const getAppUrl = (app: UserApp): string => app.url || getLegacyURL(app);
