import { defineMessages } from 'react-intl';

export const messages = defineMessages({
  open: {
    id: 'GRID.STORES.COLUMNDEFINITIONS.STATUSMAPPING.OPEN',
    defaultMessage: 'Open'
  },
  preOpen: {
    id: 'GRID.STORES.COLUMNDEFINITIONS.STATUSMAPPING.PREOPEN',
    defaultMessage: 'Pre-Open'
  },
  sold: {
    id: 'GRID.STORES.COLUMNDEFINITIONS.STATUSMAPPING.SOLD',
    defaultMessage: 'Sold'
  },
  closed: {
    id: 'GRID.STORES.COLUMNDEFINITIONS.STATUSMAPPING.CLOSED',
    defaultMessage: 'Closed'
  },
  inactive: {
    id: 'GRID.STORES.COLUMNDEFINITIONS.STATUSMAPPING.INACTIVE',
    defaultMessage: 'Inactive'
  },
  duplicate: {
    id: 'GRID.STORES.COLUMNDEFINITIONS.STATUSMAPPING.DUPLICATE',
    defaultMessage: 'Duplicate'
  },
  outOfBusiness: {
    id: 'GRID.STORES.COLUMNDEFINITIONS.STATUSMAPPING.OUTOFBUSINESS',
    defaultMessage: 'Out of Business'
  },
  storeId: {
    id: 'GRID.STORES.COLUMN.DEFINITIONS.STOREID',
    defaultMessage: 'Store ID'
  },
  name: {
    id: 'GRID.STORES.COLUMN.DEFINITIONS.NAME',
    defaultMessage: 'Name'
  },
  physicalAddress: {
    id: 'GRID.STORES.COLUMN.DEFINITIONS.PHYSICALADDRESS',
    defaultMessage: 'Physical Address'
  },
  dmsAccounts: {
    id: 'GRID.STORES.COLUMN.DEFINITIONS.DMSACCOUNTS',
    defaultMessage: 'DMS Accounts'
  },
  cmfNumber: {
    id: 'GRID.STORES.COLUMN.DEFINITIONS.CMFNUMBER',
    defaultMessage: 'CMF Number'
  },
  storeType: {
    id: 'GRID.STORES.COLUMN.DEFINITIONS.STORE.TYPE',
    defaultMessage: 'Store Type'
  },
  storeStatus: {
    id: 'GRID.STORES.COLUMN.DEFINITIONS.STORESTATUS',
    defaultMessage: 'Store Status'
  },
  visibility: {
    id: 'GRID.STORES.COLUMN.DEFINITIONS.STOREVISIBILITY',
    defaultMessage: 'Visibility'
  },
  departmentId: {
    id: 'GRID.STORES.COLUMN.DEFINITIONS.DEPARTMENTID',
    defaultMessage: 'Department ID'
  },
  departmentName: {
    id: 'GRID.STORES.COLUMN.DEFINITIONS.DEPARTMENTNAME',
    defaultMessage: 'Department Name'
  },
  description: {
    id: 'GRID.STORES.COLUMN.DEFINITIONS.DESCRIPTION',
    defaultMessage: 'Description'
  },
  departmentStatus: {
    id: 'GRID.STORES.COLUMN.DEFINITIONS.DEPARTMENTSTATUS',
    defaultMessage: 'Department Status'
  },
  company: {
    id: 'GRID.STORES.COLUMN.DEFINITIONS.ASSIGNCOMPANY',
    defaultMessage: 'Company'
  },
  enterpriseAdminName: {
    id: 'GRID.STORES.COLUMN.DEFINITIONS.TITLE.ENTERPRISEADMINNAME',
    defaultMessage: 'Enterprise Admin Name'
  },
  emailId: {
    id: 'GRID.STORES.COLUMN.DEFINITIONS.TITLE.MAILID',
    defaultMessage: 'Mail ID'
  },
  contactNo: {
    id: 'GRID.STORES.COLUMN.DEFINITIONS.TITLE.CONTACTNO',
    defaultMessage: 'Contact No.'
  },
  archived: {
    id: 'FIXTURES.STORES.COLUMNDEFINITIONS.STATUSMAPPINGARCHIVED',
    defaultMessage: 'Archived'
  },
  yesVisibilityStatus: {
    id: 'USERMANAGEMENT.STORES.STORESCOLUMNDEFINITIONS.VISIBILITY.YES',
    defaultMessage: 'Yes'
  },
  noVisibilityStatus: {
    id: 'USERMANAGEMENT.STORES.STORESCOLUMNDEFINITIONS.VISIBILITY.NO',
    defaultMessage: 'No'
  }
});
