/* eslint-disable @typescript-eslint/no-unsafe-call */
import { createApi } from '@reduxjs/toolkit/query/react';
import baseQuery from './baseQuery';
import apiEndPoints from './apiEndPoints';
import {
  AllPackageResponseType,
  DMSConnectionInfo,
  EnablePackageInstanceBody,
  GetPackagesByStoreId,
  PackageDetail,
  RefreshIPAddress
} from './types';

export const packageEnablementApi = createApi({
  reducerPath: 'packageEnablement',
  baseQuery,
  endpoints: builder => ({
    getAllPackages: builder.query<AllPackageResponseType[], void>({
      query: () => `${apiEndPoints.baseCommonProductAPI}/packages`
    }),
    getPackageInstancesForOrgUnit: builder.query<
      any,
      { orgUnitId: string; cb: number }
    >({
      query: ({ orgUnitId, cb }) =>
        `${apiEndPoints.baseCommonProductAPI}/packageInstances/search.json?orgUnitId=${orgUnitId}&cb=${cb}`
    }),
    getFeatureStatuses: builder.query<any, { orgUnitId: string; cb: number }>({
      query: ({ orgUnitId, cb }) =>
        `${apiEndPoints.baseCommonProductAPI}/packageInstances/search.json?orgUnitId=${orgUnitId}&responseFields=packageEnablementSummary&cb=${cb}`
    }),
    enablePackageInstance: builder.mutation<void, EnablePackageInstanceBody>({
      query: body => ({
        url: `${apiEndPoints.baseCommonProductAPI}/packageInstances`,
        method: 'POST',
        body
      })
    }),
    disablePackageInstance: builder.mutation<void, string>({
      query: (packageInstanceId: string) => ({
        url: `${apiEndPoints.baseCommonProductAPI}/packageInstances/${packageInstanceId}`,
        method: 'DELETE'
      })
    }),
    cancelPackageInstance: builder.mutation<void, string>({
      query: (packageInstanceId: string) => ({
        url: `${apiEndPoints.baseCommonProductAPI}/packageInstances/${packageInstanceId}/enablement/cancel`,
        method: 'POST'
      })
    }),
    getCompleteViewDetails: builder.query<
      any,
      { orgUnitId: string; packageId: string }
    >({
      query: ({ orgUnitId, packageId }) =>
        `${apiEndPoints.baseCommonProductAPI}/packages/${packageId}?orgUnitId=${orgUnitId}&responseFields=packageEnablementDetails`,
      transformResponse: (response: PackageDetail) => {
        const totalFeaturesCount =
          response.package?.packageInstance?.featureCount;
        const packageSummary =
          response.package?.packageInstance?.packageEnablementSummary;
        let featuresStatus = '';
        let resultCompeletedCount = 0;
        if (packageSummary) {
          const resultCompeleted = packageSummary.find(
            (summary: any) =>
              summary.provisioningStatus.toUpperCase() === 'COMPLETED'
          );
          const inProgress = packageSummary.find(
            (summary: any) =>
              summary.provisioningStatus.toUpperCase() === 'IN PROGRESS'
          );
          const pending = packageSummary.find(
            (summary: any) =>
              summary.provisioningStatus.toUpperCase() === 'PENDING'
          );
          resultCompeletedCount = resultCompeleted?.count;
          if (resultCompeletedCount === totalFeaturesCount) {
            featuresStatus = 'COMPLETED';
          } else if (inProgress?.count > 0) {
            featuresStatus = 'INPROGRESS';
          } else if (pending?.count > 0) {
            featuresStatus = 'PENDING';
          } else {
            featuresStatus = 'ABORTED';
          }
        }
        const tree = {
          displayName: response.package?.displayName,
          id: 0,
          totalFeaturesCount,
          startTime: response.package?.packageInstance?.startDateTime,
          endTime: response.package?.packageInstance?.endDateTime,
          enabledBy: response.package?.packageInstance?.enabledBy,
          packageEnablementSummary:
            response.package?.packageInstance?.packageEnablementSummary,
          nested: response.package.packageProduct?.features?.length > 0,
          child: response.package?.packageProduct,
          featuresStatus
        };

        return tree;
      }
    }),
    getPackagesByStoreId: builder.query<GetPackagesByStoreId, string>({
      query: (storeId: string) =>
        `${apiEndPoints.getPackagesByStoreId}${storeId}`
    }),
    putRefreshIPAddressDetails: builder.mutation<void, RefreshIPAddress>({
      query: body => ({
        url: `${apiEndPoints.putRefreshIPAddressDetails}`,
        method: 'PUT',
        body
      })
    }),

    getDMSConnectionInfo: builder.query<DMSConnectionInfo, string>({
      query: (departmentId: string) =>
        `${apiEndPoints.getDMSConnectionInfo}${departmentId}`
    })
  })
});

export const {
  useGetAllPackagesQuery,
  useGetPackageInstancesForOrgUnitQuery,
  useGetFeatureStatusesQuery,
  useEnablePackageInstanceMutation,
  useDisablePackageInstanceMutation,
  useCancelPackageInstanceMutation,
  useGetCompleteViewDetailsQuery,
  usePutRefreshIPAddressDetailsMutation,
  useGetDMSConnectionInfoQuery,
  useGetPackagesByStoreIdQuery
} = packageEnablementApi;
