import React, { useRef, useState } from 'react';

import { BUTTON_ICON_COLORS } from 'lib/button';
import { IconMoreVert } from 'lib/icons';
import { Menu, MenuListItem } from 'lib/menu';
import PropTypes from 'prop-types';

import { TableHeaderButton } from './TableHeaderButton';

const TableOverflowButton = ({
  ariaLabel = 'More actions',
  contextMenuOptions,
  dataTestId = 'overflow-button',
  onClose = () => {},
  onOpen = () => {},
  onSelect = () => {},
  text = 'More actions',
}) => {
  const overflowButtonRef = useRef();

  const [isOpen, setIsOpen] = useState(false);
  const [selectedOption, setSelectedOption] = useState({});
  const menuId = 'table-overflow-button-menu';

  const handleClose = (option) => {
    if (option?.relatedTarget?.parentElement?.parentElement?.id === menuId) {
      // catches "blur" on selected menu items
      handleSelect(option);
    } else {
      setIsOpen(false);
      onClose();
    }
  };

  const handleOpen = () => {
    setIsOpen(true);
    onOpen();
  };

  const handleSelect = (option) => {
    setIsOpen(false);
    setSelectedOption(option);
    onSelect();
  };

  return (
    <>
      <TableHeaderButton
        dataTestId={dataTestId}
        icon={<IconMoreVert />}
        iconColor={BUTTON_ICON_COLORS.GRAY}
        onBlur={isOpen ? handleClose : () => {}}
        onClick={isOpen ? handleClose : handleOpen}
        ref={overflowButtonRef}
        text={text}
      />
      <Menu
        aria-label={ariaLabel}
        dataTestId={dataTestId}
        labelRef={overflowButtonRef}
        placement="bottom-end"
        id={menuId}
        isAuto={false}
        onClose={handleClose}
        onOpen={handleOpen}
      >
        {contextMenuOptions.map((option, ind) => (
          <MenuListItem
            dataTestId={`menu-item-${ind}`}
            graphicContent={option.icon}
            isDisabled={option.disabled}
            isSelected={selectedOption.label === option.label}
            key={ind}
            onClick={() => handleSelect(option)}
            hideSelectedItemCheckmark={false}
          >
            {option.label}
          </MenuListItem>
        ))}
      </Menu>
    </>
  );
};

TableOverflowButton.propTypes = {
  /** Informs screen reader users what actions they should take */
  ariaLabel: PropTypes.node,
  /** Id value used for testing */
  dataTestId: PropTypes.string,
  /** The Dropdown menu options. For correct data structure refer to Menu/MenuListItem component documentation */
  contextMenuOptions: PropTypes.arrayOf(
    PropTypes.shape({
      /** Renders icon before label */
      icon: PropTypes.node,
      /** Unique identifier for option */
      id: PropTypes.string,
      /** If true, disables option */
      isDisabled: PropTypes.bool,
      /** Option label */
      label: PropTypes.string,
    })
  ).isRequired,
  /** Callback that is called when overflow menu closes on icon click */
  onClose: PropTypes.func,
  /** Callback that is called when overflow menu is getting opened */
  onOpen: PropTypes.func,
  /** Callback that is called when an item is clicked in the overflow menu */
  onSelect: PropTypes.func,
  /** Sets text for table header overflow button */
  text: PropTypes.node,
};

export { TableOverflowButton };
