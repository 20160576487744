import React from 'react';
import { AVATAR_COLORS, AVATAR_SIZES, AVATAR_VARIANTS } from 'lib/avatar';
import { TOOLTIP_HORIZONTAL_ALIGNMENTS, TOOLTIP_VERTICAL_ALIGNMENTS } from 'lib/tooltip';
import { THEMES } from 'lib/utilities';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import { StandardList } from './StandardList';
import { LIST_SIZES, LIST_ITEM_DIVIDER_TYPES } from '../../constants';
import { ListGroup } from '../blocks';

const StyledGroupsWrapper = styled.div`
  box-sizing: border-box;
  width: 100%;
`;

const StandardGroupList = ({
  className = undefined,
  customListGroupTag = undefined,
  customListItemPrimaryTextTag = undefined,
  customListItemTag = undefined,
  data,
  dividerType = LIST_ITEM_DIVIDER_TYPES.FULL,
  hideGroupLabel = false,
  showDivider = false,
  size = LIST_SIZES.STANDARD,
  ...other
}) => (
  <StyledGroupsWrapper className={className} {...other}>
    {!!data && data.length > 0 && (
      <>
        {data.map((group, index) => (
          <ListGroup
            as={customListGroupTag}
            className={className ? `${className}-group` : undefined}
            groupLabel={group.groupLabel}
            hideGroupLabel={hideGroupLabel}
            id={group.id}
            key={group.id || index}
          >
            <StandardList
              className={className ? `${className}-group-list` : undefined}
              dataTestId={group.dataTestId}
              items={group.items}
              {...{ customListItemTag, customListItemPrimaryTextTag, dividerType, showDivider, size }}
            />
          </ListGroup>
        ))}
      </>
    )}
  </StyledGroupsWrapper>
);

StandardGroupList.propTypes = {
  /** Adds additional class for styling */
  className: PropTypes.string,
  /** Ability to supply a different element instead of the default one for ListGroup element */
  customListGroupTag: PropTypes.elementType,
  /** Ability to supply a different element instead of the default one for ListItemPrimaryText element */
  customListItemPrimaryTextTag: PropTypes.elementType,
  /** Ability to supply a different element instead of the default one for ListItem element */
  customListItemTag: PropTypes.elementType,
  /** List data which gets rendered. 'items' prop is StandardList 'data' prop */
  data: PropTypes.arrayOf(
    PropTypes.shape({
      /** Id value used for testing */
      dataTestId: PropTypes.string,
      /** Label of a single group */
      groupLabel: PropTypes.node.isRequired,
      /** Unique identifier of a single group */
      id: PropTypes.string.isRequired,
      /** Items array of a single group */
      items: PropTypes.arrayOf(
        PropTypes.shape({
          /** Renders avatar as a first element inside list item */
          avatarProps: PropTypes.shape({
            /** Accessibility measurement for verbal image description */
            alt: PropTypes.node,
            /** Sets background color of avatar component */
            color: PropTypes.oneOf(Object.values(AVATAR_COLORS)),
            /** Outputs icon inside the avatar. Use icon component from the library */
            icon: PropTypes.node,
            /** Username, that this avatar depicts. */
            label: PropTypes.node.isRequired,
            /** Avatar wrapper size. Icon size changes depending on wrapper size */
            size: PropTypes.oneOf(Object.values(AVATAR_SIZES)),
            /** Path to image file */
            src: PropTypes.string,
            /** Defines multiple sizes of the same image */
            srcset: PropTypes.string,
            /** Avatar variant. Values: [EMPTY, TEXT, ICON, IMAGE]. Default TEXT */
            variant: PropTypes.oneOf(Object.values(AVATAR_VARIANTS)),
          }),
          /** Custom anchor Element */
          customAnchorElement: PropTypes.func,
          /** Sets hyperlink for anchor tag */
          href: PropTypes.string,
          /** Renders icon as a first element inside list item */
          icon: PropTypes.node,
          /** Unique single list item identifier */
          id: PropTypes.string,
          /** Renders meta information after main text group */
          metadata: PropTypes.node,
          /** Callback function for item */
          onClick: PropTypes.func,
          /** Custom anchor Element */
          renderCustomAnchorElement: PropTypes.func,
          /** Secondary line of text */
          subtext: PropTypes.node,
          /** Main list item content */
          text: PropTypes.node.isRequired,
          /** Display a tooltip */
          tooltip: PropTypes.shape({
            /** Dynamically show/hide tooltip */
            showTooltip: PropTypes.bool,
            /** Dynamically change tooltip text */
            setTooltipText: PropTypes.string,
            /** Tooltip positioning props */
            positioningProps: PropTypes.shape({
              /** Aligns tooltip horizontally by wrapped component */
              horizontalAlignment: PropTypes.oneOf(Object.values(TOOLTIP_HORIZONTAL_ALIGNMENTS)),
              /** Distance between element and tooltip */
              marginAroundElement: PropTypes.number,
              /** Aligns tooltip vertically by wrapped component */
              verticalAlignment: PropTypes.oneOf(Object.values(TOOLTIP_VERTICAL_ALIGNMENTS)),
            }),
            /** Tooltip props */
            tooltipProps: PropTypes.shape({
              /** Unique identifier of the component */
              id: PropTypes.string.isRequired,
              /** Inline styles applied to main component wrapper */
              style: PropTypes.shape({}),
              /** Component content */
              text: PropTypes.node.isRequired,
              /** Changes style depending on theme */
              theme: PropTypes.oneOf(Object.values(THEMES)),
            }),
          }),
        })
      ).isRequired,
    })
  ).isRequired,
  /** Adjusts divider length */
  dividerType: PropTypes.oneOf(Object.values(LIST_ITEM_DIVIDER_TYPES)),
  /** If true, visually hides group label */
  hideGroupLabel: PropTypes.bool,
  /** If true, shows divider after each item, except last */
  showDivider: PropTypes.bool,
  /** Changes list item height */
  size: PropTypes.oneOf(Object.values(LIST_SIZES)),
};

export { StandardGroupList };
