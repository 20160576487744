/* eslint-disable @typescript-eslint/no-unsafe-call */
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import moment, { Moment } from 'moment';
import {
  DataRemovalType,
  RolesDataRemoval,
  TemplateRowFieldType,
  TimeType,
  UserImportState,
  SelectedCNumber
} from './types/userImportSliceTypes';

export const initialState: UserImportState = {
  selectedCNumber: null,
  selectedRows: null,
  selectedRowsStepTwo: null,
  autoMapRoleJobProfiles: [],
  contentHeader: '',
  isCDKEditAccess: -1,
  jobName: '',
  importDate: moment(),
  importTime: null,
  newUserValidations: {
    step_one: {
      isStepValid: null,
      error: '',
      selectedCNumber: {
        isValid: null,
        error: ''
      }
    },
    step_two: {
      isStepValid: null,
      error: ''
    },
    step_three: {
      isStepValid: null,
      error: '',
      errorRows: {}
    },
    step_four: {
      isStepValid: null,
      error: '',
      jobName: {
        isValid: null,
        error: ''
      },
      importDate: {
        isValid: null,
        error: ''
      },
      importTime: {
        isValid: null,
        error: ''
      }
    }
  },
  editJobValidations: {
    step_one: {
      isStepValid: null,
      error: ''
    },
    step_two: {
      isStepValid: null,
      error: ''
    },
    step_three: {
      isStepValid: null,
      error: '',
      errorRows: {}
    },
    step_four: {
      isStepValid: null,
      error: '',
      jobName: {
        isValid: null,
        error: ''
      },
      importDate: {
        isValid: null,
        error: ''
      },
      importTime: {
        isValid: null,
        error: ''
      }
    }
  }
};

export const userImportSlice = createSlice({
  name: 'userImportState',

  initialState,
  reducers: {
    setSelectedCNumber: (
      state,
      action: PayloadAction<SelectedCNumber | null>
    ) => {
      state.selectedCNumber = action.payload;
      state.selectedRows = null;
      state.selectedRowsStepTwo = null;
    },
    setSelectedRows: (state, action: PayloadAction<Record<string, any>[]>) => {
      state.selectedRows = action.payload;
      state.selectedRowsStepTwo = action.payload;
    },
    setAutoMapRoleJobProfiles: (
      state,
      action: PayloadAction<Record<string, any>[]>
    ) => {
      state.autoMapRoleJobProfiles = action.payload;
    },
    setContentHeader: (state, action: PayloadAction<string>) => {
      state.contentHeader = action.payload;
    },
    setEditAcessStatus: (state, action: PayloadAction<number>) => {
      state.isCDKEditAccess = action.payload;
    },
    setTemplateRolesAndStores: (
      state,
      {
        payload: { rowIndex, field, value, keyToAccess = '' }
      }: PayloadAction<TemplateRowFieldType>
    ) => {
      if (state.selectedRows) {
        const isValidArray =
          Array.isArray(state.selectedRows[rowIndex][field]) &&
          state.selectedRows[rowIndex][field].length;

        // eslint-disable-next-line
        const newItems = isValidArray
          ? [...state.selectedRows[rowIndex][field], ...value]
          : value;
        // eslint-disable-next-line
        const uniqueItems = newItems.filter(
          (v: Record<string, any>, i: number, a: Record<string, any>[]) =>
            a.findIndex(t => t[keyToAccess] === v[keyToAccess]) === i
        );
        state.selectedRows[rowIndex][field] = uniqueItems;
      }
    },
    setTemplatDataRows: (
      state,
      { payload: { filteredRows, filteredRowsStepTwo } }
    ) => {
      if (state.selectedRowsStepTwo) {
        state.selectedRowsStepTwo = filteredRowsStepTwo;
      }
      if (state.selectedRows) {
        state.selectedRows = filteredRows;
      }
    },
    setTemplatDataRowField: (
      state,
      {
        payload: { rowIndex, field, value }
      }: PayloadAction<TemplateRowFieldType>
    ) => {
      if (state.selectedRows) {
        state.selectedRows[rowIndex][field] = value;
      }
    },
    setTemplateFieldExpansion: (
      state,
      {
        payload: { rowIndex, field, value }
      }: PayloadAction<TemplateRowFieldType>
    ) => {
      if (state.selectedRows) {
        state.selectedRows[rowIndex][field] = [
          ...state.selectedRows[rowIndex][field]
        ];
        state.selectedRows[rowIndex][`${field}_expansion`] = value;
      }
    },
    setTemplateRowHeight: (
      state,
      { payload: { rowIndex, rowHeight, field } }
    ) => {
      if (state.selectedRows && state.selectedRows[rowIndex] !== undefined) {
        const newFieldHeights = state.selectedRows[rowIndex].fieldHeight
          ? { ...state.selectedRows[rowIndex].fieldHeight, [field]: rowHeight }
          : { [field]: rowHeight };
        state.selectedRows[rowIndex].fieldHeight = newFieldHeights;
        const heightsArray: number[] = Object.values(newFieldHeights);
        const calculatedRowHeight = Math.max(...heightsArray);
        state.selectedRows[rowIndex].rowHeight = calculatedRowHeight;
      }
    },
    handleDataRemoval: (
      state,
      {
        payload: { fieldIndex, rowIndex, field }
      }: PayloadAction<DataRemovalType>
    ) => {
      if (state.selectedRows) {
        const isValidArray = Array.isArray(state.selectedRows[rowIndex][field]);
        // eslint-disable-next-line
        const filteredArray = isValidArray
          ? state.selectedRows[rowIndex][field].filter(
              (item: string, i: number) => i !== fieldIndex
            )
          : [];
        state.selectedRows[rowIndex][field] = filteredArray;
      }
    },
    handleRemoveAll: (
      state,
      { payload: { rowIndex, field } }: PayloadAction<RolesDataRemoval>
    ) => {
      if (state.selectedRows) {
        state.selectedRows[rowIndex][field] = [];
      }
    },
    setJobName: (state, action: PayloadAction<string>) => {
      state.jobName = action.payload;
    },
    setImportDate: (state, action: PayloadAction<Moment | null>) => {
      state.importDate = action.payload;
    },
    setImportTime: (state, action: PayloadAction<TimeType>) => {
      state.importTime = action.payload;
    },
    resetUserInfo: state => {
      state.selectedRows = null;
      state.selectedRowsStepTwo = null;
      state.autoMapRoleJobProfiles = [];
      state.contentHeader = '';
      state.jobName = '';
      state.importDate = moment();
      state.importTime = null;
    },
    setValidationStatus: (
      state,
      action: PayloadAction<{
        currentStep: 'step_one' | 'step_two' | 'step_three' | 'step_four';
        validations: any;
      }>
    ) => {
      const { currentStep, validations } = action.payload;
      state.newUserValidations[currentStep] = validations;
    },
    setEditJobValidationStatus: (
      state,
      action: PayloadAction<{
        currentStep: 'step_one' | 'step_two' | 'step_three' | 'step_four';
        validations: any;
      }>
    ) => {
      const { currentStep, validations } = action.payload;
      state.editJobValidations[currentStep] = validations;
    }
  }
});

export const {
  setSelectedCNumber,
  setSelectedRows,
  setAutoMapRoleJobProfiles,
  setTemplatDataRowField,
  setTemplatDataRows,
  setContentHeader,
  setEditAcessStatus,
  setTemplateRowHeight,
  resetUserInfo,
  setTemplateFieldExpansion,
  handleDataRemoval,
  handleRemoveAll,
  setJobName,
  setImportDate,
  setImportTime,
  setTemplateRolesAndStores,
  setValidationStatus,
  setEditJobValidationStatus
} = userImportSlice.actions;

export default userImportSlice.reducer;
