import {
  Input,
  CELL_RENDERERS,
  CELL_CLASSES,
  EmailInput,
  INPUT_SIZES
} from 'cdk-radial';
import { useIntl } from 'react-intl';
import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { RootState } from 'redux/store';
import ExpandableChips from '../../../components/molecules/common/ExpandableChips/ExpandableChips';
import { messages } from './messages';

type CellValue = {
  value: string | Array<string>;
  colDef: {
    field: string;
  };
  rowIndex: number;
  context: {
    handleFieldChange: (rowIndex: number, field: string, value: string) => void;
  };
};

type CustomRendererDMSProps = {
  lastName: CellValue;
  firstName: CellValue;
  dmsUserId: CellValue;
  cNumber: CellValue;
  email: CellValue;
  federationId: CellValue;
  employeeId: CellValue;
  jobTitle: CellValue;
  roles: CellValue;
  stores: CellValue;
};

type ParamsTypeDMS = {
  data: CustomRendererDMSProps;
};

type CustomRendererNonDMSProps = {
  lastname: CellValue;
  firstname: CellValue;
  simpleid: CellValue;
  emailId: CellValue;
  roles: CellValue;
  stores: CellValue;
  altSystemId: CellValue;
  altUserId: CellValue;
  federationid: CellValue;
  employeeId: CellValue;
  jobTitle: CellValue;
  primaryPhone: CellValue;
};

type ParamsTypeNonDMS = {
  data: CustomRendererNonDMSProps;
};

type RolesType = {
  data: {
    roles: { value: Record<string, any>[] };
    roles_expansion: boolean;
  };
  colDef: {
    field: string;
  };
  rowIndex: number;
  context: {
    setRowFieldExpansion: (
      rowIndex: number,
      field: string,
      isCollapsed: boolean
    ) => void;
    setRowHeight: (rowIndex: number, divHeight: number, field: string) => void;
    handleFieldRemoval: (
      roleIndex: number,
      rowIndex: number,
      field: string
    ) => void;
  };
};

type StoresType = {
  data: {
    stores: { value: Record<string, any>[] };
    stores_expansion: boolean;
  };
  colDef: {
    field: string;
  };
  rowIndex: number;
  context: {
    setRowFieldExpansion: (
      rowIndex: number,
      field: string,
      isCollapsed: boolean
    ) => void;
    setRowHeight: (rowIndex: number, divHeight: number, field: string) => void;
    handleFieldRemoval: (
      roleIndex: number,
      rowIndex: number,
      field: string
    ) => void;
  };
};

export const CustomStoresRenderer = ({
  data: { stores, stores_expansion: isExpanded },
  colDef: { field },
  rowIndex,
  context: { setRowFieldExpansion, setRowHeight, handleFieldRemoval }
}: StoresType): JSX.Element => (
  <ExpandableChips
    data={stores?.value || []}
    keysArray={['id']}
    isExpanded={isExpanded}
    field={field}
    rowIndex={rowIndex}
    setRowFieldExpansion={setRowFieldExpansion}
    setRowHeight={setRowHeight}
    handleFieldRemoval={handleFieldRemoval}
  />
);

export const CustomRolesRenderer = ({
  data: { roles, roles_expansion: isExpanded },
  colDef: { field },
  rowIndex,
  context: { setRowFieldExpansion, setRowHeight, handleFieldRemoval }
}: RolesType): JSX.Element => (
  <ExpandableChips
    data={roles?.value || []}
    keysArray={['code']}
    isExpanded={isExpanded}
    field={field}
    rowIndex={rowIndex}
    setRowFieldExpansion={setRowFieldExpansion}
    setRowHeight={setRowHeight}
    handleFieldRemoval={handleFieldRemoval}
  />
);

const CustomInputRenderer = ({
  value,
  colDef: { field },
  rowIndex,
  context: { handleFieldChange }
}: CellValue): JSX.Element => {
  const [newValue, setNewalue] = useState(value);
  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setNewalue(event.target.value);
  };

  const {
    csvValidations: {
      step_three: { errorRows: stepThreeValidations }
    }
  } = useSelector((state: RootState) => state.slices.csvImportState);

  const { hasError = false, errorMessage = '' } =
    stepThreeValidations[rowIndex] && stepThreeValidations[rowIndex][field]
      ? stepThreeValidations[rowIndex][field]
      : { hasError: false, errorMessage: '' };

  return (
    <Input
      dataTestId="input"
      id="first-name-input"
      inputDivider="left"
      label={field}
      name={field}
      ref={(ref: any) => {
        if (!ref) return;
        ref.onclick = (e: React.MouseEvent<HTMLInputElement>) => {
          e.stopPropagation();
          e.preventDefault();
        };

        ref.onkeyup = (e: React.KeyboardEvent<HTMLInputElement>) => {
          e.stopPropagation();
        };

        ref.onkeydown = (e: React.KeyboardEvent<HTMLInputElement>) => {
          e.stopPropagation();
        };
      }}
      onChange={handleChange}
      enableCustomValidation
      hasError={hasError}
      isRequired
      errorMessage={errorMessage}
      onBlur={(event: React.ChangeEvent<HTMLInputElement>) =>
        handleFieldChange(rowIndex, field, event.target.value)
      }
      placeholder=""
      value={newValue}
    />
  );
};

const CustomEmailInputRenderer = ({
  value,
  colDef: { field },
  rowIndex,
  context: { handleFieldChange }
}: CellValue): JSX.Element => {
  const [newValue, setNewalue] = useState(value);
  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setNewalue(event.target.value);
  };
  const {
    csvValidations: {
      step_three: { errorRows: stepThreeValidations }
    }
  } = useSelector((state: RootState) => state.slices.csvImportState);

  const { hasError = false, errorMessage = '' } =
    stepThreeValidations[rowIndex] && stepThreeValidations[rowIndex][field]
      ? stepThreeValidations[rowIndex][field]
      : { hasError: false, errorMessage: '' };

  return (
    <EmailInput
      dataTestId="input"
      id="first-name-input"
      inputDivider="left"
      label=""
      name={field}
      size={INPUT_SIZES.SMALL}
      ref={(ref: any) => {
        if (!ref) return;
        ref.onclick = (e: React.MouseEvent<HTMLInputElement>) => {
          e.stopPropagation();
          e.preventDefault();
        };

        ref.onkeyup = (e: React.KeyboardEvent<HTMLInputElement>) => {
          e.stopPropagation();
        };

        ref.onkeydown = (e: React.KeyboardEvent<HTMLInputElement>) => {
          e.stopPropagation();
        };
      }}
      onChange={handleChange}
      enableCustomValidation
      hasError={hasError}
      isRequired
      errorMessage={errorMessage}
      onBlur={(event: React.ChangeEvent<HTMLInputElement>) =>
        handleFieldChange(rowIndex, field, event.target.value)
      }
      placeholder=""
      value={newValue}
    />
  );
};
const useColumns = (): Array<any> => {
  const intl = useIntl();
  const dmscolumns = [
    {
      cellClass: CELL_CLASSES.CHECKBOX,
      checkboxSelection: true,
      field: 'check',
      filter: false,
      headerCheckboxSelection: true,
      headerCheckboxSelectionFilteredOnly: true,
      headerClass: [CELL_CLASSES.CHECKBOX, CELL_CLASSES.SELECT_ALL],
      headerName: '',
      maxWidth: 55,
      minWidth: 55,
      resizable: false
    },
    {
      cellRendererFramework: CustomInputRenderer,
      cellRendererParams: (params: ParamsTypeDMS): CellValue =>
        params.data.lastName,
      valueGetter: (params: ParamsTypeDMS): string | string[] =>
        params.data.lastName?.value || '',
      field: 'lastName',
      headerName: intl.formatMessage(messages.lastName),
      minWidth: 150,
      sortable: true
    },
    {
      cellRendererFramework: CustomInputRenderer,
      cellRendererParams: (params: ParamsTypeDMS): CellValue =>
        params.data.firstName,
      valueGetter: (params: ParamsTypeDMS): string | string[] =>
        params.data.firstName?.value || '',
      field: 'firstName',
      headerName: intl.formatMessage(messages.firstName),
      minWidth: 150,
      sortable: true
    },
    {
      cellRenderer: CELL_RENDERERS.TEXT,
      cellRendererParams: (params: ParamsTypeDMS): string | string[] =>
        params.data.dmsUserId?.value,
      valueGetter: (params: ParamsTypeDMS): string | string[] =>
        params.data.dmsUserId?.value || '',
      field: 'dmsUserId',
      headerName: intl.formatMessage(messages.dmsUserId),
      minWidth: 150,
      sortable: true
    },
    {
      cellRenderer: CELL_RENDERERS.TEXT,
      cellRendererParams: (params: ParamsTypeDMS): CellValue =>
        params.data.cNumber,
      valueGetter: (params: ParamsTypeDMS): string | string[] =>
        params.data.cNumber?.value || '',
      field: 'cNumber',
      headerName: intl.formatMessage(messages.cNumber),
      minWidth: 150,
      sortable: true
    },
    {
      cellRendererFramework: CustomEmailInputRenderer,
      cellRendererParams: (params: ParamsTypeDMS): CellValue =>
        params.data.email,
      valueGetter: (params: ParamsTypeDMS): string | string[] =>
        params.data.email?.value || '',
      field: 'email',
      headerName: intl.formatMessage(messages.email),
      minWidth: 150,
      sortable: true
    },
    {
      cellRenderer: CELL_RENDERERS.TEXT,
      cellRendererParams: (params: ParamsTypeDMS): CellValue =>
        params.data.federationId,
      valueGetter: (params: ParamsTypeDMS): string | string[] =>
        params.data.federationId?.value || '',
      field: 'federationId',
      headerName: intl.formatMessage(messages.federationId),
      minWidth: 150,
      sortable: true
    },
    {
      cellRenderer: CELL_RENDERERS.TEXT,
      cellRendererParams: (params: ParamsTypeDMS): CellValue =>
        params.data.employeeId,
      valueGetter: (params: ParamsTypeDMS): string | string[] =>
        params.data.employeeId?.value || '',
      field: 'employeeId',
      headerName: intl.formatMessage(messages.employeeId),
      minWidth: 150,
      sortable: true
    },
    {
      cellRenderer: CELL_RENDERERS.TEXT,
      cellRendererParams: (params: ParamsTypeDMS): CellValue =>
        params.data.jobTitle,
      valueGetter: (params: ParamsTypeDMS): string | string[] =>
        params.data.jobTitle?.value || '',
      field: 'jobTitle',
      headerName: intl.formatMessage(messages.jobTitle),
      minWidth: 150,
      sortable: true
    },
    {
      cellRendererFramework: CustomRolesRenderer,
      cellRendererParams: (params: ParamsTypeDMS): any => params.data,
      valueGetter: (params: ParamsTypeDMS): string | string[] =>
        params.data.roles?.value || [],
      field: 'roles',
      headerName: intl.formatMessage(messages.roles),
      cellStyle: { 'white-space': 'normal' },
      minWidth: 350,
      autoHeight: true
    },
    {
      cellRendererFramework: CustomStoresRenderer,
      cellRendererParams: (params: ParamsTypeDMS): any => params.data,
      valueGetter: (params: ParamsTypeDMS): string | string[] =>
        params.data.stores?.value || [],
      field: 'stores',
      headerName: intl.formatMessage(messages.stores),
      cellStyle: { 'white-space': 'normal' },
      minWidth: 350,
      autoHeight: true
    }
  ];

  const nondmscolumns = [
    {
      cellClass: CELL_CLASSES.CHECKBOX,
      checkboxSelection: true,
      field: 'check',
      filter: false,
      headerCheckboxSelection: true,
      headerCheckboxSelectionFilteredOnly: true,
      headerClass: [CELL_CLASSES.CHECKBOX, CELL_CLASSES.SELECT_ALL],
      headerName: '',
      maxWidth: 55,
      minWidth: 55,
      resizable: false
    },
    {
      cellRenderer: CELL_RENDERERS.TEXT,
      cellRendererParams: (params: ParamsTypeNonDMS): CellValue =>
        params.data.lastname,
      valueGetter: (params: ParamsTypeNonDMS): string | string[] =>
        params.data.lastname?.value || '',
      field: 'lastname',
      headerName: intl.formatMessage(messages.lastName),
      minWidth: 150,
      sortable: true
    },
    {
      cellRenderer: CELL_RENDERERS.TEXT,
      cellRendererParams: (params: ParamsTypeNonDMS): CellValue =>
        params.data.firstname,
      valueGetter: (params: ParamsTypeNonDMS): string | string[] =>
        params.data.firstname?.value || '',
      field: 'firstname',
      headerName: intl.formatMessage(messages.firstName),
      minWidth: 150,
      sortable: true
    },
    {
      cellRenderer: CELL_RENDERERS.TEXT,
      cellRendererParams: (params: ParamsTypeNonDMS): CellValue =>
        params.data.simpleid,
      valueGetter: (params: ParamsTypeNonDMS): string | string[] =>
        params.data.simpleid?.value || '',
      field: 'simpleid',
      headerName: intl.formatMessage(messages.simpleID),
      minWidth: 150,
      cellStyle: { 'white-space': 'normal', 'line-height': '25px' },
      wrapText: true,
      sortable: true
    },
    {
      cellRenderer: CELL_RENDERERS.TEXT,
      cellRendererParams: (params: ParamsTypeNonDMS): CellValue =>
        params.data.emailId,
      valueGetter: (params: ParamsTypeNonDMS): string | string[] =>
        params.data.emailId?.value || '',
      field: 'emailId',
      headerName: intl.formatMessage(messages.emailId),
      minWidth: 150,
      cellStyle: { 'white-space': 'normal', 'line-height': '25px' },
      wrapText: true,
      sortable: true
    },
    {
      cellRendererFramework: CustomRolesRenderer,
      cellRendererParams: (params: ParamsTypeNonDMS): CellValue =>
        params.data.roles,
      valueGetter: (params: ParamsTypeNonDMS): string | string[] =>
        params.data.roles?.value || '',
      field: 'roles',
      headerName: intl.formatMessage(messages.roles),
      cellStyle: { 'white-space': 'normal' },
      minWidth: 350,
      autoHeight: true,
      sortable: true
    },
    {
      cellRendererFramework: CustomStoresRenderer,
      cellRendererParams: (params: ParamsTypeNonDMS): CellValue =>
        params.data.stores,
      valueGetter: (params: ParamsTypeNonDMS): string | string[] =>
        params.data.stores?.value || '',
      field: 'stores',
      headerName: intl.formatMessage(messages.stores),
      cellStyle: { 'white-space': 'normal' },
      minWidth: 350,
      autoHeight: true,
      sortable: true
    },
    {
      cellRenderer: CELL_RENDERERS.TEXT,
      cellRendererParams: (params: ParamsTypeNonDMS): CellValue =>
        params.data.altSystemId,
      valueGetter: (params: ParamsTypeNonDMS): string | string[] =>
        params.data.altSystemId?.value || '',
      field: 'altSystemId',
      headerName: intl.formatMessage(messages.altSystemId),
      minWidth: 150,
      sortable: true
    },
    {
      cellRenderer: CELL_RENDERERS.TEXT,
      cellRendererParams: (params: ParamsTypeNonDMS): CellValue =>
        params.data.altUserId,
      valueGetter: (params: ParamsTypeNonDMS): string | string[] =>
        params.data.altUserId?.value || '',
      field: 'altUserId',
      headerName: intl.formatMessage(messages.altUserID),
      minWidth: 150,
      sortable: true
    },
    {
      cellRenderer: CELL_RENDERERS.TEXT,
      cellRendererParams: (params: ParamsTypeNonDMS): CellValue =>
        params.data.federationid,
      valueGetter: (params: ParamsTypeNonDMS): string | string[] =>
        params.data.federationid?.value || '',
      field: 'federationid',
      headerName: intl.formatMessage(messages.federationId),
      minWidth: 150,
      cellStyle: { 'white-space': 'normal', 'line-height': '25px' },
      wrapText: true,
      sortable: true
    },
    {
      cellRenderer: CELL_RENDERERS.TEXT,
      cellRendererParams: (params: ParamsTypeNonDMS): CellValue =>
        params.data.employeeId,
      valueGetter: (params: ParamsTypeNonDMS): string | string[] =>
        params.data.employeeId?.value || '',
      field: 'employeeId',
      headerName: intl.formatMessage(messages.employeeId),
      minWidth: 150,
      sortable: true
    },
    {
      cellRenderer: CELL_RENDERERS.TEXT,
      cellRendererParams: (params: ParamsTypeNonDMS): CellValue =>
        params.data.jobTitle,
      valueGetter: (params: ParamsTypeNonDMS): string | string[] =>
        params.data.jobTitle?.value || '',
      field: 'jobTitle',
      headerName: intl.formatMessage(messages.jobTitle),
      minWidth: 150,
      cellStyle: { 'white-space': 'normal', 'line-height': '25px' },
      wrapText: true,
      sortable: true
    },
    {
      cellRenderer: CELL_RENDERERS.TEXT,
      cellRendererParams: (params: ParamsTypeNonDMS): CellValue =>
        params.data.primaryPhone,
      valueGetter: (params: ParamsTypeNonDMS): string | string[] =>
        params.data.primaryPhone?.value || '',
      field: 'primaryPhone',
      headerName: intl.formatMessage(messages.primaryPhone),
      minWidth: 150,
      sortable: true
    }
  ];
  return [dmscolumns, nondmscolumns];
};

export default useColumns;
