import { defineMessages } from 'react-intl';

export const messages = defineMessages({
  profileId: {
    id: 'GRID.AUTOMAPROLES.COLOUMN.HEADER.PROFILEID',
    defaultMessage: 'Profile ID'
  },
  dmsJobProfile: {
    id: 'GRID.AUTOMAPROLES.COLOUMN.HEADER.DMSJOBPROFILE',
    defaultMessage: 'DMS Job Profile'
  },
  mappedRole: {
    id: 'GRID.AUTOMAPROLES.COLOUMN.HEADER.MAPPEDROLE',
    defaultMessage: 'Mapped Role to CDK Admin'
  }
});
