import { lazy } from 'react';
import newUserImportStepperRoutes from './newUserImportStepperRoutes';
import stepperRoutes from './scheduleJobEditStepperRoutes';
import csvImportStepperRoutes from './csvImportStepperRoutes';
import routeIdentifiers from '../../../fixtures/routeIdentifiers';

const ImportListDetailsPage = lazy(
  () => import('../../pages/ImportListDetailsPage/ImportListDetailsPage')
);
const ManageUserImports = lazy(
  () => import('../../pages/ManageUserImports/ManageUserImports')
);
const ScheduledJobEditPage = lazy(
  () => import('../../pages/EditImportJob/EditImportJob')
);
const NewUserImport = lazy(
  () => import('../../pages/NewUserImport/NewUserImport')
);
const CsvUserImport = lazy(
  () => import('../../pages/UserImportCsv/UserImportCsv')
);
const UserDetails = lazy(
  () => import('../../pages/UserManagement/UserDetails/UserDetails')
);

const SettingsHome = lazy(
  () => import('../../pages/settingsHome/settingsHome')
);
const ReportsHome = lazy(() => import('../../pages/Reporting/LogsAndReports'));
const UserManagementReportsHome = lazy(
  () => import('pages/UserManagementReports')
);
const ReportsMfaActivity = lazy(
  () => import('../../pages/Reporting/MfaActivityTable')
);
const ManageStoreMFASettings = lazy(
  () => import('../../pages/manageStoreMFASettings/manageStoreMFASettings')
);
const ManageStoreDetails = lazy(
  () => import('../../pages/storeDetails/StoreDetails')
);

const EnterpriseGeneralDetails = lazy(
  () => import('../../pages/EnterpriseGeneral/EnterpriseGeneral')
);

const Users = lazy(() => import('../../pages/Users/Users'));
const Roles = lazy(() => import('../../pages/Roles/Roles'));
// const RoleDetails = lazy(() => import('../../pages/roleDetails/RoleDetails'));
const RoleDetails = lazy(() => import('pages/RoleDetails'));
const StoresPage = lazy(() => import('../../pages/Stores/Stores'));
const ManageEnterpriseMFASettings = lazy(
  () =>
    import(
      '../../pages/manageEnterpriseMFASettings/manageEnterpriseMFASettings'
    )
);
const ManageDMSConfiguration = lazy(
  () => import('../../../pages/ManageDMSConfiguration/ManageDMSConfiguration')
);
const NewUser = lazy(() => import('../../pages/NewUser/NewUser'));
const ManageUserMFASettings = lazy(
  () => import('../../pages/ManageUserMFASettings/ManageUserMFASettings')
);
const Groups = lazy(() => import('../../pages/Groups/GroupsDetails'));
const NewGroup = lazy(() => import('../../pages/Groups/NewGroup'));
const ViewGroup = lazy(() => import('../../pages/Groups/ViewGroup'));

const ManageCDKEditAccessSettings = lazy(
  () =>
    import(
      '../../pages/ManageCDKEditAccessSettings/manageCDKEditAccessSettings'
    )
);
const AllEnterprises = lazy(
  () => import('components/pages/AllEnterprises/AllEnterprisesSelector')
);

const MergeUser = lazy(() => import('../../pages/MergeUsers/UserSteps'));
const DMSUsers = lazy(
  () =>
    import(
      '../../organisms/userMangement/UserDetails/LinkedDMSUser/LinkUsersSteps/DmsSteps'
    )
);
const EmailNotification = lazy(
  () =>
    import(
      '../../pages/UserManagement/UserDetails/EmailNotification/EmailNotification'
    )
);
const CreateRole = lazy(() => import('../../organisms/NewRole/RoleSteps'));

const Home = lazy(() => import('../../pages/Home/Home'));
const DepartmentDetails = lazy(
  () => import('../../pages/Departments/DepartmentDetails/DepartmentDetails')
);
const legacyAdminContainer = lazy(
  () => import('../../pages/LegacyAdminContainer/LegacyAdminContainer')
);

const legacyStoreSettingContainer = lazy(
  () =>
    import(
      '../../pages/LegacyStoreSettingContainer/LegacyStoreSettingContainer'
    )
);

const ServiceUsers = lazy(() => import('pages/ServiceUsers'));
const ServiceUserDetails = lazy(() => import('pages/ServiceUserDetails'));
const NewServiceUser = lazy(() => import('pages/NewServiceUser'));
const AllEnterpriseUsers = lazy(
  () => import('pages/AllEnterpriseUsers/AllEnterpriseUsers')
);
const MoveStores = lazy(() => import('pages/MoveStores/MoveStores'));

const Tools = lazy(() => import('pages/Tools/Tools'));

const MergeEnterprise = lazy(
  () => import('pages/MergeEnterprise/MergeEnterprise')
);

const BulkUnassignUsers = lazy(
  () => import('pages/BulkUnassignUsers/BulkUnassignUsersJobs')
);

const ScheduleNewUserUnassingJob = lazy(
  () => import('pages/BulkUnassignUsers/components/ScheduleNewUserUnassingJob')
);

const BulkUnassignJobDetails = lazy(
  () => import('pages/BulkUnassignUsers/components/BulkUnassignJobDetails')
);

const LastLoginReport = lazy(
  () => import('pages/LastLoginReport/LastLoginReport')
);

const UserActivityReport = lazy(
  () => import('pages/UserActivityReport/UserActivityReport')
);

const UserRolesReport = lazy(
  () => import('pages/UserRolesReport/UserRolesReport')
);

const UserStoresReport = lazy(
  () => import('pages/UserStoresReport/UserStoresReport')
);

const RoleDetailsReport = lazy(
  () => import('pages/RoleDetailsReport/RoleDetailsReport')
);

const UserListReport = lazy(
  () => import('pages/UserListReport/UserListReport')
);

const StoreDepartmentDetailsReport = lazy(
  () => import('pages/StoreDeparmentDetailsReport/StoreDepartmentDetailsReport')
);

const EnterpriseAdminReport = lazy(
  () => import('pages/EnterpriseAdminReport/EnterpriseAdminReport')
);

const ProductConsumptionReport = lazy(
  () => import('pages/ProductConsumptionReport/ProductConsumptionReport')
);

type RoutesConfigProps = {
  path: string;
  component: React.FunctionComponent | React.LazyExoticComponent<React.FC<any>>;
  id: string;
  children?: any[] | undefined;
  exact?: boolean;
}[];

const routes: RoutesConfigProps = [
  {
    ...routeIdentifiers.HOME,
    component: Home,
    exact: true
  },
  {
    ...routeIdentifiers.ENTERPRISE_GENERAL,
    component: EnterpriseGeneralDetails,
    exact: true
  },
  {
    ...routeIdentifiers.NEW_USER,
    component: NewUser,
    exact: true
  },
  {
    ...routeIdentifiers.COPY_USER,
    component: NewUser,
    exact: true
  },

  {
    ...routeIdentifiers.MANAGE_USER_IMPORTS,
    component: ManageUserImports
  },
  {
    ...routeIdentifiers.MANAGE_USER_IMPORTS_DETAILS,
    component: ImportListDetailsPage,
    exact: true
  },
  {
    ...routeIdentifiers.NEW_USER_IMPORT,
    component: NewUserImport,
    children: newUserImportStepperRoutes
  },
  {
    ...routeIdentifiers.SCHEDULED_JOB_EDIT,
    component: ScheduledJobEditPage,
    children: stepperRoutes
  },
  {
    ...routeIdentifiers.CSV_USER_IMPORT,
    component: CsvUserImport,
    children: csvImportStepperRoutes
  },
  {
    ...routeIdentifiers.MANAGE_CDK_EDIT_ACCESS_SETTINGS,
    component: ManageCDKEditAccessSettings,
    exact: true
  },
  {
    ...routeIdentifiers.REPORTS_HOME,
    component: ReportsHome,
    exact: true
  },
  {
    ...routeIdentifiers.USER_MANAGEMENT_REPORTS_HOME,
    component: UserManagementReportsHome,
    exact: true
  },
  {
    ...routeIdentifiers.REPORTS_MFA,
    component: ReportsMfaActivity,
    exact: true
  },
  {
    ...routeIdentifiers.USER_DETAILS_EDIT,
    component: UserDetails
  },
  {
    ...routeIdentifiers.SETTINGS_HOME,
    component: SettingsHome,
    exact: true
  },
  {
    ...routeIdentifiers.MANAGE_STORE_MFA_SETTINGS,
    component: ManageStoreMFASettings,
    exact: true
  },

  {
    ...routeIdentifiers.USERS,
    component: Users,
    exact: true
  },
  {
    ...routeIdentifiers.ROLES,
    component: Roles,
    exact: true
  },
  {
    ...routeIdentifiers.ROLE_DETAILS,
    component: RoleDetails,
    exact: true
  },
  {
    ...routeIdentifiers.ROLE_ASSIGNED_USERS,
    component: RoleDetails,
    exact: true
  },
  {
    ...routeIdentifiers.STORES,
    component: StoresPage,
    exact: true
  },
  {
    ...routeIdentifiers.MANAGE_DEPARTMENT_DETAILS,
    component: DepartmentDetails,
    exact: true
  },
  {
    ...routeIdentifiers.MANAGE_ENTERPRISE_MFA_SETTINGS,
    component: ManageEnterpriseMFASettings,
    exact: true
  },
  {
    ...routeIdentifiers.MANAGE_DMS_CONFIGURATION,
    component: ManageDMSConfiguration,
    exact: true
  },
  {
    ...routeIdentifiers.MANAGE_USER_MFA_SETTINGS,
    component: ManageUserMFASettings,
    exact: true
  },
  {
    ...routeIdentifiers.MANAGE_STORE_DETAILS,
    component: ManageStoreDetails,
    exact: true
  },
  {
    ...routeIdentifiers.GROUPS,
    component: Groups,
    exact: true
  },
  {
    ...routeIdentifiers.NEWGROUP,
    component: NewGroup,
    exact: true
  },
  {
    ...routeIdentifiers.VIEWGROUP,
    component: ViewGroup,
    exact: true
  },
  {
    ...routeIdentifiers.CDK_HOME,
    component: AllEnterprises,
    exact: true
  },
  {
    ...routeIdentifiers.MERGE_USER,
    component: MergeUser,
    exact: true
  },
  {
    ...routeIdentifiers.DMS_USER_STEPS,
    component: DMSUsers,
    exact: true
  },
  {
    ...routeIdentifiers.EMAIL_NOTIFICATIONS,
    component: EmailNotification,
    exact: true
  },
  {
    ...routeIdentifiers.CREATE_ROLE,
    component: CreateRole,
    exact: true
  },
  {
    ...routeIdentifiers.LEGACY_SETTINGS,
    component: legacyAdminContainer,
    exact: true
  },
  {
    ...routeIdentifiers.LEGACY_STORE_SETTINGS,
    component: legacyStoreSettingContainer,
    exact: true
  },
  {
    ...routeIdentifiers.SERVICE_USERS,
    component: ServiceUsers,
    exact: true
  },
  {
    ...routeIdentifiers.SERVICE_USER_DETAILS_EDIT,
    component: ServiceUserDetails,
    exact: true
  },
  {
    ...routeIdentifiers.SERVICE_USER_NEW,
    component: NewServiceUser,
    exact: true
  },
  {
    ...routeIdentifiers.ALL_ENTERPRISE_USERS,
    component: AllEnterpriseUsers,
    exact: true
  },
  {
    ...routeIdentifiers.MOVE_STORES,
    component: MoveStores,
    exact: true
  },
  {
    ...routeIdentifiers.TOOLS,
    component: Tools,
    exact: true
  },
  {
    ...routeIdentifiers.MERGE_ENTERPRISE,
    component: MergeEnterprise,
    exact: true
  },
  {
    ...routeIdentifiers.BULK_UNASSIGN_USERS,
    component: BulkUnassignUsers,
    exact: true
  },
  {
    ...routeIdentifiers.NEW_BULK_UNASSIGN_USERS_JOB,
    component: ScheduleNewUserUnassingJob,
    exact: true
  },
  {
    ...routeIdentifiers.BULK_UNASSIGN_USER_JOB_DETAILS,
    component: BulkUnassignJobDetails,
    exact: true
  },
  {
    ...routeIdentifiers.LOOKER_REPORT,
    component: LastLoginReport,
    exact: true
  },
  {
    ...routeIdentifiers.LOOKER_USER_ACTIVITY_REPORT,
    component: UserActivityReport,
    exact: true
  },
  {
    ...routeIdentifiers.LOOKER_USER_ROLES_REPORT,
    component: UserRolesReport,
    exact: true
  },
  {
    ...routeIdentifiers.LOOKER_USER_STORES_REPORT,
    component: UserStoresReport,
    exact: true
  },
  {
    ...routeIdentifiers.LOOKER_ROLE_DETAILS_REPORT,
    component: RoleDetailsReport,
    exact: true
  },
  {
    ...routeIdentifiers.LOOKER_USER_LIST_REPORT,
    component: UserListReport,
    exact: true
  },
  {
    ...routeIdentifiers.LOOKER_STORE_DEPARTMENT_DETAILS_REPORT,
    component: StoreDepartmentDetailsReport,
    exact: true
  },
  {
    ...routeIdentifiers.LOOKER_ENTERPRISE_ADMIN_REPORT,
    component: EnterpriseAdminReport,
    exact: true
  },
  {
    ...routeIdentifiers.LOOKER_PRODUCT_CONSUMPTION_REPORT,
    component: ProductConsumptionReport,
    exact: true
  }
];

export default routes;
