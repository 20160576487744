import React, { useState } from 'react';

import { Avatar } from 'lib/avatar';
import { PrimaryButton } from 'lib/button';
import { ListItemPrimaryText, StandardGroupList } from 'lib/list';
import { preventDefaultBehavior } from 'lib/utilities';
import PropTypes from 'prop-types';
import styled, { css } from 'styled-components';
import {
  focusOutlineColors,
  getHexToRgb,
  getLineHeight,
  getRem,
  transitionAnimation,
  typographyBody1,
  typographyButton,
  truncateText,
} from './../../core';
import { BaseInputField, BaseInput, Input, InputLabel } from '../../input';

const StyledListItemPrimaryText = styled(ListItemPrimaryText)``;

const StyledHeaderContextMenuContent = styled.div`
  background-color: ${({ theme }) => theme.color.gray[800].value};
  border-radius: ${({ theme }) => theme.size.borderRadius.large.value};
  box-shadow: ${({ theme }) => theme.elevation[4].value};
  box-sizing: border-box;
  display: flex;
  flex: 1 1 100%;
  flex-direction: column;
  margin-left: ${({ theme }) => theme.size.spacing.medium.value};
  margin-top: ${getRem(6)};
  min-height: ${getRem(40)};
  width: ${getRem(254)};
`;

const StyledBody = styled.div`
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
`;

const StyledHeader = styled.div`
  align-items: center;
  box-sizing: border-box;
  display: flex;
  padding: ${({ theme }) =>
    `${theme.size.spacing.large.value} ${theme.size.spacing.large.value} ${theme.size.spacing.medium.value}`};
  width: 100%;
`;

const StyledAvatar = styled(Avatar)`
  flex-shrink: 0;
  margin-right: ${getRem(12)};
`;

const StyledTitle = styled.div`
  ${({ theme }) => typographyButton(theme)};
  ${truncateText};
  color: ${({ theme }) => theme.color.additional.light.value};
`;

const StyledInput = styled(Input)`
  ${({ isDisabled, isInvalid, isReadOnly, theme }) => {
    const inputFocusColor = `rgba(${getHexToRgb(theme.color.additional.light.value)}, 0.7)`;

    return css`
      ${BaseInput} {
        background-color: transparent;
        border: 1px solid rgba(${getHexToRgb(theme.color.additional.light.value)}, 0.3);
        border-radius: ${theme.size.borderRadius.large.value};
        letter-spacing: ${getRem(0.1)};
      }

      ${InputLabel} {
        color: ${theme.color.additional.light.value};
      }

      ${BaseInputField} {
        caret-color: ${inputFocusColor};
        color: ${inputFocusColor};
        &::placeholder {
          color: rgba(${getHexToRgb(theme.color.additional.light.value)}, 0.4);
        }
      }
      ${!isDisabled &&
      !isInvalid &&
      !isReadOnly &&
      css`
        ${BaseInput} {
          &:hover {
            border-color: ${inputFocusColor};
            box-shadow: 0 0 0 1px ${inputFocusColor};
          }
        }
      `}
    `;
  }}

  /* No other way to interact with input label */
    & ~ span {
    color: rgba(${({ theme }) => getHexToRgb(theme.color.additional.light.value)}, 0.7);
  }
`;

const StyledInputForm = styled.form`
  border-bottom: 1px solid rgba(${({ theme }) => getHexToRgb(theme.color.additional.dark.value)}, 0.85);
  box-sizing: border-box;
  padding: ${({ theme }) =>
    `${theme.size.spacing.medium.value} ${theme.size.spacing.large.value} ${theme.size.spacing.large.value}`};
`;

const StyledListGroup = styled.div`
  border-top-color: rgba(${({ theme }) => getHexToRgb(theme.color.additional.dark.value)}, 0.85);

  &:not(:first-of-type) {
    border-top-color: rgba(${({ theme }) => getHexToRgb(theme.color.additional.dark.value)}, 0.85);
    padding-top: 0;
  }
`;

const StyledListItem = styled.a`
  padding-left: ${({ theme }) => theme.size.spacing.large.value};
  padding-right: ${({ theme }) => theme.size.spacing.large.value};
  ${({ isDisabled, isSelectable, theme }) =>
    !isDisabled &&
    isSelectable &&
    css`
      &:hover {
        background-color: rgba(${getHexToRgb(theme.color.additional.dark.value)}, 0.85);
      }
      &&:focus {
        background-color: rgba(${getHexToRgb(theme.color.additional.dark.value)}, 0.85);
        box-shadow: inset 0 0 0 4px ${({ theme }) => focusOutlineColors.getPrimary(theme)};
        ${transitionAnimation('box-shadow')};
      }

      &:active {
        background-color: rgba(${getHexToRgb(theme.color.additional.dark.value)}, 0.95);
      }
    `}

  ${StyledListItemPrimaryText} {
    ${({ theme }) => typographyBody1(theme)};
    color: ${({ theme }) => theme.color.additional.light.value};
    letter-spacing: ${getRem(0.09)};
    line-height: ${({ theme }) => getLineHeight('24px', theme.font.size.body1.value)};
  }
`;

const StyledFooter = styled.div`
  box-sizing: border-box;
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-end;
  margin-top: auto;
  padding: ${({ theme }) =>
    `${theme.size.spacing.medium.value} ${theme.size.spacing.large.value} ${theme.size.spacing.large.value}`};
`;

const HeaderContextMenuContent = ({
  ariaLabel = 'Global navigation context menu',
  avatarProps,
  dataTestId = undefined,
  inputProps = undefined,
  lastElementAriaLabel = 'Sign out. Signs the user off the system. If you press tab again the menu will close.',
  listItems,
  onInputChange = () => {},
  onInputSubmit = () => {},
  onSignOut = () => {},
  signOutLabel = 'Sign out',
  usernameTitle,
  ...other
}) => {
  const [value, setValue] = useState('');

  const handleChange = (event) => {
    onInputChange(event);
    setValue(event.target.value);
  };

  const handleInputSubmit = (event) => {
    preventDefaultBehavior(event);
    onInputSubmit(event);
  };

  const handleFocus = (event) => {
    // eslint-disable-next-line react/prop-types
    if (inputProps?.onFocus) inputProps.onFocus(event);
  };

  const handleBlur = (event) => {
    // eslint-disable-next-line react/prop-types
    if (inputProps?.onBlur) inputProps.onBlur(event);
  };

  return (
    <StyledHeaderContextMenuContent aria-label={ariaLabel} data-testid={dataTestId} {...other}>
      <StyledBody>
        <StyledHeader>
          <StyledAvatar data-testid={dataTestId ? `${dataTestId}-avatar` : undefined} {...avatarProps} />
          <StyledTitle>{usernameTitle}</StyledTitle>
        </StyledHeader>
        {inputProps && (
          <StyledInputForm onSubmit={handleInputSubmit}>
            <StyledInput
              dataTestId={dataTestId ? `${dataTestId}-input` : undefined}
              onChange={handleChange}
              onFocus={handleFocus}
              onBlur={handleBlur}
              value={value}
              {...inputProps}
            />
          </StyledInputForm>
        )}
        <StandardGroupList
          customListGroupTag={StyledListGroup}
          customListItemPrimaryTextTag={StyledListItemPrimaryText}
          customListItemTag={StyledListItem}
          dataTestId={dataTestId}
          data={listItems}
          hideGroupLabel
        />
        <StyledFooter>
          <PrimaryButton
            aria-label={lastElementAriaLabel}
            dataTestId={dataTestId ? `${dataTestId}-sign-out` : undefined}
            text={signOutLabel}
            onClick={onSignOut}
          />
        </StyledFooter>
      </StyledBody>
    </StyledHeaderContextMenuContent>
  );
};

HeaderContextMenuContent.propTypes = {
  /** Informs screen reader users what actions they should take */
  ariaLabel: PropTypes.node,
  /** Object of properties, which is applied to avatar component */
  avatarProps: PropTypes.shape({ ...Avatar.propTypes }).isRequired,
  /** Id value used for testing */
  dataTestId: PropTypes.string,
  /** Object of properties, which is applied to input component */
  inputProps: PropTypes.shape({}),
  /** Informs screen reader users about last menu element */
  lastElementAriaLabel: PropTypes.node,
  /** Menu items for the context menu */
  listItems: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  /** Callback which is called on input value change */
  onInputChange: PropTypes.func,
  /** Callback which is called on input submit */
  onInputSubmit: PropTypes.func,
  /** Callback which is called on sign out button click */
  onSignOut: PropTypes.func,
  /** Sets label for Sign out button */
  signOutLabel: PropTypes.node,
  /** Sets label of signed in user */
  usernameTitle: PropTypes.node.isRequired,
};

export { HeaderContextMenuContent };
