import { css } from 'styled-components';

import { focusOutlinesStyles } from './../../../core';
import { getButtonContentElementsColor } from '../../shared-styles';

const primaryButtonLightBackgroundStates = {
  active: css`
    background-color: ${({ theme }) => theme.color.button.primary.background.active.value};
    ${({ theme }) => getButtonContentElementsColor(theme.color.button.primary.text.normal.value)};
  `,
  default: css`
    background-color: ${({ theme }) => theme.color.button.primary.background.enabled.value};
    ${({ theme }) => getButtonContentElementsColor(theme.color.button.primary.text.normal.value)};
  `,
  disabled: css`
    background-color: ${({ theme }) => theme.color.button.primary.background.disabled.value};
    ${({ theme }) => getButtonContentElementsColor(theme.color.button.primary.text.disabled.value)};
  `,
  focus: css`
    background-color: ${({ theme }) => theme.color.button.primary.background.hover.value};
    ${({ theme }) => getButtonContentElementsColor(theme.color.button.primary.text.normal.value)};
    ${focusOutlinesStyles.primary};
  `,
  hover: css`
    background-color: ${({ theme }) => theme.color.button.primary.background.hover.value};
    ${({ theme }) => getButtonContentElementsColor(theme.color.button.primary.text.normal.value)};
  `,
};

const primaryButtonPrimaryBackgroundStates = {
  active: css`
    background-color: ${({ theme }) => theme.color.primary[200].value};
    ${({ theme }) => getButtonContentElementsColor(theme.color.primary[700].value)}
  `,
  default: css`
    background-color: ${({ theme }) => theme.color.additional.light.value};
    ${({ theme }) => getButtonContentElementsColor(theme.color.primary[500].value)}
  `,
  disabled: css`
    background-color: ${({ theme }) => theme.color.state.primary.disabled.value};
  `,
  focus: css`
    background-color: ${({ theme }) => theme.color.primary[100].value};
    ${focusOutlinesStyles.light};
    ${({ theme }) => getButtonContentElementsColor(theme.color.primary[600].value)}
  `,
  hover: css`
    background-color: ${({ theme }) => theme.color.primary[100].value};
    ${({ theme }) => getButtonContentElementsColor(theme.color.primary[600].value)}
  `,
};

export const primaryButtonStateStyles = {
  default: primaryButtonLightBackgroundStates,
  defaultOnPrimaryBackground: primaryButtonPrimaryBackgroundStates,
};

export const getStateStyles = (isOnPrimaryBackground) => {
  return isOnPrimaryBackground ? primaryButtonStateStyles.defaultOnPrimaryBackground : primaryButtonStateStyles.default;
};
