import React from 'react';
import PropTypes from 'prop-types';
import styled, { css } from 'styled-components';

import { BREAKPOINTS, getRem, typographyBody1 } from './../../core';
import { RADIO_SIZES } from '../constants';

const StyledRadioLabel = styled.span`
  ${({ theme }) => typographyBody1(theme)}
  align-self: center;
  display: inline-flex;
  font-size: ${({ theme }) => theme.font.size.default.value};
  line-height: ${getRem('20px')};

  @media ${BREAKPOINTS.L} {
    font-size: ${({ size, theme }) => size !== RADIO_SIZES.LARGE && theme.font.size.body1.value};
    line-height: ${getRem('24px')};
  }

  ${({ size, theme }) =>
    size === RADIO_SIZES.LARGE &&
    css`
      font-size: ${theme.font.size.body2.value};
      line-height: ${getRem('20px')};
    `}
`;

const RadioLabel = ({ isLabelBefore = false, ...other }) => (
  <StyledRadioLabel isLabelBefore={isLabelBefore} {...other} />
);

RadioLabel.propTypes = {
  /** If true, will use specific spacing on left and right */
  isLabelBefore: PropTypes.bool,
};

export { RadioLabel };
