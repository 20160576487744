import ScheduleImportStepThree from '../../organisms/EditImportJob/StepThree/StepThree';
import ScheduledJobEditStepFour from '../../organisms/EditImportJob/StepFour/StepFour';
import routeIdentifiers from '../../../fixtures/routeIdentifiers';

const stepperRoutes = [
  {
    path: '',
    pathId: '',
    component: (): JSX.Element => <div />
  },
  {
    path: '',
    pathId: '',
    component: (): JSX.Element => <div />
  },
  {
    ...routeIdentifiers.SCHEDULED_JOB_EDIT_STEP_THREE,
    component: ScheduleImportStepThree
  },
  {
    ...routeIdentifiers.SCHEDULED_JOB_EDIT_STEP_FOUR,
    component: ScheduledJobEditStepFour
  }
];

export default stepperRoutes;
