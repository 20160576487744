/* eslint-disable @typescript-eslint/no-unsafe-call */
/* eslint-disable  @typescript-eslint/no-unsafe-return */

import { useIntl } from 'react-intl';
import { StatusIndicator, STATUS_INDICATOR_VARIANTS } from 'cdk-radial';
import { StatusInLower, TransformStatusProps } from './types';
import { messages } from './messages';

export const TransformStatus = ({
  status
}: TransformStatusProps): JSX.Element => {
  const intl = useIntl();
  const statusMappings = {
    open: {
      label: intl.formatMessage(messages.storeStatusOpen),
      variant: STATUS_INDICATOR_VARIANTS.POSITIVE
    },
    'pre-open': {
      label: intl.formatMessage(messages.storeStatusPreOpen),
      variant: STATUS_INDICATOR_VARIANTS.INFORMATION
    },
    sold: {
      label: intl.formatMessage(messages.storeStatusSold),
      variant: STATUS_INDICATOR_VARIANTS.DEFAULT
    },
    closed: {
      label: intl.formatMessage(messages.storeStatusClosed),
      variant: STATUS_INDICATOR_VARIANTS.NEGATIVE
    },
    inactive: {
      label: intl.formatMessage(messages.storeStatusInactive),
      variant: STATUS_INDICATOR_VARIANTS.WARNING
    },
    duplicate: {
      label: intl.formatMessage(messages.storeStatusDuplicate),
      variant: STATUS_INDICATOR_VARIANTS.WARNING
    },
    out_of_business: {
      label: intl.formatMessage(messages.storeStatusOutOfBusiness),
      variant: STATUS_INDICATOR_VARIANTS.WARNING
    },
    archived: {
      label: intl.formatMessage(messages.departmentStatusArchived),
      variant: STATUS_INDICATOR_VARIANTS.WARNING
    }
  };
  const statusToLower = status.toLocaleLowerCase() as StatusInLower;
  const { label, variant } = statusMappings[statusToLower];
  return <StatusIndicator label={label} variant={variant} showIcon />;
};
