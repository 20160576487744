import React from 'react';

import { NormalizedButton } from 'lib/button';
import { IconArrowDropDown } from 'lib/icons';
import PropTypes from 'prop-types';
import styled, { css } from 'styled-components';

import { ElementStateWrapper, getRem, typographyButton, visuallyHidden } from '../../core';
import { MENU_SWITCHER_SIZES } from '../constants';
import { menuSwitcherButtonStateStyles } from '../menuSwitcherStateStyles';

const menuSwitcherButtonSizes = {
  [MENU_SWITCHER_SIZES.LARGE]: getRem('40px'),
  [MENU_SWITCHER_SIZES.SMALL]: getRem('32px'),
  [MENU_SWITCHER_SIZES.STANDARD]: getRem('36px'),
};
const menuSwitcherVerticalPadding = {
  [MENU_SWITCHER_SIZES.LARGE]: getRem('8px'),
  [MENU_SWITCHER_SIZES.SMALL]: getRem('4px'),
  [MENU_SWITCHER_SIZES.STANDARD]: getRem('6px'),
};

const StyledMenuSwitcherButton = styled(NormalizedButton)`
  ${({ isOpen, size, theme }) => css`
    align-items: center;
    background-color: ${isOpen ? theme.color.gray[200].value : theme.color.additional.light.value};
    border-radius: ${theme.size.borderRadius.medium.value};
    cursor: pointer;
    display: flex;
    height: ${menuSwitcherButtonSizes[size]};
    padding: ${menuSwitcherVerticalPadding[size]};
    padding-left: ${theme.size.spacing.medium.value};
    padding-right: ${theme.size.spacing.small.value};

    &::after {
      &:hover {
        background-color: ${theme.color.gray[200].value};
        cursor: pointer;
      }
    }
  `}
`;

const StyledIcon = styled(({ icon, ...props }) => React.cloneElement(icon, props))`
  fill: ${({ theme }) => theme.color.text.light.secondary.value};
  height: ${getRem(24)};
  width: ${getRem(24)};
`;

const StyledLabel = styled.span`
  ${({ hideLabel, size, theme }) => css`
    ${hideLabel && visuallyHidden}
    ${typographyButton(theme)};
    ${size === MENU_SWITCHER_SIZES.LARGE &&
    css`
      font-size: ${getRem('16px')};
      line-height: 1;
    `};
    color: ${theme.color.text.light.secondary.value};
    padding: ${hideLabel ? 0 : `${getRem(2)} 0 ${getRem(2)} ${theme.size.spacing.small.value}`};
  `}
`;

const StyledIconCaret = styled(IconArrowDropDown).withConfig({
  shouldForwardProp: (prop) => !['isOpen'].includes(prop),
})`
  fill: ${({ theme }) => theme.color.text.light.secondary.value};
  ${({ isOpen }) =>
    isOpen &&
    css`
      transform: rotate(180deg);
    `};
`;

export const MenuSwitcherButton = React.forwardRef(
  (
    {
      dataTestId = undefined,
      hideIcon = false,
      hideLabel = false,
      icon = undefined,
      isOpen = false,
      label = undefined,
      size = MENU_SWITCHER_SIZES.STANDARD,
      ...other
    },
    ref
  ) => {
    return (
      <ElementStateWrapper stateStyles={menuSwitcherButtonStateStyles}>
        <StyledMenuSwitcherButton data-testid={dataTestId} {...{ isOpen, size }} {...other} ref={ref}>
          {!!icon && !hideIcon && <StyledIcon icon={icon} />}
          <StyledLabel {...{ hideLabel, size }}>{label}</StyledLabel>
          <StyledIconCaret isOpen={isOpen} />
        </StyledMenuSwitcherButton>
      </ElementStateWrapper>
    );
  }
);

MenuSwitcherButton.propTypes = {
  /** Id value used for testing */
  dataTestId: PropTypes.string,
  /** If true, hides icon from view switcher */
  hideIcon: PropTypes.bool,
  /** If true, visually hides label from view switcher */
  hideLabel: PropTypes.bool,
  /** Current icon of view switcher button */
  icon: PropTypes.node,
  /** If true, shows view switcher content */
  isOpen: PropTypes.bool,
  /** Label of pre-selected option */
  label: PropTypes.node,
  /** Changes view switcher container height */
  size: PropTypes.oneOf(Object.values(MENU_SWITCHER_SIZES)),
};
