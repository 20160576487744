/* eslint-disable indent */
// Running eslint fix causes the function flatChildren to break as it adds indentations where not needed

import React, {
  cloneElement,
  createRef,
  forwardRef,
  useCallback,
  useEffect,
  useLayoutEffect,
  useRef,
  useState,
} from 'react';
import {
  flatMap,
  getDynamicPosition,
  getPositionStyles,
  getStaticPosition,
  KEY_VALUES,
  removeObjectProperties,
} from 'lib/utilities';
import PropTypes from 'prop-types';
import { createPortal } from 'react-dom';
import styled from 'styled-components';
import { useWindowWidth } from '../../utilities';
import { MENU_PLACEMENT } from '../constants';
import { StyledMenuContainer } from '../elements';
import { useOutsideClickEventListener } from '../utilities';

const StyledItemContainer = styled.div`
  padding: ${({ theme }) => theme.size.spacing.small.value} 0;
`;

const getScrollableParent = (node) => {
  if (node === null || node === undefined) return null;
  else if (node.scrollHeight > node.clientHeight) return node;
  else return getScrollableParent(node.parentNode);
};

const Menu = forwardRef(
  (
    {
      children,
      className = '',
      dataTestId = '',
      dimensionRef = undefined,
      isAuto = true,
      isOpen = false,
      labelRef,
      multi = false,
      onClose = undefined,
      onOpen = undefined,
      placement = MENU_PLACEMENT.BOTTOM_START,
      style = {},
      triggerOffset = 4,
      ...other
    },
    ref
  ) => {
    const { ARROW_DOWN, ARROW_LEFT, ARROW_RIGHT, ARROW_UP, ENTER, ESCAPE, SPACE, TAB } = KEY_VALUES;

    const flatChildren = Array.isArray(children)
      ? flatMap(children, (item) => (Array.isArray(item) ? flatMap(item, (item) => item) : item))
      : [children];

    const focusItem = useRef(null);
    const menuRef = useRef(document.createElement('div'));
    const menuContainerRef = ref || useRef();
    const menuOpenStatus = useRef(null);

    const initialRender = useRef(true);
    const [keyBoardFocus, setKeyBoardFocus] = useState(0);
    const [openMenu, setOpenMenu] = useState(isOpen);
    const [animateMenu, setAnimateMenu] = useState(false);

    const isKeyboardFocused = keyBoardFocus !== 0;
    const [position, showMenu] = isAuto
      ? getDynamicPosition(placement, menuContainerRef, dimensionRef || labelRef, openMenu, triggerOffset)
      : getStaticPosition(placement, openMenu);

    const resetKeyBoardFocus = useCallback((event) => {
      if (event.pointerType === 'mouse') {
        setKeyBoardFocus(0);
      }
    });
    const scanWindow = useWindowWidth(5);
    const [targetNode, setTargetNode] = useState(null);
    const menuListRef = useRef(flatChildren.map(() => createRef()));

    // Set styles
    const styles = getPositionStyles(position, triggerOffset, dimensionRef || labelRef, openMenu, menuContainerRef);
    const isStyleNull = isNaN(styles.top) && isNaN(styles.left);
    const finalStyles = isStyleNull ? removeObjectProperties(styles, 'top', 'left') : styles;
    const nullStyles = removeObjectProperties(styles, 'top', 'left');
    const insertedStyles = style ? { ...style, ...finalStyles } : finalStyles;

    const setSelectedFocusStyles = (ref) => {
      if (!multi) handleClose();
      focusItem.current = ref;
    };

    const handleClose = () => {
      menuOpenStatus.current = false;
      setOpenMenu(false);
      setKeyBoardFocus(0);
      labelRef.current.focus();
    };

    const handleLabelKeyDown = useCallback((event) => {
      if ([ARROW_UP, ARROW_DOWN].includes(event.key)) {
        event.preventDefault();
        setOpenMenu(true);
        setKeyBoardFocus(event.key);
        const focusedItem = focusItem.current;
        if (focusedItem) {
          focusedItem.current.focus();
        } else {
          const listItemRef = menuListRef.current;
          const itemsLength = listItemRef.length - 1;
          if (event.key === ARROW_DOWN) {
            listItemRef[0]?.current?.focus();
          } else if (event.key === ARROW_UP) {
            listItemRef[itemsLength]?.current?.focus();
          }
        }
      }

      if ([ARROW_RIGHT, ARROW_LEFT, ESCAPE, TAB].includes(event.key)) handleClose();

      if ([ENTER, SPACE].includes(event.key)) {
        event.preventDefault();
        handleMenu();
        setKeyBoardFocus(event.key);
        const focusedItem = focusItem.current;
        if (focusedItem) {
          focusedItem.current.focus();
        } else {
          const listItemRef = menuListRef.current;
          setTimeout(() => listItemRef[0].current.focus(), 0); // setTimeout is in place to ensure the DOM element is ready for focus
          // TO DO : setTimeout should get cleared
        }
      }
    });

    const handleMenu = () => setOpenMenu((openMenu) => !openMenu);

    const listItemKeyDown = useCallback((event, target = null) => {
      const eventTarget = target || event.target;
      if (event.key === ARROW_DOWN) {
        event.preventDefault();
        setKeyBoardFocus(1);
        if (eventTarget && eventTarget.nextSibling) {
          if (eventTarget.nextSibling.getAttribute('disabled') !== null) {
            return listItemKeyDown(event, eventTarget.nextSibling);
          }
          eventTarget.nextSibling.focus();
        } else {
          menuListRef.current[0].current.focus();
        }
      }
      if (event.key === ARROW_UP) {
        event.preventDefault();
        setKeyBoardFocus(event.key);
        if (eventTarget && eventTarget.previousElementSibling) {
          if (eventTarget.previousElementSibling.getAttribute('disabled') !== null) {
            return listItemKeyDown(event, eventTarget.previousElementSibling);
          }
          eventTarget.previousElementSibling.focus();
        } else {
          const listLength = menuListRef.current.length;
          menuListRef.current[listLength - 1].current.focus();
        }
      }
      if ([ESCAPE, TAB].includes(event.key)) handleClose();
    });

    // Following hook adds div to document body and assign node to menu
    useEffect(() => {
      // Save a reference to menuRef.current so we can clean it up in the return function
      const menuNode = menuRef.current;
      document.body.appendChild(menuNode);

      setTargetNode(menuNode);

      // Cleanup function
      return () => document.body.removeChild(menuNode);
    }, []);

    useEffect(() => {
      setOpenMenu(isOpen);
    }, [isOpen]);

    useEffect(() => {
      const event = new CustomEvent('customMenuOpenEvent', {
        detail: {
          openMenu: openMenu,
          ref: menuContainerRef,
        },
        bubbles: false,
      });

      document.dispatchEvent(event);
    }, [openMenu]);

    //  Following hook adds click and keydown event handlers to Label element
    useEffect(() => {
      const labelElm = labelRef.current;
      if (labelElm) {
        labelElm.addEventListener('click', handleMenu);
        labelElm.addEventListener('keydown', handleLabelKeyDown);
      } else {
        console.error('Missing Label ref, labelRef should match label component ref ');
      }
      return () => {
        if (labelElm) {
          labelElm.removeEventListener('click', handleMenu);
          labelElm.removeEventListener('keydown', handleLabelKeyDown);
        }
      };
    }, [labelRef]);

    useEffect(() => {
      if (onOpen && openMenu) onOpen();

      if (initialRender.current && onClose && !openMenu) {
        initialRender.current = false;
        onClose();
      }
      if (openMenu) menuOpenStatus.current = true;
      if (!openMenu) setKeyBoardFocus(0);

      const scrollParent = getScrollableParent(labelRef.current);
      if (labelRef.current) {
        document.addEventListener('scroll', () => {
          if (menuOpenStatus.current) handleClose();
        });
        if (scrollParent) {
          scrollParent.addEventListener('scroll', () => {
            if (menuOpenStatus.current) handleClose();
          });
        }
      }
      const focusedItem = focusItem.current;
      if (focusedItem) {
        focusedItem.current.focus();
      } else {
        const listItemRef = menuListRef.current;
        const itemsLength = listItemRef.length - 1;
        if (keyBoardFocus === ARROW_DOWN) {
          listItemRef[0].current.focus();
        } else if (keyBoardFocus === ARROW_UP) {
          listItemRef[itemsLength].current.focus();
        }
      }

      setAnimateMenu(openMenu);

      return () => {
        if (labelRef.current) {
          document.removeEventListener('scroll', handleClose);
          if (scrollParent) {
            scrollParent.removeEventListener('scroll', handleClose);
          }
        }
      };
    }, [openMenu]);

    useEffect(() => {
      menuListRef.current.forEach((el) => {
        if (el.current) {
          el.current.addEventListener('keydown', listItemKeyDown);
          el.current.addEventListener('click', resetKeyBoardFocus);
        }
      });
      return () => {
        menuListRef.current.forEach((el) => {
          if (el.current) {
            el.current.removeEventListener('keydown', listItemKeyDown);
            el.current.removeEventListener('click', resetKeyBoardFocus);
          }
        });
      };
    }, [listItemKeyDown, openMenu]);

    // This section closes the Menu on screen resize
    useLayoutEffect(() => {
      if (openMenu) {
        setOpenMenu(false);
      }
    }, [scanWindow]);

    useOutsideClickEventListener(menuRef, labelRef, handleClose, openMenu);

    return (
      openMenu &&
      createPortal(
        <StyledMenuContainer
          $animateMenu={animateMenu}
          className={className}
          data-testid={dataTestId}
          ref={menuContainerRef}
          style={showMenu ? insertedStyles : nullStyles}
          {...other}
        >
          <StyledItemContainer>
            {flatChildren?.map((child, index) =>
              cloneElement(child, {
                ref: menuListRef.current[index],
                key: child.key,
                tabIndex: 0,
                isKeyboardFocused: isKeyboardFocused,
                showCheckbox: !!multi,
                onSelect: setSelectedFocusStyles,
              })
            )}
          </StyledItemContainer>
        </StyledMenuContainer>,
        targetNode
      )
    );
  }
);

Menu.propTypes = {
  /** the Ref of the wrapper element  */
  labelRef: PropTypes.shape({ current: PropTypes.instanceOf(Element) }).isRequired,
  /** Menu items to populate menu */
  children: PropTypes.array.isRequired,
  /** Adds new class to Menu */
  className: PropTypes.string,
  /** the Ref of the element relative to which the menu will be positioned */
  dimensionRef: PropTypes.shape({ current: PropTypes.instanceOf(Element) }),
  /** Call back function will trigger after opening Menu */
  onOpen: PropTypes.func,
  /** Call back function will trigger after closing Menu */
  onClose: PropTypes.func,
  /** If true, switch automatically to a placement that is more visible on the screen */
  isAuto: PropTypes.bool,
  /** Flag to open/close menu */
  isOpen: PropTypes.bool,
  /** Preferred placement of the menu */
  placement: PropTypes.oneOf(Object.values(MENU_PLACEMENT)),
  /** Custom inline style applied for the menu */
  style: PropTypes.shape({}),
  /** Parameter defining space between the target component and popup. */
  triggerOffset: PropTypes.number,
  /** Flag to enable multi selection */
  multi: PropTypes.bool,
  /** Id value used for testing */
  dataTestId: PropTypes.string,
};

export { Menu };
