import { useIntl } from 'react-intl';
import { IconChevronLeft } from 'cdk-radial';
import { useLocation, useHistory } from 'react-router-dom';
import { useSelector } from 'react-redux';
import useEnterpriseId from 'hooks/useEnterpriseId';
import { RootState } from 'redux/store';
import { messages } from './messages';
import { BlankHeader } from './BlankHeader';
import { PATH_WITH_BACK_BUTTONS } from './constants';

const ContentHeader = (): JSX.Element => {
  const { pathname } = useLocation();
  const enterpriseId = useEnterpriseId();
  const history = useHistory();
  const intl = useIntl();
  const {
    userImportState: { contentHeader },
    commonState: { actionButtons }
  } = useSelector((state: RootState) => state.slices);

  const handleBackButton = () => {
    const previousState = history.location.state;
    if (previousState) {
      history.goBack();
      return;
    }
    let target = '';
    const enterprisePath = `enterprise/${enterpriseId}`;
    if (
      pathname.includes('/user_details') ||
      pathname.includes('/copy_user/')
    ) {
      target = `/${enterprisePath}/users`;
      history.replace(target);
      return;
    }
    if (pathname.includes('/role/')) {
      target = `/${enterprisePath}/roles`;
      history.replace(target);
      return;
    }
    if (pathname.includes('/view_group/') || pathname.includes('/new_group')) {
      target = `/${enterprisePath}/groups`;
      history.replace(target);
      return;
    }
    if (pathname.includes('/service-user')) {
      target = `/service-users`;
      history.replace(target);
      return;
    }
    if (pathname.includes('/move-stores')) {
      target = `/tools`;
      history.replace(target);
      return;
    }
    if (pathname.includes('/merge-enterprise')) {
      target = `/tools`;
      history.replace(target);
      return;
    }
    if (pathname.includes('/bulk-unassign-user-job-details')) {
      target = `/${enterprisePath}/bulk-unassign-users`;
      history.replace(target);
      return;
    }
    if (pathname.includes('/user-management-report/')) {
      target = `/${enterprisePath}/user-management-reports`;
      history.replace(target);
      return;
    }
    if (pathname.includes('/settings/')) {
      target = `/${enterprisePath}/settings`;
      history.replace(target);
      return;
    }
    history.goBack();
  };

  const getContentHeader = () => {
    const header = contentHeader || intl.formatMessage(messages.contentHeader);
    const canShowBackButton = PATH_WITH_BACK_BUTTONS.some(path =>
      pathname.includes(path)
    );
    if (canShowBackButton) {
      return (
        <div>
          <span
            data-testid="back-arrow"
            onClick={handleBackButton}
            role="button"
            tabIndex={0}
            onKeyDown={handleBackButton}
          >
            <IconChevronLeft className="backIcon" />
          </span>
          <span>{header}</span>
        </div>
      );
    }

    return (
      <div>
        <span>{header}</span>
      </div>
    );
  };

  return (
    <BlankHeader title={getContentHeader()} actionButtons={actionButtons} />
  );
};

export default ContentHeader;
