import { cloneElement } from 'react';
import styled from 'styled-components';

import { getRem } from './../../core';
import { iconElementBasePositioning } from '../iconElementBasePositioning';

const CHIP_ICON_SIZE = getRem('20px');

const ChipIcon = styled(({ children, ...props }) => cloneElement(children, props))`
  ${iconElementBasePositioning};
  fill: ${({ theme }) => theme.color.text.light.secondary.value};
  height: ${CHIP_ICON_SIZE};
  left: ${getRem('7px')};
  width: ${CHIP_ICON_SIZE};
  z-index: ${({ theme }) => theme.zIndex.chipContent.value};

  && {
    position: absolute;
  }
`;

export { ChipIcon };
