import { getRem, transitionAnimation, typographyBody1, typographyBody2 } from 'lib/core';
import styled, { css } from 'styled-components';

import { INPUT_SIZES } from '../constants';

const sizeStyles = css`
  ${({ size, theme }) =>
    size === INPUT_SIZES.LARGE
      ? css`
          ${typographyBody2(theme)};
          height: ${getRem(24)};
        `
      : css`
          height: ${getRem(20)};
        `}
`;

const BaseInputField = styled.input.withConfig({
  shouldForwardProp: (prop) => !['hasError', 'hideBorder', 'inputDivider', 'isObscured', 'size'].includes(prop),
})`
  ${transitionAnimation('border, box-shadow')}
  ${({ theme }) => typographyBody1(theme)}
  background: transparent;
  border: none;
  box-shadow: none;
  box-sizing: border-box;
  min-width: 0;
  outline: none;
  padding: 0;
  padding-right: ${({ theme }) => theme.size.spacing.small.value};
  width: 100%;

  ${sizeStyles}

  &::placeholder {
    color: ${({ theme }) => theme.color.gray[600].value};
    font-weight: ${({ theme }) => theme.font.weight.light.value};
  }

  &::-ms-clear,
  &::-ms-reveal {
    display: none;
  }
  /* Direct selection necessary to apply styling for all other possible password input cases */
  &[type='password']:not([value='']) {
    font-family: ${({ theme }) => theme.font.family.obscured.value};
  }
  ${({ isObscured, theme }) =>
    isObscured &&
    css`
      font-family: ${theme.font.family.obscured.value};
    `}
`;

export { BaseInputField };
