/* eslint-disable @typescript-eslint/no-unsafe-return */
/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
/* eslint-disable @typescript-eslint/no-unsafe-call */
import { GridApi } from 'ag-grid-community';
import { DEFAULT_ROWS_PER_PAGE_OPTIONS } from 'cdk-radial';
import TableFilterType, { OptionType } from './types/tableFilterType';

const paginationPageSize = (gridApi?: GridApi): number => {
  if (gridApi) {
    return gridApi.paginationGetPageSize();
  }

  return DEFAULT_ROWS_PER_PAGE_OPTIONS[3].value as number;
};

export const getPageSummaryText = (
  currentPage: number,
  totalPages: number
): string => `Page ${currentPage} of ${totalPages}`;

export const getRowsSummaryText = (
  currentPage: number,
  pageSize: number,
  totalRows: number
): string => {
  if (totalRows === 0) {
    return `${totalRows}-${totalRows} of ${totalRows}`;
  }

  const rowsFrom = (currentPage - 1) * pageSize + 1;
  const rowsTo = currentPage * pageSize;
  return `${rowsFrom}-${
    rowsTo > totalRows ? totalRows : rowsTo
  } of ${totalRows}`;
};

export const generateFilterSelectionObject = (
  filters: TableFilterType[]
): Record<string, any> => {
  const finalObject: Record<string, any> = {};
  filters.forEach((filter: { uid: string }) => {
    finalObject[filter?.uid.toString()] = [];
  });
  return finalObject;
};

export const generateAssignStoreFilterSelectionObject = (
  filters: TableFilterType[]
): Record<string, OptionType[]> => {
  const finalObject: Record<string, OptionType[]> = {};
  filters.forEach((filter: { options: OptionType[]; uid: string }) => {
    finalObject[filter?.uid.toString()] = [filter?.options[0]];
  });
  return finalObject;
};

export const getEmptyTableMessage = (
  val: string,
  intl: Record<string, any>
) => {
  if (val && val.length < 3)
    return intl.formatMessage({
      id: 'ACTIVITYLOG.TABLE.NOROWTEMPLATEMESSAGE',
      defaultMessage:
        'The search input should have at least 3 characters to show any data'
    });
  return intl.formatMessage({
    id: 'ACTIVITYLOG.TABLE.NOWROWMESSAGE',
    defaultMessage: 'No Rows To Show'
  });
};

export default paginationPageSize;
