/* eslint-disable @typescript-eslint/no-unsafe-call */
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RoleState } from './types/roleSliceType';

export const initialState: RoleState = {
  contentHeader: '',
  isRoleDetailsChanged: false,
  rolePermissionsDataItem: {},
  selectedPermissionsDataItem: []
};

export const roleSlice = createSlice({
  name: 'userImportState',

  initialState,
  reducers: {
    setContentHeader: (state, action: PayloadAction<string>) => {
      state.contentHeader = action.payload;
    },
    setIsRoleDetailsChanged: (state, action: PayloadAction<boolean>) => {
      state.isRoleDetailsChanged = action.payload;
    },
    setSelectedRolesPermissions: (state, action: PayloadAction<any>) => {
      state.rolePermissionsDataItem = action.payload.rolePermissionsDataItem;
      state.selectedPermissionsDataItem =
        action.payload.selectedPermissionsDataItem;
    }
  }
});

export const {
  setContentHeader,
  setIsRoleDetailsChanged,
  setSelectedRolesPermissions
} = roleSlice.actions;

export default roleSlice.reducer;
