import React from 'react';
import PropTypes from 'prop-types';
import styled, { css } from 'styled-components';

import { getRem, truncateText, typographyGroupLabel, visuallyHidden } from '../../core';
import { INPUT_SIZES } from '../../input';

import { DROPDOWN_VARIANTS } from '../constants';

const StyledDropdownLabel = styled.label`
  ${({ theme }) => typographyGroupLabel(theme)}
  box-sizing: border-box;
  padding: 0 ${getRem('13px')};
  pointer-events: none;
  ${({ variant }) =>
    variant === DROPDOWN_VARIANTS.DEFAULT &&
    css`
      ${truncateText}
      left: 0;
      position: absolute;
      top: 0;
      width: 100%;
    `}
  ${({ variant }) =>
    variant === DROPDOWN_VARIANTS.INLINE &&
    css`
      align-items: center;
      display: inline-flex;
      line-height: 1;
      padding-left: 0;
      padding-right: ${({ theme }) => theme.size.spacing.medium.value};
      padding-top: 0;
      position: relative;
      white-space: nowrap;
      width: auto;
    `}
    ${({ variant }) =>
    variant === DROPDOWN_VARIANTS.NO_OUTLINE &&
    css`
      ${visuallyHidden}
    `}
    ${({ hasExtraPadding }) =>
    hasExtraPadding &&
    css`
      padding-right: ${getRem('64px')};
    `}
    ${({ hasError, isFocused, theme }) =>
    !hasError &&
    isFocused &&
    css`
      color: ${theme.color.primary[500].value};
    `}
    ${({ hasError, theme }) =>
    hasError &&
    css`
      color: ${theme.color.system.negative[500].value};
    `}
    ${({ isReadOnly, theme }) =>
    isReadOnly &&
    css`
      color: ${theme.color.text.light.secondary.value};
    `}
    ${({ isDisabled, theme }) =>
    isDisabled &&
    css`
      color: ${theme.color.text.light.disabled.value};
    `}
    ${({ hideLabel }) =>
    hideLabel &&
    css`
      ${visuallyHidden}
    `}
    ${({ size, variant }) =>
    size === INPUT_SIZES.SMALL &&
    variant !== DROPDOWN_VARIANTS.INLINE &&
    css`
      padding-top: ${getRem('5px')};
    `}
    ${({ size, variant }) =>
    size === INPUT_SIZES.STANDARD &&
    variant !== DROPDOWN_VARIANTS.INLINE &&
    css`
      padding-top: ${getRem('6px')};
    `}
    ${({ size, variant }) =>
    size === INPUT_SIZES.LARGE &&
    variant !== DROPDOWN_VARIANTS.INLINE &&
    css`
      padding-top: ${getRem('8px')};
    `}
`;

const DropdownLabel = ({
  hasError = false,
  hasExtraPadding = false,
  isDisabled = false,
  isFocused = false,
  isReadOnly = false,
  isRequired = false,
  size = INPUT_SIZES.STANDARD,
  ...other
}) => (
  <StyledDropdownLabel
    hasError={hasError}
    hasExtraPadding={hasExtraPadding}
    isDisabled={isDisabled}
    isFocused={isFocused}
    isReadOnly={isReadOnly}
    isRequired={isRequired}
    size={size}
    {...other}
  />
);

DropdownLabel.propTypes = {
  /** When true, input is in error state */
  hasError: PropTypes.bool,
  /** If true, adds additional spacing to the input right */
  hasExtraPadding: PropTypes.bool,
  /** If true, component is disabled and value of it cannot be edited */
  isDisabled: PropTypes.bool,
  /** If true, applies focus appearance */
  isFocused: PropTypes.bool,
  /** If true, component is in read only state, value cannot be edited */
  isReadOnly: PropTypes.bool,
  /** If true, asterisk will be shown on the input top right corner and input will become must to fill in the forms */
  isRequired: PropTypes.bool,
  /** Sets the size of the select input */
  size: PropTypes.oneOf(Object.values(INPUT_SIZES)),
};

export { DropdownLabel };
