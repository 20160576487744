/* eslint-disable react/no-array-index-key */
import { Toast, BUTTON_VARIANTS } from 'cdk-radial';
import { useIntl } from 'react-intl';
import StyledTemplateInfoDialog from './styled/StyledTemplateInfoDialog';
import { messages } from './messages';

type TemplateInfoDialogProps = {
  isModalOpen: boolean;
  closeModal: (isVisible: boolean) => void;
  content: {
    title: string;
    rules: string[];
  };
};

const TemplateInfoDialog = ({
  isModalOpen,
  closeModal,
  content: { title, rules }
}: TemplateInfoDialogProps): JSX.Element => {
  const intl = useIntl();
  return (
    <StyledTemplateInfoDialog
      dataTestId="template-info-dialog"
      id="emplate-info-dialog"
      className="emplate-info-dialog"
      isOpen={isModalOpen}
      onClose={() => closeModal(false)}
      title={title}
      buttonsProps={[
        {
          id: 'dialog-1-action-1',
          onClick: () => closeModal(false),
          text: intl.formatMessage(messages.okButton),
          variant: BUTTON_VARIANTS.PRIMARY
        }
      ]}
    >
      <Toast
        className="custom-toast"
        content={
          <ul className="custom-ul">
            {rules.map(rule => (
              <p key={rule?.slice(0, 3)}>{rule}</p>
            ))}
          </ul>
        }
        id="toast-1"
      />
    </StyledTemplateInfoDialog>
  );
};

export default TemplateInfoDialog;
