import { defineMessages } from 'react-intl';

export const messages = defineMessages({
  stepFourScheduleTime: {
    id: 'NEWUSERIMPORT.STEP4.SCHEDULETIME',
    defaultMessage: 'Schedule Time'
  },
  stepFourPlaceholder: {
    id: 'NEWUSERIMPORT.STEP4.PLACEHOLDER',
    defaultMessage: 'Select time...'
  },
  stepFourTimeHelper: {
    id: 'NEWUSERIMPORT.STEP4.HELPER',
    defaultMessage: 'Select a time'
  }
});
