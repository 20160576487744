import React from 'react';
import { Chip } from 'lib/chips';
import PropTypes from 'prop-types';
import styled, { css } from 'styled-components';

import { getRem } from './../../core';

const StyledShortcutChip = styled(Chip)`
  margin-top: ${({ theme }) => theme.size.spacing.medium.value};
  ${({ isBottom }) =>
    isBottom &&
    css`
      max-width: ${getRem('272px')};
      &:not(:last-child) {
        margin-right: ${({ theme }) => theme.size.spacing.medium.value};
      }
    `}
  ${({ isRight }) =>
    isRight &&
    css`
      display: flex;
      width: 100%;
    `}
`;

const ShortcutChip = React.forwardRef(({ isBottom = false, isRight = false, ...other }, ref) => (
  <StyledShortcutChip {...{ isBottom, isRight }} {...other} ref={ref} />
));

ShortcutChip.propTypes = {
  /** If true, will render container at the bottom */
  isBottom: PropTypes.bool,
  /** If true, will render container on the right */
  isRight: PropTypes.bool,
};

export { ShortcutChip };
