import styled from 'styled-components';

import { DatePickerContainerBase } from './DatePickerContainerBase';

export const DatePickerContainer = styled(DatePickerContainerBase)`
  display: flex;
  position: relative;

  .CalendarMonth_caption {
    background-color: ${({ theme }) => theme.color.datepicker.background.value};
    strong {
      color: ${({ theme }) => theme.color.datepicker.labels.value};
    }
    padding: 8px 0px;
    margin: 12px 0px 25px 0px;
    border-radius: 4px 4px 0 0;
  }

  .DayPicker_weekHeader_ul {
    background-color: ${({ theme }) => theme.color.additional.light.value};
  }
`;
