import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import { BREAKPOINTS, getHexToRgb, getRem, transitionAnimation } from '../../core';
import { SWITCH_SIZES } from '../constants';

const INDICATOR_SIZE = getRem('20px');
const SWITCH_INDICATOR_SIZES = {
  [SWITCH_SIZES.LARGE]: '40px',
  [SWITCH_SIZES.SMALL]: '32px',
  [SWITCH_SIZES.STANDARD]: '36px',
};
const SWITCH_TOGGLE_POSSITIONS = {
  [SWITCH_SIZES.LARGE]: '10px',
  [SWITCH_SIZES.SMALL]: '6px',
  [SWITCH_SIZES.STANDARD]: '8px',
};
const SWITCH_INDICATOR_POSITIONS = {
  [SWITCH_SIZES.LARGE]: '-50%',
  [SWITCH_SIZES.SMALL]: '-30%',
  [SWITCH_SIZES.STANDARD]: '-40%',
};

const StyledSwitchHolder = styled.div`
  align-items: center;
  display: inline-flex;
  flex-shrink: 0;
  height: ${getRem(SWITCH_INDICATOR_SIZES[SWITCH_SIZES.LARGE])};
  margin: 0 ${getRem('15px')};
  pointer-events: none;
  position: relative;

  @media ${BREAKPOINTS.L} {
    height: ${({ size }) => getRem(SWITCH_INDICATOR_SIZES[size])};
  }
`;

const StyledSwitchTrack = styled.span`
  background-color: ${({ checked, isDisabled, theme }) => {
    if (checked) {
      return isDisabled ? theme.color.primary[50].value : theme.color.primary[200].value;
    }

    return isDisabled ? theme.color.gray[300].value : theme.color.gray[400].value;
  }};
  border-radius: ${({ theme }) => theme.size.borderRadius.large.value};
  box-sizing: border-box;
  height: ${getRem('14px')};
  margin: 0 ${getRem('3px')};
  width: ${getRem('34px')};
`;

const StyledSwitchToggle = styled.span`
  ${transitionAnimation('transform')}
  background-color: ${({ checked, isDisabled, theme }) => {
    if (checked) {
      return isDisabled ? theme.color.primary[200].value : theme.color.primary[500].value;
    }

    return isDisabled ? theme.color.gray[400].value : theme.color.additional.light.value;
  }};
  border-radius: ${({ theme }) => theme.size.borderRadius.full.value};
  box-shadow: ${({ isDisabled, theme }) =>
    isDisabled
      ? `0 1px 4px 0 rgba(${getHexToRgb(theme.color.additional.dark.value)}, 0.24)`
      : `0 1px 4px 0 rgba(${getHexToRgb(theme.color.additional.dark.value)}, 0.4)`};
  box-sizing: border-box;
  height: ${INDICATOR_SIZE};
  left: 0;
  position: absolute;
  top: ${getRem(SWITCH_TOGGLE_POSSITIONS[SWITCH_SIZES.LARGE])};
  transform: ${({ checked }) => `translateX(${checked ? INDICATOR_SIZE : 0})`};
  width: ${INDICATOR_SIZE};

  @media ${BREAKPOINTS.L} {
    top: ${({ size }) => SWITCH_TOGGLE_POSSITIONS[size]};
  }

  &::after {
    ${transitionAnimation('background-color')}
    border-radius: ${({ theme }) => theme.size.borderRadius.full.value};
    box-sizing: border-box;
    content: '';
    display: inline-block;
    height: ${getRem(SWITCH_INDICATOR_SIZES[SWITCH_SIZES.LARGE])};
    left: ${SWITCH_INDICATOR_POSITIONS[SWITCH_SIZES.LARGE]};
    position: absolute;
    top: ${SWITCH_INDICATOR_POSITIONS[SWITCH_SIZES.LARGE]};
    width: ${getRem(SWITCH_INDICATOR_SIZES[SWITCH_SIZES.LARGE])};

    @media ${BREAKPOINTS.L} {
      height: ${({ size }) => getRem(SWITCH_INDICATOR_SIZES[size])};
      left: ${({ size }) => SWITCH_INDICATOR_POSITIONS[size]};
      top: ${({ size }) => SWITCH_INDICATOR_POSITIONS[size]};
      width: ${({ size }) => getRem(SWITCH_INDICATOR_SIZES[size])};
    }
  }
`;

const SwitchIndicator = ({ checked = false, isDisabled = false, size = SWITCH_SIZES.STANDARD, ...other }) => (
  <StyledSwitchHolder size={size} {...other}>
    <StyledSwitchTrack {...{ checked, isDisabled }} />
    <StyledSwitchToggle {...{ checked, isDisabled, size }} />
  </StyledSwitchHolder>
);

SwitchIndicator.propTypes = {
  /** If true, changes appearance to enabled */
  checked: PropTypes.bool,
  /** If true, changes indicator appearance to look non-interactive */
  isDisabled: PropTypes.bool,
  /** Switch size */
  size: PropTypes.oneOf(Object.values(SWITCH_SIZES)),
};

export { StyledSwitchToggle, SwitchIndicator };
