import React from 'react';

import { BaseCheckbox, CheckboxLabel } from 'lib/checkbox';
import { KEY_VALUES } from 'lib/utilities';
import PropTypes from 'prop-types';

import { ElementStateWrapper } from '../../core';
import { SWITCH_LABEL_POSITIONS, SWITCH_SIZES } from '../constants';
import { StyledSwitchToggle, SwitchIndicator } from '../elements';
import { getStateStyles } from '../switchStateStyles';

const triggerKeys = [KEY_VALUES.SPACE];

const Switch = React.forwardRef(
  (
    {
      checked,
      dataTestId = '',
      hideLabel = false,
      id = '',
      isDisabled = false,
      label,
      labelPosition = SWITCH_LABEL_POSITIONS.AFTER,
      name,
      onChange,
      size = SWITCH_SIZES.STANDARD,
      ...other
    },
    ref
  ) => {
    const renderIndicator = () => <SwitchIndicator />;
    const renderLabel = () => <CheckboxLabel labelPosition={labelPosition} />;

    return (
      <ElementStateWrapper
        stateStyles={getStateStyles(checked, isDisabled, StyledSwitchToggle)}
        triggerClickEventAsKeyDown
        triggerKeys={triggerKeys}
        {...other}
      >
        <BaseCheckbox
          data-testid={dataTestId}
          id={id || name}
          {...{
            checked,
            hideLabel,
            isDisabled,
            label,
            labelPosition,
            name,
            onChange,
            ref,
            renderIndicator,
            renderLabel,
            size,
          }}
        />
      </ElementStateWrapper>
    );
  }
);

Switch.propTypes = {
  /** If true, sets switch style to on */
  checked: PropTypes.bool.isRequired,
  /** Provides test id for react-testlibrary */
  dataTestId: PropTypes.string,
  /** Visually hides switch label */
  hideLabel: PropTypes.bool,
  /** Identifier for this switch */
  id: PropTypes.string,
  /** Disables to change the value of switch and shows it visually disabled */
  isDisabled: PropTypes.bool,
  /** Switch label */
  label: PropTypes.node.isRequired,
  /** Places label before or after indicator */
  labelPosition: PropTypes.oneOf(Object.values(SWITCH_LABEL_POSITIONS)),
  /** Name of the switch */
  name: PropTypes.string.isRequired,
  /** Callback that is called when interacting with switch */
  onChange: PropTypes.func.isRequired,
  /** Switch size */
  size: PropTypes.oneOf(Object.values(SWITCH_SIZES)),
};

export { Switch };
