import styled from 'styled-components';
import { HeaderSection } from 'cdk-radial';

export const StyledContentHeader = styled(HeaderSection)`
  .top-header {
    box-shadow: rgb(130, 198, 0) 0px -1px 0px 0px inset;
  }
  .backIcon {
    margin-right: 5px;
    margin-bottom: -3px;
    cursor: pointer;
  }
`;
