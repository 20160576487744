/* eslint-disable @typescript-eslint/no-unsafe-return */
import { useIntl } from 'react-intl';
import { messages } from 'fixtures/grid/errorTable/messages';
import {
  CellValue,
  ParamsTypeBulkUnassignUser,
  ParamsTypeDMS,
  ParamsTypeNonDMS
} from 'fixtures/grid/errorTable/types';

const CustomLinkRenderer = ({ value, error }: CellValue): JSX.Element => (
  <div>
    {value}
    <span style={{ color: 'red' }}>{error}</span>
  </div>
);

const useDmsColumns = (): Array<any> => {
  const intl = useIntl();
  const dmsColumns = [
    {
      cellRendererFramework: CustomLinkRenderer,
      cellRendererParams: (params: ParamsTypeDMS): CellValue =>
        params.data.lastName,
      field: 'lastName',
      label: intl.formatMessage(messages.lastName),
      minWidth: 250,
      sortable: true
    },
    {
      cellRendererFramework: CustomLinkRenderer,
      cellRendererParams: (params: ParamsTypeDMS): CellValue =>
        params.data.firstName,
      field: 'firstName',
      label: intl.formatMessage(messages.firstName),
      minWidth: 250,
      sortable: true
    },
    {
      cellRendererFramework: CustomLinkRenderer,
      cellRendererParams: (params: ParamsTypeDMS): CellValue =>
        params.data.dmsUserId,
      field: 'dmsUserId',
      headerName: intl.formatMessage(messages.dmsUserId),
      minWidth: 250,
      sortable: true
    },
    {
      cellRendererFramework: CustomLinkRenderer,
      cellRendererParams: (params: ParamsTypeDMS): CellValue =>
        params.data.cNumber,
      field: 'cNumber',
      headerName: intl.formatMessage(messages.cNumber),
      minWidth: 150,
      sortable: true
    },
    {
      cellRendererFramework: CustomLinkRenderer,
      cellRendererParams: (params: ParamsTypeDMS): CellValue =>
        params.data.email,
      field: 'email',
      headerName: intl.formatMessage(messages.email),
      minWidth: 250,
      sortable: true
    },
    {
      cellRendererFramework: CustomLinkRenderer,
      cellRendererParams: (params: ParamsTypeNonDMS): CellValue =>
        params.data.roles,
      field: 'roles',
      headerName: intl.formatMessage(messages.roles),
      cellStyle: { 'white-space': 'normal' },
      autoHeight: true,
      minWidth: 300,
      sortable: true
    },
    {
      cellRendererFramework: CustomLinkRenderer,
      cellRendererParams: (params: ParamsTypeNonDMS): CellValue =>
        params.data.stores,
      field: 'stores',
      headerName: intl.formatMessage(messages.stores),
      cellStyle: { 'white-space': 'normal' },
      autoHeight: true,
      minWidth: 300,
      sortable: true
    },
    {
      cellRendererFramework: CustomLinkRenderer,
      cellRendererParams: (params: ParamsTypeDMS): CellValue =>
        params.data.federationId,
      field: 'federationId',
      headerName: intl.formatMessage(messages.federationId),
      minWidth: 250,
      sortable: true
    },
    {
      cellRendererFramework: CustomLinkRenderer,
      cellRendererParams: (params: ParamsTypeDMS): CellValue =>
        params.data.employeeId,
      field: 'employeeId',
      headerName: intl.formatMessage(messages.employeeId),
      minWidth: 250,
      sortable: true
    },
    {
      cellRendererFramework: CustomLinkRenderer,
      cellRendererParams: (params: ParamsTypeDMS): CellValue =>
        params.data.jobTitle,
      field: 'jobTitle',
      headerName: intl.formatMessage(messages.jobTitle),
      minWidth: 250,
      sortable: true
    }
  ];
  return [dmsColumns];
};

const useNondmscolumns = (): Array<any> => {
  const intl = useIntl();
  const nonDMSColumns = [
    {
      cellRendererFramework: CustomLinkRenderer,
      cellRendererParams: (params: ParamsTypeNonDMS): CellValue =>
        params.data.lastname,
      field: 'lastname',
      label: intl.formatMessage(messages.lastName),
      minWidth: 250,
      sortable: true
    },
    {
      cellRendererFramework: CustomLinkRenderer,
      cellRendererParams: (params: ParamsTypeNonDMS): CellValue =>
        params.data.firstname,
      field: 'firstname',
      label: intl.formatMessage(messages.firstName),
      minWidth: 250,
      sortable: true
    },
    {
      cellRendererFramework: CustomLinkRenderer,
      cellRendererParams: (params: ParamsTypeNonDMS): CellValue =>
        params.data.simpleid,
      field: 'simpleid',
      headerName: intl.formatMessage(messages.simpleID),
      minWidth: 250,
      sortable: true
    },
    {
      cellRendererFramework: CustomLinkRenderer,
      cellRendererParams: (params: ParamsTypeNonDMS): CellValue =>
        params.data.emailId,
      field: 'emailId',
      headerName: intl.formatMessage(messages.emailId),
      minWidth: 250,
      sortable: true
    },
    {
      cellRendererFramework: CustomLinkRenderer,
      cellRendererParams: (params: ParamsTypeNonDMS): CellValue =>
        params.data.roles,
      field: 'roles',
      headerName: intl.formatMessage(messages.roles),
      minWidth: 300,
      sortable: true,
      cellStyle: { 'white-space': 'normal' },
      autoHeight: true
    },
    {
      cellRendererFramework: CustomLinkRenderer,
      cellRendererParams: (params: ParamsTypeNonDMS): CellValue =>
        params.data.stores,
      field: 'stores',
      headerName: intl.formatMessage(messages.stores),
      minWidth: 300,
      sortable: true,
      cellStyle: { 'white-space': 'normal' },
      autoHeight: true
    },
    {
      cellRendererFramework: CustomLinkRenderer,
      cellRendererParams: (params: ParamsTypeNonDMS): CellValue =>
        params.data.altSystemId,
      field: 'altSystemId',
      headerName: intl.formatMessage(messages.altSystemId),
      minWidth: 150,
      sortable: true
    },
    {
      cellRendererFramework: CustomLinkRenderer,
      cellRendererParams: (params: ParamsTypeNonDMS): CellValue =>
        params.data.altUserId,
      field: 'altUserId',
      headerName: intl.formatMessage(messages.altUserID),
      minWidth: 150,
      sortable: true
    },
    {
      cellRendererFramework: CustomLinkRenderer,
      cellRendererParams: (params: ParamsTypeNonDMS): CellValue =>
        params.data.federationid,
      field: 'federationid',
      headerName: intl.formatMessage(messages.federationId),
      minWidth: 250,
      sortable: true
    },
    {
      cellRendererFramework: CustomLinkRenderer,
      cellRendererParams: (params: ParamsTypeNonDMS): CellValue =>
        params.data.employeeId,
      field: 'employeeId',
      headerName: intl.formatMessage(messages.employeeId),
      minWidth: 250,
      sortable: true
    },
    {
      cellRendererFramework: CustomLinkRenderer,
      cellRendererParams: (params: ParamsTypeNonDMS): CellValue =>
        params.data.jobTitle,
      field: 'jobTitle',
      headerName: intl.formatMessage(messages.jobTitle),
      minWidth: 250,
      sortable: true
    },
    {
      cellRendererFramework: CustomLinkRenderer,
      cellRendererParams: (params: ParamsTypeNonDMS): CellValue =>
        params.data.primaryPhone,
      field: 'primaryPhone',
      headerName: intl.formatMessage(messages.primaryPhone),
      minWidth: 200,
      sortable: true
    }
  ];
  return [nonDMSColumns];
};

const useBulkUnassignUsersColumns = (): Array<any> => {
  const intl = useIntl();
  const bulkUnassignUsersColumns = [
    {
      cellRendererFramework: CustomLinkRenderer,
      cellRendererParams: (params: ParamsTypeBulkUnassignUser): CellValue =>
        params.data.loginId,
      field: 'loginId',
      label: intl.formatMessage(messages.loginId),
      minWidth: 250,
      sortable: true
    },
    {
      cellRendererFramework: CustomLinkRenderer,
      cellRendererParams: (params: ParamsTypeBulkUnassignUser): CellValue =>
        params.data.roles,
      field: 'roles',
      label: intl.formatMessage(messages.roles),
      minWidth: 250,
      sortable: true
    },
    {
      cellRendererFramework: CustomLinkRenderer,
      cellRendererParams: (params: ParamsTypeBulkUnassignUser): CellValue =>
        params.data.stores,
      field: 'stores',
      headerName: intl.formatMessage(messages.stores),
      minWidth: 250,
      sortable: true
    },
    {
      cellRendererFramework: CustomLinkRenderer,
      cellRendererParams: (params: ParamsTypeBulkUnassignUser): CellValue =>
        params.data.dmsUserIdAndcNumber,
      field: 'dmsUserIdAndcNumber',
      headerName: intl.formatMessage(messages.dmsUserIdAndCNumber),
      minWidth: 150,
      sortable: true
    }
  ];
  return [bulkUnassignUsersColumns];
};

export default { useDmsColumns, useNondmscolumns, useBulkUnassignUsersColumns };
