import { useEffect, useCallback } from 'react';
import { useIntl } from 'react-intl';
import { useDispatch } from 'react-redux';
import { TOAST_VARIANTS } from 'cdk-radial';
import { isErrorWithMessage } from 'helpers/errorHelpers';
import { ErrorData, MultiErrors } from 'types/Error';
import { setGlobalToast } from 'redux/slices/commonSlice';
import { messages } from '../../../commonMessages';
import { Data } from '../../../types';
import { ErrorToastProps } from './types';

const ErrorToast = ({ dataError }: ErrorToastProps): JSX.Element => {
  const intl = useIntl();
  const dispatch = useDispatch();

  const dispatchMessage = useCallback(
    (message: string, type: string) => {
      dispatch(
        setGlobalToast({
          content: message,
          variant: type,
          autoHide: false
        })
      );
    },
    [dispatch]
  );

  useEffect(() => {
    let errMsg = '';
    if (isErrorWithMessage(dataError)) {
      errMsg = dataError?.message;
    } else if (dataError && 'data' in dataError) {
      errMsg = (dataError?.data as Data)?.error?.message;
      errMsg =
        !errMsg && (dataError?.data as MultiErrors)?.items?.length > 0
          ? (dataError?.data as MultiErrors)?.items[0]?.message
          : errMsg;
      errMsg = errMsg || (dataError?.data as ErrorData)?.message;
    }
    dispatchMessage(
      errMsg
        ? intl.formatMessage(messages.errorMessage, { errMsg })
        : intl.formatMessage(messages.unknownErrorMessage),
      TOAST_VARIANTS.NEGATIVE
    );
  }, [dataError, dispatchMessage, intl]);
  return <div />;
};

export default ErrorToast;
