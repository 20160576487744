import { SecondaryButton, IconDelete } from 'cdk-radial';
import { useIntl } from 'react-intl';
import StyledCustomHeader from './styled';
import { messages } from './messages';

type CustomHeaderProps = {
  onRemoveFromImportList: () => void;
  onAssignRoles: () => void;
  onAssignStores: () => void;
};
const CustomHeader = ({
  onRemoveFromImportList,
  onAssignRoles,
  onAssignStores
}: CustomHeaderProps): JSX.Element => {
  const intl = useIntl();
  return (
    <StyledCustomHeader>
      <SecondaryButton
        dataTestId="import-remove-button"
        className="customized-button"
        icon={<IconDelete />}
        iconColor="primary"
        onClick={onRemoveFromImportList}
        text={intl.formatMessage(messages.removeUser)}
      />
      <SecondaryButton
        dataTestId="roles-button"
        className="customized-button"
        iconColor="primary"
        onClick={onAssignRoles}
        text={intl.formatMessage(messages.assignRoles)}
      />
      <SecondaryButton
        dataTestId="stores-button"
        className="customized-button"
        iconColor="primary"
        onClick={onAssignStores}
        text={intl.formatMessage(messages.assignStores)}
      />
    </StyledCustomHeader>
  );
};
export default CustomHeader;
