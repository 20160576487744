import { keyframes } from 'styled-components';

import { getRem } from './../../core';

export const slideDownKeyframes = keyframes`
    0% {
        height: 0;
        transform: translate(0, ${getRem(-40)});
    }

    100% {
        height: ${getRem(32)};
        transform: translate(0, 0);
    }
`;

export const slideDownKeyframesV2 = keyframes`
    0% {
        height: 0;
        transform: translate(0, ${getRem(-40)});
    }
    50% {
      height: 50%;
      transform: translate(0, ${getRem(0)});
    }
    100% {
        height: auto;
        transform: translate(0, 0);
    }
`;
