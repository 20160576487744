import React from 'react';
import PropTypes from 'prop-types';
import styled, { css } from 'styled-components';

import { BREAKPOINTS, getRem, transitionAnimation } from '../../core';
import { RADIO_SIZES } from '../constants';

const RADIO_HIGHLIGHT_SIZES = {
  [RADIO_SIZES.LARGE]: '40px',
  [RADIO_SIZES.SMALL]: '32px',
  [RADIO_SIZES.STANDARD]: '36px',
};

const INDICATOR_SIZE = getRem('18px');

const indicatorBaseStyles = css`
  border-radius: ${({ theme }) => theme.size.borderRadius.full.value};
  box-sizing: border-box;
  content: '';
  display: inline-block;
  height: ${getRem('20px')};
  left: 50%;
  position: absolute;
  top: 50%;
  width: ${getRem('20px')};
`;

const indicatorSizeStyles = (highlightSize, isLabelBefore, marginX) => css`
  height: ${getRem(RADIO_HIGHLIGHT_SIZES[RADIO_SIZES.LARGE])};
  margin: 0 ${({ theme }) => theme.size.spacing.small.value};
  width: ${getRem(RADIO_HIGHLIGHT_SIZES[RADIO_SIZES.LARGE])};
  ${isLabelBefore &&
  css`
    height: ${INDICATOR_SIZE};
    margin: 0 ${({ theme }) => theme.size.spacing.medium.value};
    width: ${INDICATOR_SIZE};
  `}

  @media ${BREAKPOINTS.L} {
    height: ${highlightSize};
    margin: 0 ${marginX};
    width: ${highlightSize};
  }
`;

export const StyledRadioIndicator = styled.span`
  border-radius: ${({ theme }) => theme.size.borderRadius.full.value};
  box-sizing: border-box;
  display: inline-block;
  position: relative;
  ${transitionAnimation('background-color')}

  /* Border */
    &::before {
    ${indicatorBaseStyles}
    border: ${getRem('2px')} solid ${({ theme }) => theme.color.gray['700'].value};
    transform: translate(-50%, -50%);
  }

  /* Fill */
  &::after {
    ${indicatorBaseStyles}
    background-color: transparent;
    transform: translate(-50%, -50%) scale(0);
    ${transitionAnimation('transform')}
  }

  ${({ isChecked, theme }) =>
    isChecked &&
    css`
      &::before {
        border-color: ${theme.color.primary['500'].value};
      }

      &::after {
        background-color: ${theme.color.primary['500'].value};
        transform: translate(-50%, -50%) scale(0.5);
      }
    `}

  ${({ isDisabled, theme }) =>
    isDisabled &&
    css`
      &::before {
        border-color: ${theme.color.gray['300'].value};
      }
    `}

    ${({ isChecked, isDisabled, theme }) =>
    isChecked &&
    isDisabled &&
    css`
      &::after {
        background-color: ${theme.color.gray['300'].value};
      }
    `}

    ${({ isLabelBefore, isLarge, theme }) =>
    isLarge &&
    indicatorSizeStyles(
      getRem(RADIO_HIGHLIGHT_SIZES[RADIO_SIZES.LARGE]),
      isLabelBefore,
      theme.size.spacing.small.value
    )}

    ${({ isLabelBefore, isStandard }) =>
    isStandard &&
    indicatorSizeStyles(getRem(RADIO_HIGHLIGHT_SIZES[RADIO_SIZES.STANDARD]), isLabelBefore, getRem('6px'))}

    ${({ isLabelBefore, isSmall, theme }) =>
    isSmall &&
    indicatorSizeStyles(
      getRem(RADIO_HIGHLIGHT_SIZES[RADIO_SIZES.SMALL]),
      isLabelBefore,
      theme.size.spacing.medium.value
    )}
`;

const RadioIndicator = ({
  isChecked = false,
  isDisabled = false,
  isLabelBefore = false,
  isLarge = false,
  isSmall = false,
  isStandard = false,
  ...other
}) => <StyledRadioIndicator {...{ isChecked, isDisabled, isLabelBefore, isLarge, isSmall, isStandard }} {...other} />;

RadioIndicator.propTypes = {
  /** If true, will style indicator to look like a checked radio */
  isChecked: PropTypes.bool,
  /** If true, will style indicator to look like a disabled radio */
  isDisabled: PropTypes.bool,
  /** If true, will style indicator for reversed element flow */
  isLabelBefore: PropTypes.bool,
  /** If true, will apply styling for thick appearance */
  isLarge: PropTypes.bool,
  /** If true, will apply styling for thin appearance */
  isSmall: PropTypes.bool,
  /** If true, will apply styling for regular */
  isStandard: PropTypes.bool,
};

export { RadioIndicator };
