export type ResetPasswordProps = {
  passwordError: string;
  handleSaveExit: (payload: ChangePasswordPayload) => void;
  handleCloseExit: () => void;
  handleClose: (isOpen: boolean) => void;
};

export enum PasswordErrorActionType {
  HAS_CHARACTER = 'HAS_CHARACTER',
  HAS_LOWER_CASE = 'HAS_LOWER_CASE',
  HAS_UPPER_CASE = 'HAS_UPPER_CASE',
  HAS_SPECIAL_CHARACTER = 'HAS_SPECIAL_CHARACTER',
  HAS_NUMERALS = 'HAS_NUMERALS',
  HAS_THREE_IDENTICAL_CHAR = 'HAS_THREE_IDENTICAL_CHAR',
  HAS_SPACES = 'HAS_SPACES',
  HAS_SQUENTIAL_CHAR = 'HAS_SQUENTIAL_CHAR',
  RESET = 'RESET'
}

export enum PasswordActionType {
  PASSWORD = 'PASSWORD',
  VALIDATION_MESSAGE = 'VALIDATION_MESSAGE'
}

export type PasswordState = {
  password: string;
  validationMessage: string;
  hasCharacter: boolean;
  hasLowerCase: boolean;
  hasUppperCase: boolean;
  hasNumerals: boolean;
  hasSpecialCharacter: boolean;
  hasThreeIdenticalChar: boolean;
  hasSquentialChar: boolean;
  hasSpaces: boolean;
};

export type PasswordErrorAction = {
  type: PasswordErrorActionType;
  error: boolean;
};

export type PasswordAction = {
  type: PasswordActionType;
  payload: string;
};

export type Action = PasswordErrorAction | PasswordAction;

export type ChangePasswordPayload = {
  changePassword: {
    oldPassword: {
      value: string;
    };
    newPassword: {
      value: string;
    };
  };
};
