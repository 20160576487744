import { useEffect, useState } from 'react';
// This "strange" custom hook is needed to fix react-dates bug while passing "phrases" prop.
// Without it SHIFT-TAB doesn't work if you have dynamically editable custom phrases.

const useCustomPhrases = (ariaLabels, defaultPhrases) => {
  const [customPhrases, setCustomPhrases] = useState({});
  useEffect(() => {
    setCustomPhrases({
      ...defaultPhrases,
      chooseAvailableEndDate: ariaLabels.chooseAvailableEndDate,
      chooseAvailableStartDate: ariaLabels.chooseAvailableStartDate,
      jumpToNextMonth: ariaLabels.jumpToNextMonth,
      jumpToPrevMonth: ariaLabels.jumpToPrevMonth,
    });
  }, [defaultPhrases]);
  return customPhrases;
};

export { useCustomPhrases };
