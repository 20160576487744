import { css } from 'styled-components';

/**
 * PLEASE NOTE: ::-webkit-scrollbar properties are widely used; however, they are non-standardized.
 *   Browsers that do not support webkit simply ignore the webkit properties.
 */

export const customScrollbar = () => css`
  overflow-x: auto;
  overflow-y: auto;

  /* Firefox compatibility */
  scrollbar-width: thin;
  scrollbar-color: ${({ theme }) => theme.color.gray[300].value} transparent;

  /* Chrome and Edge and Safari compatibility */
  &::-webkit-scrollbar {
    height: 14px;
    width: 14px;
  }
  &::-webkit-scrollbar-track {
    background: transparent;
  }
  &::-webkit-scrollbar-thumb {
    background: ${({ theme }) => theme.color.gray[300].value};
    border-radius: 7px;
    border: 4px solid ${({ theme }) => theme.color.additional.light.value};
    :hover {
      border: 3px solid ${({ theme }) => theme.color.additional.light.value};
    }
    :active {
      background: ${({ theme }) => theme.color.gray[400].value};
    }
    :vertical {
      min-height: 32px;
    }
    :horizontal {
      min-width: 48px;
    }
  }
  &::-webkit-scrollbar-corner {
    background: transparent;
    color: transparent;
  }
`;

export const customNavigationContextSwitcherScrollbar = () => css`
  /* Firefox compatibility (let FF choose the colors) */
  scrollbar-width: thin;

  /* Chrome and Edge and Safari compatibility */
  &::-webkit-scrollbar {
    height: 14px;
    width: 14px;
  }
  &::-webkit-scrollbar-track {
    background: transparent;
  }
  &::-webkit-scrollbar-thumb {
    background: ${({ theme }) => theme.color.gray[400].value};
    border-radius: 7px;
    border: 4px solid ${({ theme }) => theme.color.gray[100].value};
    :hover {
      border: 3px solid ${({ theme }) => theme.color.gray[100].value};
    }
    :active {
      background: ${({ theme }) => theme.color.gray[500].value};
    }
    :vertical {
      min-height: 32px;
    }
    :horizontal {
      min-width: 48px;
    }
  }
  &::-webkit-scrollbar-corner {
    background: transparent;
    color: transparent;
  }
`;
