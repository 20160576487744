import { useIntl } from 'react-intl';
import StyledStepOneTitle from './styled';
import { messages } from './messages';

const StepOneTitle = (): JSX.Element => {
  const intl = useIntl();
  return (
    <StyledStepOneTitle data-testid="step-one-title">
      <div>
        <h3>{intl.formatMessage(messages.stepOneSelectCnumber)}</h3>
        <p className="subTitle">{intl.formatMessage(messages.stepOneNote)}</p>
      </div>
    </StyledStepOneTitle>
  );
};

export default StepOneTitle;
