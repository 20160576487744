/* eslint-disable @typescript-eslint/no-unsafe-call ,
@typescript-eslint/restrict-template-expressions */
import { OptionType } from 'helpers/types/tableFilterType';
import { DmsUserType } from 'redux/services/identity-service/types';
import { SelectedCNumber, Store } from 'redux/services/admin-service/types';

export const getDMSStores = (dmsData: DmsUserType[]): string[] => {
  const allStores: string[] = dmsData?.reduce((acc: string[], curr) => {
    const tempStore = curr?.stores as Store[];
    const stores: string[] = tempStore.reduce(
      (storeAcc: string[], store: Store) => [...storeAcc, store.id] as string[],
      []
    );
    return [...acc, ...stores];
  }, []);
  const uniqueStores = new Set(allStores);
  return Array.from(uniqueStores);
};

export const getDMSProfiles = (dmsData: DmsUserType[]): string[] => {
  const allProfile: string[] = dmsData?.reduce((acc: string[], curr) => {
    const profiles: string[] = curr?.jobProfiles?.reduce(
      (accProfiles: string[], currJobProfile: string) => [
        ...accProfiles,
        currJobProfile
      ],
      []
    );
    return [...acc, ...profiles];
  }, []);
  const uniqueProfiles = new Set(allProfile);
  return Array.from(uniqueProfiles);
};

export const getDMSAccounts = (dmsData: DmsUserType[]): string[] => {
  const allDMSAccounts: string[] = dmsData?.reduce((acc: string[], curr) => {
    const accounts: string[] = curr?.profiles?.reduce(
      (accProfiles: string[], currProfile) => [
        ...accProfiles,
        ...currProfile.accounts
      ],
      []
    );
    return [...acc, ...accounts];
  }, []);
  const uniqueDMSAccounts = new Set(allDMSAccounts);
  return Array.from(uniqueDMSAccounts);
};

export const getUpdatedOptions = (options: string[]): OptionType[] => {
  const updatedOptions = options.map(option => ({
    id: option,
    label: option,
    value: option,
    selectionLabel: '',
    disabled: false,
    showDivider: false
  }));
  return updatedOptions;
};

export const getUpdatedCNumbers = (
  cnumbers: SelectedCNumber[]
): OptionType[] => {
  const filteredCNumbers = cnumbers.filter(cnumber => cnumber.value !== 'All');
  const updatedOptions = filteredCNumbers.map(cnumber => ({
    id: cnumber.value,
    label: `${cnumber.value} ${cnumber.alias}`.trim(),
    value: cnumber.value,
    selectionLabel: '',
    disabled: false,
    showDivider: false
  }));
  return updatedOptions;
};
