import { css } from 'styled-components';

import { focusOutlinesStyles, getHexToRgb } from '../../../core';
import { getButtonContentElementsColor } from '../../shared-styles';

export const secondaryButtonStateStyles = {
  default: {
    active: css`
      background-color: ${({ theme }) => theme.color.button.secondary.background.active.value};
      ${({ theme }) => getButtonContentElementsColor(theme.color.button.secondary.text.alternate.value)};
    `,
    default: css`
      background-color: ${({ theme }) => theme.color.button.secondary.background.enabled.value};
      ${({ theme }) => getButtonContentElementsColor(theme.color.button.secondary.text.normal.value)};
    `,
    disabled: css`
      background-color: ${({ theme }) => theme.color.button.secondary.background.disabled.value};
      ${({ theme }) => getButtonContentElementsColor(theme.color.button.secondary.text.disabled.value)};
    `,
    focus: css`
      background-color: ${({ theme }) => theme.color.button.secondary.background.hover.value};
      ${({ theme }) => getButtonContentElementsColor(theme.color.button.secondary.text.normal.value)};
      ${focusOutlinesStyles.primary};
    `,
    hover: css`
      background-color: ${({ theme }) => theme.color.button.secondary.background.hover.value};
      ${({ theme }) => getButtonContentElementsColor(theme.color.button.secondary.text.normal.value)};
    `,
  },
  defaultOnPrimaryBackground: {
    active: css`
      background-color: ${({ theme }) => theme.color.primary[900].value};
    `,
    default: css`
      background-color: ${({ theme }) => theme.color.primary[700].value};
      ${({ theme }) => getButtonContentElementsColor(theme.color.additional.light.value)}
    `,
    disabled: css`
      ${({ theme }) => {
        const color = getHexToRgb(theme.color.additional.light.value);
        return css`
          background-color: ${`rgba(${color}, 0.15)`};
          ${getButtonContentElementsColor(`rgba(${color}, 0.4)`)};
        `;
      }}
    `,
    focus: css`
      background-color: ${({ theme }) => theme.color.primary[800].value};
      ${focusOutlinesStyles.light};
    `,
    hover: css`
      background-color: ${({ theme }) => theme.color.primary[800].value};
    `,
  },
};

export const getStateStyles = (isOnPrimaryBackground) => {
  return isOnPrimaryBackground
    ? secondaryButtonStateStyles.defaultOnPrimaryBackground
    : secondaryButtonStateStyles.default;
};
