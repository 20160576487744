import styled from 'styled-components';

import { wordBreak } from '../../core';

const PageHeaderWrapper = styled.div`
  ${wordBreak}
  background-color: ${({ theme }) => theme.color.additional.light.value};
  color: ${({ theme }) => theme.color.additional.dark.value};
  flex: 1;
`;

export { PageHeaderWrapper };
