import styled, { css } from 'styled-components';

import { getHexToRgb, getRem, transitionAnimation, typographyBody1 } from './../../core';
import { SearchInputClearButton } from './SearchInputClearButton';
import { SearchInputIcon, SearchInputIndicator } from '../elements';
import { inlineInputContainerSize, searchInputBorderRadius } from '../shared-styles';

const StyledSearchInputClearButton = styled(SearchInputClearButton)``;
const SearchInputInput = styled.input.withConfig({
  shouldForwardProp: (prop) => !['expandBehavior', 'isAlwaysExpanded', 'isExpanded', 'isFilled', 'size'].includes(prop),
})`
  ${({ theme }) => typographyBody1(theme)};
  ${transitionAnimation('all')}
  background-color: transparent;
  border: none;
  box-sizing: border-box;
  color: ${({ theme }) => theme.color.text.light.primary};
  cursor: pointer;
  height: ${getRem('36px')};
  outline: 0;
  padding-left: ${getRem('36px')};
  padding-right: 0;
  text-overflow: ellipsis;
  width: ${getRem('36px')};

  ${({ isAlwaysExpanded, isFilled, theme }) =>
    isAlwaysExpanded || isFilled
      ? css`
          background-color: ${theme.color.additional.light.value};
          box-shadow: 0 0 0 1px rgba(${getHexToRgb(theme.color.additional.dark.value)}, 0.3);
          padding-right: ${getRem('40px')};
          width: 100%;

          &:hover {
            box-shadow: 0 0 0 2px rgba(${getHexToRgb(theme.color.additional.dark.value)}, 0.3);
          }
        `
      : css`
          &:hover:not(:focus) {
            ~ ${SearchInputIndicator} {
              background-color: ${theme.color.state.default.hover.value};
            }

            ~ ${StyledSearchInputClearButton} {
              height: 0;
              width: 0;
            }
          }
        `}
  ${({ isExpanded, theme }) =>
    isExpanded &&
    css`
      &:focus {
        background-color: ${theme.color.additional.light.value};
        box-shadow: 0 0 0 2px ${theme.color.primary[500].value};
        width: 100%;

        ~ ${SearchInputIcon} {
          fill: ${theme.color.primary[500].value};
          left: 6px;
        }

        ~ ${SearchInputIndicator} {
          left: 0;
          width: 100%;
        }
      }
    `}

  &::placeholder {
    color: ${({ theme }) => theme.color.text.light.secondary.value};
  }

  &::-ms-clear {
    display: none;
    height: 0;
    width: 0;
  }

  ${({ size }) => css`
    border-radius: ${searchInputBorderRadius[size]};
    height: ${inlineInputContainerSize[size]};
  `}
`;

export { SearchInputInput };
