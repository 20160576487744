const apiEndPoints = {
  getUserPermissions: '/rest/v0.1/permissions/search/byLoginId.json',
  getUserApps: '/rest/v0.1/applications/search.json',
  getAllApps: 'app/platform/appsconfig/apps.conf.json',
  postUserStatusForCVR: `/rest/v0.1/enterprises/editAccess/authorize`,
  getUserImpersonationStatus: `/rest/v0.1/users`,
  postAssignUserRoles: '/rest/v0.1/assignRoles',
  postAssignUserStores: '/rest/v0.1/assignStores',
  deleteRole: (roleGuid: string): string => `/rest/v0.1/roles/${roleGuid}`,
  getServiceUserPermissionsByRoleId: (roleGuid: string): string =>
    `/rest/v0.1/permissions/${roleGuid}`,
  getServiceUserPermissions: `/rest/v0.1/permissions`,
  getRoles: '/rest/v0.1/roles',
  getUserStoresDetails: `/rest/v0.1/enterprises`,

  cdkEditAccess: (enterpriseId: string): string =>
    `/rest/v0.1/enterprises/${enterpriseId}/editAccess`
};

export default apiEndPoints;
