import React from 'react';
import PropTypes from 'prop-types';
import styled, { css } from 'styled-components';

import { truncateText, typographyGroupLabel } from './../../core';
import { INPUT_SIZES } from '../constants';

const StyledInputLabel = styled.span`
  ${({ theme }) => typographyGroupLabel(theme)}
  ${truncateText}
  box-sizing: border-box;
  pointer-events: none;
  width: 100%;
  ${({ isDisabled, isFocused, theme }) =>
    isFocused &&
    !isDisabled &&
    css`
      color: ${theme.color.primary['500'].value};
    `}
  ${({ hasError, isDisabled, theme }) =>
    hasError &&
    !isDisabled &&
    css`
      color: ${theme.color.system.negative[500].value};
    `}
    ${({ isReadOnly, theme }) =>
    isReadOnly &&
    css`
      color: ${theme.color.text.light.secondary.value};
    `}
`;

const InputLabel = styled(
  ({
    customTag = undefined,
    hasError = false,
    isDisabled = false,
    isFocused = false,
    isReadOnly = false,
    isRequired = false,
    label,
    size = INPUT_SIZES.STANDARD,
    ...other
  }) => {
    return (
      <StyledInputLabel
        as={customTag}
        {...{ hasError, isDisabled, isFocused, isReadOnly, isRequired, size }}
        {...other}
      >
        {label}
      </StyledInputLabel>
    );
  }
)``;

InputLabel.propTypes = {
  /** Replaces the default tag with a new tag */
  customTag: PropTypes.elementType,
  /** If true, adjusts label styling */
  hasError: PropTypes.bool,
  /** If true, adjusts label styling */
  isDisabled: PropTypes.bool,
  /** If true, label has focus */
  isFocused: PropTypes.bool,
  /** If true, adjusts label styling */
  isReadOnly: PropTypes.bool,
  /** If true, adds required symbol at the end of label */
  isRequired: PropTypes.bool,
  /** Label of the input field */
  label: PropTypes.node.isRequired,
  /** Size of base input label */
  size: PropTypes.oneOf(Object.values(INPUT_SIZES)),
};

export { InputLabel };
