import React from 'react';
import { components } from 'react-select';
import styled from 'styled-components';

import { getHexToRgb, getRem } from '../../core';

const StyledGroup = styled(components.Group)`
  box-sizing: border-box;
  padding: ${getRem('4px')} 0;

  &:not(:first-of-type) {
    border-top: 1px solid rgba(${({ theme }) => getHexToRgb(theme.color.additional.dark.value)}, 0.3);
  }
`;

// eslint-disable-next-line react/prop-types
export const GroupComponent = ({ groupProps }) => <StyledGroup {...groupProps} />;
