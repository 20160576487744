import { SecondaryButton } from 'cdk-radial';
import { useIntl } from 'react-intl';
import StyledStepOneRoles from './styled';
import { messages } from './messages';

type StepOneRolesProps = {
  onAutoMapRolesClick: () => void;
  isAutoMapDisabled: boolean;
  isAutoMapLoading: boolean;
};

const StepOneRoles = ({
  onAutoMapRolesClick,
  isAutoMapDisabled,
  isAutoMapLoading
}: StepOneRolesProps): JSX.Element => {
  const intl = useIntl();
  return (
    <StyledStepOneRoles data-testid="step-one-role">
      <p className="rolesDesc">
        {intl.formatMessage(messages.stepOneAutomaproles)}
      </p>
      <hr />
      <SecondaryButton
        text={intl.formatMessage(messages.stepOneAutomapRolesButton)}
        onClick={onAutoMapRolesClick}
        isDisabled={isAutoMapDisabled}
        isLoading={isAutoMapLoading}
      />
    </StyledStepOneRoles>
  );
};

export default StepOneRoles;
