import { useEffect } from 'react';

// This is applicable only to menu as menu should close when user clicks on Menu or label
export const useOutsideClickEventListener = (ref1, ref2, callback, isOpen) => {
  const handleClick = (event) => {
    if (isOpen) {
      const clickedOutsideRef1 = ref1.current && !ref1.current.contains(event.target);
      const clickedOutsideRef2 = ref2.current && !ref2.current.contains(event.target);

      if (clickedOutsideRef1 && clickedOutsideRef2 && callback) {
        callback();
      }
    }
  };

  useEffect(() => {
    document.addEventListener('mousedown', handleClick);
    return () => {
      document.removeEventListener('mousedown', handleClick);
    };
  });
};
