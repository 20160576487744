import moment from 'moment';
import FileSaver from 'file-saver';

export type TimeType = {
  label: string;
  value: string;
};

export const getScheduleDateTime = (
  importDate: moment.Moment | null,
  importTime: TimeType | null
): string => {
  const localeDate =
    importDate && importDate.local
      ? importDate.local().format('MM/DD/yyyy')
      : new Date().toLocaleDateString();
  const localeTime = (importTime && importTime.value) || '12:00 AM';
  const scheduleDateTime = moment(
    `${localeDate} ${localeTime}`,
    'MM/DD/YYYY hh:mm A'
  ).toISOString();
  return scheduleDateTime;
};

export const handleDownloadClick = (
  gridApi: { exportDataAsCsv: () => void } | undefined
): void => {
  if (gridApi && gridApi.exportDataAsCsv) {
    gridApi.exportDataAsCsv();
  }
};

export const downLoadCSVFile = (
  fileContents: string,
  fileName: string
): void => {
  const csvFile: Blob = new Blob([fileContents], { type: 'text/csv' });
  FileSaver.saveAs(csvFile, fileName);
};

export const formatTime = (seconds: number): string => {
  const minutes = Math.floor(seconds / 60);
  const remainingSeconds = seconds % 60;
  return `${minutes.toString().padStart(2, '0')}:${remainingSeconds
    .toString()
    .padStart(2, '0')}`;
};
