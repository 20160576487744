import styled from 'styled-components';
import { Dialog } from 'cdk-radial';

const StyledTemplateInfoDialog = styled(Dialog)`
    width: 50vw !important;
    margin-bottom: 1rem;
    .custom-toast{
        margin-top: 1rem;
        margin-bottom: 1rem;
    }
    .custom-ul {
        margin: 0;
        padding:0 1rem;
    }
`;

export default StyledTemplateInfoDialog;
