import csvUserImportStepOne from '../../organisms/csvUserImport/StepOne/StepOne';
import csvUserImportStepTwo from '../../organisms/csvUserImport/StepTwo/StepTwo';
import csvUserImportStepThree from '../../organisms/csvUserImport/StepThree/StepThree';
import csvUserImportStepFour from '../../organisms/csvUserImport/StepFour/StepFour';
import routeIdentifiers from '../../../fixtures/routeIdentifiers';

const stepperRoutes = [{
  ...routeIdentifiers.CSV_USER_IMPORT_STEP_ONE,
  component: csvUserImportStepOne,
},
{
  ...routeIdentifiers.CSV_USER_IMPORT_STEP_TWO,
  component: csvUserImportStepTwo,
},
{
  ...routeIdentifiers.CSV_USER_IMPORT_STEP_THREE,
  component: csvUserImportStepThree,
},
{
  ...routeIdentifiers.CSV_USER_IMPORT_STEP_FOUR,
  component: csvUserImportStepFour,
}];

export default stepperRoutes;
