import styled from 'styled-components';

const StyledAssignRoles = styled.div`
.footer-button-group{
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
}
`;

export default StyledAssignRoles;
