import React, { useState } from 'react';

import PropTypes from 'prop-types';

import { Input, INPUT_ICON_POSITIONS, INPUT_SIZES } from '../../input';
import { ShowHideValueToggleButton } from '../blocks';

const PasswordInput = React.forwardRef(
  (
    {
      customTag = undefined,
      dataTestId = '',
      enableCustomValidation = false,
      errorMessage = '',
      hasError = false,
      helperText = '',
      icon = undefined,
      id,
      isDisabled = false,
      isReadOnly = false,
      isRequired = false,
      label,
      maxLength = 100,
      minLength = 0,
      name,
      onChange,
      placeholder = '',
      prefixText = '',
      toggleHideLabel = 'Show value',
      toggleShowLabel = 'Hide value',
      type = 'text',
      value,
      ...other
    },
    ref
  ) => {
    const [isHidden, setIsHidden] = useState(true);

    const handleToggleShowHide = (event) => (isDisabled ? event.preventDefault() : setIsHidden(!isHidden));

    const renderSuffixElement = () => (
      <ShowHideValueToggleButton
        data-testid={dataTestId ? `${dataTestId}-show-hide-value-button` : undefined}
        isDisabled={isDisabled}
        isHidden={isHidden}
        onToggleShowHide={handleToggleShowHide}
        toggleHideLabel={toggleHideLabel}
        toggleShowLabel={toggleShowLabel}
      />
    );

    const Tag = customTag || Input;

    return (
      <Tag
        type={isHidden ? 'password' : type}
        {...{
          customTag,
          dataTestId,
          enableCustomValidation,
          errorMessage,
          hasError,
          helperText,
          icon,
          id,
          isDisabled,
          isReadOnly,
          isRequired,
          label,
          maxLength,
          minLength,
          name,
          onChange,
          placeholder,
          prefixText,
          renderSuffixElement,
          value,
        }}
        {...other}
        // iconPosition isn't in the prop list, but it could be in ...other. iconPosition is AFTER ...other because the icon can only be in the leading position. The trailing position is occupied by the show/hide icon.
        iconPosition={INPUT_ICON_POSITIONS.LEADING}
        ref={ref}
      />
    );
  }
);

PasswordInput.propTypes = {
  /** Ability to supply a different input element instead of the default one */
  customTag: PropTypes.elementType,
  /** Id value used for testing */
  dataTestId: PropTypes.string,
  /** If true, custom validation is being enabled instead of built in component validation */
  enableCustomValidation: PropTypes.bool,
  /** Message to be displayed when input is in error state */
  errorMessage: PropTypes.node,
  /** When true, input is in error state */
  hasError: PropTypes.bool,
  /** Text to be displayed as a helper text near the input field */
  helperText: PropTypes.node,
  /** Icon to be displayed in input field */
  icon: PropTypes.node,
  /** Identifier of the input component */
  id: PropTypes.string.isRequired,
  /** If true, input is disabled and value of it cannot be edited */
  isDisabled: PropTypes.bool,
  /** If true, input is in read only state, value cannot be edited */
  isReadOnly: PropTypes.bool,
  /** If true, isRequired asterisk will be shown */
  isRequired: PropTypes.bool,
  /** Label of the input field */
  label: PropTypes.node.isRequired,
  /** Maximum amount of characters input field can have */
  maxLength: PropTypes.number,
  /** Minimum amount of characters to be entered so the input field would not be in error state */
  minLength: PropTypes.number,
  /** Name of the input */
  name: PropTypes.string.isRequired,
  /** Callback to be called when input's value is being changed by user interaction */
  onChange: PropTypes.func.isRequired,
  /** Text to be displayed when input is empty */
  placeholder: PropTypes.node,
  /** String to be displayed before the input value. Prefix text should be 1 character. */
  prefixText: PropTypes.node,
  /** Set the size of the input */
  size: PropTypes.oneOf(Object.values(INPUT_SIZES)),
  /** String to be displayed after the input value. Text should contain up to 5 characters, to not get cutted */
  suffixText: PropTypes.node,
  /** Text that is read for screen readers, when value inside the input is hidden */
  toggleHideLabel: PropTypes.node,
  /** Text that is read for screen readers, when value inside the input is visible */
  toggleShowLabel: PropTypes.node,
  /** Standard html input tag type attribute */
  type: PropTypes.string,
  /** Current value of the password input */
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
};

export { PasswordInput };
