import styled from 'styled-components';

export const StyledStepThree = styled.div`
  width: 100%;
  height: -webkit-fill-available;
  .custom-header {
    margin-left: 1rem;
    margin-top: -1rem;
  }
`;

export const StyledStepThreeFilterContainer = styled.div`
  display: flex;
  & > div:first-child {
    flex: 2;
  }
  & > div:last-child {
    flex: 1;
  }
`;
