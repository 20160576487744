/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import { useIntl } from 'react-intl';

interface ConfigArray {
  uid: string;
  mainHeading: string;
  mainDescription: string;
  columnNamesDesc: string;
  TemplateRule: {
    label: string;
    content: {
      title: string;
      rules: string[];
    };
  };
  downloadSampleFile: {
    label: string;
    sampleLink: string;
  };
}

const useTemplateConfig = () => {
  const intl = useIntl();
  const templatesConfig: ConfigArray[] = [
    {
      uid: 'dms',
      mainHeading: intl.formatMessage({
        id: 'CSVIMPORT.TEMPLATE.DMSTEMPLATE',
        defaultMessage: 'DMS CSV Template'
      }),
      mainDescription: intl.formatMessage({
        id: 'CSVIMPORT.TEMPLATE.DMSTEMPLATECONTENT',
        defaultMessage:
          'DMS CSV Template contains list of DMS users that you want to import in Connect CDK. It contains the following fields.'
      }),
      columnNamesDesc:
        'lastName, firstName, dmsUserId, cNumber, email, federationId, employeeId, jobTitle, stores, roles ',
      TemplateRule: {
        label: intl.formatMessage({
          id: 'CSVIMPORT.TEMPLATE.TEMPLATERULE',
          defaultMessage: 'Template Rule and Information'
        }),
        content: {
          title: intl.formatMessage({
            id: 'CSVIMPORT.TEMPLATE.DMSTEMPLATEMODALTITLE',
            defaultMessage: 'DMS CSV Template Information'
          }),
          rules: [
            intl.formatMessage({
              id: 'CSVIMPORT.TEMPLATE.DMSTEMPLATEMODALCONTENT',
              defaultMessage:
                "'lastName', 'firstName', 'cnumber' and 'email' are mandatory fields,DMS User ID and CNumber will be used for user identification during import.  'dmsuserid' of user should have mapping with provided 'cnumber'."
            })
          ]
        }
      },
      downloadSampleFile: {
        label: intl.formatMessage({
          id: 'CSVIMPORT.TEMPLATE.DOWNLOADSAMPLE',
          defaultMessage: 'Download Sample File'
        }),
        sampleLink: '/dmsImport.csv'
      }
    },
    {
      uid: 'nondms',
      mainHeading: intl.formatMessage({
        id: 'CSVIMPORT.TEMPLATE.NONDMSTEMPLATE',
        defaultMessage: 'Non DMS CSV Template'
      }),
      mainDescription: intl.formatMessage({
        id: 'CSVIMPORT.TEMPLATE.NONDMSTEMPLATECONTENT',
        defaultMessage:
          'Non DMS CSV Template contains list of  users who are not in DMS, that you want to import in Connect CDK. It contains the following fields.'
      }),
      columnNamesDesc:
        'lastName, firstName, simpleId, emailId, roles, stores, altSystemId, altUserId, federationId, employeeId, jobTitle, primaryPhone',
      TemplateRule: {
        label: intl.formatMessage({
          id: 'CSVIMPORT.TEMPLATE.TEMPLATERULE',
          defaultMessage: 'Template Rule and Information'
        }),
        content: {
          title: intl.formatMessage({
            id: 'CSVIMPORT.TEMPLATE.NONDMSTEMPLATEMODALTITLE',
            defaultMessage: 'Non DMS CSV Template Information'
          }),
          rules: [
            intl.formatMessage({
              id: 'CSVIMPORT.TEMPLATE.NONDMSTEMPLATEMODALCONTENT',
              defaultMessage:
                "'lastName', 'firstName' and 'simpleId' are mandatory fields,'simpleId should be in email format','Either both(altSystemId & altUserId) should be present or absent.','The column headers are not case sensitive - you can camel case or not, as you please','The data is case sensitive','Column order is fixed, so you cannot rearrange the columns."
            })
          ]
        }
      },
      downloadSampleFile: {
        label: intl.formatMessage({
          id: 'CSVIMPORT.TEMPLATE.DOWNLOADSAMPLE',
          defaultMessage: 'Download Sample File'
        }),
        sampleLink: '/nondmsImport.csv'
      }
    }
  ];
  return [templatesConfig];
};

export default useTemplateConfig;
