import {
  PageHeaderWrapper,
  PageHeaderTitleBlock,
  PageHeaderTitleWithButtons
} from 'cdk-radial';
import { StyledContentHeader } from './styled';
import { BlankHeaderProps } from './types';

export const BlankHeader = ({
  title,
  actionButtons
}: BlankHeaderProps): JSX.Element => (
  <StyledContentHeader data-testid="content-header">
    <PageHeaderWrapper>
      <PageHeaderTitleWithButtons buttons={actionButtons}>
        <PageHeaderTitleBlock title={title} />
      </PageHeaderTitleWithButtons>
    </PageHeaderWrapper>
  </StyledContentHeader>
);
