import FileSaver from 'file-saver';

export const getHeaders = (
  headers: Headers
): import('@reduxjs/toolkit/dist/query/tsHelpers').MaybePromise<Headers> => {
  const unparsedObject = localStorage.getItem('okta-token-storage');
  const token: string =
    unparsedObject &&
    JSON.parse(unparsedObject) &&
    JSON.parse(unparsedObject).accessToken
      ? JSON.parse(unparsedObject).accessToken.accessToken
      : '';

  if (token) {
    headers.set('authorization', `Bearer ${token}`);
    headers.set('content-type', 'application/json');
  }
  return headers;
};

export const downloadFile = (
  blob: Blob,
  reportType: string,
  extenstion = 'xlsx'
): { data: { filename: string } } => {
  const filename = `${reportType}_${new Date()
    .toJSON()
    .slice(0, 16)
    .replace(':', '-')}.${extenstion}`;
  // eslint-disable-next-line @typescript-eslint/no-unsafe-call
  FileSaver.saveAs(blob, filename);
  return { data: { filename } };
};
