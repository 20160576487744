const apiEndPoints = {
  getUserApps: '/rest/v0.1/applications/search.json',
  getUserDetails: '/1.0/rest/users/me',
  getUserPermissions: '/rest/v0.1/permissions/search/byLoginId.json',
  getDmsBoxMappings: '/dmsBoxMappings',
  postScheduleImport: '/1.1.1/rest/dms/users/schedule',
  postNewUser: '/1.1.1/rest/users',
  postNewUserCopyDMS: (copyDmsUser: string): string =>
    `/1.1.1/rest/users/?copyDmsUser=${copyDmsUser}`,
  postBulkUnassignUsers: '/users/unassign/jobs',
  getUnassignUsersJobs: (enterpriseId: string): string =>
    `/users/unassign/${enterpriseId}/jobs`,
  getUnassignUserJobDetails: (jobId: string): string =>
    `/users/unassign/jobs/${jobId}`,
  postImportNow: '/1.1.1/rest/dms/users',
  getUserImportJobs: '/1.1.1/rest/dms/users/schedule',
  getNonDmsUserImportJob: '/jobs/',
  getDmsUsers: '/1.1.1/rest/dms/users',
  getJobTitles: '/rest/v1.2/jobtitles',
  getJobProfiles: '/1.1.1/rest/dms/jobprofiles',
  postDMSJobProfiles: '/1.1.1/rest/dms/jobprofiles',
  getAllRoles: (enterpriseId: string): string =>
    `/enterprises/${enterpriseId}/roles`,
  updateRoles: (enterpriseId: string, roleGuid: string): string =>
    `/enterprises/${enterpriseId}/roles/${roleGuid}`,
  getEnterprisePermissions: (enterpriseId: string): string =>
    `/enterprises/${enterpriseId}/permissions`,
  getAllRoleTemplates: (enterpriseId: string): string =>
    `/roleTemplates?enterpriseId=${enterpriseId}`,
  getRoles: '/rest/v0.1/roles',
  deleteRole: (roleGuid: string): string => `/rest/v0.1/roles/${roleGuid}`,
  postNonDmsUsersImport: (enterpriseId: string): string =>
    `/${enterpriseId}/jobs`,
  bulkUpdateStoreMFAStatus: (enterpriseId: string): string =>
    `/1.2/rest/enterprises/${enterpriseId}/stores/mfa`,
  bulkUpdateUserMFAStatus: (enterpriseId: string): string =>
    `/1.2/rest/enterprises/${enterpriseId}/users/mfa`,
  getUserRoles: (userid: string): string => `/users/${userid}/roles`,
  getUserStores: (userid: string): string => `/stores/search?userId=${userid}`,
  getStoreDetails: '/stores',
  getDepartmentsDetailsByIds: (enterpriseId: string): string =>
    `/enterprises/${enterpriseId}/departments`,
  getDepartmentDetails: '/departments',
  getCMFNumbers: '/cmfs',
  getExternalAttributes: '/externalAttributes',
  putRefreshIPAddressDetails: '/rest/v0.1/orgUnit/metadata.json',
  postDelearChangeMFAStatus: (enterpriseId: string): string =>
    `/1.2/rest/enterprises/${enterpriseId}/stores/mfa`,
  getDmsBoxes: (cNumber: string): string => `/dmsBoxes?cNumber=${cNumber}`,
  getUsers: '/1.1.1/rest/users',
  bulkUpdateEnterpriseMFAStatus: `/1.2/rest/enterprises/mfa`,
  postAssignRoles: '/rest/v0.1/assignRoles',
  getFederationStatus: '/1.1.1/rest/federation/enterprises',
  getuserManagementDetails: (email: string): string =>
    `/1.1.1/rest/users/${email}`,
  postResetPassword: (email: string): string =>
    `/1.1.1/rest/users/${email}/expire_password`,
  postChangePassword: (userId: string): string =>
    `/1.2/rest/users/${userId}/credentials/change_password`,
  postResetPasswordWithRecovery: (email: string): string =>
    `/1.1.1/rest/users/${email}/reset_factors`,
  cdkEditAccess: (enterpriseId: string): string =>
    `/rest/v0.1/enterprises/${enterpriseId}/editAccess`,
  postRoleAssign: '/rolesassignment?action=create',
  postStoreAssign: '/storesassignment?action=create',
  postRoleUnassign: '/rolesassignment?action=delete',
  postStoreUnassign: '/storesassignment?action=delete',
  getSystems: '/1.1.1/rest/systems/identifiers/externalSystems',
  postLinkAccountForUser: (loginId: string): string =>
    `/1.1.1/rest/users/${loginId}/identifiers`,
  deleteLinkedAccount: (alternateId: string, systemId: string): string =>
    `/rest/v1.2/users/identifiers/${alternateId}/system/${systemId}`,
  putUpdateUserDetailsOnSave: (loginId: string): string =>
    `/1.1.1/rest/users/${loginId}`,
  postAssignUserRoles: '/rest/v0.1/assignRoles',
  postAssignUserStores: '/rest/v0.1/assignStores',
  getEnterpriseDetails: '/enterprises',
  getMfaReportSummary: `/1.2/rest/enterprises`,
  getCdkEditAccessEnterpriseList: `/1.1.1/rest/enterprises/exclusionList`,
  postUserStatus: '/1.1.1/rest/users/status',
  postMergeUsers: '/1.1.1/rest/users/merge',
  getUserStoresDetails: `/rest/v0.1/enterprises`,
  postUserStatusForCVR: `/rest/v0.1/enterprises/editAccess/authorize`,
  getGroupData: (enterpriseId: string): string =>
    `enterprises/${enterpriseId}/groups`,
  postGroupData: (enterpriseId: string): string =>
    `enterprises/${enterpriseId}/groups`,
  putGroupData: (enterpriseId: string, groupId: string): string =>
    `enterprises/${enterpriseId}/groups/${groupId}`,
  putOrganizationData: (enterpriseId: string, groupId: string): string =>
    `enterprises/${enterpriseId}/groups/${groupId}/organizationUnits`,
  deleteGroupData: (enterpriseId: string, groupId: string): string =>
    `enterprises/${enterpriseId}/groups/${groupId}`,
  postBulkAssignStores: `/storesassignment?action=create`,
  postBulkAssignRoles: `/rolesassignment?action=create`,
  postBulkUnAssignStores: `/storesassignment?action=delete`,
  postBulkUnAssignRoles: `/rolesassignment?action=delete`,
  getUserImpersonationStatus: `/rest/v0.1/users`,
  getUserActivities: (emailid: string): string =>
    `/1.0/rest/users/${emailid}/activities`,
  getPackagesByStoreId: `/rest/v0.1/packageInstances/search.json?orgUnitId=`,
  getDMSConnectionInfo: `/rest/v0.1/featureInstanceMetadata/search.json?productCode=GY_INT&versionIdenfier=v1.0&featureCodes=Xml_EOAPI,Xml_Apache,postgres_adpreport&orgUnitId=`,
  getLinkedDMSUsers: `/1.1.1/rest/dms/users`,
  getSeletedDMSUsers: (emailid: string): string =>
    `/1.1.1/rest/users/${emailid}/linkedDmsUser`,
  postUnlinkDMSCNumber: (emailId: string): string =>
    `/1.1.1/rest/users/${emailId}/unlinkDms`,
  deleteDMSUsers: (emailId: string): string => `/v2/users/${emailId}`,
  postLinkDMSCnumber: (emailId: string): string =>
    `/1.1.1/rest/users/${emailId}/linkDms`,
  getEmailNotificationUsersStatus: `/1.1.1/rest/users/notifications/email`,
  putSendEmailNotification: `/rest/v1.2/users/notifications/email`,
  allUsersReport: (enterpriseId: string): string =>
    `/1.0/rest/reports/enterprise/${enterpriseId}/userList`,
  putDefaultStore: (loginId: string): string =>
    `/users/${loginId}?updateFields=defaultstore`,
  baseCommonProductAPI: `/rest/v0.1`,
  postUserassignmentByRole: `/rolesassignment`,
  getStoreAdminDetails: (storeId: string): string =>
    `/stores/${storeId}/enterpriseAdmin`,
  lastLoginActivityReport: (enterpriseId: string): string =>
    `/reports/enterprise/${enterpriseId}/lastLoginActivity/export`,
  patchDMSUserStatus: `/1.1.1/rest/users/`,
  userActivityReport: (enterpriseId: string): string =>
    `/reports/enterprise/${enterpriseId}/userActivity/export`,
  postGenerateReport: (enterpriseId: string): string =>
    `/reports/enterprise/${enterpriseId}/generatereport`,
  storeAssigmnetReport: (enterpriseId: string): string =>
    `/reports/enterprise/${enterpriseId}/report/store_assignment_report`,
  roleAssigmnetReport: (enterpriseId: string): string =>
    `/reports/enterprise/${enterpriseId}/report/role_assignment_report`,
  userListReport: (enterpriseId: string): string =>
    `/reports/enterprise/${enterpriseId}/report/userlist_report`,
  reportStatus: (enterpriseId: string): string =>
    `/reports/enterprise/${enterpriseId}/status`,
  getServiceUsers: `/1.1.1/rest/identities/services/users`,
  getStoreAdminDetailsWithPermissions: (loginId: string): string =>
    `/${loginId}/primaryStore/enterpriseAdmin`,
  getOAuthToken: `/1.1.1/rest/oauth/tokenDetails/user`,
  getServiceUserPermissions: `/rest/v0.1/permissions`,
  postNewServiceUser: `/1.0/rest/identities/services`,
  dmsUser: `/1.1.1/rest/enterprises`,
  getServiceUserByLoginId: (loginId: string): string =>
    `/1.1.1/rest/identities/services/users?loginId=${loginId}`,
  getServiceUserDetailsByLoginId: (loginId: string): string =>
    `/1.1.1/rest/users/details/${loginId}`,
  getServiceUserPermissionsByRoleId: (roleGuid: string): string =>
    `/rest/v0.1/permissions/${roleGuid}`,
  putServiceUser: `/1.1.1/rest/identities/services`,
  getServiceUserActivityLogById: (loginId: string): string =>
    `/1.1.1/rest/users/${loginId}/activities`,
  postLinkDMSUser: (userId: string): string =>
    `/rest/v1.2/users/${userId}/linkDms`,
  postNewServiceUserToken: `/1.1.1/rest/oauth/tokens/user`,
  putNewServiceUserToken: `/1.1.1/rest/admin/oauth/tokens`,
  deleteServiceUserToken: `/1.1.1/rest/admin/oauth/tokens`
};
export default apiEndPoints;
