/* eslint-disable @typescript-eslint/no-unsafe-call */
import { createSlice } from '@reduxjs/toolkit';
import type { PayloadAction } from '@reduxjs/toolkit';
import { SelectedDepartmentRow } from '../services/types/store-service-types';

export const unassignDepartmentSlice = createSlice({
  name: 'unassignDepartmentSlice',
  initialState: {
    rowData: [] as SelectedDepartmentRow[],
    primaryStore: ''
  },
  reducers: {
    rowToUnassign: (state, action: PayloadAction<SelectedDepartmentRow>) => {
      state.rowData.push(action.payload);
    },
    removeSelectedRow: state => {
      state.rowData = [];
    },
    setPrimaryStore: (state, action: PayloadAction<string>) => {
      state.primaryStore = action.payload;
    }
  }
});

export const { rowToUnassign, removeSelectedRow, setPrimaryStore } =
  unassignDepartmentSlice.actions;

export default unassignDepartmentSlice.reducer;
