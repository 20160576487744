import { useEffect } from 'react';
import { Helmet, HelmetProvider } from 'react-helmet-async';
import { WALK_ME_URL } from 'components/templates/commonAppLayout/constants';
import { setWalkMeGlobalVariables } from 'components/templates/commonAppLayout/utils';
import { useClient } from './ClientProvider';

export const WalkmeProvider = (): JSX.Element => {
  const client = useClient();

  useEffect(() => {
    if (client.enterpriseId) {
      setWalkMeGlobalVariables(client.enterpriseId, client.userInfo);
    }
  }, [client.userInfo, client.enterpriseId]);

  return (
    <>
      {client.userInfo && (
        <HelmetProvider>
          <Helmet>
            <script type="text/javascript" src={WALK_ME_URL} />
          </Helmet>
        </HelmetProvider>
      )}
    </>
  );
};
