import { defineMessages } from 'react-intl';

export const messages = defineMessages({
  assignRolesDialogTitle: {
    id: 'COMMON.ASSIGNROLES.DIALOG.TITLE',
    defaultMessage: 'Assign Roles'
  },
  assignRolesTableHeader: {
    id: 'COMMON.ASSIGNROLES.TABLE.HEADER',
    defaultMessage: 'All Roles ({count})'
  },
  assignRoles: {
    id: 'COMMON.ASSIGNROLES.HEADER',
    defaultMessage: 'Assign Roles'
  }
});
