import React, { useRef } from 'react';

import { useShareForwardedRef } from 'lib/utilities';
import PropTypes from 'prop-types';
import styled, { css } from 'styled-components';

import { applyStateStyles, useIsKeyboardFocused } from '../../core';
import { RADIO_LABEL_POSITIONS, RADIO_SIZES } from '../constants';
import { RadioContainer, RadioIndicator, RadioInput, RadioLabel } from '../elements';
import { getStateStyles } from '../radioStateStyles';

const StyledRadio = styled(RadioContainer).withConfig({
  shouldForwardProp: (prop) => !['isChecked', 'isKeyboardFocused'].includes(prop),
})`
  ${({ isChecked, isDisabled, isKeyboardFocused }) =>
    css`
      ${applyStateStyles({
        isDisabled,
        stateStyles: getStateStyles(isChecked),
        useFocusStyles: false,
        useHoverState: !isKeyboardFocused,
      })}
      ${!isDisabled && isKeyboardFocused && getStateStyles(isChecked).focus}
    `}
`;

export const Radio = React.forwardRef(
  (
    {
      checked,
      className,
      dataTestId = '',
      hideLabel = false,
      id,
      indicatorClassName = '',
      isDisabled = false,
      label,
      labelPosition = RADIO_LABEL_POSITIONS.AFTER,
      name,
      onChange,
      size = RADIO_SIZES.STANDARD,
      value,
      wrapperProps = {},
      ...other
    },
    ref
  ) => {
    const isLabelBefore = labelPosition === RADIO_LABEL_POSITIONS.BEFORE;
    const isSmall = size === RADIO_SIZES.SMALL;
    const isStandard = size === RADIO_SIZES.STANDARD;
    const isLarge = size === RADIO_SIZES.LARGE;

    const inputRef = useShareForwardedRef(ref);
    const wrapperRef = useRef();

    const isKeyboardFocused = useIsKeyboardFocused(inputRef, wrapperRef);

    return (
      <StyledRadio
        data-testid={dataTestId}
        hideLabel={hideLabel || !label}
        htmlFor={id}
        isChecked={checked}
        ref={wrapperRef}
        {...{ className, isDisabled, isKeyboardFocused, isLabelBefore, isLarge, size }}
      >
        <RadioInput
          aria-label={label}
          disabled={isDisabled}
          ref={inputRef}
          type="radio"
          {...{ checked, id, name, onChange, value }}
          {...other}
        />
        <RadioIndicator
          className={indicatorClassName}
          isChecked={checked}
          {...{ isDisabled, isLabelBefore, isLarge, isSmall, isStandard }}
        />
        {!hideLabel && (
          <RadioLabel aria-hidden="true" {...{ isLabelBefore, size }}>
            {label}
          </RadioLabel>
        )}
      </StyledRadio>
    );
  }
);

Radio.propTypes = {
  /** If true, renders radio option as selected */
  checked: PropTypes.bool.isRequired,
  /** Adds additional class for radio option */
  className: PropTypes.string,
  /** Id value used for testing */
  dataTestId: PropTypes.string,
  /** Visually hides radio option label */
  hideLabel: PropTypes.bool,
  /** Identifier for this radio */
  id: PropTypes.string.isRequired,
  /** Adds additional class to radio option indicator */
  indicatorClassName: PropTypes.string,
  /** If true, disables select option */
  isDisabled: PropTypes.bool,
  /** Radio option label */
  label: PropTypes.node.isRequired,
  /** Places option label before or after indicator */
  labelPosition: PropTypes.oneOf(Object.values(RADIO_LABEL_POSITIONS)),
  /** Name of radio option */
  name: PropTypes.string.isRequired,
  /** Callback function that is being called when radio option is selected */
  onChange: PropTypes.func.isRequired,
  /** Radio size */
  size: PropTypes.oneOf(Object.values(RADIO_SIZES)),
  /** Value of radio option */
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  /** Props passed down to the wrapper element of the radio input */
  wrapperProps: PropTypes.shape({}),
};
