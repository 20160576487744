import React, { cloneElement } from 'react';
import PropTypes from 'prop-types';
import styled, { css } from 'styled-components';

import { getRem, transitionAnimation } from './../../core';
import { INPUT_ICON_POSITIONS } from '../constants';

const inputIconSize = getRem('24px');
const inlineInputIconSize = getRem('14px');

const StyledInputIcon = styled(({ icon, ...props }) => cloneElement(icon, props)).withConfig({
  shouldForwardProp: (prop) => !['hasError', 'isDisabled', 'isFocused', 'isInline', 'isReadOnly'].includes(prop),
})`
  ${transitionAnimation('fill')}
  display: flex;
  fill: ${({ theme }) => theme.color.gray['600'].value};
  flex-shrink: 0;
  height: ${inputIconSize};
  padding-right: ${({ theme }) => theme.size.spacing.small.value};
  width: ${inputIconSize};
  ${({ isDisabled, isFocused, isReadOnly, theme }) =>
    isFocused &&
    !isDisabled &&
    !isReadOnly &&
    css`
      fill: ${theme.color.primary['500'].value};
    `}
  ${({ hasError, isDisabled, theme }) =>
    hasError &&
    !isDisabled &&
    css`
      fill: ${theme.color.system.negative[500].value};
    `}
    ${({ hasError, isInline }) =>
    hasError &&
    isInline &&
    css`
      height: ${inlineInputIconSize};
      width: ${inlineInputIconSize};
    `}
`;

const InputIcon = ({
  hasError = false,
  icon,
  isInline = false,
  position = INPUT_ICON_POSITIONS.TRAILING,
  ...other
}) => <StyledInputIcon {...{ hasError, icon, isInline, position }} {...other} />;

InputIcon.propTypes = {
  /** If true, adjusts label styling */
  hasError: PropTypes.bool,
  /** Icon to be displayed within input field */
  icon: PropTypes.node.isRequired,
  /** If true, input icon is inside inline input */
  isInline: PropTypes.bool,
  /** Sets the position of icon */
  position: PropTypes.oneOf(Object.values(INPUT_ICON_POSITIONS)),
};

export { InputIcon };
