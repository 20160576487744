import { IntlShape } from 'react-intl';
import { messages } from './messages';

type ValidationReturnType = {
  content: null | Record<string, any>[];
  mainErrors: Array<string> | null;
  validated: boolean;
};

const dmsConfig: Record<string, any> = {
  lastName: {
    checkHeader: true,
    required: true
  },
  firstName: {
    checkHeader: true,
    required: true
  },
  dmsUserId: {
    checkHeader: true,
    required: true
  },
  cNumber: {
    checkHeader: true,
    required: true
  },
  email: {
    checkHeader: true,
    required: true
  },
  federationId: {
    checkHeader: true
  },
  employeeId: {
    checkHeader: true
  },
  jobTitle: {
    checkHeader: true
  },
  stores: {
    checkHeader: true
  },
  roles: {
    checkHeader: true
  }
};

const nonDmsConfig = {
  lastname: {
    checkHeader: true,
    required: true
  },
  firstname: {
    checkHeader: true,
    required: true
  },
  simpleid: {
    checkHeader: true,
    required: true
  },
  emailId: {
    checkHeader: true
  },
  roles: {
    checkHeader: true
  },
  stores: {
    checkHeader: true
  },
  altSystemId: {
    checkHeader: true
  },
  altUserId: {
    checkHeader: true
  },
  federationid: {
    checkHeader: true
  },
  employeeId: {
    checkHeader: true
  },
  jobTitle: {
    checkHeader: true
  },
  primaryPhone: {
    checkHeader: true
  }
};

const validateErrors = (
  content: Record<string, any>[],
  uid: string,
  headers: string[],
  allStores: Record<string, any>,
  allRoles: Record<string, any>,
  intl: IntlShape
): ValidationReturnType => {
  const response: ValidationReturnType = {
    content: null,
    mainErrors: null,
    validated: false
  };

  if (!uid) {
    response.mainErrors = ['Please select a valid template'];
    return response;
  }

  const missingHeaders: string[] = [];
  const headerObj: Record<string, any> =
    headers && Array.isArray(headers)
      ? headers.reduce((acc, curr) => ({ ...acc, [curr]: true }), {})
      : {};

  const config = uid === 'dms' ? dmsConfig : nonDmsConfig;
  Object.keys(config).forEach(headerName => {
    if (!headerObj[headerName]) {
      missingHeaders.push(headerName);
    }
  });

  if (missingHeaders.length) {
    response.mainErrors = [
      intl.formatMessage(messages.csvValidationError, {
        missingHeaders: missingHeaders.join(', ')
      })
    ];
    return response;
  }
  const errorRows: Record<string, any>[] = [];

  const requiredFiedls: Array<string> = [];
  Object.keys(config).forEach((headerName: string) => {
    if (config[headerName].required) {
      requiredFiedls.push(headerName);
    }
  });

  content.forEach(row => {
    const rowCopy: Record<string, any> = { ...row };
    let isErrorThere = false;

    if (requiredFiedls && requiredFiedls.length) {
      requiredFiedls.forEach((requiredField: string) => {
        if (!rowCopy[requiredField].value.length) {
          rowCopy[requiredField] = {
            value: '',
            error: 'This field is mandatory'
          };
          isErrorThere = true;
        }
      });
    }

    const validEmailRegex =
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
    // eslint-disable-next-line @typescript-eslint/no-unsafe-call
    if (
      rowCopy.email &&
      rowCopy.email.value &&
      !validEmailRegex.test(rowCopy.email.value)
    ) {
      isErrorThere = true;
      const errStr: string = rowCopy.email.value;
      rowCopy.email = {
        value: '',
        error: `${errStr} is not a valid email`
      };
    }
    if (
      rowCopy.emailId &&
      rowCopy.emailId.value &&
      !validEmailRegex.test(rowCopy.emailId.value)
    ) {
      isErrorThere = true;
      const errStr: string = rowCopy.emailId.value;
      rowCopy.emailId = {
        value: '',
        error: `${errStr} is not a valid email`
      };
    }
    if (
      rowCopy.simpleid &&
      rowCopy.simpleid.value &&
      !validEmailRegex.test(rowCopy.simpleid.value)
    ) {
      isErrorThere = true;
      const errStr: string = rowCopy.simpleid.value;
      rowCopy.simpleid = {
        value: '',
        error: `${errStr} is not a valid email`
      };
    }

    if (
      rowCopy.altSystemId &&
      rowCopy.altSystemId.value.length &&
      !rowCopy.altUserId.value.length
    ) {
      isErrorThere = true;
      rowCopy.altUserId = {
        value: '',
        error: `Alternate UserID cannot be empty`
      };
    }

    if (
      rowCopy.altUserId &&
      rowCopy.altUserId.value.length &&
      !rowCopy.altSystemId.value.length
    ) {
      isErrorThere = true;
      rowCopy.altSystemId = {
        value: '',
        error: `Alternate SystemID cannot be empty`
      };
    }

    if (rowCopy.stores.value && rowCopy.stores.value.length) {
      const invalidStores: string[] = [];
      // eslint-disable-next-line @typescript-eslint/no-unsafe-call
      const stores: string[] = rowCopy.stores.value.split(',');
      stores.forEach((store: string) => {
        if (!allStores[store.trim()]) {
          invalidStores.push(store);
        }
      });
      if (invalidStores.length) {
        isErrorThere = true;
        rowCopy.stores = {
          value: '',
          error: `${invalidStores.join(',')} are invalid`
        };
      }
    }
    if (rowCopy.roles.value && rowCopy.roles.value.length) {
      const invalidRoles: string[] = [];
      // eslint-disable-next-line @typescript-eslint/no-unsafe-call
      const roles: string[] = rowCopy.roles.value.split(',');
      roles.forEach((role: string) => {
        if (!allRoles[role.trim()]) {
          invalidRoles.push(role);
        }
      });
      if (invalidRoles.length) {
        isErrorThere = true;
        rowCopy.roles = {
          value: '',
          error: `${invalidRoles.join(',')} are invalid`
        };
      }
    }
    if (isErrorThere) {
      errorRows.push(rowCopy);
    }
  });

  if (errorRows.length) {
    response.mainErrors = [
      'Please correct the following error and re-upload the CSV file'
    ];
    response.content = errorRows;
  }

  if (!response.content && !response.mainErrors) {
    response.validated = true;
  }

  return response;
};

export const getFieldValue = (
  row: Record<string, any>,
  curr: string,
  tempType: string
): any => {
  if (curr === 'roles') {
    if (row[curr] && row[curr].value && Array.isArray(row[curr].value)) {
      if (tempType === 'dms') {
        // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
        // eslint-disable-next-line @typescript-eslint/no-unsafe-call
        return row[curr].value.map(
          (role: { roleGuid: string }) => role.roleGuid
        );
      }
      // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
      // eslint-disable-next-line @typescript-eslint/no-unsafe-call
      return row[curr].value.map((role: { code: string }) => role.code);
    }
  }
  if (curr === 'stores') {
    if (row[curr] && row[curr].value && Array.isArray(row[curr].value)) {
      // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
      // eslint-disable-next-line @typescript-eslint/no-unsafe-call
      return row[curr].value.map((store: { id: string }) => store.id);
    }
  }
  return row[curr] && row[curr].value ? row[curr].value : '';
};

export default validateErrors;
