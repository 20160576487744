import React from 'react';

import { IconStatusInformation } from 'lib/icons';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import { BaseIndicator } from './BaseIndicator';
import { neutralStateStyles } from './StateStyles';
import { ElementStateWrapper } from '../../core';
import { StatusIcon } from '../elements';

const StyledStatusIcon = styled(StatusIcon)`
  fill: ${({ theme }) => theme.color.system.neutral[500].value};
`;

const StyledBaseIndicator = styled(BaseIndicator)`
  background-color: ${({ theme }) => theme.color.system.neutral[100].value};
  border-color: ${({ theme }) => theme.color.system.neutral[500].value};
`;

const InformationIndicator = React.forwardRef(
  (
    {
      ariaLabel = 'Status Indicator',
      enableStateStyles,
      isDisabled,
      isFullWidth = undefined,
      label,
      showIcon = false,
      ...other
    },
    ref
  ) => {
    const renderIcon = () =>
      showIcon && (
        <StyledStatusIcon>
          <IconStatusInformation />
        </StyledStatusIcon>
      );

    if (enableStateStyles) {
      return (
        <ElementStateWrapper isDisabled={isDisabled} stateStyles={neutralStateStyles}>
          <StyledBaseIndicator
            {...{ ariaLabel, enableStateStyles, isFullWidth, label }}
            {...other}
            renderBeforeContent={renderIcon}
          />
        </ElementStateWrapper>
      );
    } else {
      return <StyledBaseIndicator {...{ ariaLabel, isFullWidth, label }} {...other} renderBeforeContent={renderIcon} />;
    }
  }
);

InformationIndicator.propTypes = {
  /** Informs screen reader users what actions they should take */
  ariaLabel: PropTypes.node,
  /** Enables hover, focus, active, disabled states on status indicator */
  enableStateStyles: PropTypes.bool,
  /** Changes status indicator to disabled state */
  isDisabled: PropTypes.bool,
  /** If true, status indicator takes full width of container */
  isFullWidth: PropTypes.bool,
  /** Status indicator label */
  label: PropTypes.node.isRequired,
  /** Shows icon inside the indicator */
  showIcon: PropTypes.bool,
};

export { InformationIndicator };
