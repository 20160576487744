import styled from 'styled-components';
import { ShellContainer } from 'cdk-radial';

const intFrameHeight = window.innerHeight;

const StyledCommonAppLayout = styled.div`
  .main-section {
    display: flex;
    height: ${intFrameHeight}px;
    max-height: ${intFrameHeight - 130}px;
    height: ${intFrameHeight}px;
  }

  .main-section-impersonation {
    height: ${intFrameHeight}px;
    max-height: ${intFrameHeight - 130}px;
    border: 6px solid #30d158;
    margin: 14px;
    padding: 7px;
  }

  .main-section-edit {
    height: ${intFrameHeight}px;
  }

  .card-div {
    background-color: #ffffff;
    border: 1px;
    border-radius: 8px 8px 0px 0px;
    height: ${intFrameHeight}px;
    max-height: ${intFrameHeight - 130}px;
    width: 100%;
    overflow-y: auto;
  }

  .show-table-border {
    border: 1px solid #b9bbbe;
    border-radius: 0.5rem;
    overflow: hidden;
    height: 100%;
  }
  .show-table-height {
    height: 80% !important;
  }

  .ag-center-cols-container {
    width: 100%;
  }

  .loading-container {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100vw;
    height: 100vh;
  }
`;

const StyledShellContainer = styled(ShellContainer)`
  grid-template-rows: auto auto 1fr auto;
  .global-toast {
    z-index: 50;
  }
`;

export { StyledCommonAppLayout, StyledShellContainer };
