import { IconError, IconInfo, IconCheckCircle, IconStatusWarningExclamation } from 'lib/icons';

export const ANIMATION_DURATION = 200;

export const MAX_ALLOWED_ACTIONS = 10;

export const TOAST_POSITIONS = {
  FIXED: 'fixed',
  INLINE: 'inline',
};

export const TOAST_VARIANTS = {
  NEGATIVE: 'negative',
  NEUTRAL: 'neutral',
  POSITIVE: 'positive',
  WARNING: 'warning',
};

export const TOAST_VARIANT_ICONS = {
  [TOAST_VARIANTS.NEGATIVE]: IconError,
  [TOAST_VARIANTS.NEUTRAL]: IconInfo,
  [TOAST_VARIANTS.POSITIVE]: IconCheckCircle,
  [TOAST_VARIANTS.WARNING]: IconStatusWarningExclamation,
};

export const TOAST_VISIBILITY_DURATIONS = {
  EIGHT_SECONDS: 8000,
  INFINITE: 0,
  SEVEN_SECONDS: 7000,
  SIX_SECONDS: 6000,
};
