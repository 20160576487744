import { TransformAddressProps } from './types';

export const TransformAddress = ({
  addresses
}: TransformAddressProps): JSX.Element => {
  const address = addresses?.find(
    ({ addressType }) => addressType === 'Physical'
  );
  let addressString = '';
  if (
    address &&
    (address.streetAddress ||
      address.city ||
      address.stateCode ||
      address.postalCode)
  ) {
    addressString = `${address.streetAddress || ''}, ${address.city || ''}, ${
      address.stateCode || ''
    } ${address.postalCode || ''}`;
  }

  return <span>{addressString}</span>;
};
