import React, { cloneElement } from 'react';
import { Avatar } from 'lib/avatar';
import PropTypes from 'prop-types';
import styled, { css } from 'styled-components';

import { getRem } from '../../../core';

const StyledListItemGraphic = styled(({ children, isDisabled = false, isStyledAvatar = false, ...other }) =>
  cloneElement(children, { isDisabled, isStyledAvatar, ...other })
).withConfig({
  shouldForwardProp: (prop) => !['isDisabled', 'isStyledAvatar'].includes(prop),
})`
  flex-shrink: 0;
  margin-right: ${getRem(12)};
  pointer-events: none;
  ${({ children, isStyledAvatar }) =>
    children.type !== Avatar &&
    !isStyledAvatar &&
    css`
      display: flex;
      fill: ${({ isDisabled, theme }) =>
        isDisabled ? theme.color.text.light.disabled.value : theme.color.text.light.secondary.value};
      height: ${getRem(24)};
      width: ${getRem(24)};
    `};
`;

const ListItemGraphic = (props) => <StyledListItemGraphic {...props} />;

ListItemGraphic.propTypes = {
  /** Changes appearance to look visually non-interactable */
  isDisabled: PropTypes.bool,
  /** Changes appearance if children is Avatar styled with styled-components */
  isStyledAvatar: PropTypes.bool,
};

export { ListItemGraphic };
