import styled from 'styled-components';

const StyledImportHeader = styled.div`
  display: flex;
  margin: 0 1rem;
  .customized-button {
    margin-right: 0.7rem;
  }
  align-items: center;
`;

export default StyledImportHeader;
