import { fetchBaseQuery } from '@reduxjs/toolkit/query';
import { getHeaders } from 'redux/utils';

const API_BASE_URL: string =
  process.env.REACT_APP_AUTHZ_NEBULA_API_BASE_URL || '';

const baseQuery = fetchBaseQuery({
  baseUrl: API_BASE_URL,
  prepareHeaders: headers => getHeaders(headers),
  credentials: 'include'
});

export default baseQuery;
