import { css } from 'styled-components';

import { getHexToRgb } from './../../core';

export const footerButtonStyles = css`
  background-color: ${({ theme }) => `rgba(${getHexToRgb(theme.color.additional.light.value)}, 0.15)`};
  z-index: ${({ theme }) => theme.zIndex.globalNavigationFooter.value};

  & span {
    color: ${({ theme }) => theme.color.additional.dark.value};
  }

  &&& svg {
    fill: ${({ theme }) => `rgba(${getHexToRgb(theme.color.additional.dark.value)}, 0.7)`};
  }
`;
