import styled from 'styled-components';

import { removeOutline } from './../../core';

export const NormalizedButton = styled.button`
  ${({ showOutline }) => !showOutline && removeOutline};
  background-color: transparent;
  border: none;
  border-color: transparent;
  box-sizing: border-box;
  color: ${({ theme }) => theme.color.additional.dark.value};
  margin: 0;

  & > div,
  & > span,
  & > svg,
  ::after,
  ::before {
    position: relative;
  }
`;
