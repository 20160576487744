import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import moment, { Moment } from 'moment';

export type FileDataType = null | {
  path: string;
  currentStep: number;
  rows: Record<string, any>[] | null;
  errorContent: {
    content: null | Record<string, any>[];
    mainErrors: Array<string> | null;
    validated: boolean;
  };
};

type TimeType = {
  label: string;
  value: string;
};

type CSVImportState = {
  templateType: string;
  fileDetails: FileDataType;
  jobName: string;
  importDate: Moment | null;
  importTime: TimeType | null;
  csvValidations: {
    step_one: {
      isStepValid: null | boolean;
      error: string;
    };
    step_two: {
      isStepValid: null | boolean;
      error: string;
    };
    step_three: {
      isStepValid: null | boolean;
      error: string;
      errorRows: Record<number, any>;
    };
    step_four: {
      isStepValid: null | boolean;
      error: string;
      jobName: {
        isValid: null | boolean;
        error: string;
      };
      importDate: {
        isValid: null | boolean;
        error: string;
      };
      importTime: {
        isValid: null | boolean;
        error: string;
      };
    };
  };
};

type DataRemovalType = {
  fieldIndex: number;
  rowIndex: number;
  field: string;
};

// Define the initial state using that type
export const initialState: CSVImportState = {
  templateType: '',
  fileDetails: null,
  jobName: '',
  importDate: moment(),
  importTime: null,
  csvValidations: {
    step_one: {
      isStepValid: null,
      error: ''
    },
    step_two: {
      isStepValid: null,
      error: ''
    },
    step_three: {
      isStepValid: null,
      error: '',
      errorRows: {}
    },
    step_four: {
      isStepValid: null,
      error: '',
      jobName: {
        isValid: null,
        error: ''
      },
      importDate: {
        isValid: null,
        error: ''
      },
      importTime: {
        isValid: null,
        error: ''
      }
    }
  }
};

type TemplateRowFieldType = {
  rowIndex: number;
  keyToAccess?: string;
  field: string;
  value: any;
};

export const csvImportSlice = createSlice({
  name: 'csvImportState',
  // `createSlice` will infer the state type from the `initialState` argument
  initialState,
  reducers: {
    resetCSVInfo: state => {
      state.templateType = '';
      state.fileDetails = null;
    },
    setTemplateType: (state, action: PayloadAction<string>) => {
      state.templateType = action.payload;
    },
    setTemplateUploadData: (state, action: PayloadAction<FileDataType>) => {
      state.fileDetails = action.payload;
    },
    setTemplatDataRows: (
      state,
      action: PayloadAction<Record<string, any>[]>
    ) => {
      if (state.fileDetails && state.fileDetails.rows) {
        state.fileDetails.rows = action.payload;
      }
    },
    setTemplatDataRowField: (
      state,
      {
        payload: { rowIndex, field, value }
      }: PayloadAction<TemplateRowFieldType>
    ) => {
      if (state.fileDetails && state.fileDetails.rows) {
        state.fileDetails.rows[rowIndex][field].value = value;
      }
    },
    setTemplateRolesAndStores: (
      state,
      {
        payload: { rowIndex, field, value, keyToAccess = '' }
      }: PayloadAction<TemplateRowFieldType>
    ) => {
      if (state.fileDetails && state.fileDetails.rows) {
        const isValidArray =
          Array.isArray(state.fileDetails.rows[rowIndex][field].value) &&
          state.fileDetails.rows[rowIndex][field].value.length;

        // eslint-disable-next-line
        const newItems = isValidArray
          ? [...state.fileDetails.rows[rowIndex][field].value, ...value]
          : value;
        // eslint-disable-next-line
        const uniqueItems = newItems.filter(
          (v: Record<string, any>, i: number, a: Record<string, any>[]) =>
            a.findIndex(t => t[keyToAccess] === v[keyToAccess]) === i
        );
        state.fileDetails.rows[rowIndex][field].value = uniqueItems;
      }
    },
    setTemplateFieldExpansion: (
      state,
      {
        payload: { rowIndex, field, value }
      }: PayloadAction<TemplateRowFieldType>
    ) => {
      if (state.fileDetails?.rows) {
        state.fileDetails.rows[rowIndex][field].value = [
          ...state.fileDetails.rows[rowIndex][field].value
        ];
        state.fileDetails.rows[rowIndex][`${field}_expansion`] = value;
      }
    },
    setTemplateRowHeight: (
      state,
      { payload: { rowIndex, rowHeight, field } }
    ) => {
      if (state.fileDetails?.rows) {
        const newFieldHeights = state.fileDetails.rows[rowIndex].fieldHeight
          ? {
              ...state.fileDetails.rows[rowIndex].fieldHeight,
              [field]: rowHeight
            }
          : { [field]: rowHeight };
        state.fileDetails.rows[rowIndex].fieldHeight = newFieldHeights;
        const heightsArray: number[] = Object.values(newFieldHeights);
        const calculatedRowHeight = Math.max(...heightsArray);
        state.fileDetails.rows[rowIndex].rowHeight = calculatedRowHeight;
      }
    },
    handleDataRemoval: (
      state,
      {
        payload: { fieldIndex, rowIndex, field }
      }: PayloadAction<DataRemovalType>
    ) => {
      if (state.fileDetails?.rows) {
        const isValidArray = Array.isArray(
          state.fileDetails.rows[rowIndex][field].value 
        );
        
        // eslint-disable-next-line
        const filteredArray = isValidArray ? state.fileDetails.rows[rowIndex][field].value.filter(
          (item: string, i: number) => i !== fieldIndex
        )
      : [];
          
        state.fileDetails.rows[rowIndex][field].value = filteredArray;
      }
    },
    setJobName: (state, action: PayloadAction<string>) => {
      state.jobName = action.payload;
    },
    setImportDate: (state, action: PayloadAction<Moment | null>) => {
      state.importDate = action.payload;
    },
    setImportTime: (state, action: PayloadAction<TimeType>) => {
      state.importTime = action.payload;
    },
    setValidationStatus: (
      state,
      action: PayloadAction<{
        currentStep: 'step_one' | 'step_two' | 'step_three' | 'step_four';
        validations: any;
      }>
    ) => {
      const { currentStep, validations } = action.payload;
      state.csvValidations[currentStep] = validations;
    }
  }
});

export const {
  resetCSVInfo,
  setTemplateType,
  setTemplateUploadData,
  setTemplatDataRows,
  setTemplatDataRowField,
  setTemplateFieldExpansion,
  setTemplateRowHeight,
  setTemplateRolesAndStores,
  handleDataRemoval,
  setJobName,
  setImportDate,
  setImportTime,
  setValidationStatus
} = csvImportSlice.actions;

export default csvImportSlice.reducer;