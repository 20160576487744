/* eslint-disable react/jsx-props-no-spreading, @typescript-eslint/no-unsafe-call */
import { GridApi } from 'ag-grid-community';
import { useIntl } from 'react-intl';
import {
  TablePagination,
  DEFAULT_ROWS_PER_PAGE_OPTIONS,
  preventDefaultBehavior,
  focusLastCell
} from 'cdk-radial';
import { messages } from 'components/templates/messages';
import paginationPageSize, {
  getPageSummaryText,
  getRowsSummaryText
} from '../../../../helpers/tableHelper';
import StyledImportHeader from './styled';

type TablePaginationProps = {
  currentPage: number;
  totalPages: number;
  totalRows: number;
  gridApi?: GridApi;
};

export const handleTabbingFromPagination = (
  event: Event | null,
  gridApi?: GridApi
): void => {
  const hasRowsToDisplay = gridApi && gridApi.getDisplayedRowCount.length > 0;
  if (hasRowsToDisplay) {
    preventDefaultBehavior(event);
    focusLastCell(gridApi);
  }
};

const TablePaging = ({
  currentPage,
  totalPages,
  totalRows,
  gridApi
}: TablePaginationProps): JSX.Element => {
  const intl = useIntl();

  const paginationProps = {
    ariaLabel: 'Pagination',
    buttonLabels: {
      goToFirst: 'Go to first',
      goToLast: 'Go to last',
      goToNext: 'Go to next',
      goToPrevious: 'Go to previous'
    },
    className: '',
    dataTestId: 'table-pagination',
    defaultPaginationPageSize: DEFAULT_ROWS_PER_PAGE_OPTIONS[3],
    gridApi,
    hideBorder: false,
    onTabbingIntoTable: (event: Event) =>
      handleTabbingFromPagination(event, gridApi),
    pageSizeDropdownId: 'page-size-dropdown-table',
    pageSummaryLabelId: 'pagination-summary-label-table',
    pageSummaryText: getPageSummaryText(
      totalPages === 0 ? 0 : currentPage,
      totalPages
    ),
    rowsPerPageLabel: intl.formatMessage(messages.rowsPerPage),
    rowsPerPageOptions: DEFAULT_ROWS_PER_PAGE_OPTIONS,
    rowsSummaryText: getRowsSummaryText(
      currentPage,
      paginationPageSize(gridApi),
      totalRows
    ),
    showGoToFirstLastPage: true
  };
  return (
    <StyledImportHeader data-testid="table-header-pagination">
      {gridApi && <TablePagination {...paginationProps} />}
    </StyledImportHeader>
  );
};

export default TablePaging;
