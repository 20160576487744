/* eslint-disable @typescript-eslint/no-unsafe-call */
import { Chip, MenuListItem } from 'cdk-radial';
import { useEffect, useRef, useState } from 'react';
import { useIntl } from 'react-intl';
import {
  StyledMultiSelectFilter,
  StyledMenu,
  StyledMenuListItem
} from './styled';
import { OptionType } from '../../../../helpers/types/tableFilterType';
import getLabel from '../../../../helpers/filterHeaderHelper';
import { OptionValueType, MultiSelectFilterProps } from './types';
import { messages } from './messages';

const MultiSelectFilter = ({
  options,
  selectedOptions,
  setSelectedOptions,
  prefix,
  isAuto
}: MultiSelectFilterProps): JSX.Element => {
  const targetRef = useRef<any>(null);
  const newTargetRef = useRef<any>(null);
  const intl = useIntl();
  const [isOpen, setIsOpen] = useState(false);

  const handleClickOutside = (event: MouseEvent) => {
    if (
      newTargetRef.current &&
      !newTargetRef.current.contains(event.target as Node) &&
      targetRef.current &&
      !targetRef.current.contains(event.target as Node)
    ) {
      setIsOpen(false);
    }
  };

  useEffect(() => {
    if (isOpen) {
      document.addEventListener('mousedown', handleClickOutside);
    }
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [isOpen]);

  const isAllSelected = selectedOptions.length === options.length;
  const isAnySelected = selectedOptions.length > 0;
  const isIndeterminate = !isAllSelected && isAnySelected;

  const compareValues = (
    selectedValue: OptionValueType,
    currentValue: OptionValueType,
    isEqual = true
  ): boolean => {
    if (typeof selectedValue === 'string' && typeof currentValue === 'string') {
      return isEqual
        ? selectedValue === currentValue
        : selectedValue !== currentValue;
    }
    if (
      typeof selectedValue === 'object' &&
      selectedValue !== null &&
      typeof currentValue === 'object' &&
      currentValue !== null
    ) {
      const minEqual = selectedValue.min === currentValue.min;
      const maxEqual = selectedValue.max === currentValue.max;
      return isEqual ? minEqual && maxEqual : !minEqual || !maxEqual;
    }
    return false;
  };

  const handleSelect = (option: OptionType) => {
    const newSelectedOptions = selectedOptions.filter(opt =>
      compareValues(opt.value, option.value, false)
    );
    const isAlreadySelected =
      newSelectedOptions.length !== selectedOptions.length;
    if (isAlreadySelected) {
      setSelectedOptions(newSelectedOptions);
    } else {
      const updatedSelectedOptions = [option, ...selectedOptions];
      setSelectedOptions(updatedSelectedOptions);
    }
  };
  const handleAllSelect = () => {
    if (!isAnySelected) {
      const optionsExcludingDisabled = options.filter(
        (option: OptionType) => !option.disabled
      );
      setSelectedOptions(optionsExcludingDisabled);
      return;
    }
    setSelectedOptions([]);
  };

  const handleClearSelect = () => {
    setIsOpen(false);
    setSelectedOptions([]);
  };

  const getIsOptionSelected = (selectedOption: OptionType) =>
    selectedOptions.some(opt => compareValues(opt.value, selectedOption.value));

  const handleOpen = () => {
    setIsOpen(true);
  };

  const handleClose = () => {
    setIsOpen(false);
  };

  return (
    <StyledMultiSelectFilter data-testid="multi-select-container">
      <Chip
        data-testid="chip"
        label={getLabel(selectedOptions, prefix)}
        ref={targetRef}
        isSelected={!!selectedOptions.length}
        onClick={isOpen ? handleClose : handleOpen}
        onRemove={
          selectedOptions.length ? () => handleClearSelect() : undefined
        }
        hasDropdown={selectedOptions.length === 0}
      />
      <StyledMenu
        isOpen={isOpen}
        labelRef={targetRef}
        multi
        ref={newTargetRef}
        data-testid="menu"
        isAuto={isAuto}
        onClose={handleClose}
      >
        <StyledMenuListItem
          data-testid="menu-item"
          onClick={handleAllSelect}
          isSelected={isAllSelected || isIndeterminate}
          indeterminate={isIndeterminate}
          showDivider
        >
          {intl.formatMessage(messages.selectAllLabel)}
        </StyledMenuListItem>
        {options.map(option => (
          <MenuListItem
            key={option.label}
            isSelected={getIsOptionSelected(option)}
            onClick={() => handleSelect(option)}
            isDisabled={option.disabled}
            showDivider={option.showDivider}
          >
            {option.label}
          </MenuListItem>
        ))}
      </StyledMenu>
    </StyledMultiSelectFilter>
  );
};

export default MultiSelectFilter;
