import {
  IconDashboard,
  IconPeople,
  IconAdmin,
  IconRepairOrder,
  IconHome,
  IconFace,
  IconBuildingCorporateOffice,
  IconOtherReports,
  IconSettings,
  IconNetworkingRoom
} from 'cdk-radial';
import routeIdentifiers from 'fixtures/routeIdentifiers';
import { useIntl } from 'react-intl';
import { messages } from './messages';

type MenuItem = {
  href: string;
  icon?: JSX.Element;
  text: string;
  id: string;
  onClick?: () => void;
  items?: MenuItem[];
};

type Key =
  | 'GENERAL'
  | 'USERS'
  | 'ROLES'
  | 'STORES'
  | 'REPORTS'
  | 'SETTINGS'
  | 'GROUPS'
  | 'LOOKER_REPORT'
  | 'BULK_UN_ASSIGNMENT';

type RouteDirType = (route: string, event?: string) => void;

type DealerMenuItemsProps = {
  handleGeneralMenu: () => void;
  handleUsers: () => void;
  handleUser: () => void;
  handleManageUserImport: () => void;
  handleMergeUser: () => void;
  handleEmailNotification: () => void;
  handleRoles: () => void;
  handleAllRoles: () => void;
  handleStores: () => void;
  handleReports: () => void;
  handleSetting: () => void;
  handleGroup: () => void;
  handleLookerReport: () => void;
  handleBulkUnAssignment: () => void;
};

type ProfileMenuItem = {
  dataTestId: string;
  groupLabel: string;
  id: string;
  items: {
    onClick: () => void;
    id: string;
    text: string;
  }[];
};

export const useCdkUserMenuItems = (routeToDir: RouteDirType): MenuItem[] => {
  const intl = useIntl();
  return [
    {
      href: '',
      icon: <IconDashboard />,
      text: intl.formatMessage(messages.sideNavAllEnterprises),
      id: routeIdentifiers.CDK_HOME.pageId,
      onClick: () => routeToDir(routeIdentifiers.CDK_HOME.pageId)
    },
    {
      href: '',
      icon: <IconPeople />,
      text: intl.formatMessage(messages.sideNavUsers),
      id: routeIdentifiers.ALL_ENTERPRISE_USERS.id,
      onClick: () => routeToDir(routeIdentifiers.ALL_ENTERPRISE_USERS.pageId)
    },
    {
      href: '',
      icon: <IconAdmin />,
      text: intl.formatMessage(messages.sideNavServiceUsers),
      id: routeIdentifiers.SERVICE_USERS.pageId,
      onClick: () => routeToDir(routeIdentifiers.SERVICE_USERS.pageId)
    },
    {
      href: '',
      icon: <IconRepairOrder />,
      text: intl.formatMessage(messages.sideNaveTools),
      id: routeIdentifiers.TOOLS.id,
      onClick: () => routeToDir(routeIdentifiers.TOOLS.pageId)
    }
  ];
};

export const useDealerMenuItems = ({
  handleGeneralMenu,
  handleUsers,
  handleUser,
  handleManageUserImport,
  handleMergeUser,
  handleEmailNotification,
  handleRoles,
  handleAllRoles,
  handleStores,
  handleReports,
  handleSetting,
  handleGroup,
  handleLookerReport,
  handleBulkUnAssignment
}: DealerMenuItemsProps): Record<Key, MenuItem> => {
  const intl = useIntl();
  return {
    GENERAL: {
      href: '',
      icon: <IconHome />,
      text: intl.formatMessage(messages.sideNavGeneral),
      id: routeIdentifiers.ENTERPRISE_GENERAL.id,
      onClick: handleGeneralMenu
    },
    USERS: {
      href: '',
      icon: <IconPeople />,
      text: intl.formatMessage(messages.sideNavUsersMenu),
      id: 'users-section',
      onClick: handleUsers,
      items: [
        {
          href: '',
          text: intl.formatMessage(messages.sideNavAllUsers),
          id: routeIdentifiers.USERS.id,
          onClick: handleUser
        },
        {
          href: '',
          text: intl.formatMessage(messages.sideNavUserImports),
          id: routeIdentifiers.MANAGE_USER_IMPORTS.id,
          onClick: handleManageUserImport
        },
        {
          href: '',
          text: intl.formatMessage(messages.sideNavMergeUser),
          id: routeIdentifiers.MERGE_USER.id,
          onClick: handleMergeUser
        },
        {
          href: '',
          text: intl.formatMessage(messages.sideNavEmailNotifications),
          id: routeIdentifiers.EMAIL_NOTIFICATIONS.id,
          onClick: handleEmailNotification
        }
      ]
    },
    ROLES: {
      href: '',
      icon: <IconFace />,
      text: intl.formatMessage(messages.sideNavRoles),
      id: 'roles-section',
      items: [
        {
          href: '',
          text: intl.formatMessage(messages.sideNavAllRoles),
          id: routeIdentifiers.ROLES.pageId,
          onClick: handleRoles
        }
      ],
      onClick: handleAllRoles
    },
    STORES: {
      href: '',
      icon: <IconBuildingCorporateOffice />,
      text: intl.formatMessage(messages.sideNavStores),
      id: routeIdentifiers.STORES.pageId,
      onClick: handleStores
    },
    REPORTS: {
      href: '',
      icon: <IconOtherReports />,
      text: intl.formatMessage(messages.sideNavLogsAndReports),
      id: routeIdentifiers.REPORTS_HOME.pageId,
      onClick: handleReports
    },
    SETTINGS: {
      href: '',
      icon: <IconSettings />,
      text: intl.formatMessage(messages.sideNavSettings),
      id: routeIdentifiers.SETTINGS_HOME.pageId,
      onClick: handleSetting
    },
    GROUPS: {
      href: '',
      icon: <IconNetworkingRoom />,
      text: intl.formatMessage(messages.sideNavGroups),
      id: routeIdentifiers.GROUPS.id,
      onClick: handleGroup
    },
    LOOKER_REPORT: {
      href: '',
      icon: <IconOtherReports />,
      text: intl.formatMessage(messages.sideNavUserManagementReports),
      id: routeIdentifiers.USER_MANAGEMENT_REPORTS_HOME.pageId,
      onClick: handleLookerReport
    },
    BULK_UN_ASSIGNMENT: {
      href: '',
      text: intl.formatMessage(messages.sideNavBulkUnassignUsers),
      id: routeIdentifiers.BULK_UNASSIGN_USERS.id,
      onClick: handleBulkUnAssignment
    }
  };
};

export const useProfileMenuItem = (
  handleResetPassword: () => void
): ProfileMenuItem[] => {
  const intl = useIntl();
  return [
    {
      dataTestId: 'global-navigation-menu-group-1',
      groupLabel: 'Default Group',
      id: 'group-list-default',
      items: [
        {
          onClick: handleResetPassword,
          id: 'group-default-list-item-0',
          text: intl.formatMessage(messages.resetPassword)
        }
      ]
    }
  ];
};
