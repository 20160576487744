import styled, { css } from 'styled-components';

import { getRem, transitionAnimation } from '../../core';
import { INPUT_SIZES } from '../../input';
import { EXPAND_BEHAVIORS } from '../constants';
import { inlineInputContainerSize, searchInputBorderRadius } from '../shared-styles';

const getInputIndicatorMarginX = (size) => {
  const inputIndicatorMarginX = {
    [INPUT_SIZES.LARGE]: getRem(-2),
    [INPUT_SIZES.SMALL]: getRem(2),
    [INPUT_SIZES.STANDARD]: 0,
  };

  return inputIndicatorMarginX[size];
};

const inputIndicatorMarginLeftSlideLeft = {
  [INPUT_SIZES.LARGE]: `calc(100% - ${getRem('38px')})`,
  [INPUT_SIZES.SMALL]: `calc(100% - ${getRem('34px')});`,
  [INPUT_SIZES.STANDARD]: `calc(100% - ${getRem('36px')});`,
};

const SearchInputIndicator = styled.span.withConfig({
  shouldForwardProp: (prop) => !['expandBehavior', 'size'].includes(prop),
})`
  ${transitionAnimation('all')};
  ${({ expandBehavior, size, theme }) => css`
    border-radius: ${searchInputBorderRadius[size]};
    height: ${inlineInputContainerSize[size]};
    left: ${expandBehavior === EXPAND_BEHAVIORS.SLIDE_LEFT
      ? inputIndicatorMarginLeftSlideLeft[size]
      : getInputIndicatorMarginX(size, theme)};
    pointer-events: none;
    position: absolute;
    right: ${expandBehavior === EXPAND_BEHAVIORS.SLIDE_LEFT ? getInputIndicatorMarginX(size, theme) : 'auto'};
    top: 0;
    width: ${inlineInputContainerSize[size]};
  `}
`;

export { SearchInputIndicator };
