import { CheckboxIndicator } from 'lib/checkbox';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import { getRem } from '../../../core';

const ListItemCheckbox = styled(CheckboxIndicator).attrs(({ indeterminate, isDisabled, onClick }) => ({
  indeterminate: indeterminate || false,
  isDisabled: isDisabled || false,
  onClick: onClick || (() => {}),
}))`
  margin-left: ${getRem(5)};
`;

ListItemCheckbox.propTypes = {
  /** The current value of checkbox - checked or unchecked */
  checked: PropTypes.bool.isRequired,
  /** Changes the icon and sets checkbox indeterminate parameter state */
  indeterminate: PropTypes.bool,
  /** Disables to change the value of checkbox and shows it visually disabled */
  isDisabled: PropTypes.bool,
  /** Optional callback to be called on checkbox click */
  onClick: PropTypes.func,
};

export { ListItemCheckbox };
