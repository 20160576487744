import styled from 'styled-components';

const StyledNoPermissionDialog = styled.div`
.footer-button-group{
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    margin-top: 0.75rem;
}
.no-permission-text{
    margin-top: 0.5rem;
    margin-bottom: 1rem;
}
`;

export default StyledNoPermissionDialog;
