import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import { getHexToRgb } from './../../core';

const StyledDivider = styled.hr.withConfig({
  shouldForwardProp: (prop) => !['showMargins'].includes(prop),
})`
  background-color: rgba(${({ theme }) => getHexToRgb(theme.color.additional.dark.value)}, 0.3);
  border: none;
  box-sizing: border-box;
  height: 1px;
  margin: ${({ showMargins, theme }) => (showMargins ? theme.size.spacing.medium.value : 0)} 0;
  width: 100%;
`;

const Divider = ({ showMargins = false, ...props }) => <StyledDivider showMargins={showMargins} {...props} />;

Divider.propTypes = {
  /** Enables top and bottom margins */
  showMargins: PropTypes.bool,
};

export { Divider };
