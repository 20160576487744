import { useEventListener } from './useEventListener';

export const useKeyDownEventListener = (keyValue, handler, element = window) => {
  const handleKeyDownEvent = (event) => {
    const eventKeyValue = event.key || undefined;

    if (eventKeyValue === keyValue) {
      handler(event);
    }
  };

  useEventListener('keydown', handleKeyDownEvent, element);
};

export const useKeyCodeEventListener = (codeValue, handler, element = window) => {
  const handleKeyDownEvent = (event) => {
    const eventKeyValue = event.code || undefined;

    if (eventKeyValue === codeValue) {
      handler(event);
    }
  };

  useEventListener('keydown', handleKeyDownEvent, element);
};
