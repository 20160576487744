import React from 'react';
import PropTypes from 'prop-types';
import styled, { css } from 'styled-components';

import { BaseIndicator } from './BaseIndicator';
import { StatusIcon, StatusLabel } from '../elements';

const StyledStatusIcon = styled(StatusIcon).withConfig({
  shouldForwardProp: (prop) => !['iconColor'].includes(prop),
})`
  fill: ${({ theme }) => theme.color.gray[500].value};
  ${({ iconColor }) =>
    iconColor &&
    css`
      fill: ${iconColor};
    `};
`;

const StyledBaseIndicator = styled(BaseIndicator)`
  ${({ borderColor }) =>
    borderColor &&
    css`
      border-color: ${borderColor};
    `};
  ${({ backgroundColor }) =>
    backgroundColor &&
    css`
      background-color: ${backgroundColor};
    `};

  ${StatusLabel} {
    ${({ textColor }) =>
      textColor &&
      css`
        color: ${textColor};
      `};
  }
`;

const CustomIndicator = ({
  backgroundColor = '',
  borderColor = '',
  icon = undefined,
  iconColor = '',
  showIcon = false,
  textColor = '',
  ...other
}) => {
  const renderIcon = () => !!icon && <StyledStatusIcon iconColor={iconColor}>{icon}</StyledStatusIcon>;

  return (
    <StyledBaseIndicator
      {...{ backgroundColor, borderColor, showIcon, textColor }}
      {...other}
      renderBeforeContent={renderIcon}
    />
  );
};

CustomIndicator.propTypes = {
  /** Adds custom background color to status indicator */
  backgroundColor: PropTypes.string,
  /** Adds custom border color to status indicator */
  borderColor: PropTypes.string,
  /** Shows icon inside the indicator. Use icon component from the library */
  icon: PropTypes.node,
  /** Changes icon fill color inside the indicator */
  iconColor: PropTypes.string,
  /** Status indicator label */
  label: PropTypes.node.isRequired,
  /** Shows icon inside the indicator */
  showIcon: PropTypes.bool,
  /** Adds custom label text color to status indicator */
  textColor: PropTypes.string,
};

export { CustomIndicator };
