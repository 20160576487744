import React from 'react';

import { Loader, StyledLoaderSpinner } from 'lib/loader';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import { getRem } from './../../core';
import { ChipContent } from './ChipContent';
import { iconElementBasePositioning } from '../iconElementBasePositioning';

const StyledChipContent = styled(ChipContent)`
  cursor: progress;
  padding-left: ${getRem('30px')};
`;

const StyledSpinner = styled(StyledLoaderSpinner)``;

export const StyledChipLoader = styled(Loader)`
  ${iconElementBasePositioning};
  left: ${getRem('7px')};

  ${StyledSpinner} {
    height: ${getRem('15px')};
    width: ${getRem('15px')};
  }

  && {
    position: absolute;
  }
`;

const ChipContentWithLoader = React.forwardRef(
  (
    {
      disableFocus = false,
      isLoading = false,
      label,
      loaderText = 'Loading...',
      onClick = undefined,
      value = null,
      ...other
    },
    ref
  ) => {
    const renderLoader = () => !!isLoading && <StyledChipLoader hideLabel label={loaderText} />;

    return (
      <StyledChipContent
        {...{ disableFocus, label, onClick, value }}
        {...other}
        ref={ref}
        renderBeforeContent={renderLoader}
      />
    );
  }
);

ChipContentWithLoader.propTypes = {
  /** If true, chips won't be focusable via keyboard */
  disableFocus: PropTypes.bool,
  /** If true, changes button content into loader icon with loader text */
  isLoading: PropTypes.bool,
  /** Chip label */
  label: PropTypes.node.isRequired,
  /** Provided information is rendered as loader text */
  loaderText: PropTypes.node,
  /** Callback function which is triggered on chip click */
  onClick: PropTypes.func,
  /** Value of the chip */
  value: PropTypes.node,
};

export { ChipContentWithLoader };
