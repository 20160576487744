/* eslint-disable react/jsx-props-no-spreading */
import {
  TOAST_VARIANTS,
  IndeterminateProgressCircle,
  LOADER_SIZE,
  StatusIndicator,
  STATUS_INDICATOR_VARIANTS
} from 'cdk-radial';
import { useDispatch } from 'react-redux';
import { useState, useEffect } from 'react';
import { useIntl } from 'react-intl';
import { messages as commonMessages } from 'commonMessages';
import { setGlobalToast } from 'redux/slices/commonSlice';
import { ActionsContainerProps } from 'components/molecules/common/Header/ShowCounter/type';
import { ShowCounter } from 'components/molecules/common/Header/ShowCounter/ShowCounter';
import useCountdown from 'hooks/useCountDown';
import { ACTIVE } from '../../../../../fixtures/headerConstant';
import { messages } from '../messages';
import {
  StyledButtonImperpesonateContainer,
  StyledImpersonateContainer
} from '../styled';

const ImpersonationContainer = ({
  impersonationDetails,
  handleImpersonationChange
}: ActionsContainerProps): JSX.Element => {
  const intl = useIntl();
  const [isImperonateActive, setIsImperonateActive] = useState(false);
  const [isImperonateOn, setIsImperonateOn] = useState(['']);

  const [days, hours, minutes, seconds] = useCountdown(
    new Date(`${impersonationDetails?.impersonationTimeLeft}Z`)
  );
  const dispatch = useDispatch();
  useEffect(() => {
    if (
      impersonationDetails &&
      impersonationDetails?.impersonationStatus === ACTIVE
    ) {
      setIsImperonateOn([impersonationDetails?.impersonationStatus]);
      setIsImperonateActive(true);
    } else {
      setIsImperonateOn([]);
      setIsImperonateActive(false);
    }
  }, [impersonationDetails]);

  const handleOptionSelect = () => {
    setIsImperonateOn([impersonationDetails?.impersonationStatus]);
    handleImpersonationChange([impersonationDetails?.impersonationStatus]);
    setIsImperonateActive(current => !current);
  };

  if (isImperonateActive) {
    const totalTimeRemaining = days + hours + minutes + seconds;
    if (hours === 0 && minutes === 5 && seconds === 0) {
      dispatch(
        setGlobalToast({
          content: intl.formatMessage(messages.impersonationHeaderAlert, {
            enterpriseId: impersonationDetails.impersonatingEnterpriseId
          }),
          variant: TOAST_VARIANTS.WARNING
        })
      );
    }
    if (totalTimeRemaining === 0) {
      dispatch(
        setGlobalToast({
          content: intl.formatMessage(messages.impersonationExpired, {
            enterpriseId: impersonationDetails.impersonatingEnterpriseId
          }),
          variant: TOAST_VARIANTS.ERROR
        })
      );
    }
  }

  return (
    <StyledImpersonateContainer data-testid="Top-header-actions-container">
      {impersonationDetails?.hasImpersonationPermission && (
        <>
          {impersonationDetails.isLoading ? (
            <div className="impersonations-loader">
              <IndeterminateProgressCircle
                data-testid="impersonation-loader"
                size={LOADER_SIZE.SMALL}
                ariaLabel={intl.formatMessage(commonMessages.loading)}
              />
            </div>
          ) : (
            <div
              data-testid="impersonation-container"
              className="impersonation-container"
            >
              {isImperonateActive && (
                <StatusIndicator
                  className="impersonation-status"
                  label={
                    <div>
                      <ShowCounter
                        hours={hours}
                        minutes={minutes}
                        seconds={seconds}
                      />
                    </div>
                  }
                  showIcon
                  variant={STATUS_INDICATOR_VARIANTS.WARNING}
                />
              )}
              <StyledButtonImperpesonateContainer
                text={
                  isImperonateOn.length > 0
                    ? intl.formatMessage(messages.endImpersonation)
                    : intl.formatMessage(messages.impersonate)
                }
                variant={isImperonateOn.length > 0 ? 'secondary' : 'primary'}
                onClick={handleOptionSelect}
                data-testid="impersonation-filterchip"
              />
            </div>
          )}
        </>
      )}
    </StyledImpersonateContainer>
  );
};

export default ImpersonationContainer;
