import { defineMessages } from 'react-intl';

export const messages = defineMessages({
  sideNaveTools: {
    id: 'COMMON.SIDENAV.MOVESTORES',
    defaultMessage: 'Tools'
  },
  sideNavMergeEnterprises: {
    id: 'COMMON.SIDENAV.MERGEENTERPRISES',
    defaultMessage: 'Merge Enterprises'
  },
  sideNavGeneral: {
    id: 'COMMON.SIDENAV.NAVMENUITEM.GENERAL',
    defaultMessage: 'General'
  },
  sideNavUsersMenu: {
    id: 'COMMON.SIDENAV.NAVMENUITEM.USERS',
    defaultMessage: 'Users'
  },
  sideNavAllUsers: {
    id: 'COMMON.SIDENAV.NAVMENUITEM.ALLUSERS',
    defaultMessage: 'All Users'
  },
  sideNavUserImports: {
    id: 'COMMON.SIDENAV.NAVMENUITEM.MANAGEUSERIMPORTS',
    defaultMessage: 'Manage User Imports'
  },
  sideNavMergeUser: {
    id: 'COMMON.SIDENAV.NAVMENUITEM.MERGEUSER',
    defaultMessage: 'Merge User'
  },
  sideNavEmailNotifications: {
    id: 'COMMON.SIDENAV.NAVMENUITEM.EMAILNOTIFICATIONS',
    defaultMessage: 'Email Notifications'
  },
  sideNavBulkUnassignUsers: {
    id: 'COMMON.SIDENAV.NAVMENUITEM.BULKUNASSIGNUSERS',
    defaultMessage: 'Bulk Unassign Users'
  },
  sideNavRoles: {
    id: 'COMMON.SIDENAV.NAVMENUITEM.ROLES',
    defaultMessage: 'Roles'
  },
  sideNavAllRoles: {
    id: 'COMMON.SIDENAV.NAVMENUITEM.ALLROLES',
    defaultMessage: 'All Roles'
  },
  sideNavStores: {
    id: 'COMMON.SIDENAV.NAVMENUITEM.STORES',
    defaultMessage: 'Stores'
  },
  sideNavLogsAndReports: {
    id: 'COMMON.SIDENAV.NAVMENUITEM.LOGSANDREPORTS',
    defaultMessage: 'Logs and Reports (OLD)'
  },
  sideNavUserManagementReports: {
    id: 'COMMON.SIDENAV.NAV.MENU.ITEM.USER.MANAGEMENT.REPORTS',
    defaultMessage: 'Reports'
  },
  sideNavSettings: {
    id: 'COMMON.SIDENAV.NAVMENUITEM.SETTINGS',
    defaultMessage: 'Settings'
  },
  sideNavGroups: {
    id: 'FIXTURES.NAVIGATIONMENUITEMS.TEXT.GROUPSDETAILS',
    defaultMessage: 'Groups'
  },
  sideNavAllEnterprises: {
    id: 'COMMON.SIDENAV.CDKHOMENAVMENUITEMS.ALLENTERPRISES',
    defaultMessage: 'All Enterprises'
  },
  sideNavServiceUsers: {
    id: 'COMMON.SIDENAV.CDKHOMENAVMENUITEMS.SERVICEUSERS',
    defaultMessage: 'Service Users'
  },
  sideNavUsers: {
    id: 'COMMON.SIDENAV.ALLUSERS',
    defaultMessage: 'Users'
  },
  sideNavManageUserImports: {
    id: 'COMMON.SIDENAV.NONPILOTNAVMENUITEMS.MANAGEUSERIMPORTS',
    defaultMessage: 'Manage User Imports'
  },
  sideNavLogsAndReportsMenu: {
    id: 'COMMON.SIDENAV.NONPILOTNAVMENUITEMS.LOGSANDREPORTS',
    defaultMessage: 'Logs and Reports'
  },
  sideNavSettingsMenu: {
    id: 'COMMON.SIDENAV.NONPILOTNAVMENUITEMS.SETTINGS',
    defaultMessage: 'Settings'
  },
  profile: {
    id: 'COMMON.HEADER.TOPBAR.OPTION.PROFILE',
    defaultMessage: 'Profile'
  },
  resetPassword: {
    id: 'COMMON.HEADER.ACCOUNT.PROFILE.RESETPASSWORD',
    defaultMessage: 'Reset Password'
  },
  signOut: {
    id: 'COMMON.HEADER.ACCOUNT.PROFILE.SIGNOUT',
    defaultMessage: 'Sign out'
  },
  disableImpersonation: {
    id: 'COMMON.HEADER.DISABLEIMPERSONATION',
    defaultMessage: `Impersonation disabled {enterprise}`
  },
  enableImpersonation: {
    id: 'COMMON.HEADER.ENABLEIMPERSONATION',
    defaultMessage: `Impersonation enabled {enterprise}`
  },
  allEnterprisesTitle: {
    id: 'COMMON.HEADER.NAVIGATIONMENU.TEXT.ALLENTERPRISES',
    defaultMessage: 'All Enterprises'
  },
  impersonationHeaderAlert: {
    id: 'COMMON.HEADER.IMPERSONATIONALERT',
    defaultMessage: 'Impersonation will expire in about 5 minutes.'
  },
  impersonationExpired: {
    id: 'COMMON.HEADER.IMPERSONATIONEXPIRED',
    defaultMessage: 'Impersonation expired'
  },
  newCommonAdminHeader: {
    id: 'COMMON.HEADER.NEWCOMMONADMIN',
    defaultMessage: 'Modern Common Admin'
  },
  impersonatingTitle: {
    id: 'COMMON.HEADER.BANNER.TITLE.IMPERSONATING',
    defaultMessage: 'Impersonating {id}'
  },
  impersonateTitle: {
    id: 'COMMON.HEADER.BANNER.TITLE.IMPERSONATE',
    defaultMessage: 'Impersonate {id}'
  },
  endImpersonation: {
    id: 'COMMON.HEADER.BUTTON.END.IMPERSONATION',
    defaultMessage: `End Impersonation`
  },
  impersonate: {
    id: 'COMMON.HEADER.BUTTON.IMPERSONATE',
    defaultMessage: `Impersonate`
  },
  appSwitcherToggleMessage: {
    id: 'COMMON.HEADER.APPSWITCHER.TOGGLE.MESSAGE',
    defaultMessage: `Toggle this switch to return to Classic Admin`
  }
});
