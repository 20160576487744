import React from 'react';

import { IconBookmark, IconFavorite, IconStar } from 'lib/icons';
import { getEmptyArrayFromLength } from 'lib/utilities';
import PropTypes from 'prop-types';
import styled, { css } from 'styled-components';

import { getCellRendererProps } from './getCellRendererProps';
import { BREAKPOINTS, getRem } from '../../core';
import { CELL_ICON_TYPE, ROW_SIZES } from '../constants';

const StyledCustomIcon = styled(({ icon, ...props }) => React.cloneElement(icon, props)).withConfig({
  shouldForwardProp: (prop) => !['isMobile'].includes(prop),
})`
  fill: ${({ theme }) => theme.color.gray[700].value};
  height: ${getRem(20)};
  width: ${getRem(20)};

  @media ${BREAKPOINTS.L_MAX} {
    height: ${getRem(24)};
    width: ${getRem(24)};
  }

  ${({ isMobile }) =>
    isMobile &&
    css`
      height: ${getRem(24)};
      width: ${getRem(24)};
    `};
`;

const IconRenderer = ({ rendererIcon = CELL_ICON_TYPE.STAR, rowSize = ROW_SIZES.STANDARD, value, ...other }) => {
  const iconProps = getCellRendererProps(other);
  const isMobile = rowSize === ROW_SIZES.MOBILE;

  const getCustomIcon = () => {
    switch (rendererIcon) {
      case CELL_ICON_TYPE.BOOKMARK:
        return <IconBookmark />;
      case CELL_ICON_TYPE.STAR:
        return <IconStar />;
      default:
        return <IconFavorite />;
    }
  };

  const renderIcon = (key) => (
    <StyledCustomIcon icon={getCustomIcon()} isMobile={isMobile} key={`icon-${key}`} {...iconProps} />
  );

  return getEmptyArrayFromLength(value).map((_, index) => renderIcon(index));
};

IconRenderer.propTypes = {
  /** Icon to be rendered */
  rendererIcon: PropTypes.string,
  /** Table row height */
  rowSize: PropTypes.number,
  /** Defines how many times icon should be rendered */
  value: PropTypes.number.isRequired,
};

export { IconRenderer };
