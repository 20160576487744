import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import { visuallyHidden } from '../../core';

export const StyledRadioInput = styled.input`
  ${visuallyHidden}
`;

const RadioInput = React.forwardRef(({ checked = false, disabled = false, ...other }, ref) => (
  <StyledRadioInput {...{ checked, disabled }} {...other} ref={ref} />
));

RadioInput.propTypes = {
  /** If true, will color child indicator using primary color on active/focus states. Otherwise will use gray  */
  checked: PropTypes.bool,
  /** If true, will not color indicators as checked on active/focus states */
  disabled: PropTypes.bool,
};

export { RadioInput };
