/* eslint-disable @typescript-eslint/no-unsafe-call,
@typescript-eslint/no-unsafe-return,
@typescript-eslint/no-floating-promises,
react-hooks/exhaustive-deps */
/* eslint-disable */
import { GridApi } from 'ag-grid-community';
import { BUTTON_VARIANTS, Button, Dialog, TOAST_VARIANTS } from 'cdk-radial';
import { messages as commonMessages } from 'commonMessages';
import ErrorToast from 'components/ui/ErrorToast';
import useColumns from 'fixtures/grid/autoMapRoles/columnDefinitions';
import schema from 'fixtures/grid/autoMapRoles/filterSchema';
import { generateFilterSelectionObject } from 'helpers/tableHelper';
import { useEffect, useRef, useState } from 'react';
import { useIntl } from 'react-intl';
import { useDispatch, useSelector } from 'react-redux';
import { usePostDMSJobProfilesMutation } from 'redux/services/identity-service';
import {
  JobProfileType,
  JobRoleType
} from 'redux/services/identity-service/types';
import { setGlobalToast } from 'redux/slices/commonSlice';
import { setAutoMapRoleJobProfiles } from 'redux/slices/userImportSlice';
import { RootState } from 'redux/store';
import { handleDownloadClick } from 'utils/utility';
import FilterHeader from '../../../templates/TableLayout/FilterHeader/FilterHeader';
import TableContainer from '../../../templates/TableLayout/TableContainer/TableContainer';
import TableContent from '../../../templates/TableLayout/TableContent/TableContent';
import TableHeader from '../../../templates/TableLayout/TableHeader/TableHeader';
import TablePaging from '../../../templates/TableLayout/TablePaging/TablePaging';
import { messages } from './messages';
import StyledAutoMapRoles from './styled/autoMapRoles';
import { AutoMapDialogProps, JobRole, Role } from './types';

export const getJobProfile = (
  jobProfilesRef: JobProfileType[],
  jobRolesRef: JobRoleType[],
  updatedRole: JobRole[]
): any => {
  const jobProilesArray = [...jobProfilesRef];
  const map: Record<any, any> = {};
  jobProilesArray.forEach((item: JobProfileType) => {
    map[item.id] = item;
  });
  updatedRole.forEach((item: JobRole) => {
    if (!item.roles) {
      item.roles = jobRolesRef;
    }
    map[item.id] = item;
  });
  const result = Object.values(map);
  return result;
};

const AutoMapRolesDialog: React.FC<AutoMapDialogProps> = ({
  isAutoMapRolesDilaogOpen,
  closeAutoMapRolesDialog,
  dmsUsers
}: AutoMapDialogProps) => {
  const [postDMSJobProfiles, response] = usePostDMSJobProfilesMutation();
  const { autoMapRoleJobProfiles } = useSelector(
    (state: RootState) => state.slices.userImportState
  );
  const intl = useIntl();
  const dispatch = useDispatch();
  const filterSchema = [...schema];
  const [columns] = useColumns();
  const [searchValue, setSearchValue] = useState('');
  const [filterSelections, setFilterSelections] = useState<
    Record<string, Array<string | undefined>>
  >(generateFilterSelectionObject(filterSchema));
  const [gridApi, setGridApi] = useState<GridApi>();
  const [currentPage, setCurrentPage] = useState(0);
  const [totalPages, setTotalPages] = useState(0);
  const [totalRows, setTotalRows] = useState(0);
  const [newJobProfiles, setNewJobProfiles] = useState<Array<JobRole>>([]);
  const displayedRowCount: number = gridApi?.getDisplayedRowCount() || 0;
  let tempJobs: Array<JobRole> = [];
  const jobProfilesRef = useRef<JobProfileType[]>([]);
  const jobRolesRef = useRef<JobRoleType[]>([]);

  const handleCancel = () => {
    setNewJobProfiles([]);
    if (dmsUsers) {
      let profiles = dmsUsers.jobProilesArray;
      const allRoles = dmsUsers.allRolesArray;
      let rolesForOptions: JobRoleType[] = [];
      if (profiles.length > 0 && allRoles.length > 0) {
        rolesForOptions = allRoles.map((role: Role, index: number) => ({
          value: role.roleName,
          label: role.roleName,
          roleGuid: role.roleGuid,
          id: index
        }));
        profiles = profiles.map((jobProfile: JobProfileType) => {
          const profile = { ...jobProfile };
          profile.roles = rolesForOptions;
          return profile;
        });
        jobProfilesRef.current = profiles;
        jobRolesRef.current = rolesForOptions;
        dispatch(setAutoMapRoleJobProfiles(profiles));
      }
    }
    closeAutoMapRolesDialog();
    tempJobs = [];
  };

  const handleSave = async () => {
    const payload = {
      dmsJobProfile: {
        dmsJobProfile: newJobProfiles
      }
    };
    if (newJobProfiles.length > 0) {
      const postDMSJobProfilesResponse = await postDMSJobProfiles(payload);
      if (!('error' in postDMSJobProfilesResponse)) {
        dispatch(
          setGlobalToast({
            content:
              newJobProfiles.length > 1
                ? intl.formatMessage(messages.automapToastForRoles)
                : intl.formatMessage(messages.automapToastForRole),
            variant: TOAST_VARIANTS.POSITIVE
          })
        );
      }
    }
    setNewJobProfiles([]);
    closeAutoMapRolesDialog();
    tempJobs = [];
  };

  const updateJobProfiles = (updatedRole: JobRole[]) => {
    const result = getJobProfile(
      jobProfilesRef.current,
      jobRolesRef.current,
      updatedRole
    );
    dispatch(setAutoMapRoleJobProfiles([...result]));
  };

  const updateRoleField = (updatedRole: JobRole) => {
    if (tempJobs.length > 0) {
      let sameRole = false;
      tempJobs = tempJobs.map(i => {
        if (i.id === updatedRole.id) {
          sameRole = true;
          return updatedRole;
        }
        return i;
      });
      if (!sameRole) tempJobs.push(updatedRole);
    } else {
      tempJobs.push(updatedRole);
    }
    setNewJobProfiles(tempJobs);
    updateJobProfiles([...tempJobs]);
  };

  useEffect(() => {
    if (dmsUsers) {
      let profiles = dmsUsers.jobProilesArray;
      const allRoles = dmsUsers.allRolesArray;
      let rolesForOptions: JobRoleType[] = [];
      if (profiles.length > 0 && allRoles.length > 0) {
        rolesForOptions = allRoles.map((role: Role, index: number) => ({
          value: role.roleName,
          label: role.roleName,
          roleGuid: role.roleGuid,
          id: index
        }));
        profiles = profiles.map((jobProfile: JobProfileType) => {
          const profile = { ...jobProfile };
          profile.roles = rolesForOptions;
          return profile;
        });
        jobProfilesRef.current = profiles;
        jobRolesRef.current = rolesForOptions;
        dispatch(setAutoMapRoleJobProfiles(profiles));
      }
    }
  }, [dmsUsers, dispatch]);

  return (
    <>
      {response.isError && <ErrorToast dataError={response.error} />}

      <Dialog
        dataTestId="auto-map-dialog"
        id="auto-map-roles-dialog"
        className="auto-map-dialog"
        isOpen={isAutoMapRolesDilaogOpen}
        onClose={closeAutoMapRolesDialog}
        title={intl.formatMessage(messages.automapRolesButton)}
        style={{ width: '70vw' }}
      >
        <StyledAutoMapRoles>
          <TableContainer showBorder>
            <TableHeader
              isDownloadable
              disableDownloadButton={displayedRowCount === 0}
              onDownloadClick={() => handleDownloadClick(gridApi)}
              headerTitle={`Roles (${displayedRowCount})`}
            />
            <FilterHeader
              filterSchema={filterSchema}
              searchValue={searchValue}
              setSearchValue={setSearchValue}
              filterSelections={filterSelections}
              setFilterSelections={setFilterSelections}
            />
            <TableContent
              gridApi={gridApi}
              searchValue={searchValue}
              filterSchema={filterSchema}
              filterSelections={filterSelections}
              setGridApi={setGridApi}
              setCurrentPage={setCurrentPage}
              setTotalPages={setTotalPages}
              setTotalRows={setTotalRows}
              columns={columns}
              data={autoMapRoleJobProfiles}
              rowHeight={50}
              context={{ updateRoleField }}
            />
            <TablePaging
              gridApi={gridApi}
              currentPage={currentPage}
              totalPages={totalPages}
              totalRows={totalRows}
            />
          </TableContainer>
          <div className="dialog-footer">
            <div className="footer-button-group">
              <Button
                text={intl.formatMessage(commonMessages.cancel)}
                variant={BUTTON_VARIANTS.TEXT}
                onClick={() => {
                  handleCancel();
                }}
              />
              <Button
                text={intl.formatMessage(commonMessages.save)}
                variant={BUTTON_VARIANTS.TEXT}
                onClick={() => {
                  handleSave();
                }}
              />
            </div>
          </div>
        </StyledAutoMapRoles>
      </Dialog>
    </>
  );
};

export default AutoMapRolesDialog;
