import styled from 'styled-components';

const StyledStepFourTimeInput = styled.div`
.schedule-time{
    width: 14rem;
    height: 3.4rem;
}
`;

export default StyledStepFourTimeInput;
