import React from 'react';
import { components } from 'react-select';
import styled, { css } from 'styled-components';

import { NormalizedButton } from '../../button';
import { getRem, transitionAnimation, visuallyHidden } from '../../core';
import { IconCancelOutlined } from '../../icons';
import { KEY_VALUES, removeObjectProperties } from '../../utilities';

const BUTTON_ICON_SIZE = getRem('24px');

const StyledClearButtonContainer = styled(components.ClearIndicator)`
  && {
    margin: 0;
    padding: 0;
  }
`;

const StyledClearButton = styled(NormalizedButton)`
  ${transitionAnimation('background-color, fill')}
  align-items: center;
  border-radius: ${({ theme }) => theme.size.borderRadius.full.value};
  cursor: pointer;
  display: flex;
  fill: ${({ theme }) => theme.color.text.light.secondary.value};
  height: ${BUTTON_ICON_SIZE};
  justify-content: center;
  padding: 0;
  width: ${BUTTON_ICON_SIZE};
  ${({ isDisabled, isReadOnly, theme }) =>
    !isDisabled &&
    !isReadOnly &&
    css`
      &:hover,
      &:focus {
        background-color: ${theme.color.text.light.secondary.value};
        fill: ${theme.color.additional.light.value};
      }
    `}
`;

const StyledClearButtonText = styled.span`
  ${visuallyHidden};

  && {
    position: absolute;
  }
`;

const StyledClearButtonIcon = styled(IconCancelOutlined)`
  height: ${BUTTON_ICON_SIZE};
  width: ${BUTTON_ICON_SIZE};
`;

/* eslint-disable react/prop-types */
export const ClearIndicatorComponent = ({
  clearButtonText,
  clearIndicatorProps,
  dataTestId,
  isDisabled,
  isReadOnly,
}) => {
  const { onMouseDown } = clearIndicatorProps.innerProps;

  const innerPropsWithoutAriaHidden = removeObjectProperties(clearIndicatorProps.innerProps, 'aria-hidden');
  const modifiedClearIndicatorProps = {
    ...clearIndicatorProps,
    innerProps: innerPropsWithoutAriaHidden,
  };
  /* eslint-enable */

  const handleKeyDown = (event) => {
    const keyValue = event.key || undefined;
    if (keyValue === KEY_VALUES.SPACE) {
      event.target.click();
    }
  };

  return (
    <StyledClearButtonContainer {...modifiedClearIndicatorProps}>
      <StyledClearButton
        data-testid={dataTestId ? `${dataTestId}-clear-button` : undefined}
        isDisabled={isDisabled}
        isReadOnly={isReadOnly}
        onClick={onMouseDown}
        onKeyDown={handleKeyDown}
        type="button"
      >
        <StyledClearButtonText>{clearButtonText}</StyledClearButtonText>
        <StyledClearButtonIcon />
      </StyledClearButton>
    </StyledClearButtonContainer>
  );
};
