import { fetchBaseQuery } from '@reduxjs/toolkit/query';

const API_BASE_URL: string = process.env.REACT_APP_BASE_URL || '';

const baseQuery = fetchBaseQuery({
  baseUrl: API_BASE_URL,

  prepareHeaders: headers => {
    const unparsedObject = localStorage.getItem('okta-token-storage');
    const token: string =
      unparsedObject &&
      JSON.parse(unparsedObject) &&
      JSON.parse(unparsedObject).accessToken
        ? JSON.parse(unparsedObject).accessToken.accessToken
        : '';
    // const token = localStorage.getItem('okta-token');
    headers.set('authorization', `Bearer ${token}`);
    return headers;
  },
  credentials: 'include'
});

export default baseQuery;
