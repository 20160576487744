import React from 'react';
import PropTypes from 'prop-types';
import styled, { css } from 'styled-components';

import { BREAKPOINTS, getRem } from '../../core';
import { RADIO_SIZES } from '../constants';

const RADIO_CONTAINER_SIZES = {
  [RADIO_SIZES.LARGE]: '48px',
  [RADIO_SIZES.SMALL]: '32px',
  [RADIO_SIZES.STANDARD]: '40px',
};

export const StyledRadioContainer = styled.label.withConfig({
  shouldForwardProp: (prop) => !['hideLabel', 'isChecked', 'isDisabled', 'isLabelBefore', 'isLarge'].includes(prop),
})`
  align-items: center;
  box-sizing: border-box;
  cursor: ${({ isDisabled }) => (isDisabled ? 'not-allowed' : 'pointer')};
  display: inline-flex;
  height: ${getRem(RADIO_CONTAINER_SIZES[RADIO_SIZES.LARGE])};
  margin-right: 0;
  width: 100%;

  @media ${BREAKPOINTS.L} {
    height: ${({ size }) => getRem(RADIO_CONTAINER_SIZES[size])};
    padding-right: ${({ hideLabel, isLabelBefore }) => !hideLabel && (isLabelBefore ? getRem('18px') : getRem('32px'))};
    width: auto;
  }

  ${({ isLabelBefore }) =>
    isLabelBefore &&
    css`
      flex-direction: row-reverse;
      justify-content: space-between;
    `}
`;

const RadioContainer = React.forwardRef(
  ({ isChecked = false, isDisabled = false, isLabelBefore = false, isLarge = false, ...other }, ref) => (
    <StyledRadioContainer {...{ isChecked, isDisabled, isLabelBefore, isLarge }} {...other} ref={ref} />
  )
);

RadioContainer.propTypes = {
  /** If true, will use appropriate color scheme */
  isChecked: PropTypes.bool,
  /** If true, will use appropriate color scheme */
  isDisabled: PropTypes.bool,
  /** If true, will reverse order of children */
  isLabelBefore: PropTypes.bool,
  /** If true, will increase row height */
  isLarge: PropTypes.bool,
};

export { RadioContainer };
