/* eslint-disable  @typescript-eslint/restrict-template-expressions */
import { createApi } from '@reduxjs/toolkit/query/react';
import apiEndPoints from './apiEndPoints';
import baseQuery from './baseQuery';
import { SignedURLPayload, SignedURLResponse } from './types';

export const lookerServiceAPI = createApi({
  reducerPath: 'looker',
  baseQuery,
  refetchOnMountOrArgChange: true,
  endpoints: builder => ({
    getSignedURL: builder.query<SignedURLResponse, SignedURLPayload>({
      query: ({ body, reportName }) => ({
        url: `${apiEndPoints.getSignedURL}?reportName=${reportName}`,
        method: 'POST',
        body
      })
    })
  })
});

export const { useGetSignedURLQuery } = lookerServiceAPI;
