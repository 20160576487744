/* eslint-disable @typescript-eslint/restrict-template-expressions */
/* eslint-disable @typescript-eslint/no-unsafe-call ,
@typescript-eslint/restrict-template-expressions,
@typescript-eslint/no-unsafe-return */

import { createApi } from '@reduxjs/toolkit/query/react';
import { downloadFile } from 'redux/utils';
import {
  DeleteDMSUser,
  DeleteLinkAccount,
  EmailNotificationUsersStatus,
  LinkDMSCnumber,
  LinkIdentifier,
  NewServiceUser,
  SelectedDMSUser,
  ServiceActivtyByUserLog,
  ServiceUserDetails,
  UnlinkDMSCNumber,
  UpdateServiceUser
} from 'redux/services/types/user-management-service-types';
import { PageType } from 'components/pages/Users/types';
import { DMSUserPayload } from '../types/external-attributes-types';
import {
  JobTitleResponse,
  PostLinkDMSUserPayload,
  PostMergeUsersPayload,
  PostNewUserPayload,
  UsersResponse
} from '../types/new-user-service-types';
import { UpdateMFAStatus } from '../types/store-service-types';
import apiEndPoints from './apiEndPoints';
import baseQuery from './baseQuery';
import {
  BulkUpdateEnterpriseMFAStatusBody,
  BulkUpdateStoreMFAStatusBody,
  DMSUserDataResponse,
  DMSUserResponse,
  DmsJobProfileResponse,
  DmsUserType,
  DmsUsersResponse,
  EnterpriseExclusionList,
  FullUser,
  ImportJobsType,
  ImportNowPostBody,
  JobProfileType,
  JobRoleType,
  LinkedDMSUserType,
  MfaReportingType,
  OAuthTokenResponse,
  PostDMSJobProfiles,
  SchedulePostBody,
  ServiceUser,
  ServiceUserTokenPayload,
  UserDetailsType
} from './types';

import {
  GenerateReportPayload,
  PutEmailNotificationPayload
} from '../types/identity-service-types';

export const identityServiceAPI = createApi({
  reducerPath: 'identity',
  baseQuery,
  tagTypes: [
    'getUserDetails',
    'getPermissions',
    'getUserImpersonationStatus',
    'DMS_USER',
    'userImportJobs',
    'getDMSUsers',
    'getEnterpriseDetails',
    'getMfaReport'
  ],
  endpoints: builder => ({
    getAuthentication: builder.query<FullUser, void>({
      query: () => `${apiEndPoints.getUserDetails}`,
      transformResponse: (response: FullUser) => {
        const enterpriseId = localStorage.getItem('enterpriseId');
        const enterpriseName = localStorage.getItem('enterpriseName');
        return {
          ...response,
          fullUser: {
            ...response.fullUser,
            enterprise: {
              ...response.fullUser.enterprise,
              id: enterpriseId || response.fullUser.enterprise.id,
              name: enterpriseName || response.fullUser.enterprise.name,
              selfId: response.fullUser.enterprise.id,
              selfName: response.fullUser.enterprise.name
            }
          }
        };
      },
      providesTags: ['getUserDetails']
    }),

    getCdkEditAccessEnterpriseList: builder.query<
      EnterpriseExclusionList,
      void
    >({
      query: () => `${apiEndPoints.getCdkEditAccessEnterpriseList}`
    }),

    getOAuthTokens: builder.query<OAuthTokenResponse, string>({
      query: (loginId: string) => `${apiEndPoints.getOAuthToken}/${loginId}`
    }),

    putDMSUser: builder.mutation<
      void,
      { enterpriseId: string; payload: DMSUserPayload }
    >({
      query: ({ payload, enterpriseId }) => ({
        url: `${apiEndPoints.dmsUser}/${enterpriseId}/dms/users`,
        method: 'PUT',
        body: payload
      })
    }),
    postDMSUser: builder.mutation<
      void,
      { enterpriseId: string; payload: DMSUserPayload }
    >({
      query: ({ payload, enterpriseId }) => ({
        url: `${apiEndPoints.dmsUser}/${enterpriseId}/dms/users`,
        method: 'POST',
        body: payload
      }),
      invalidatesTags: ['DMS_USER']
    }),
    getJobTitles: builder.query<JobTitleResponse, void>({
      query: () => `${apiEndPoints.getJobTitles}`
    }),
    getJobTitlesUser: builder.query({
      query: () => `${apiEndPoints.getJobTitles}`
    }),
    postNewUser: builder.mutation<void, PostNewUserPayload>({
      query: body => ({
        url: body.user?.dmsUser?.dmsUserDetails?.Id
          ? `${apiEndPoints.postNewUserCopyDMS(
              (!!body.user?.dmsUser?.dmsUserDetails?.Id).toString()
            )}`
          : `${apiEndPoints.postNewUser}`,
        method: 'POST',
        body
      })
    }),
    deleteUser: builder.mutation<Record<string, any>, string>({
      query: (loginId: string) => ({
        url: `${apiEndPoints.postNewUser}/${loginId}`,
        method: 'DELETE'
      })
    }),
    getallUsersReport: builder.query<Record<string, any>, string>({
      query: (enterpriseId: string) =>
        `${apiEndPoints.allUsersReport(enterpriseId)}`
    }),

    getUserActivities: builder.query<Record<string, any>, string>({
      query: (userId: string) => `${apiEndPoints.getUserActivities(userId)}`
    }),
    getFederationStatus: builder.query<Record<string, any>, string>({
      query: (enterpriseId: string) =>
        `${apiEndPoints.getFederationStatus}/${enterpriseId}/idp`
    }),
    getUsers: builder.query<
      UsersResponse,
      {
        offset: number;
        limit: number;
        sortBy: string | null;
        dir: string | null;
        search: string;
        enterpriseId: string | null;
      }
    >({
      query: ({ offset, limit, sortBy, dir, search, enterpriseId }) => {
        const enterpriseIdQuery = enterpriseId
          ? `&enterpriseId=${enterpriseId}`
          : '';
        const sortByQuery = sortBy ? `&sortBy=${sortBy}` : '';
        const dirQuery = dir ? `&dir=${dir}` : '';
        const searchQuery = search ? `&keyword=${search}` : '';
        return `${apiEndPoints.getUsers}?offset=${offset}&aliasLookup=false&limit=${limit}${enterpriseIdQuery}${sortByQuery}${dirQuery}${searchQuery}`;
      }
    }),
    getUsersDetails: builder.query<UsersResponse, PageType>({
      query: ({
        offset,
        limit,
        sortBy,
        dir,
        search,
        enterpriseId,
        searchId
      }) => {
        const enterpriseIdQuery = enterpriseId
          ? `&enterpriseId=${enterpriseId}`
          : '';
        const sortByQuery = sortBy ? `&sortBy=${sortBy}` : '';
        const dirQuery = dir ? `&dir=${dir}` : '';
        const searchData = search ? `&${searchId}=${search}` : '';
        return `${apiEndPoints.getUsers}?offset=${offset}&aliasLookup=false&limit=${limit}${enterpriseIdQuery}${sortByQuery}${dirQuery}${searchData}`;
      }
    }),
    getRoleByUsers: builder.query<
      UsersResponse,
      {
        offset: number;
        limit: number;
        sortBy: string | null;
        dir: string | null;
        search: string;
        enterpriseId: string | null;
        roleGuid: string | null;
      }
    >({
      query: ({
        offset,
        limit,
        sortBy,
        dir,
        search,
        enterpriseId,
        roleGuid
      }) => {
        const enterpriseIdQuery = enterpriseId
          ? `&enterpriseId=${enterpriseId}`
          : '';
        const roleGuidQuery = roleGuid ? `&roleGuid=${roleGuid}` : '';
        const sortByQuery = sortBy ? `&sortBy=${sortBy}` : '';
        const dirQuery = dir ? `&dir=${dir}` : '';
        const searchQuery = search ? `&keyword=${search}` : '';
        return `${apiEndPoints.getUsers}?aliasLookup=false${enterpriseIdQuery}&offset=${offset}&limit=${limit}${roleGuidQuery}${sortByQuery}${dirQuery}${searchQuery}`;
      }
    }),
    postUserMFA: builder.mutation<void, PostNewUserPayload>({
      query: ({ enterpriseId, body }) => ({
        url: `${apiEndPoints.bulkUpdateUserMFAStatus(enterpriseId)}`,
        method: 'POST',
        body
      })
    }),
    postUserStatus: builder.mutation<void, PostNewUserPayload>({
      query: ({ body }) => ({
        url: `${apiEndPoints.postUserStatus}`,
        method: 'PUT',
        body
      })
    }),
    postMergeUsers: builder.mutation<void, PostMergeUsersPayload>({
      query: body => ({
        url: `${apiEndPoints.postMergeUsers}`,
        method: 'POST',
        body
      })
    }),
    postLinkDMSUser: builder.mutation<void, PostLinkDMSUserPayload>({
      query: body => ({
        url: `${apiEndPoints.postLinkDMSUser(body.userId)}`,
        method: 'POST',
        body: body.payload
      })
    }),
    postDelearChangeMFAStatus: builder.mutation<void, UpdateMFAStatus>({
      query: body => ({
        url: `${apiEndPoints.postDelearChangeMFAStatus(body.enterpriseId)}`,
        method: 'POST',
        body: body.payload
      })
    }),
    getImportJobs: builder.query<ImportJobsType, string>({
      query: (enterpriseId: string) =>
        `${apiEndPoints.getUserImportJobs}?enterpriseId=${enterpriseId}`,
      providesTags: ['userImportJobs']
    }),
    getDMSUsers: builder.query<DMSUserDataResponse, Record<string, string>>({
      queryFn: async (
        { enterpriseId, cnumber },
        _queryApi,
        _extraOptions,
        fetchWithBQ
      ) => {
        const getJobProfiles = fetchWithBQ(
          `${apiEndPoints.getJobProfiles}?enterpriseId=${enterpriseId}${
            cnumber ? `&cNumber=${cnumber}` : ''
          }`
        );
        const getDmsUser = fetchWithBQ(
          `${apiEndPoints.getDmsUsers}?enterpriseId=${enterpriseId}${
            cnumber ? `&cNumber=${cnumber}` : ''
          }`
        );
        const rawResponse = await Promise.allSettled([
          getJobProfiles,
          getDmsUser
        ]);
        const response = rawResponse.filter(
          res => res.status === 'fulfilled'
        ) as [
          PromiseFulfilledResult<DmsJobProfileResponse>,
          PromiseFulfilledResult<DmsUsersResponse>
        ];

        let jobProilesByKey: Record<string, JobProfileType> = {};
        const roleIdbyJobProfileName: Record<string, string> = {};

        response[0].value.data.dmsJobProfiles.forEach(jobProfile => {
          roleIdbyJobProfileName[jobProfile.jobprofileName] =
            jobProfile.roleGuid;
        });

        const allRoles = response[0].value.data.dmsJobProfiles.length
          ? response[0].value.data.dmsJobProfiles[0].roles
          : [];
        const roleIdbyroleName: Record<string, string> = allRoles.reduce(
          (acc, curr) => ({ ...acc, [curr.roleGuid]: curr.roleName }),
          {}
        );

        if (
          response[0].value.data.dmsJobProfiles &&
          Array.isArray(response[0].value.data.dmsJobProfiles)
        ) {
          jobProilesByKey = response[0].value.data.dmsJobProfiles.reduce(
            (acc: Record<string, JobProfileType>, curr: JobProfileType) => ({
              ...acc,
              [curr.id]: curr
            }),
            {}
          );
        }

        const dmsUsers: DmsUserType[] = [];

        if (
          response[1].value.data.dmsUsers &&
          Array.isArray(response[1].value.data.dmsUsers)
        ) {
          response[1].value.data.dmsUsers.forEach(dmsUser => {
            const dmsUserModified = { ...dmsUser };
            const userJobProfiles: string[] = [];
            const userRoles: { code: string; roleGuid: string }[] = [];

            dmsUser.profiles.forEach(userProfile => {
              const profileName =
                jobProilesByKey[userProfile.id]?.jobprofileName || '';
              userJobProfiles.push(profileName);
              const roleGuid: string =
                roleIdbyJobProfileName[profileName] || '';
              const userRole = roleGuid
                ? {
                    code: roleIdbyroleName[roleGuid],
                    roleGuid
                  }
                : null;
              if (userRole) {
                userRoles.push(userRole);
              }
            });

            dmsUserModified.jobProfiles = userJobProfiles;
            dmsUserModified.roles = userRoles;

            dmsUserModified.stores = dmsUser.stores?.map(storeName => ({
              id: storeName
            })) as { id: string }[];
            dmsUsers.push(dmsUserModified);
          });
        }

        let jobProilesArray: JobProfileType[] = [];
        let allRolesArray: JobRoleType[] = [];
        if (
          response[0].value.data.dmsJobProfiles &&
          Array.isArray(response[0].value.data.dmsJobProfiles)
        ) {
          jobProilesArray = response[0].value.data.dmsJobProfiles;
          allRolesArray = jobProilesArray[0].roles;
        }

        return {
          data: {
            jobProilesByKey,
            dmsUsers,
            jobProilesArray,
            allRolesArray
          }
        };
      },
      providesTags: ['getDMSUsers']
    }),
    getDMSUsersByCNumber: builder.query<
      DMSUserResponse,
      Record<string, string>
    >({
      query: ({ enterpriseId, cnumber }) =>
        `${apiEndPoints.getDmsUsers}?enterpriseId=${enterpriseId}&cNumber=${cnumber}&fetchUsers=all`
    }),
    postBulkUnassignUsers: builder.mutation<void, any>({
      query: body => ({
        url: `${apiEndPoints.postBulkUnassignUsers}`,
        method: 'POST',
        body
      })
    }),
    getUnassignUsersJobs: builder.query<any, string>({
      query: (enterpriseId: string) =>
        `${apiEndPoints.getUnassignUsersJobs(enterpriseId)}`
    }),
    getUnassignUserJobDetails: builder.query<any, string>({
      query: (jobId: string) =>
        `${apiEndPoints.getUnassignUserJobDetails(jobId)}`
    }),
    postImportNow: builder.mutation<void, ImportNowPostBody>({
      query: body => ({
        url: `${apiEndPoints.postImportNow}`,
        method: 'POST',
        body
      }),
      invalidatesTags: ['userImportJobs']
    }),
    putImportNow: builder.mutation<void, ImportNowPostBody>({
      query: body => ({
        url: `${apiEndPoints.postImportNow}`,
        method: 'PUT',
        body
      }),
      invalidatesTags: ['userImportJobs']
    }),
    postScheduleImport: builder.mutation<void, SchedulePostBody>({
      query: body => ({
        url: `${apiEndPoints.postScheduleImport}`,
        method: 'POST',
        body
      }),
      invalidatesTags: ['userImportJobs']
    }),
    putScheduleImport: builder.mutation<void, SchedulePostBody>({
      query: body => ({
        url: `${apiEndPoints.postScheduleImport}`,
        method: 'PUT',
        body
      }),
      invalidatesTags: ['userImportJobs']
    }),
    postDMSJobProfiles: builder.mutation<void, PostDMSJobProfiles>({
      query: body => ({
        url: `${apiEndPoints.postDMSJobProfiles}`,
        method: 'POST',
        body
      }),
      invalidatesTags: ['getDMSUsers']
    }),
    postBulkStoreMFAStatus: builder.mutation<
      void,
      BulkUpdateStoreMFAStatusBody
    >({
      query: ({ enterpriseId, body }) => ({
        url: `${apiEndPoints.bulkUpdateStoreMFAStatus(enterpriseId)}`,
        method: 'POST',
        body
      })
    }),
    postBulkEnterpriseMFAStatus: builder.mutation<
      void,
      BulkUpdateEnterpriseMFAStatusBody
    >({
      query: ({ body }) => ({
        url: `${apiEndPoints.bulkUpdateEnterpriseMFAStatus}`,
        method: 'POST',
        body
      }),
      invalidatesTags: ['getEnterpriseDetails']
    }),
    getMfaSummaryReport: builder.query<MfaReportingType, string>({
      query: (enterpriseId: string) =>
        `${apiEndPoints.getMfaReportSummary}/${enterpriseId}/mfa/summary`,
      providesTags: ['getMfaReport']
    }),
    getMfaSummaryReportEntity: builder.query<
      MfaReportingType,
      Record<string, string>
    >({
      query: ({ enterpriseId, entityValue }) =>
        `${apiEndPoints.getMfaReportSummary}/${enterpriseId}/mfa/activities?${entityValue}`
    }),
    getMfaSummaryTransaction: builder.query<
      MfaReportingType,
      Record<string, string>
    >({
      query: ({ enterpriseId, transactionId }) =>
        `${apiEndPoints.getMfaReportSummary}/${enterpriseId}/mfa/activities?${transactionId}`
    }),
    getuserManagementDetails: builder.query<UserDetailsType, string>({
      query: (email: string) =>
        `${apiEndPoints.getuserManagementDetails(email)}?aliasLookup=false`
    }),
    postResetPassword: builder.mutation<void, Record<string, string>>({
      query: ({ email, body }) => ({
        url: `${apiEndPoints.postResetPassword(email)}`,
        method: 'POST',
        body
      })
    }),
    postChangePassword: builder.mutation<void, Record<string, any>>({
      query: ({ userId, body }) => ({
        url: `${apiEndPoints.postChangePassword(userId)}`,
        method: 'POST',
        body
      })
    }),
    postResetPasswordWithRecovery: builder.mutation<
      void,
      Record<string, string>
    >({
      query: ({ email, body }) => ({
        url: `${apiEndPoints.postResetPasswordWithRecovery(email)}`,
        method: 'POST',
        body
      })
    }),

    getSystem: builder.query({
      query: () => `${apiEndPoints.getSystems}`
    }),
    postLinkAccountForUser: builder.mutation<void, LinkIdentifier>({
      query: ({ loginID, body }) => ({
        url: `${apiEndPoints.postLinkAccountForUser(loginID)}`,
        method: 'POST',
        body
      })
    }),
    deleteLinkAccoutForUser: builder.mutation<void, DeleteLinkAccount>({
      query: ({ alternateId, systemId }) => ({
        url: `${apiEndPoints.deleteLinkedAccount(alternateId, systemId)}`,
        method: 'DELETE'
      })
    }),
    putSaveUser: builder.mutation<void, any>({
      query: ({ loginID, body }) => ({
        url: `${apiEndPoints.putUpdateUserDetailsOnSave(loginID)}`,
        method: 'PUT',
        body
      })
    }),
    getLinkedDMSUsers: builder.query<LinkedDMSUserType, Record<string, string>>(
      {
        query: ({ enterpriseId, cnumber }) =>
          `${apiEndPoints.getLinkedDMSUsers}?enterpriseId=${enterpriseId}&cNumber=${cnumber}`
      }
    ),
    getEmailNotificationUsersStatus: builder.query<
      EmailNotificationUsersStatus[],
      string
    >({
      query: (enterpriseId: string) =>
        `${apiEndPoints.getEmailNotificationUsersStatus}?enterpriseId=${enterpriseId}`
    }),

    getSeletedDMSUsers: builder.query<SelectedDMSUser[], string>({
      query: (emailId: string) => `${apiEndPoints.getSeletedDMSUsers(emailId)}`
    }),
    postUnlinkDMSCNumber: builder.mutation<string, UnlinkDMSCNumber>({
      query: body => ({
        url: `${apiEndPoints.postUnlinkDMSCNumber(body.loginId)}`,
        method: 'POST',
        body: body.payload
      })
    }),

    deleteDMSUser: builder.mutation<string, DeleteDMSUser>({
      query: body => ({
        url: `${apiEndPoints.deleteDMSUsers(body.loginId)}`,
        method: 'DELETE'
      })
    }),
    postLinkDMSCNumber: builder.mutation<string, LinkDMSCnumber>({
      query: body => ({
        url: `${apiEndPoints.postLinkDMSCnumber(body.loginId)}`,
        method: 'POST',
        body: body.payload
      })
    }),
    putSendEmailNotification: builder.mutation<
      void,
      PutEmailNotificationPayload
    >({
      query: body => ({
        url: `${apiEndPoints.putSendEmailNotification}`,
        method: 'PUT',
        body
      })
    }),
    patchDisableDMSUser: builder.mutation<void, string>({
      query: (userId: string) => ({
        url: `${apiEndPoints.patchDMSUserStatus}${userId}?status=Disabled`,
        method: 'PATCH'
      })
    }),
    getServiceUsers: builder.query<ServiceUser[], void>({
      query: () => `${apiEndPoints.getServiceUsers}`
    }),
    postNewServiceUser: builder.mutation<string, NewServiceUser>({
      query: body => ({
        url: `${apiEndPoints.postNewServiceUser}`,
        method: 'POST',
        body
      })
    }),
    getServiceUserByLoginId: builder.query<ServiceUser[], string>({
      query: (loginId: string) =>
        `${apiEndPoints.getServiceUserByLoginId(loginId)}`
    }),
    getServiceUserDetailsByLoginId: builder.query<ServiceUserDetails, string>({
      query: (loginId: string) =>
        `${apiEndPoints.getServiceUserDetailsByLoginId(loginId)}`
    }),

    putServiceUser: builder.mutation<string, UpdateServiceUser>({
      query: body => ({
        url: `${apiEndPoints.putServiceUser}`,
        method: 'PUT',
        body
      })
    }),
    getServiceUserActivityLogById: builder.query<
      ServiceActivtyByUserLog,
      string
    >({
      query: (loginId: string) =>
        `${apiEndPoints.getServiceUserActivityLogById(loginId)}`
    }),
    postNewServiceUserToken: builder.mutation<
      string,
      { payload: ServiceUserTokenPayload; serviceUser: string }
    >({
      query: ({ payload, serviceUser }) => ({
        url: `${apiEndPoints.postNewServiceUserToken}/${serviceUser}`,
        method: 'POST',
        body: payload
      })
    }),
    putNewServiceUserToken: builder.mutation<
      string,
      { payload: ServiceUserTokenPayload; tokenId: string }
    >({
      query: ({ payload, tokenId }) => ({
        url: `${apiEndPoints.putNewServiceUserToken}/${tokenId}`,
        method: 'PUT',
        body: payload
      })
    }),
    deleteServiceUserToken: builder.mutation<string, { tokenId: string }>({
      query: ({ tokenId }) => ({
        url: `${apiEndPoints.deleteServiceUserToken}/${tokenId}`,
        method: 'DELETE'
      })
    }),
    postGenerateReport: builder.mutation<void, GenerateReportPayload>({
      query: body => ({
        url: `${apiEndPoints.postGenerateReport(body.enterpriseId)}`,
        method: 'POST',

        body: body.payload
      })
    }),
    getStoreAssignmentReport: builder.query<any, string>({
      query: (enterpriseId: string) => ({
        url: `${apiEndPoints.storeAssigmnetReport(enterpriseId)}`,
        method: 'GET'
      })
    }),
    getRoleAssignmentReport: builder.query<any, string>({
      query: (enterpriseId: string) => ({
        url: `${apiEndPoints.roleAssigmnetReport(enterpriseId)}`,
        method: 'GET'
      })
    }),
    getUserListReport: builder.query<any, string>({
      query: (enterpriseId: string) => ({
        url: `${apiEndPoints.userListReport(enterpriseId)}`,
        method: 'GET'
      })
    }),
    downloadLastLoginReport: builder.query<any, string>({
      query: (enterpriseId: string) => ({
        url: `${apiEndPoints.lastLoginActivityReport(enterpriseId)}`,
        method: 'GET',

        responseHandler: async response => {
          if (response.ok) {
            const blob = await response.blob();
            downloadFile(blob, 'Last_Login_Report');
          }
          return { error: { response } };
        }
      })
    }),
    downloadUserActivityReport: builder.query<any, string>({
      query: (enterpriseId: string) => ({
        url: `${apiEndPoints.userActivityReport(enterpriseId)}`,
        method: 'GET',

        responseHandler: async response => {
          if (response.ok) {
            const blob = await response.blob();
            downloadFile(blob, 'User_Activity_Report', 'xls');
          }
          return { error: { response } };
        }
      })
    }),
    getReportStatus: builder.query<any, string>({
      query: (enterpriseId: string) => ({
        url: `${apiEndPoints.reportStatus(enterpriseId)}`,
        method: 'GET'
      })
    })
  })
});

export const {
  useGetAuthenticationQuery,
  useGetCdkEditAccessEnterpriseListQuery,
  useGetOAuthTokensQuery,
  usePutDMSUserMutation,
  usePostDMSUserMutation,
  useGetJobTitlesQuery,
  useGetJobTitlesUserQuery,
  usePostNewUserMutation,
  useDeleteUserMutation,
  useGetUsersQuery,
  useGetRoleByUsersQuery,
  usePostUserMFAMutation,
  usePostUserStatusMutation,
  usePostMergeUsersMutation,
  useGetUserActivitiesQuery,
  useGetFederationStatusQuery,
  useGetallUsersReportQuery,
  usePostLinkDMSUserMutation,
  usePostDelearChangeMFAStatusMutation,
  usePostBulkStoreMFAStatusMutation,
  usePostBulkEnterpriseMFAStatusMutation,
  useGetMfaSummaryReportQuery,
  useGetMfaSummaryReportEntityQuery,
  useGetMfaSummaryTransactionQuery,
  usePostBulkUnassignUsersMutation,
  useGetUnassignUsersJobsQuery,
  useGetUnassignUserJobDetailsQuery,
  usePostImportNowMutation,
  usePostScheduleImportMutation,
  usePutImportNowMutation,
  usePutScheduleImportMutation,
  usePostDMSJobProfilesMutation,
  useGetImportJobsQuery,
  useGetDMSUsersQuery,
  useGetDMSUsersByCNumberQuery,
  useGetuserManagementDetailsQuery,
  usePostResetPasswordMutation,
  usePostChangePasswordMutation,
  usePostResetPasswordWithRecoveryMutation,
  useGetSystemQuery,
  usePostLinkAccountForUserMutation,
  useDeleteLinkAccoutForUserMutation,
  usePutSaveUserMutation,
  useGetLinkedDMSUsersQuery,
  useGetSeletedDMSUsersQuery,
  usePostUnlinkDMSCNumberMutation,
  usePostLinkDMSCNumberMutation,
  useGetEmailNotificationUsersStatusQuery,
  usePutSendEmailNotificationMutation,
  usePatchDisableDMSUserMutation,
  useGetServiceUsersQuery,
  usePostNewServiceUserMutation,
  useGetServiceUserByLoginIdQuery,
  useGetServiceUserDetailsByLoginIdQuery,
  usePutServiceUserMutation,
  useGetServiceUserActivityLogByIdQuery,
  usePostNewServiceUserTokenMutation,
  useDeleteDMSUserMutation,
  usePutNewServiceUserTokenMutation,
  useDeleteServiceUserTokenMutation,
  usePostGenerateReportMutation,
  useGetStoreAssignmentReportQuery,
  useGetRoleAssignmentReportQuery,
  useGetUserListReportQuery,
  useLazyDownloadLastLoginReportQuery,
  useLazyDownloadUserActivityReportQuery,
  useGetReportStatusQuery,
  useGetUsersDetailsQuery
} = identityServiceAPI;
