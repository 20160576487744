import styled from 'styled-components';

export const StyledPageSkeletonLoader = styled.div`
  display: grid;
  grid-template-areas:
    'menu header'
    'menu content';

  .skeleton-sidebar {
    grid-area: menu;
    height: 100vh;
    width: 17.1rem;
    background: #f2f2f2;
  }

  .skeleton-header {
    grid-area: header;
    height: 10vh;
    width: 100vw;
  }

  .skeleton-body {
    grid-area: content;
    height: 80vh;
  }
`;
