/* eslint-disable @typescript-eslint/no-unsafe-call */
import { createSlice } from '@reduxjs/toolkit';
import type { PayloadAction } from '@reduxjs/toolkit';

export const allEnterprisesSlice = createSlice({
  name: 'allEnterprisesSlice',
  initialState: {
    searchEntity: ''
  },
  reducers: {
    setSearchEntity: (state, action: PayloadAction<string>) => {
      state.searchEntity = action.payload;
    },
    removeSearchEntity: state => {
      state.searchEntity = '';
    }
  }
});

export const { setSearchEntity, removeSearchEntity } =
  allEnterprisesSlice.actions;

export default allEnterprisesSlice.reducer;
