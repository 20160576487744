export const SHORTCUT_CHIPS_POSITIONS = {
  BOTTOM: 'bottom',
  NONE: 'none',
  RIGHT: 'after',
};

export const START_DATE = 'startDate';
export const END_DATE = 'endDate';

export const CUSTOM_ARIA_LABELS = {
  NEXT_MONTH: 'Click this button to move to the next month',
  PREVIOUS_MONTH: 'Click this button to move to the previous month',
  CHOOSE_AVAILABLE_START_DATE: ({ date }) => `Choose ${date} as your start date.`,
  CHOOSE_AVAILABLE_END_DATE: ({ date }) => `Choose ${date} as your end date.`,
};
