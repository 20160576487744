/* eslint-disable @typescript-eslint/no-unsafe-call,
  @typescript-eslint/no-unsafe-return */
import {
  useState,
  useEffect,
  useCallback,
  useRef,
  MutableRefObject
} from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useIntl } from 'react-intl';
import { TOAST_VARIANTS } from 'cdk-radial';
import { handleDownloadClick } from 'utils/utility';
import { GridApi, RowNode } from 'ag-grid-community';
import { UserRole, UserStore } from 'redux/services/admin-service/types';
import { RootState } from 'redux/store';
import {
  setTemplatDataRows,
  setTemplatDataRowField,
  setTemplateFieldExpansion,
  setTemplateRolesAndStores,
  setTemplateRowHeight,
  handleDataRemoval
} from 'redux/slices/csvImportSlice';
import { setGlobalToast } from 'redux/slices/commonSlice';
import TableContainer from '../../../templates/TableLayout/TableContainer/TableContainer';
import TableHeader from '../../../templates/TableLayout/TableHeader/TableHeader';
import FilterHeader from '../../../templates/TableLayout/FilterHeader/FilterHeader';
import CustomHeader from '../../../molecules/csvUserImport/StepThree/CustomHeader/CustomHeader';
import TableContent from '../../../templates/TableLayout/TableContent/TableContent';
import TablePaging from '../../../templates/TableLayout/TablePaging/TablePaging';
import useColumns from '../../../../fixtures/grid/csvImportStepThree/columnDefinitions';
import schema from '../../../../fixtures/grid/ReviewImport/fitlerSchema';
import { generateFilterSelectionObject } from '../../../../helpers/tableHelper';
import AssignRolesDialog from '../../common/AssignRolesModal/AssignRolesDialog';
import AssignStoresDialog from '../../common/AssignStoresModal/AssignStoresDialog';
import StyledStepThree from './styled';
import { messages } from '../messages';

type SelectedRowsType = {
  rowIndex: number;
};

export const getFilteredRows = (
  selectedRows: SelectedRowsType[] | null,
  rowData: Record<string, any>[]
): Record<string, any>[] => {
  const selectedRowIndices: Record<string, boolean> | undefined =
    selectedRows?.reduce(
      (acc, curr) => ({ ...acc, [curr.rowIndex]: true }),
      {}
    );
  let filteredRows;
  if (selectedRowIndices)
    filteredRows = rowData.filter(
      ({ rowIndex }) => !selectedRowIndices[rowIndex]
    );
  return filteredRows || [];
};

const StepThree = (): JSX.Element => {
  const intl = useIntl();
  const [searchValue, setSearchValue] = useState('');
  const [gridApi, setGridApi] = useState<GridApi>();
  const [currentPage, setCurrentPage] = useState(0);
  const [totalPages, setTotalPages] = useState(0);
  const [totalRows, setTotalRows] = useState(0);
  const [isAssignRolesDialogOpen, setIsAssignRolesDialogOpen] =
    useState<boolean>(false);
  const [isAssignStoresDialogOpen, setIsAssignStoresDialogOpen] =
    useState<boolean>(false);
  const displayedRowCount =
    gridApi && gridApi.getDisplayedRowCount
      ? gridApi.getDisplayedRowCount()
      : 0;
  const dispatch = useDispatch();

  const [filterSelections, setFilterSelections] = useState<
    Record<string, Array<string | undefined>>
  >(generateFilterSelectionObject(schema));

  const { fileDetails, templateType } = useSelector(
    (state: RootState) => state.slices.csvImportState
  );
  type RowHeightType = {
    data: {
      rowHeight: number;
    };
  };

  const containerRef = useRef() as MutableRefObject<HTMLDivElement>;
  const paginationHeight = 207;

  const minHeight = 80;
  const getRowHeight = useCallback(
    (params: RowHeightType) =>
      params.data.rowHeight > minHeight ? params.data.rowHeight : minHeight,
    []
  );

  const rowData = fileDetails?.rows ? fileDetails.rows : [];

  const [dmscolumns, nondmscolumns] = useColumns();
  const columns = templateType === 'dms' ? dmscolumns : nondmscolumns;

  useEffect(() => {
    if (gridApi && fileDetails && fileDetails.rows) {
      gridApi.setRowData(fileDetails.rows);
      gridApi.resetRowHeights();
    }
  }, [fileDetails, gridApi]);

  const onRemoveFromImportList = () => {
    const filteredRows = getFilteredRows(
      gridApi?.getSelectedRows() as SelectedRowsType[],
      rowData
    );

    dispatch(setTemplatDataRows(filteredRows));
  };

  const handleFieldChange = (
    rowIndex: number,
    field: string,
    value: string
  ) => {
    dispatch(setTemplatDataRowField({ rowIndex, field, value }));
  };

  const setRowFieldExpansion = (
    rowIndex: number,
    field: string,
    isCollapsed: boolean
  ) => {
    dispatch(
      setTemplateFieldExpansion({ rowIndex, field, value: isCollapsed })
    );
  };

  const setRowHeight = (rowIndex: number, rowHeight: number, field: string) => {
    dispatch(setTemplateRowHeight({ rowIndex, rowHeight, field }));
  };

  const onAssignRolesClick = () => {
    setIsAssignRolesDialogOpen(!isAssignRolesDialogOpen);
  };
  const onAssignStoresClick = () => {
    setIsAssignStoresDialogOpen(!isAssignStoresDialogOpen);
  };

  const handleAssignment = (
    selectedItems: UserRole[] | UserStore[],
    field: string,
    keyToAccess: string
  ) => {
    if (rowData && gridApi && selectedItems?.length) {
      const selectedNodes: RowNode[] = gridApi.getSelectedNodes();
      gridApi.getSelectedNodes().forEach((row: RowNode) => {
        const { rowIndex } = row;
        dispatch(
          setTemplateRolesAndStores({
            rowIndex,
            field,
            value: selectedItems,
            keyToAccess
          })
        );
      });
      const rolesText: string[] = selectedItems.map(
        selectedItem => selectedItem.code || selectedItem.id
      ) as string[];
      const selectedNodesLength: number = selectedNodes.length;
      dispatch(
        setGlobalToast({
          content: selectedNodesLength
            ? intl.formatMessage(messages.assignUserSuccess, {
                roles: rolesText.toString(),
                selectedUserCount: selectedNodesLength
              })
            : intl.formatMessage(messages.assignUserWarning),
          variant: selectedNodesLength
            ? TOAST_VARIANTS.POSITIVE
            : TOAST_VARIANTS.NEGATIVE
        })
      );
    }
  };

  const handleFieldRemoval = (
    fieldIndex: number,
    rowIndex: number,
    field: string
  ) => {
    dispatch(handleDataRemoval({ fieldIndex, rowIndex, field }));
  };

  const getRowNodeId = (row: Record<string, string>) => row.rowIndex;

  return (
    <StyledStepThree ref={containerRef}>
      <TableContainer showBorder>
        <TableHeader
          isDownloadable
          disableDownloadButton={displayedRowCount === 0}
          onDownloadClick={() => handleDownloadClick(gridApi)}
          headerTitle={intl.formatMessage(messages.reviewImportList)}
        />
        <FilterHeader
          filterSchema={schema}
          searchValue={searchValue}
          setSearchValue={setSearchValue}
          filterSelections={filterSelections}
          setFilterSelections={setFilterSelections}
        />
        <CustomHeader
          onRemoveFromImportList={onRemoveFromImportList}
          onAssignRoles={onAssignRolesClick}
          onAssignStores={onAssignStoresClick}
        />
        <TableContent
          gridApi={gridApi}
          searchValue={searchValue}
          filterSchema={schema}
          tableHeight={
            containerRef &&
            containerRef.current &&
            containerRef.current.clientHeight
              ? containerRef.current.clientHeight - paginationHeight
              : 300
          }
          getRowHeight={getRowHeight}
          filterSelections={filterSelections}
          setGridApi={setGridApi}
          setCurrentPage={setCurrentPage}
          setTotalPages={setTotalPages}
          setTotalRows={setTotalRows}
          immutableData
          columns={columns}
          getRowNodeId={getRowNodeId}
          rowHeight={60}
          context={{
            handleFieldChange,
            setRowFieldExpansion,
            setRowHeight,
            handleFieldRemoval
          }}
        />
        <TablePaging
          gridApi={gridApi}
          currentPage={currentPage}
          totalPages={totalPages}
          totalRows={totalRows}
        />
      </TableContainer>
      {isAssignRolesDialogOpen && (
        <AssignRolesDialog
          isAssignRolesDialogOpen={isAssignRolesDialogOpen}
          closeAssignRolesDialog={() => setIsAssignRolesDialogOpen(false)}
          context={{
            handleAssignRoles: (selectedRoles: UserRole[]) =>
              handleAssignment(selectedRoles, 'roles', 'code')
          }}
        />
      )}

      {isAssignStoresDialogOpen && (
        <AssignStoresDialog
          isAssignStoresDialogOpen={isAssignStoresDialogOpen}
          closeAssignStoresDialog={() => setIsAssignStoresDialogOpen(false)}
          context={{
            handleAssignStores: (selectedStores: UserStore[]) =>
              handleAssignment(selectedStores, 'stores', 'id')
          }}
        />
      )}
    </StyledStepThree>
  );
};

export default StepThree;
