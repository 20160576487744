import {
  Action,
  PasswordActionType,
  PasswordErrorActionType,
  PasswordState
} from './type';

const initialErrorState = {
  hasCharacter: false,
  hasLowerCase: false,
  hasUppperCase: false,
  hasNumerals: false,
  hasSpecialCharacter: false,
  hasThreeIdenticalChar: false,
  hasSquentialChar: false,
  hasSpaces: false
};

export const initialState: PasswordState = {
  password: '',
  validationMessage: '',
  ...initialErrorState
};

export const passwordReducer = (
  state: PasswordState,
  action: Action
): PasswordState => {
  switch (action.type) {
    case PasswordErrorActionType.HAS_UPPER_CASE:
      return {
        ...state,
        hasUppperCase: action.error
      };
    case PasswordErrorActionType.HAS_NUMERALS:
      return {
        ...state,
        hasNumerals: action.error
      };
    case PasswordErrorActionType.HAS_SPECIAL_CHARACTER:
      return {
        ...state,
        hasSpecialCharacter: action.error
      };
    case PasswordErrorActionType.HAS_CHARACTER:
      return {
        ...state,
        hasCharacter: action.error
      };
    case PasswordErrorActionType.HAS_LOWER_CASE:
      return {
        ...state,
        hasLowerCase: action.error
      };
    case PasswordErrorActionType.HAS_THREE_IDENTICAL_CHAR:
      return {
        ...state,
        hasThreeIdenticalChar: action.error
      };
    case PasswordErrorActionType.HAS_SQUENTIAL_CHAR:
      return {
        ...state,
        hasSquentialChar: action.error
      };
    case PasswordErrorActionType.HAS_SPACES:
      return {
        ...state,
        hasSpaces: action.error
      };
    case PasswordActionType.PASSWORD:
      return {
        ...state,
        password: action.payload
      };
    case PasswordActionType.VALIDATION_MESSAGE:
      return {
        ...state,
        validationMessage: action.payload
      };
    case PasswordErrorActionType.RESET:
      return {
        ...state,
        ...initialErrorState
      };
    default:
      return state;
  }
};
