import styled from 'styled-components';

const StyledFilterHeader = styled.div`
  display: flex;
  align-items: center;
  margin: 0 1rem;
  padding: 0.5rem 0rem;

  .customize-multi-select {
    margin-left: 0.5rem;
  }

  .customize-search-input {
    margin-right: 0.5rem;
  }

  .customize-search-input {
    margin-right: 0.5rem;
  }

  .customize-download-icon {
    margin-left: 0.5rem;
  }
`;

export default StyledFilterHeader;
