/* eslint-disable @typescript-eslint/restrict-template-expressions */
/* eslint-disable @typescript-eslint/no-unsafe-return */
/* eslint-disable @typescript-eslint/no-unsafe-call */
import {
  CELL_RENDERERS,
  CELL_CLASSES,
  StatusIndicator,
  STATUS_INDICATOR_VARIANTS,
  Switch
} from 'cdk-radial';
import { useIntl } from 'react-intl';
import { Link } from 'react-router-dom';
import { TransformStoreIdLink } from 'components/organisms/userMangement/UserDetails/Stores/TransformStoreIdLink';
import useEnterpriseId from 'hooks/useEnterpriseId';
import { ColumnDefinitionProps } from 'components/templates/SimpleTableLayout/TableContentV3';
import ExpandedCellText from 'components/molecules/common/ExpandableChips/ExpandedCellText';
import {
  TransformCMFNumber,
  TransformDMSAccount,
  TransformStatus,
  TransformStoreId,
  transformStoreLocation,
  TransformStoreVisibility
} from 'helpers/StorePageTransform';
import { messages } from './messages';
import {
  CustomAddressType,
  CustomCMFRenderProps,
  CustomDMSAccountsProps,
  CustomStoreDeptRenderProps,
  CustomStoreStatusRenderProps,
  DepartmentParamsType,
  EnterpriseParamsType,
  FilterCustomCMFRenderProps,
  FilterCustomDMSAccountsProps,
  ParamsType,
  StatusInLower,
  StatusMapping,
  VisibilityRenderProps
} from './types';

export const CustomNameMerger = ({
  data: { firstName, lastName }
}: EnterpriseParamsType): string => {
  let nameString = '';
  if (firstName || lastName) {
    nameString = `${firstName} ${lastName}`;
  }
  return nameString;
};

export const CustomAddressRenderer = ({
  data: { addresses }
}: ParamsType): string => {
  const address = addresses.find(
    ({ addressType }) => addressType === 'Physical'
  );
  let addressString = '';
  if (
    address &&
    (address.streetAddress ||
      address.city ||
      address.stateCode ||
      address.postalCode)
  ) {
    addressString = `${address.streetAddress || ''}, ${address.city || ''}, ${
      address.stateCode || ''
    } ${address.postalCode || ''}`;
  }

  return addressString;
};

export const CustomCMFRenderer = ({
  data: { externalSystemIdentifiers }
}: CustomCMFRenderProps): string => {
  const cmsFnumbers = externalSystemIdentifiers?.cmfNumber?.map(
    ({ externalSystemId, status }) =>
      status === 'ACTIVE' ? externalSystemId : ''
  );
  return cmsFnumbers?.filter(n => n)?.join(', ') || '';
};

export const CustomDMSAccountRenderer = ({
  data: { departments },
  colDef: { field },
  rowIndex,
  context: { setRowHeight }
}: CustomDMSAccountsProps): JSX.Element => {
  const departmentarr: string[] = departments?.map(
    department => department.name
  );

  const uniqueValues =
    departmentarr &&
    departmentarr?.filter(
      (item, index) => departmentarr.indexOf(item) === index
    );

  return (
    <ExpandedCellText
      data={uniqueValues || []}
      field={field}
      rowIndex={rowIndex}
      setRowHeight={setRowHeight}
    />
  );
};
export const filterCustomDMSAccountRenderer = ({
  data: { departments }
}: FilterCustomDMSAccountsProps): string =>
  departments?.map(department => department.name)?.join(', ') || '';

export const useGetStatusMappings = (): Record<string, StatusMapping> => {
  const intl = useIntl();
  return {
    open: {
      label: intl.formatMessage(messages.open),
      variant: STATUS_INDICATOR_VARIANTS.POSITIVE
    },
    'pre-open': {
      label: intl.formatMessage(messages.preOpen),
      variant: STATUS_INDICATOR_VARIANTS.INFORMATION
    },
    sold: {
      label: intl.formatMessage(messages.sold),
      variant: STATUS_INDICATOR_VARIANTS.DEFAULT
    },
    closed: {
      label: intl.formatMessage(messages.closed),
      variant: STATUS_INDICATOR_VARIANTS.NEGATIVE
    },
    inactive: {
      label: intl.formatMessage(messages.inactive),
      variant: STATUS_INDICATOR_VARIANTS.WARNING
    },
    duplicate: {
      label: intl.formatMessage(messages.duplicate),
      variant: STATUS_INDICATOR_VARIANTS.WARNING
    },
    out_of_business: {
      label: intl.formatMessage(messages.outOfBusiness),
      variant: STATUS_INDICATOR_VARIANTS.WARNING
    },
    archived: {
      label: intl.formatMessage(messages.archived),
      variant: STATUS_INDICATOR_VARIANTS.WARNING
    }
  };
};

export const useGetStoreVisibilityStatusMappings = (): Record<
  string,
  StatusMapping
> => {
  const intl = useIntl();
  return {
    yes: {
      label: intl.formatMessage(messages.yesVisibilityStatus),
      variant: STATUS_INDICATOR_VARIANTS.POSITIVE
    },
    no: {
      label: intl.formatMessage(messages.noVisibilityStatus),
      variant: STATUS_INDICATOR_VARIANTS.NEGATIVE
    }
  };
};

export const CustomStoreStatusRenderer = ({
  status
}: CustomStoreStatusRenderProps): JSX.Element => {
  const statusToLower = status.toLocaleLowerCase() as StatusInLower;
  const statusMappings = useGetStatusMappings();
  const { label, variant } = statusMappings[statusToLower];
  return <StatusIndicator label={label} variant={variant} showIcon />;
};

export const CustomCMFNumbersRenderer = ({
  data: { externalSystemIdentifiers },
  colDef: { field },
  rowIndex,
  context: { setRowHeight }
}: CustomCMFRenderProps): JSX.Element => {
  const cmsFnumbers: string[] = externalSystemIdentifiers?.cmfNumber?.map(
    ({ externalSystemId, status }) =>
      status === 'ACTIVE' ? externalSystemId : ''
  );
  const uniqueValues =
    cmsFnumbers &&
    cmsFnumbers?.filter((item, index) => cmsFnumbers.indexOf(item) === index);
  return (
    <ExpandedCellText
      data={uniqueValues || []}
      field={field}
      rowIndex={rowIndex}
      setRowHeight={setRowHeight}
    />
  );
};

export const filterCustomCMFRenderer = ({
  data: { externalSystemIdentifiers }
}: FilterCustomCMFRenderProps): string => {
  const cmsFnumbers = externalSystemIdentifiers?.cmfNumber?.map(
    ({ externalSystemId, status }) =>
      status === 'ACTIVE' ? externalSystemId : ''
  );
  return cmsFnumbers?.filter(n => n)?.join(', ') || '';
};

const CustomDepartementLinkRenderer = ({
  value
}: {
  value: string;
}): JSX.Element => {
  const enterpriseId = useEnterpriseId();
  const enterprisePath = `enterprise/${enterpriseId}`;
  return (
    <Link
      style={{ color: '#006cfa', textDecoration: 'none' }}
      to={`/${enterprisePath}/department/${value}`}
    >
      {value}
    </Link>
  );
};

export const CustomVisibilityRenderer = ({
  data: { isVisible },
  id,
  context: { handleVisibilityChange, putStoreVisibility, dispatch },
  api
}: VisibilityRenderProps): JSX.Element => (
  <Switch
    dataTestId="switch"
    id={`visibility-${id}`}
    label={isVisible ? 'Yes' : 'No'}
    name="visibility"
    checked={isVisible}
    onChange={() =>
      handleVisibilityChange(id, isVisible, api, putStoreVisibility, dispatch)
    }
  />
);

export const CustomCompanyRenderer = ({
  data: { externalSystemIdentifiers }
}: CustomStoreDeptRenderProps): string => {
  if (externalSystemIdentifiers && externalSystemIdentifiers?.deptCompanyName) {
    const deptCompanyName = externalSystemIdentifiers?.deptCompanyName.map(
      companyName => companyName.externalSystemId
    );
    return deptCompanyName.join('');
  }
  return '';
};

const useColumns = (): Array<any> => {
  const intl = useIntl();

  const columns = [
    {
      cellRendererFramework: TransformStoreIdLink,
      cellRendererParams: (params: ParamsType): any => params.data.id,
      valueGetter: (params: ParamsType): string => params.data.id,
      field: 'id',
      headerName: intl.formatMessage(messages.storeId),
      minWidth: 150,
      sortable: true
    },
    {
      cellRenderer: CELL_RENDERERS.TEXT,
      field: 'name',
      headerName: intl.formatMessage(messages.name),
      minWidth: 220,
      cellStyle: { 'white-space': 'normal', 'line-height': '25px' },
      wrapText: true,
      sortable: true
    },
    {
      cellRenderer: CustomAddressRenderer,
      cellRendererParams: (params: ParamsType): CustomAddressType[] =>
        params.data.addresses,
      valueGetter: (params: ParamsType): string | string[] =>
        CustomAddressRenderer(params),
      field: 'storeLocation',
      headerName: intl.formatMessage(messages.physicalAddress),
      cellStyle: { 'white-space': 'normal', 'line-height': '25px' },
      autoHeight: true,
      sortable: true,
      wrapText: true,
      minWidth: 320,
      maxWidth: 300
    },
    {
      cellRendererFramework: CustomDMSAccountRenderer,
      cellRendererParams: (params: ParamsType): any => params.data,
      valueGetter: (params: ParamsType): string | string[] =>
        filterCustomDMSAccountRenderer(params),
      field: 'dmsAccount',
      headerName: intl.formatMessage(messages.dmsAccounts),
      cellStyle: { 'white-space': 'normal', 'line-height': '25px' },
      sortable: true,
      wrapText: true,
      minWidth: 300,
      maxWidth: 400,
      autoHeight: true
    },

    {
      cellRendererFramework: CustomCMFNumbersRenderer,
      cellRendererParams: (params: ParamsType): any => params.data,
      valueGetter: (params: ParamsType): string | string[] =>
        filterCustomCMFRenderer(params),
      field: 'cmfNumber',
      headerName: intl.formatMessage(messages.cmfNumber),
      cellStyle: { 'white-space': 'normal', 'line-height': '25px' },
      minWidth: 390,
      maxWidth: 500,
      sortable: true,
      wrapText: true,
      autoHeight: true
    },
    {
      cellRendererFramework: CustomStoreStatusRenderer,
      cellRendererParams: (params: ParamsType): any => params.data,
      valueGetter: (params: ParamsType): string =>
        params.data.status?.toLowerCase(),
      field: 'status',
      headerName: intl.formatMessage(messages.storeStatus),
      minWidth: 150,
      maxWidth: 300,
      sortable: true
    },
    {
      cellRendererFramework: CustomVisibilityRenderer,
      cellRendererParams: (params: ParamsType): any => params.data,
      valueGetter: (params: ParamsType): boolean => params.data.isVisible,
      field: 'visible',
      headerName: intl.formatMessage(messages.visibility),
      minWidth: 150,
      maxWidth: 300,
      sortable: true
    }
  ];

  const columnDepartments = [
    {
      cellClass: CELL_CLASSES.CHECKBOX,
      checkboxSelection: true,
      field: 'check',
      filter: false,
      headerCheckboxSelection: true,
      headerCheckboxSelectionFilteredOnly: true,
      headerClass: [CELL_CLASSES.CHECKBOX, CELL_CLASSES.SELECT_ALL],
      headerName: '',
      maxWidth: 55,
      minWidth: 55,
      resizable: false
    },
    {
      cellRendererFramework: CustomDepartementLinkRenderer,
      cellRendererParams: (params: ParamsType): any => params.data,
      valueGetter: (params: ParamsType): string => params.data.id,
      field: 'id',
      headerName: intl.formatMessage(messages.departmentId),
      minWidth: 100,
      sortable: true
    },
    {
      cellRenderer: CELL_RENDERERS.TEXT,
      field: 'name',
      headerName: intl.formatMessage(messages.departmentName),
      minWidth: 170,
      sortable: true
    },
    {
      cellRenderer: CELL_RENDERERS.TEXT,
      field: 'description',
      headerName: intl.formatMessage(messages.description),
      minWidth: 170,
      cellStyle: { 'white-space': 'normal', 'line-height': '25px' },
      wrapText: true,
      sortable: true
    },
    {
      cellRenderer: CustomAddressRenderer,
      cellRendererParams: (params: ParamsType): CustomAddressType[] =>
        params.data.addresses,
      valueGetter: (params: ParamsType): string | string[] =>
        CustomAddressRenderer(params),
      field: 'departmentLocation',
      headerName: intl.formatMessage(messages.physicalAddress),
      cellStyle: { 'white-space': 'normal', 'line-height': '25px' },
      autoHeight: true,
      wrapText: true,
      sortable: true
    },
    {
      cellRendererFramework: CustomStoreStatusRenderer,
      cellRendererParams: (params: ParamsType): any => params.data,
      valueGetter: (params: ParamsType): string =>
        params.data.status?.toLowerCase(),
      field: 'status',
      headerName: intl.formatMessage(messages.departmentStatus),
      minWidth: 150,
      maxWidth: 300,
      sortable: true
    }
  ];

  const columnAssignDepartments = [
    {
      cellClass: CELL_CLASSES.CHECKBOX,
      checkboxSelection: true,
      field: 'check',
      filter: false,
      headerCheckboxSelection: true,
      headerCheckboxSelectionFilteredOnly: true,
      headerClass: [CELL_CLASSES.CHECKBOX, CELL_CLASSES.SELECT_ALL],
      headerName: '',
      maxWidth: 55,
      minWidth: 55,
      resizable: false
    },
    {
      cellRendererFramework: TransformStoreIdLink,
      cellRendererParams: (params: ParamsType): any => params.data,
      valueGetter: (params: ParamsType): string => params.data.id,
      field: 'id',
      headerName: intl.formatMessage(messages.storeId),
      minWidth: 100,
      sortable: true
    },
    {
      cellRenderer: CELL_RENDERERS.TEXT,
      field: 'name',
      headerName: intl.formatMessage(messages.departmentName),
      minWidth: 170,
      sortable: true
    },
    {
      cellRenderer: CELL_RENDERERS.TEXT,
      field: 'description',
      headerName: intl.formatMessage(messages.description),
      minWidth: 170,
      cellStyle: { 'white-space': 'normal', 'line-height': '25px' },
      wrapText: true,
      sortable: true
    },
    {
      cellRenderer: CustomAddressRenderer,
      cellRendererParams: (params: ParamsType): CustomAddressType[] =>
        params.data.addresses,
      valueGetter: (params: ParamsType): string | string[] =>
        CustomAddressRenderer(params),
      field: 'departmentLocation',
      headerName: intl.formatMessage(messages.physicalAddress),
      cellStyle: { 'white-space': 'normal', 'line-height': '25px' },
      wrapText: true,
      autoHeight: true,
      sortable: true
    },
    {
      cellRendererFramework: CustomStoreStatusRenderer,
      cellRendererParams: (params: ParamsType): any => params.data,
      valueGetter: (params: ParamsType): string =>
        params.data.status?.toLowerCase(),
      field: 'status',
      headerName: intl.formatMessage(messages.departmentStatus),
      minWidth: 150,
      maxWidth: 300,
      sortable: true
    },
    {
      cellRenderer: CustomCompanyRenderer,
      cellRendererParams: (
        params: DepartmentParamsType
      ): CustomStoreDeptRenderProps => params,
      valueGetter: (params: DepartmentParamsType): string | string[] =>
        CustomCompanyRenderer(params),
      field: 'company',
      headerName: intl.formatMessage(messages.company),
      cellStyle: { 'white-space': 'normal' },
      autoHeight: true,
      sortable: true
    }
  ];
  return [columns, columnDepartments, columnAssignDepartments];
};

const useAllStoresColumns = (): ColumnDefinitionProps[] => {
  const intl = useIntl();

  const columns: ColumnDefinitionProps[] = [
    {
      field: 'id',
      label: intl.formatMessage(messages.storeId),
      headingProps: { width: '6%' },
      isSortable: true,
      sortOrder: 'asc',
      valueGetter: TransformStoreId
    },
    {
      field: 'name',
      label: intl.formatMessage(messages.name),
      headingProps: { width: '8%' },
      isSortable: true,
      sortOrder: 'asc'
    },
    {
      field: 'storeLocation',
      label: intl.formatMessage(messages.physicalAddress),
      headingProps: { width: '10%' },
      isSortable: true,
      sortOrder: 'asc',
      valueGetter: transformStoreLocation
    },
    {
      field: 'dmsAccount',
      label: intl.formatMessage(messages.dmsAccounts),
      headingProps: { width: '8%' },
      isSortable: true,
      sortOrder: 'asc',
      valueGetter: TransformDMSAccount
    },
    {
      field: 'cmfNumber',
      label: intl.formatMessage(messages.cmfNumber),
      headingProps: { width: '8%' },
      isSortable: true,
      sortOrder: 'asc',
      valueGetter: TransformCMFNumber
    },
    {
      field: 'type',
      label: intl.formatMessage(messages.storeType),
      headingProps: { width: '8%' },
      isSortable: true,
      sortOrder: 'asc'
    },
    {
      field: 'status',
      label: intl.formatMessage(messages.storeStatus),
      headingProps: { width: '8%' },
      isSortable: true,
      sortOrder: 'asc',
      valueGetter: TransformStatus
    },
    {
      field: 'visible',
      label: intl.formatMessage(messages.visibility),
      headingProps: { width: '8%' },
      isSortable: true,
      sortOrder: 'asc',
      valueGetter: TransformStoreVisibility
    }
  ];
  return columns;
};

const useEnterpriseAdminColumns = (): Array<any> => {
  const intl = useIntl();

  const columns = [
    {
      cellRendererFramework: CustomNameMerger,
      cellRendererParams: (params: EnterpriseParamsType): any => params.data,
      valueGetter: (params: EnterpriseParamsType): string =>
        params.data.loginId,
      field: 'firstName',
      headerName: intl.formatMessage(messages.enterpriseAdminName),
      minWidth: 170,
      sortable: true
    },
    {
      cellRenderer: CELL_RENDERERS.TEXT,
      field: 'primaryEmail',
      headerName: intl.formatMessage(messages.emailId),
      minWidth: 170,
      sortable: true
    },
    {
      cellRenderer: CELL_RENDERERS.TEXT,
      field: 'primaryPhone',
      headerName: intl.formatMessage(messages.contactNo),
      minWidth: 170,
      sortable: true
    }
  ];
  return [columns];
};

export { useColumns, useAllStoresColumns, useEnterpriseAdminColumns };
