import { useIntl } from 'react-intl';
import moment from 'moment';
import TableFilterType from '../../../../helpers/types/tableFilterType';
import { messages } from './messages';

const useFilters = (): TableFilterType[][] => {
  const intl = useIntl();
  const filters: TableFilterType[] = [
    {
      label: 'User Count',
      uid: 'userCount',
      typecastTo: null,
      type: 'range',
      multi: true,
      options: [
        {
          id: '1to10',
          label: intl.formatMessage(messages.oneto10Users),
          value: { min: 1, max: 10 },
          selectionLabel: '',
          disabled: false,
          showDivider: false
        },
        {
          id: '10to50',
          label: intl.formatMessage(messages.tenTo50Users),
          value: { min: 10, max: 50 },
          selectionLabel: '',
          disabled: false,
          showDivider: false
        },
        {
          id: '50to100',
          label: intl.formatMessage(messages.fiftyTo100Users),
          value: { min: 50, max: 100 },
          selectionLabel: '',
          disabled: false,
          showDivider: false
        },
        {
          id: '100to300',
          label: intl.formatMessage(messages.hundredTo300Users),
          value: { min: 100, max: 300 },
          selectionLabel: '',
          disabled: false,
          showDivider: false
        },
        {
          id: '300plus',
          label: intl.formatMessage(messages.plus300Users),
          value: { min: 300, max: Infinity },
          selectionLabel: '',
          disabled: false,
          showDivider: false
        }
      ]
    },
    {
      label: 'Date Created',
      uid: 'createdDate',
      typecastTo: moment,
      type: 'range',
      multi: false,
      options: [
        {
          id: 'currentweek',
          label: intl.formatMessage(messages.currentWeek),
          value: { min: moment().startOf('week'), max: moment().endOf('week') },
          selectionLabel: '',
          disabled: false,
          showDivider: false
        },
        {
          id: 'previousweek',
          label: intl.formatMessage(messages.previousWeek),
          value: {
            min: moment().startOf('week').subtract('7', 'd'),
            max: moment().endOf('week').subtract('7', 'd')
          },
          selectionLabel: '',
          disabled: false,
          showDivider: false
        },
        {
          id: 'currentmonth',
          label: intl.formatMessage(messages.currentMonth),
          value: {
            min: moment().startOf('month'),
            max: moment().endOf('month')
          },
          selectionLabel: '',
          disabled: false,
          showDivider: false
        },
        {
          id: 'previousmonth',
          label: intl.formatMessage(messages.previousMonth),
          value: {
            min: moment().startOf('month').subtract(1, 'M'),
            max: moment().endOf('month').subtract(1, 'M')
          },
          selectionLabel: '',
          disabled: false,
          showDivider: false
        },
        {
          id: 'past6months',
          label: intl.formatMessage(messages.past6Months),
          value: { min: moment().subtract(6, 'M'), max: moment() },
          selectionLabel: '',
          disabled: false,
          showDivider: false
        },
        {
          id: 'pastyear',
          label: intl.formatMessage(messages.pastYear),
          value: { min: moment().subtract(1, 'y'), max: moment() },
          selectionLabel: '',
          disabled: false,
          showDivider: false
        }
      ]
    }
  ];
  return [filters];
};

export default useFilters;
