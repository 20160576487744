import React from 'react';

import { ICON_SIZE } from 'lib/icons';
import { removeObjectProperties } from 'lib/utilities';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import { getStateStyles } from './iconButtonStateStyles';
import { BaseButton } from '../../blocks';
import { BUTTON_BACKGROUND_APPEARANCES, BUTTON_ICON_COLORS, BUTTON_SIZES } from '../../constants';

const StyledIconButton = styled(BaseButton)`
  border-radius: ${({ theme }) => theme.size.borderRadius.full.value};

  &::after {
    border-radius: ${({ theme }) => theme.size.borderRadius.full.value};
  }
`;

const defaultIconButtonProps = {
  customTagElement: '',
  dataTestId: undefined,
  href: undefined,
  icon: undefined,
  iconClassName: '',
  iconSize: ICON_SIZE.DEFAULT,
  isDisabled: false,
  onClick: () => {},
  onKeyDown: () => {},
  preserveClickableArea: false,
  size: BUTTON_SIZES.STANDARD,
  type: '',
};

const IconButton = React.forwardRef(
  (
    { backgroundAppearance = BUTTON_BACKGROUND_APPEARANCES.LIGHT, iconColor = BUTTON_ICON_COLORS.GRAY, ...other },
    ref
  ) => {
    const propsWithoutExcluded = removeObjectProperties(other, 'hideText', 'isLoading', 'loaderText');

    return (
      <StyledIconButton
        hideText
        {...defaultIconButtonProps}
        {...propsWithoutExcluded}
        ref={ref}
        stateStyles={getStateStyles(backgroundAppearance, iconColor)}
      />
    );
  }
);

IconButton.propTypes = {
  /** @ignore */
  backgroundAppearance: PropTypes.oneOf(Object.values(BUTTON_BACKGROUND_APPEARANCES)),
  /** Replaces default tag: button or anchor with new value */
  customTagElement: PropTypes.string,
  /** Id value used for testing */
  dataTestId: PropTypes.string,
  /** When URL is provided, element changes from button to hyperlink <a> */
  href: PropTypes.string,
  /** If not loading, will render specified icon before text */
  icon: PropTypes.node,
  /** Adds new class for icon element */
  iconClassName: PropTypes.string,
  /** @ignore */
  iconColor: PropTypes.oneOf(Object.values(BUTTON_ICON_COLORS)),
  /** Will set size of the icon */
  iconSize: PropTypes.oneOf(Object.values(ICON_SIZE)),
  /** Disallows user to interact with the button and adjusts appearance */
  isDisabled: PropTypes.bool,
  /** Callback that is called on click */
  onClick: PropTypes.func,
  /** Callback that is called on key down */
  onKeyDown: PropTypes.func,
  /** If true, will add vertical margins to the component */
  preserveClickableArea: PropTypes.bool,
  /** Changes button height */
  size: PropTypes.oneOf(Object.values(BUTTON_SIZES)),
  /** Will display text inside button */
  text: PropTypes.node.isRequired,
  /** Default html button 'type' attribute values when button component is used */
  type: PropTypes.string,
};

export { IconButton };
