import { createSlice } from '@reduxjs/toolkit';
import type { PayloadAction } from '@reduxjs/toolkit';

import { Permissions, Resources } from './types/userManagementSliceTypes';

export const userRoleSlice = createSlice({
  name: 'userRoleSlice',
  initialState: {
    permissions: [] as Permissions[],
    editRolePermissions: [] as Permissions[],
    permissionsBeforeEdit: [] as Permissions[],
    hasUpdateRolesPermission: false,
    isAssignRolesByUsersDialogOpen: false,
    selectedForUnassign: [] as string[],
    selectedForAssign: [] as string[],
    refetchGetRoleByUsersQuery: false,
    roleCode: '' as string,
    currentTabIndex: 0 as number,
    tabChanged: false as boolean
  },
  reducers: {
    addSelectedPermission: (state, action: PayloadAction<Permissions>) => {
      state.permissions.push(action.payload);
    },
    removeSelectedPermission: (state, action: PayloadAction<Permissions>) => {
      const { permissionId } = action.payload;
      const arr = state.permissions.filter(
        item => item.permissionId !== permissionId
      );
      state.permissions = arr;
    },
    addSelectedResource: (state, action: PayloadAction<Resources>) => {
      const { permissionId, resourceId } = action.payload;
      state?.permissions
        ?.find(item => item?.permissionId === permissionId)
        ?.resources?.push(resourceId);
    },
    removeSelectedReource: (state, action: PayloadAction<Resources>) => {
      const { permissionId, resourceId } = action.payload;
      const resources = state.permissions.find(
        item => item.permissionId === permissionId
      )?.resources;
      const resourceIdIndex = state.permissions
        .find(item => item.permissionId === permissionId)
        ?.resources?.indexOf(resourceId);

      if (
        resources &&
        resources?.length > 0 &&
        resourceIdIndex &&
        resourceIdIndex >= 0
      ) {
        state.permissions
          .find(item => item.permissionId === permissionId)
          ?.resources?.splice(resourceIdIndex, 1);
      } else {
        state.permissions
          .find(item => item.permissionId === permissionId)
          ?.resources?.splice(0, 1);
      }
    },
    editRoleAddSelectedPermission: (
      state,
      action: PayloadAction<Permissions>
    ) => {
      state.editRolePermissions.push(action.payload);
    },
    editRoleAddSelectedPermissionBeforeEdit: (
      state,
      action: PayloadAction<Permissions>
    ) => {
      state.permissionsBeforeEdit.push(action.payload);
    },
    editRoleRemoveSelectedPermission: (
      state,
      action: PayloadAction<Permissions>
    ) => {
      const { permissionId } = action.payload;
      const arr = state.editRolePermissions.filter(
        item => item.permissionId !== permissionId
      );
      state.editRolePermissions = arr;
    },
    editRoleAddSelectedResource: (state, action: PayloadAction<Resources>) => {
      const { permissionId, resourceId } = action.payload;
      state?.editRolePermissions
        ?.find(item => item?.permissionId === permissionId)
        ?.resources?.push(resourceId);
    },
    editRoleRemoveSelectedReource: (
      state,
      action: PayloadAction<Resources>
    ) => {
      const { permissionId, resourceId } = action.payload;
      const resources = state.editRolePermissions.find(
        item => item.permissionId === permissionId
      )?.resources;
      const resourceIdIndex = state.editRolePermissions
        .find(item => item.permissionId === permissionId)
        ?.resources?.indexOf(resourceId);

      if (
        resources &&
        resources?.length > 0 &&
        resourceIdIndex &&
        resourceIdIndex >= 0
      ) {
        state.editRolePermissions
          .find(item => item.permissionId === permissionId)
          ?.resources?.splice(resourceIdIndex, 1);
      } else {
        state.editRolePermissions
          .find(item => item.permissionId === permissionId)
          ?.resources?.splice(0, 1);
      }
    },
    editRoleRemoveAllPermissions: state => {
      state.editRolePermissions = [];
      state.permissionsBeforeEdit = [];
    },
    editRoleModifyhasUpdateRolesPermission: state => {
      state.hasUpdateRolesPermission = true;
    },
    editRoleIsAssignRolesByUsersDialogOpen: (
      state,
      action: PayloadAction<boolean>
    ) => {
      state.isAssignRolesByUsersDialogOpen = action.payload;
    },
    editRoleAddRowsForUnassign: (state, action: PayloadAction<string>) => {
      state.selectedForUnassign.push(action.payload);
    },
    editRoleRemoveRowsForUnassign: (state, action: PayloadAction<string>) => {
      const index = state.selectedForUnassign.indexOf(action.payload);
      if (index > -1) {
        state.selectedForUnassign.splice(index, 1);
      }
    },
    editRoleAddAllForUnassign: (state, action: PayloadAction<string[]>) => {
      state.selectedForUnassign = action.payload;
    },
    editRoleRemoveAllForUnassign: state => {
      state.selectedForUnassign = [];
    },
    editRoleAddRowsForAssign: (state, action: PayloadAction<string>) => {
      state.selectedForAssign.push(action.payload);
    },
    editRoleRemoveRowsForAssign: (state, action: PayloadAction<string>) => {
      const index = state.selectedForAssign.indexOf(action.payload);
      if (index > -1) {
        state.selectedForAssign.splice(index, 1);
      }
    },
    editRoleAddAllForAssign: (state, action: PayloadAction<string[]>) => {
      state.selectedForAssign = action.payload;
    },
    editRoleRemoveAllForAssign: state => {
      state.selectedForAssign = [];
    },
    editRoleRefetchGetRoleByUsersQuery: (
      state,
      action: PayloadAction<boolean>
    ) => {
      state.refetchGetRoleByUsersQuery = action.payload;
    },
    editRoleUpdateRoleCode: (state, action: PayloadAction<string>) => {
      state.roleCode = action.payload;
    },
    updateDefaultTabChanged: (state, action: PayloadAction<boolean>) => {
      state.tabChanged = action.payload;
    },
    updateActiveTabIndex: (state, action: PayloadAction<number>) => {
      state.currentTabIndex = action.payload;
    }
  }
});

export const {
  addSelectedPermission,
  removeSelectedPermission,
  addSelectedResource,
  removeSelectedReource,
  editRoleAddSelectedPermission,
  editRoleAddSelectedPermissionBeforeEdit,
  editRoleRemoveSelectedPermission,
  editRoleAddSelectedResource,
  editRoleRemoveSelectedReource,
  editRoleRemoveAllPermissions,
  editRoleModifyhasUpdateRolesPermission,
  editRoleIsAssignRolesByUsersDialogOpen,
  editRoleAddRowsForUnassign,
  editRoleRemoveRowsForUnassign,
  editRoleAddRowsForAssign,
  editRoleAddAllForUnassign,
  editRoleRemoveAllForUnassign,
  editRoleAddAllForAssign,
  editRoleRemoveRowsForAssign,
  editRoleRemoveAllForAssign,
  editRoleRefetchGetRoleByUsersQuery,
  editRoleUpdateRoleCode,
  updateActiveTabIndex,
  updateDefaultTabChanged
} = userRoleSlice.actions;

export default userRoleSlice.reducer;
