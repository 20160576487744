import { css } from 'styled-components';

import { getLineHeight } from './getLineHeight';

export const typographyBaseStyle = (theme) => css`
  color: ${theme.color.text.light.primary.value};
  margin: 0;
`;

export const typographyDisplay1 = (theme) => {
  const fontSize = theme.typography.display1.fontSize.value;

  return css`
    ${typographyBaseStyle(theme)};
    font-family: ${theme.typography.display1.fontFamily.value};
    font-size: ${fontSize};
    font-weight: ${theme.typography.display1.fontWeight.value};
    letter-spacing: ${theme.typography.display1.letterSpacing.value};
    line-height: ${getLineHeight(theme.typography.display1.lineHeight.value, fontSize)};
  `;
};

export const typographyDisplay2 = (theme) => {
  const fontSize = theme.typography.display2.fontSize.value;

  return css`
    ${typographyBaseStyle(theme)};
    font-family: ${theme.typography.display2.fontFamily.value};
    font-size: ${fontSize};
    font-weight: ${theme.typography.display2.fontWeight.value};
    letter-spacing: ${theme.typography.display2.letterSpacing.value};
    line-height: ${getLineHeight(theme.typography.display2.lineHeight.value, fontSize)};
  `;
};

export const typographyDisplay3 = (theme) => {
  const fontSize = theme.typography.display3.fontSize.value;

  return css`
    ${typographyBaseStyle(theme)};
    font-family: ${theme.typography.display3.fontFamily.value};
    font-size: ${fontSize};
    font-weight: ${theme.typography.display3.fontWeight.value};
    letter-spacing: ${theme.typography.display3.letterSpacing.value};
    line-height: ${getLineHeight(theme.typography.display3.lineHeight.value, fontSize)};
  `;
};

export const typographyHeading1 = (theme) => {
  const fontSize = theme.typography.heading1.fontSize.value;

  return css`
    ${typographyBaseStyle(theme)};
    font-family: ${theme.typography.heading1.fontFamily.value};
    font-size: ${fontSize};
    font-weight: ${theme.typography.heading1.fontWeight.value};
    letter-spacing: ${theme.typography.heading1.letterSpacing.value};
    line-height: ${getLineHeight(theme.typography.heading1.lineHeight.value, fontSize)};
  `;
};

export const typographyHeading2 = (theme) => {
  const fontSize = theme.typography.heading2.fontSize.value;

  return css`
    ${typographyBaseStyle(theme)};
    font-family: ${theme.typography.heading2.fontFamily.value};
    font-size: ${fontSize};
    font-weight: ${theme.typography.heading2.fontWeight.value};
    letter-spacing: ${theme.typography.heading2.letterSpacing.value};
    line-height: ${getLineHeight(theme.typography.heading2.lineHeight.value, fontSize)};
  `;
};

export const typographySubtitle1 = (theme) => {
  const fontSize = theme.typography.subtitle1.fontSize.value;

  return css`
    ${typographyBaseStyle(theme)};
    font-family: ${theme.typography.subtitle1.fontFamily.value};
    font-size: ${fontSize};
    font-weight: ${theme.typography.subtitle1.fontWeight.value};
    letter-spacing: ${theme.typography.subtitle1.letterSpacing.value};
    line-height: ${getLineHeight(theme.typography.subtitle1.lineHeight.value, fontSize)};
  `;
};

export const typographySubtitle2 = (theme) => {
  const fontSize = theme.typography.subtitle2.fontSize.value;

  return css`
    ${typographyBaseStyle(theme)};
    font-family: ${theme.typography.subtitle2.fontFamily.value};
    font-size: ${fontSize};
    font-weight: ${theme.typography.subtitle2.fontWeight.value};
    letter-spacing: ${theme.typography.subtitle2.letterSpacing.value};
    line-height: ${getLineHeight(theme.typography.subtitle2.lineHeight.value, fontSize)};
  `;
};

export const typographySubtitle3 = (theme) => {
  const fontSize = theme.typography.subtitle3.fontSize.value;

  return css`
    ${typographyBaseStyle(theme)};
    font-family: ${theme.typography.subtitle3.fontFamily.value};
    font-size: ${fontSize};
    font-weight: ${theme.typography.subtitle3.fontWeight.value};
    letter-spacing: ${theme.typography.subtitle3.letterSpacing.value};
    line-height: ${getLineHeight(theme.typography.subtitle3.lineHeight.value, fontSize)};
  `;
};

export const typographyBody1 = (theme) => {
  const fontSize = theme.typography.body1.fontSize.value;

  return css`
    ${typographyBaseStyle(theme)};
    font-family: ${theme.typography.body1.fontFamily.value};
    font-size: ${fontSize};
    font-weight: ${theme.typography.body1.fontWeight.value};
    letter-spacing: ${theme.typography.body1.letterSpacing.value};
    line-height: ${getLineHeight(theme.typography.body1.lineHeight.value, fontSize)};
  `;
};

export const typographyBody2 = (theme) => {
  const fontSize = theme.typography.body2.fontSize.value;

  return css`
    ${typographyBaseStyle(theme)};
    font-family: ${theme.typography.body2.fontFamily.value};
    font-size: ${fontSize};
    font-weight: ${theme.typography.body2.fontWeight.value};
    letter-spacing: ${theme.typography.body2.letterSpacing.value};
    line-height: ${getLineHeight(theme.typography.body2.lineHeight.value, fontSize)};
  `;
};

export const typographyCaption = (theme) => {
  const fontSize = theme.typography.caption.fontSize.value;

  return css`
    ${typographyBaseStyle(theme)};
    color: ${theme.color.text.light.secondary.value};
    font-family: ${theme.typography.caption.fontFamily.value};
    font-size: ${fontSize};
    font-weight: ${theme.typography.caption.fontWeight.value};
    letter-spacing: ${theme.typography.caption.letterSpacing.value};
    line-height: ${getLineHeight(theme.typography.caption.lineHeight.value, fontSize)};
  `;
};

export const typographyGroupLabel = (theme) => css`
  ${typographyCaption(theme)};
  color: ${theme.color.text.light.secondary.value};
  font-weight: ${theme.font.weight.medium.value};
`;

export const typographyButton = (theme) => {
  const fontSize = theme.typography.button.fontSize.value;

  return css`
    ${typographyBaseStyle(theme)};
    font-family: ${theme.typography.button.fontFamily.value};
    font-size: ${fontSize};
    font-weight: ${theme.typography.button.fontWeight.value};
    letter-spacing: ${theme.typography.button.letterSpacing.value};
    line-height: ${getLineHeight(theme.typography.button.lineHeight.value, fontSize)};
  `;
};

export const typographyOverline = (theme) => {
  const fontSize = theme.typography.overline.fontSize.value;

  return css`
    ${typographyBaseStyle(theme)};
    font-family: ${theme.typography.overline.fontFamily.value};
    font-size: ${fontSize};
    font-weight: ${theme.typography.overline.fontWeight.value};
    letter-spacing: ${theme.typography.overline.letterSpacing.value};
    line-height: ${getLineHeight(theme.typography.overline.lineHeight.value, fontSize)};
    text-transform: uppercase;
  `;
};

export const typographyLink = (theme) => {
  const fontSize = theme.typography.link1.fontSize.value;

  return css`
    color: ${theme.color.primary['500'].value};
    font-family: ${theme.typography.link1.fontFamily.value};
    font-size: ${fontSize};
    font-weight: ${theme.typography.link1.fontWeight.value};
    letter-spacing: ${theme.typography.link1.letterSpacing.value};
    line-height: ${getLineHeight(theme.typography.link1.lineHeight.value, fontSize)};
    margin: 0;
  `;
};

export const typographyLink2 = (theme) => {
  const fontSize = theme.typography.link2.fontSize.value;

  return css`
    color: ${theme.color.primary['500'].value};
    font-family: ${theme.typography.link2.fontFamily.value};
    font-size: ${fontSize};
    font-weight: ${theme.typography.link2.fontWeight.value};
    letter-spacing: ${theme.typography.link2.letterSpacing.value};
    line-height: ${getLineHeight(theme.typography.link2.lineHeight.value, fontSize)};
    margin: 0;
  `;
};
