import React, { useCallback, useState, useEffect, useLayoutEffect, useRef, createRef } from 'react';
import {
  flatMap,
  getDynamicPosition,
  getPositionStyles,
  getStaticPosition,
  KEY_VALUES,
  removeObjectProperties,
} from 'lib/utilities';
import PropTypes from 'prop-types';
import { createPortal } from 'react-dom';
import styled from 'styled-components';

import { getScrollableParent, useWindowWidth } from '../../utilities';
import { MENU_PLACEMENT } from '../constants';
import { StyledMenuContainer } from '../elements';
import { useOutsideClickEventListener } from '../utilities';

const StyledItemContainer = styled.div`
  padding: ${({ theme }) => theme.size.spacing.small.value} 0;
`;

const Autocomplete = ({
  children,
  className = '',
  dataTestId = '',
  dimensionRef = undefined,
  isAuto = true,
  isOpen = false,
  labelRef,
  onClose = undefined,
  onOpen = undefined,
  onUnselect = false,
  placement = MENU_PLACEMENT.BOTTOM_START,
  style = {},
  triggerOffset = 5,
  ...other
}) => {
  const [targetNode, setTargetNode] = useState(null);
  const focusItem = useRef(null);
  const [keyBoardFocus, setKeyBoardFocus] = useState(0);
  const [animateMenu, setAnimateMenu] = useState(false);

  const menuRef = useRef(document.createElement('div'));
  const { ARROW_DOWN, ARROW_UP, ESCAPE, TAB } = KEY_VALUES;
  const menuContainerRef = useRef();
  const menuOpenStatus = useRef(null);

  const flatChildren = Array.isArray(children)
    ? flatMap(children, (item) => (Array.isArray(item) ? flatMap(item, (item) => item) : item))
    : [children];

  const menuListRef = useCallback(
    flatChildren.map(() => createRef()),
    [flatChildren.length]
  );

  const setSelectedFocusStyles = (ref) => (keyBoardFocus ? (focusItem.current = ref.current.innerText) : null);

  const handleClose = () => (isOpen ? onClose() : null);

  if (onUnselect) focusItem.current = null;

  const handleLabelKeyDown = useCallback((event) => {
    if ([ARROW_UP, ARROW_DOWN].includes(event.key)) {
      event.preventDefault();
      onOpen();
      setKeyBoardFocus(event.key);
      const listItemRef = menuListRef;
      const itemsLength = listItemRef.length - 1;
      if (event.key === ARROW_DOWN) setTimeout(() => listItemRef[0].current.focus(), 0);
      else if (event.key === ARROW_UP) setTimeout(() => listItemRef[itemsLength].current.focus(), 0);
    }
    if ([ESCAPE, TAB].includes(event.key)) handleClose();
  });

  // This section closes the Menu on screen resize
  const scanWindow = useWindowWidth(5);
  useLayoutEffect(() => {
    handleClose();
  }, [scanWindow]);

  const listItemKeyDown = useCallback((event, target = null) => {
    const eventTarget = target || event.target;
    if (event.key === ARROW_DOWN) {
      event.preventDefault();
      if (eventTarget && eventTarget.nextSibling) {
        if (eventTarget.nextSibling.getAttribute('disabled') !== null) {
          return listItemKeyDown(event, eventTarget.nextSibling);
        }
        eventTarget.nextSibling.focus();
      } else {
        menuListRef[0].current.focus();
      }
    }
    if (event.key === ARROW_UP) {
      event.preventDefault();
      if (eventTarget && eventTarget.previousElementSibling) {
        if (eventTarget.previousElementSibling.getAttribute('disabled') !== null) {
          return listItemKeyDown(event, eventTarget.previousElementSibling);
        }
        eventTarget.previousElementSibling.focus();
      } else {
        const listLength = menuListRef.length;
        menuListRef[listLength - 1].current.focus();
      }
    }
    if ([ESCAPE, TAB].includes(event.key)) handleClose();
  });

  // Following hook adds div to document body and assign node to menu
  useEffect(() => {
    const menuNode = menuRef.current;
    document.body.appendChild(menuNode);
    setTargetNode(menuNode);

    return () => document.body.removeChild(menuNode);
  }, []);

  //  Following hook adds click, scroll, and keydown event handlers to Label element
  useEffect(() => {
    const labelElm = labelRef.current;
    const scrollField = getScrollableParent(labelElm);

    if (labelElm && onOpen) {
      scrollField.addEventListener('scroll', handleClose);
      labelElm.addEventListener('keydown', handleLabelKeyDown);
    } else {
      console.error('Missing Label ref, labelRef should match label component ref ');
    }
    return () => {
      if (labelElm && onOpen) {
        labelElm.removeEventListener('keydown', handleLabelKeyDown);
        scrollField.removeEventListener('scroll', handleClose);
      }
    };
  }, [labelRef, onOpen]);

  useEffect(() => {
    if (isOpen) menuOpenStatus.current = true;
    else setKeyBoardFocus(0); // Resetting keyboard focus on close

    if (labelRef.current) document.addEventListener('scroll', () => (menuOpenStatus.current ? handleClose() : null));

    setAnimateMenu(isOpen);

    return () => {
      if (labelRef.current) document.removeEventListener('scroll', handleClose);
    };
  }, [isOpen]);

  useEffect(() => {
    menuListRef.forEach((el) => {
      if (el.current) {
        if (el.current.innerText === focusItem.current) el.current.focus();
        el.current.addEventListener('keydown', listItemKeyDown);
      }
    });
    return () => {
      menuListRef.forEach((el) => {
        if (el.current) el.current.removeEventListener('keydown', listItemKeyDown);
      });
    };
  }, [isOpen, listItemKeyDown]);

  useOutsideClickEventListener(menuRef, labelRef, handleClose, isOpen);
  const [position, showMenu] = isAuto
    ? getDynamicPosition(placement, menuContainerRef, dimensionRef || labelRef, isOpen, triggerOffset)
    : getStaticPosition(placement, isOpen);
  const styles = getPositionStyles(position, triggerOffset, dimensionRef || labelRef, isOpen, menuContainerRef);
  const isStyleNull = isNaN(styles.top) && isNaN(styles.left);
  const finalStyles = isStyleNull ? removeObjectProperties(styles, 'top', 'left') : styles;
  const nullStyles = removeObjectProperties(styles, 'top', 'left');
  const insertedStyles = style ? { ...style, ...finalStyles } : finalStyles;
  const isKeyboardFocused = keyBoardFocus !== 0;

  return (
    isOpen &&
    createPortal(
      <StyledMenuContainer
        $animateMenu={animateMenu}
        className={className}
        data-testid={`${dataTestId}-list`}
        ref={menuContainerRef}
        style={showMenu ? insertedStyles : nullStyles}
        {...other}
      >
        <StyledItemContainer data-testid={`${dataTestId}-list-item`}>
          {flatChildren?.map((child, index) =>
            React.cloneElement(child, {
              ref: menuListRef[index],
              key: child.key,
              tabIndex: 0,
              isKeyboardFocused: isKeyboardFocused,
              onSelect: setSelectedFocusStyles,
            })
          )}
        </StyledItemContainer>
      </StyledMenuContainer>,
      targetNode
    )
  );
};

Autocomplete.propTypes = {
  /** Menu items to populate menu */
  children: PropTypes.array.isRequired,
  /** Adds new class to Menu */
  className: PropTypes.string,
  /** Id value used for testing */
  dataTestId: PropTypes.string,
  /** The Ref of the element relative to which the menu will be positioned */
  dimensionRef: PropTypes.shape({ current: PropTypes.instanceOf(Element) }),
  /** If true, switch automatically to a placement that is more visible on the screen */
  isAuto: PropTypes.bool,
  /** Flag to open/close menu */
  isOpen: PropTypes.bool,
  /** The Ref of the wrapper element  */
  labelRef: PropTypes.shape({ current: PropTypes.instanceOf(Element) }).isRequired,
  /** Call back function will trigger after closing Menu */
  onClose: PropTypes.func,
  /** Call back function will trigger after opening Menu */
  onOpen: PropTypes.func,
  /** Callback function to reset focus styles */
  onUnselect: PropTypes.bool,
  /** Preferred placement of the menu */
  placement: PropTypes.oneOf(Object.values(MENU_PLACEMENT)),
  /** Custom inline style applied for the menu */
  style: PropTypes.shape({}),
  /** Parameter defining space between the target component and popup. */
  triggerOffset: PropTypes.number,
};

export { Autocomplete };
