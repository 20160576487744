import { useIntl } from 'react-intl';
import { messages } from 'commonMessages';
import { IndeterminateProgressCircle, LOADER_SIZE } from 'cdk-radial';
import { StyledProgressCircleContainer } from './styled';

type IProps = {
  dataTestId?: string;
  className?: string;
  label?: string;
};

const Loading = ({ dataTestId, className, label }: IProps): JSX.Element => {
  const intl = useIntl();
  return (
    <StyledProgressCircleContainer>
      <IndeterminateProgressCircle
        data-testid={dataTestId || 'loader'}
        className={className || 'centeredLoader'}
        size={LOADER_SIZE.SMALL}
        ariaLabel={label || intl.formatMessage(messages.loading)}
      />
    </StyledProgressCircleContainer>
  );
};

export default Loading;
