/* eslint-disable @typescript-eslint/no-unsafe-return */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable react/jsx-props-no-spreading */
import { useMemo, useCallback } from 'react';
import XLSX from 'xlsx';
import { useIntl } from 'react-intl';
import { useDropzone } from 'react-dropzone';
import {
  StepProgressBar,
  Grid,
  Row,
  Column,
  IconClose,
  IconAssignment
} from 'cdk-radial';
import { FileDataType } from 'redux/slices/csvImportSlice';
import validateErrors from '../../../../../helpers/csvHelper';
import StyledTemplateUpload from './styled';
import { messages } from './messages';

const baseStyle = {
  flex: 1,
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  width: '50%',
  padding: '0.6rem',
  borderWidth: 2,
  borderRadius: '0.5rem',
  borderColor: '#2196f3',
  borderStyle: 'dashed',
  backgroundColor: '#fff',
  color: '#006CFA',
  outline: 'none',
  transition: 'border .24s ease-in-out'
};

const acceptStyle = {
  borderColor: '#00e676'
};

const rejectStyle = {
  borderColor: '#ff1744'
};

type TemplateUploadProps = {
  setFileDetails: (fileDetails: FileDataType) => void;
  fileDetails: FileDataType;
  uid: string;
  allStores: Record<string, any>;
  allRoles: Record<string, any>;
};

type TransformeddataType = {
  stores: { value: string; error: string };
  roles: { value: string; error: string };
  rowIndex: number;
};

const TemplateUpload = ({
  setFileDetails,
  fileDetails,
  uid,
  allStores,
  allRoles
}: TemplateUploadProps): JSX.Element => {
  const intl = useIntl();
  const onDrop = useCallback(
    (acceptedFiles: any[]) => {
      acceptedFiles.forEach((file: any) => {
        const reader = new FileReader();

        reader.onabort = () => setFileDetails(null);
        reader.onerror = () => setFileDetails(null);
        reader.onload = (event: any) => {
          // Do whatever you want with the file contents
          const bstr = event.target.result;
          const wb = XLSX.read(bstr, { type: 'string', raw: true });
          /* Get first worksheet */
          const wsname = wb.SheetNames[0];
          const ws = wb.Sheets[wsname];
          /* Convert array of arrays */
          const data = XLSX.utils.sheet_to_json(ws, { header: 1, defval: '' });
          const [headers, ...csvData] = data;
          if (Array.isArray(csvData) && Array.isArray(headers)) {
            const transformedData: TransformeddataType[] = csvData.map(
              (csvRow: string[], rowIndex: number) =>
                csvRow.reduce(
                  (acc, curr, index) => ({
                    ...acc,
                    [headers[index]]: { value: curr, error: '' }
                  }),
                  {
                    rowIndex,
                    stores: { value: '', error: '' },
                    roles: { value: '', error: '' }
                  }
                )
            );

            let processedData = null;

            const errorContent = validateErrors(
              transformedData,
              uid,
              headers,
              allStores,
              allRoles,
              intl
            );
            if (errorContent.validated) {
              // attach stores and roles Object
              processedData = transformedData.map(userRow => ({
                ...userRow,
                stores: {
                  // eslint-disable-next-line @typescript-eslint/no-unsafe-return
                  ...userRow.stores,
                  value: userRow.stores.value
                    ? userRow.stores.value
                        .split(',')
                        .map((store: string) => allStores[store.trim()])
                    : []
                },
                roles: {
                  // eslint-disable-next-line @typescript-eslint/no-unsafe-return
                  ...userRow.roles,
                  value: userRow.roles.value
                    ? userRow.roles.value
                        .split(',')
                        .map((role: string) => allRoles[role.trim()])
                    : []
                }
              }));
            }
            setFileDetails({
              path: file.path,
              currentStep: 10,
              errorContent,
              rows: processedData
            });
          }
        };
        reader.readAsArrayBuffer(file);
      });
    },
    [setFileDetails, uid, allRoles, allStores, intl]
  );

  const { getRootProps, getInputProps, isDragAccept, isDragReject } =
    useDropzone({
      accept: '.csv, application/vnd.ms-excel, text/csv',
      maxFiles: 1,
      onDrop
    });

  const style = useMemo(
    () => ({
      ...baseStyle,
      ...(isDragAccept ? acceptStyle : {}),
      ...(isDragReject ? rejectStyle : {})
    }),
    [isDragAccept, isDragReject]
  );

  // text/csv

  const removeFile = () => {
    setFileDetails(null);
  };

  return (
    <StyledTemplateUpload>
      <div {...getRootProps({ style })}>
        <input {...getInputProps()} />
        <p className="pre-drag-text">
          {intl.formatMessage(messages.dragTemplateToUpload)}{' '}
          <span className="post-drag-text">
            {intl.formatMessage(messages.browseTemplate)}
          </span>
        </p>
      </div>
      {fileDetails && (
        <Grid>
          <Row className="info-row">
            <Column className="icon-column" xs={2}>
              <IconAssignment height={48} width={48} />
            </Column>
            <Column className="progress-name-column" xs={7}>
              <div>
                <p>{fileDetails.path}</p>
                <StepProgressBar
                  currentStep={fileDetails.currentStep}
                  totalSteps={10}
                />
              </div>
            </Column>
            <Column className="cancel-column" xs={2}>
              <span
                className="close-container"
                data-testid="remove-file"
                onClick={removeFile}
              >
                <IconClose />
              </span>
            </Column>
          </Row>
        </Grid>
      )}
    </StyledTemplateUpload>
  );
};

export default TemplateUpload;
