import { defineMessages } from 'react-intl';

export const messages = defineMessages({
  toasterDismiss: {
    id: 'TEMPLATES.COMMONAPPLAYOUT.TOASTER.BUTTON.DISMISS',
    defaultMessage: 'Dismiss'
  },
  noContentMessage: {
    id: 'TEMPLATES.SIMPLETABLELAYOUT.CONTENT.EMPTYMESSAGE',
    defaultMessage: 'No Data to display'
  },
  rowsPerPage: {
    id: 'TEMPLATES.SIMPLETABLELAYOUT.TABLE.COLUMNDEFINITIONS.ROWSPERPAGE',
    defaultMessage: 'Rows per page:'
  },
  searchPlaceholder: {
    id: 'TEMPLATES.TABLELAYOUT.USERSTABLE.PLACEHOLDER.SEARCH',
    defaultMessage: 'Search'
  }
});
