import React, { useState } from 'react';

import { useErrorValidation } from 'lib/utilities';
import PropTypes from 'prop-types';

import { Input, INPUT_ICON_POSITIONS, INPUT_SIZES } from './../../../input';
import { isEmailValid } from './emailValidator';
import { EMAIL_INPUT_ERRORS } from '../../errors';

const EmailInput = React.forwardRef(
  (
    {
      customEmailValidator = undefined,
      customTag = undefined,
      dataTestId = undefined,
      enableCustomValidation = false,
      errorMessage = '',
      hasError = false,
      helperText = '',
      icon = undefined,
      iconPosition = INPUT_ICON_POSITIONS.TRAILING,
      isDisabled = false,
      isFocused = false,
      isReadOnly = false,
      isRequired = false,
      onBlur = () => {},
      onChange,
      onError = () => {},
      onFocus = () => {},
      placeholder = '',
      prefixText = '',
      size = INPUT_SIZES.STANDARD,
      suffixText = '',
      value,
      ...other
    },
    ref
  ) => {
    const [isBlurred, setIsBlurred] = useState(false);
    const [editedSinceFocus, setEditedSinceFocus] = useState(false);

    const emailValidator = customEmailValidator || isEmailValid;
    const invalidEmailError = !editedSinceFocus && isBlurred && !emailValidator(value);

    const errors = [invalidEmailError && EMAIL_INPUT_ERRORS.INVALID_EMAIL].filter(Boolean);

    const { onChildError: onInputError, updatedHasError } = useErrorValidation(errors, {
      enableCustomValidation,
      hasError,
      onError,
    });

    const handleBlur = (event) => {
      setEditedSinceFocus(false);
      setIsBlurred(true);
      onBlur(event, { setEditedSinceFocus, setIsBlurred });
    };

    const handleChange = (...args) => {
      setEditedSinceFocus(true);
      onChange(...args);
    };

    const handleFocus = (event) => {
      setEditedSinceFocus(false);
      onFocus(event, { setEditedSinceFocus });
    };

    const Tag = customTag || Input;

    return (
      <Tag
        enableCustomValidation
        hasError={updatedHasError}
        onBlur={handleBlur}
        onChange={handleChange}
        onError={onInputError}
        onFocus={handleFocus}
        type="email"
        {...{
          customTag,
          dataTestId,
          errorMessage,
          helperText,
          icon,
          iconPosition,
          isDisabled,
          isFocused,
          isReadOnly,
          isRequired,
          placeholder,
          prefixText,
          size,
          suffixText,
          value,
        }}
        {...other}
        ref={ref}
      />
    );
  }
);

EmailInput.propTypes = {
  /** NOTE THAT EVERYTHING ELSE IS ALSO APPLICABLE WHAT YOU CAN FIND IN INPUT COMPONENT */
  /** Custom optional email validation function */
  customEmailValidator: PropTypes.func,
  /** Ability to supply a different input element instead of the default one */
  customTag: PropTypes.elementType,
  /** Id value used for testing */
  dataTestId: PropTypes.string,
  /** If true, custom validation is being enabled instead of built in component validation */
  enableCustomValidation: PropTypes.bool,
  /** Message to be displayed when input is in error state */
  errorMessage: PropTypes.node,
  /** When true, input is in error state */
  hasError: PropTypes.bool,
  /** Text to be displayed as a helper text near the input field */
  helperText: PropTypes.node,
  /** Icon to be displayed in input field */
  icon: PropTypes.node,
  /** Sets the position of icon */
  iconPosition: PropTypes.oneOf(Object.values(INPUT_ICON_POSITIONS)),
  /** Identifier of the input component */
  id: PropTypes.string.isRequired,
  /** If true, input is disabled and value of it cannot be edited */
  isDisabled: PropTypes.bool,
  /** If true, visually applies focused input styles */
  isFocused: PropTypes.bool,
  /** If true, input is in read only state, value cannot be edited */
  isReadOnly: PropTypes.bool,
  /** If true, input has to be filled or error state would be displayed */
  isRequired: PropTypes.bool,
  /** Label of the input field */
  label: PropTypes.node.isRequired,
  /** Name of the input */
  name: PropTypes.string.isRequired,
  /** Callback to be called when input looses focus */
  onBlur: PropTypes.func,
  /** Callback to be called when input's value is being changed by user interaction */
  onChange: PropTypes.func.isRequired,
  /** Callback to be called when inputs validation fails */
  onError: PropTypes.func,
  /** Callback to be called when input gains focus */
  onFocus: PropTypes.func,
  /** Text to be displayed when input is empty */
  placeholder: PropTypes.node,
  /** String to be displayed before the input value. Prefix text should be 1 character. */
  prefixText: PropTypes.node,
  /** Set the size of the input */
  size: PropTypes.oneOf(Object.values(INPUT_SIZES)),
  /** String to be displayed after the input value. Text should contain up to 5 characters, to not get cutted */
  suffixText: PropTypes.node,
  /** Current value of the email */
  value: PropTypes.string.isRequired,
};

export { EmailInput };
