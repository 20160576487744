import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { LOADER_VARIANTS } from '../constants';
import { StyledLoaderSpinner, LoaderLabel } from '../elements';

const StyledLoaderWrapper = styled.div`
  align-items: center;
  cursor: progress;
  display: inline-flex;
  flex: 1 1 auto;
  justify-content: center;
  stroke: ${({ theme }) => theme.color.additional.dark.value};
`;

export const Loader = ({
  hideLabel = false,
  label,
  spinnerClassName = '',
  variant = LOADER_VARIANTS.INLINE, // new
  ...other
}) => {
  if (process.env.NODE_ENV === 'development') {
    console.warn(
      'Radial has deprecated the Loader Component, and it will be removed in v8. Loader will have only limited support through v7. Developers will need to use the IndeterminateProgressCircle Component, and place elements as needed. Please visit https://svc-radial-storybook.ext.nebula.connectcdk.com/?path=/docs/components-loaders-indeterminate-progress-circle--indeterminate-progress-circle for more details.'
    );
  }
  return (
    <StyledLoaderWrapper role="status" {...other}>
      <StyledLoaderSpinner className={spinnerClassName} />
      {label && (
        <LoaderLabel hideLabel={hideLabel} label={label}>
          {label}
        </LoaderLabel>
      )}
    </StyledLoaderWrapper>
  );
};

Loader.propTypes = {
  /** Visually hides loader text */
  hideLabel: PropTypes.bool,
  /** Adds message on the right of the spinner */
  label: PropTypes.node.isRequired,
  /** Adds additional class to spinner */
  spinnerClassName: PropTypes.string,
  /** Loader variations. Values: ['inline'] */
  variant: PropTypes.oneOf(Object.values(LOADER_VARIANTS)),
};
