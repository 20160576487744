export const PATH_WITH_BACK_BUTTONS = [
  '/view_user_imports',
  '/scheduled_job_edit',
  '/user_details',
  '/copy_user/',
  '/settings/manage_enterprise_mfa',
  '/settings/manage_store_mfa',
  '/settings/manage_user_mfa',
  '/store/',
  '/linkusers/',
  '/role/',
  '/create-role',
  '/reports/mfa_activity/transactionId',
  '/settings/manage_cdk_edit_access',
  '/view_group/',
  '/new_group',
  '/department',
  '/settings/legacy-settings',
  '/legacy-store-settings',
  '/reports/mfa_activity',
  '/manage_users',
  '/service-user/',
  '/move-stores',
  '/merge-enterprise',
  '/bulk-unassign-user-job-details',
  '/user-management-report/',
  '/manage-dms-configuration'
];
