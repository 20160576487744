import React, { useState } from 'react';
import { DropdownMenu, DROPDOWN_VARIANTS, KEY_CODES } from 'cdk-radial';
import StyledAutoMaprolesDropDown from './styled';

type JobRole = {
  value: string,
  label: string,
  roleGuid: string,
  id: string,
};

type MappedRoleDropDownProps = {
  options:Array<JobRole>
  onMenuClose: () => void;
  onMenuOpen: () => void;
  onSpaceKeyClick: () => void;
  initialRole:JobRole
  updateRole: (selectedValue:JobRole) => void;
};

const MappedRoleDropDown = React.forwardRef(
  (
    {
      options,
      onMenuClose,
      onMenuOpen,
      onSpaceKeyClick,
      initialRole,
      updateRole,
    }: MappedRoleDropDownProps,
    ref,
  ) => {
    const [isFocused, setIsFocused] = useState(false);
    const [isMenuOpen, setIsMenuOpen] = useState(false);
    const [value, setValue] = useState(initialRole);
    const handleBlur = () => {
      setIsFocused(false);
    };

    const handleFocus = () => {
      setIsFocused(true);
    };

    const handleChange = (selectedValue: JobRole) => {
      setValue(selectedValue);
      updateRole(selectedValue);
    };

    const handleKeyDown = (event: any) => {
      const key = event.keyCode;
      const isArrowKey = key === KEY_CODES.ARROW_DOWN || key === KEY_CODES.ARROW_UP;
      const isSpaceKey = key === KEY_CODES.SPACE;

      if (!isMenuOpen && isArrowKey) {
        setIsMenuOpen(false);
      }

      if (isSpaceKey) {
        setIsMenuOpen(!isMenuOpen);
        onSpaceKeyClick();
      }
    };

    const handleMenuClose = () => {
      setIsMenuOpen(false);
      onMenuClose();
    };

    const handleMenuOpen = () => {
      setIsMenuOpen(true);
      onMenuOpen();
    };

    return (
      <StyledAutoMaprolesDropDown>
        <DropdownMenu
          className="auromap-roles-dropdown"
          isFocused={isFocused}
          menuIsOpen={isMenuOpen}
          isDropTop
          menuPortalTarget={document.body}
          onBlur={handleBlur}
          onChange={handleChange}
          onFocus={handleFocus}
          onKeyDown={handleKeyDown}
          onMenuClose={handleMenuClose}
          onMenuOpen={handleMenuOpen}
          ref={ref}
          value={value}
          variant={DROPDOWN_VARIANTS.INLINE}
          options={options}
        />
      </StyledAutoMaprolesDropDown>
    );
  },
);

export default MappedRoleDropDown;
