import { defineMessages } from 'react-intl';

export const messages = defineMessages({
  stepOneAutomaproles: {
    id: 'NEWUSERIMPORT.STEP1.AUTOMAPROLES.DESCRIPTION',
    defaultMessage:
      'Roles from DMS can be mapped to Roles in Connect CDK.Click the following button to Automap roles from DMS to roles in Connect CDK.Once this is done, your roles mapping can be saved for all future import jobs.'
  },
  stepOneAutomapRolesButton: {
    id: 'NEWUSERIMPORT.STEP1.TITLE.AUTOMAPROLES.BUTTON',
    defaultMessage: 'Automap Roles'
  }
});
