import { AMPLITUDE_API_KEY } from 'components/templates/commonAppLayout/constants';
import { AnalyticsProvider } from 'dms-analytics';
import { useState, useEffect } from 'react';
import { useClient } from './ClientProvider';

export const AmplitudeProvider = ({
  children
}: {
  children: React.ReactNode;
}): JSX.Element => {
  const client = useClient();
  const [userId, setUserId] = useState('');

  useEffect(() => {
    const user = client?.userInfo?.fullUser?.user;
    const FULLNAME = `${user?.firstName || ''} ${user?.lastName || ''} `;
    const USER_ID = user?.loginId || `${FULLNAME}`;
    setUserId(USER_ID);
  }, [client?.userInfo]);

  return (
    <>
      {userId ? (
        <AnalyticsProvider apiKey={AMPLITUDE_API_KEY} userId={userId}>
          <>{children}</>
        </AnalyticsProvider>
      ) : (
        <>{children}</>
      )}
    </>
  );
};
