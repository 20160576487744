import { defineMessages } from 'react-intl';

export const messages = defineMessages({
  lessText: {
    id: 'COMMON.EXPANDABLE.TEXT.BUTTON.LABEL.LESS',
    defaultMessage: '- Less'
  },
  moreText: {
    id: 'COMMON.EXPANDABLE.TEXT.BUTTON.LABEL.MORE',
    defaultMessage: '+ {count} More'
  }
});
