import { SecureRoute } from '@okta/okta-react';
import React from 'react';

type RoutesConfigProps = {
  routesConfig: {
    path: string;
    component: React.FunctionComponent;
    children?: any[];
    exact?: boolean;
  }[];
};

const RouteGeneratorComponent = ({
  routesConfig
}: RoutesConfigProps): JSX.Element => (
  <>
    {routesConfig.map(
      ({ path, component: Component, children = [], exact = false }) => (
        <SecureRoute path={path} exact={exact} key={path}>
          <Component>
            {children.length ? (
              <RouteGeneratorComponent routesConfig={children} />
            ) : null}
          </Component>
        </SecureRoute>
      )
    )}
  </>
);

export default RouteGeneratorComponent;
