import { IconApps, NavigationContextSwitcher } from 'cdk-radial';
import { useIntl } from 'react-intl';
import StyledAppSwitcher from './styled';
import { messages } from './messages';
import { UserApp, UserAppsProps } from './types';
import { getAppUrl } from './utils';

const AppSwitcher = ({ userApps }: UserAppsProps): JSX.Element => {
  const intl = useIntl();

  const sortedUserApps = [...userApps].sort((a, b) =>
    a.title.localeCompare(b.title)
  );

  const handleAppClick = (app: UserApp) => {
    const url = getAppUrl(app);
    if (url) {
      window.open(url, '_blank')?.focus();
    }
  };
  const transformedApps = sortedUserApps.map(userApp => ({
    avatarProps: {
      label: userApp.title,
      src: ''
    },
    href: '',
    id: `${userApp.title}-option`,
    isDisabled: false,
    label: userApp.title,
    onClick: () => handleAppClick(userApp)
  }));

  return (
    <StyledAppSwitcher>
      <NavigationContextSwitcher
        closeContextSwitcherPopupOnOutsideClick
        ariaLabel="Context switcher"
        dataTestId="global-navigation-app-switcher"
        className="global-navigation-app-switcher"
        isOnGlobalHeader
        defaultAvatarProps={{
          icon: <IconApps />,
          label: 'All Options',
          variant: 'icon'
        }}
        defaultSelectedValue={intl.formatMessage(
          messages.appSwitcherCommonAdmin
        )}
        options={transformedApps}
      />
    </StyledAppSwitcher>
  );
};

export default AppSwitcher;
