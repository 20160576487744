import { defineMessages } from 'react-intl';

export const messages = defineMessages({
  firstName: {
    id: 'GRID.ERRORTABLE.COLUMN.DEFINITION.FIRSTNAME',
    defaultMessage: 'First Name'
  },
  lastName: {
    id: 'GRID.ERRORTABLE.COLUMN.DEFINITION.LASTNAME',
    defaultMessage: 'Last Name'
  },
  dmsUserId: {
    id: 'GRID.ERRORTABLE.COLUMN.DEFINITION.DMSUSERID',
    defaultMessage: 'DMS User ID'
  },
  cNumber: {
    id: 'GRID.ERRORTABLE.COLUMN.DEFINITION.CNUMBER',
    defaultMessage: 'CNumber'
  },
  email: {
    id: 'GRID.ERRORTABLE.COLUMN.DEFINITION.EMAIL',
    defaultMessage: 'Email'
  },
  federationId: {
    id: 'GRID.ERRORTABLE.COLUMN.DEFINITION.FEDERATIONID',
    defaultMessage: 'Federation ID'
  },
  employeeId: {
    id: 'GRID.ERRORTABLE.COLUMN.DEFINITION.EMPLOYEEID',
    defaultMessage: 'Employee ID'
  },
  jobTitle: {
    id: 'GRID.ERRORTABLE.COLUMN.DEFINITION.JOBTITLE',
    defaultMessage: 'Job Title'
  },
  roles: {
    id: 'GRID.ERRORTABLE.COLUMN.DEFINITION.ROLES',
    defaultMessage: 'Roles'
  },
  stores: {
    id: 'GRID.ERRORTABLE.COLUMN.DEFINITION.STORES',
    defaultMessage: 'Stores'
  },
  simpleID: {
    id: 'GRID.ERRORTABLE.COLUMN.DEFINITION.SIMPLEID',
    defaultMessage: 'Simple ID'
  },
  emailId: {
    id: 'GRID.ERRORTABLE.COLUMN.DEFINITION.EMAILID',
    defaultMessage: 'Email ID'
  },
  altSystemId: {
    id: 'GRID.ERRORTABLE.COLUMN.DEFINITION.ALTSYSTEMID',
    defaultMessage: 'Alt System ID'
  },
  altUserID: {
    id: 'GRID.ERRORTABLE.COLUMN.DEFINITION.ALTUSERID',
    defaultMessage: 'Alt User ID'
  },
  primaryPhone: {
    id: 'GRID.ERRORTABLE.COLUMN.DEFINITION.PRIMARYPHONE',
    defaultMessage: 'Primary Phone'
  },
  loginId: {
    id: 'GRID.ERRORTABLE.COLUMN.DEFINITION.LOGINID',
    defaultMessage: 'Login Id'
  },
  dmsUserIdAndCNumber: {
    id: 'GRID.ERRORTABLE.COLUMN.DEFINITION.DMSUSERIDANDCNUMBER',
    defaultMessage: 'DMS User Id : CNumber'
  }
});
