import styled from 'styled-components';

const LanguageSwitcherWrapper = styled.div`
  #language-menu-switcher-button {
    background-color: transparent;

    span {
      color: #000000;
    }
    svg {
      fill: #000000;
    }
  }
`;
export default LanguageSwitcherWrapper;
