import styled from 'styled-components';

const StyledStepFourTitle = styled.div`
.schedule-desc{
    width: 37rem;
    height: 2.5rem;
    font-size: 14px;
    font-weight: 400;
    line-height: 1.4rem;
}
`;

export default StyledStepFourTitle;
