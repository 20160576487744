const apiEndPoints = {
  getSearchAllEnterprise: `enterpriseInfoService/rest/v0.1/search`,
  mergeEnterprise: `/enterpriseInfoService/rest/v0.1/enterpriseMapping/enterprise/merge`,
  getSearchEntities: `/enterpriseInfoService/rest/v0.1/search`,
  putDepartmentAssignUnassign: (storeId: string, updateType: string): string =>
    `/enterpriseInfoService/rest/v0.1/dealerStores/${storeId}/storeDepartmentMapping?updateType=${updateType}`,
  putStoreVisibility: (storeId: string, visibility: string): string =>
    `/enterpriseInfoService/rest/v0.1/admin/dealerStores/${storeId}/visibility/${visibility}`,
  putCreateStoreDetails: (storeId: string): string =>
    `/enterpriseInfoService/rest/v0.1/dealerStores/${storeId}`,
  putCreateDepartmentDetails: (storeId: string): string =>
    `/enterpriseInfoService/rest/v0.1/storeDepartments/${storeId}`,
  putCreateAlternateDetails: (
    storeId: string,
    identifierName: string
  ): string =>
    `/enterpriseInfoService/rest/v0.1/dealerStores/${storeId}/externalSystemMapping/${identifierName}?isForcedUpdate=true`,
  putCreateAlternateDepartmentDetails: (
    departmentId: string,
    identifierName: string
  ): string =>
    `/enterpriseInfoService/rest/v0.1/storeDepartments/${departmentId}/externalSystemMapping/${identifierName}?isForcedUpdate=true`,
  putDeleteAlternateDetails: (
    dmsValue: string,
    ipaddress: string,
    storeId: string
  ): string =>
    `/enterpriseInfoService/rest/v0.1/dmsMappings/${dmsValue}/${ipaddress}/${storeId}`,
  postCreateDMSInfoDetails: (dmsValue: string, ipaddress: string): string =>
    `/enterpriseInfoService/rest/v0.1/dmsMappings/${dmsValue}/${ipaddress}`,
  putCreateEnterpriseAlternateDetails: (
    enterpriseId: string,
    identifierName: string
  ): string =>
    `/enterpriseInfoService/rest/v0.1/enterprises/${enterpriseId}/externalSystemMapping/${identifierName}?isForcedUpdate=true`,
  putCreateEnterpriseDetails: (enterpriseId: string): string =>
    `/enterpriseInfoService/rest/v0.1/enterprises/${enterpriseId}`,
  putMoveStore: (storeId: string): string =>
    `/enterpriseInfoService/rest/v0.1/dealerStores/${storeId}`
};

export default apiEndPoints;
