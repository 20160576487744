import React from 'react';
import { CHECKBOX_SIZES } from 'lib/checkbox';
import { useWindowSize } from 'lib/utilities';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import { isMobileScreen } from '../../../core';
import { LIST_SIZES } from '../../constants';
import {
  ListItemCheckbox,
  ListItemGraphic,
  ListItemMetadata,
  ListItemPrimaryText,
  ListItemSubtext,
  ListItemText,
} from '../../List/elements';
import { NestedListItemContent, NestedListItemIcon } from '../elements';
import { nestedListItemPropTypes } from '../propTypes';

const StyledNestedListItem = styled.li`
  list-style: none;
`;

const NestedListItem = ({
  children = undefined,
  className = undefined,
  customContentTag = undefined,
  customGraphicTag = undefined,
  customPrimaryTextTag = undefined,
  dataTestId = undefined,
  id = undefined,
  isCheckable = false,
  isChecked = false,
  isExpanded = false,
  item,
  nestingLevel,
  onItemCheck = () => {},
  onItemExpandCollapse,
  size = LIST_SIZES.STANDARD,
  ...other
}) => {
  const windowSize = useWindowSize();
  const hasItems = !!(item?.items?.length || 0);
  const hasIcon = !!item.icon;
  const hasAvatar = !!item.avatar;
  const hasTooltip = !!item.tooltip;
  const { isDisabled } = item;

  return (
    <StyledNestedListItem
      className={className ? `${className}-li` : undefined}
      data-testid={dataTestId ? `${dataTestId}-item-${item.id}` : undefined}
    >
      <NestedListItemContent
        data-testid={dataTestId ? `${dataTestId}-item-button-${item.id}` : undefined}
        aria-expanded={hasItems ? !!children : null}
        aria-haspopup={hasItems}
        className={className ? `${className}-content` : undefined}
        customListItemTag={customContentTag}
        href={hasItems ? '' : item.href}
        id={item.id}
        isMultiLine={!!item.subtext}
        isNested={hasItems}
        onClick={(...args) => {
          if (item.onClick) {
            item.onClick(...args);
          }
          onItemExpandCollapse(item.id);
        }}
        renderCustomAnchorElement={item.renderCustomAnchorElement}
        showOutline
        tooltip={hasTooltip ? item.tooltip : undefined}
        {...{ hasAvatar, hasIcon, isCheckable, isDisabled, isExpanded, nestingLevel, size }}
        {...other}
      >
        {hasItems && (
          <NestedListItemIcon
            className={className ? `${className}-expandcollapse-icon` : undefined}
            data-testid={dataTestId ? `${dataTestId}-item-button-${item.id}-expandcollpase` : undefined}
            id={`expandcollapse-icon-${item.id}`}
            withExtraMargin={!isCheckable && !hasAvatar && !hasIcon}
            {...{ isDisabled, isExpanded, nestingLevel }}
          />
        )}
        {isCheckable && (
          <ListItemCheckbox
            className={className ? `${className}-checkbox` : undefined}
            data-testid={dataTestId ? `${dataTestId}-item-button-${item.id}-checkbox` : undefined}
            id={`checkbox-${item.id}`}
            isDisabled={isDisabled}
            checked={isChecked}
            onClick={() => onItemCheck(item.id)}
            size={isMobileScreen(windowSize.width) ? CHECKBOX_SIZES.LARGE : size}
          />
        )}
        {hasAvatar && (
          <ListItemGraphic
            className={className ? `${className}-avatar` : undefined}
            data-testid={dataTestId ? `${dataTestId}-item-button-${item.id}-avatar` : undefined}
            as={customGraphicTag}
            id={`avatar-${item.id}`}
            isDisabled={isDisabled}
          >
            {item.avatar}
          </ListItemGraphic>
        )}
        {hasIcon && (
          <ListItemGraphic
            className={className ? `${className}-icon` : undefined}
            data-testid={dataTestId ? `${dataTestId}-item-button-${item.id}-icon` : undefined}
            as={customGraphicTag}
            id={`icon-${item.id}`}
            isDisabled={isDisabled}
          >
            {item.icon}
          </ListItemGraphic>
        )}
        <ListItemText>
          <ListItemPrimaryText
            className={className ? `${className}-primary-text` : undefined}
            data-testid={dataTestId ? `${dataTestId}-item-button-${item.id}-primary-text` : undefined}
            as={customPrimaryTextTag}
            id={`primary-text-${item.id}`}
            {...{ isDisabled, size }}
          >
            {item.text}
          </ListItemPrimaryText>
          {!!item.subtext && (
            <ListItemSubtext
              className={className ? `${className}-sub-text` : undefined}
              data-testid={dataTestId ? `${dataTestId}-item-button-${item.id}-sub-text` : undefined}
              id={`sub-text-${item.id}`}
            >
              {item.subtext}
            </ListItemSubtext>
          )}
        </ListItemText>
        {!!item.metadata && (
          <ListItemMetadata
            className={className ? `${className}-metadata` : undefined}
            data-testid={dataTestId ? `${dataTestId}-item-button-${item.id}-metadata` : undefined}
            id={`metadata-${item.id}`}
          >
            {item.metadata}
          </ListItemMetadata>
        )}
      </NestedListItemContent>
      {children}
    </StyledNestedListItem>
  );
};

NestedListItem.propTypes = {
  /** Content that is rendered inside list item */
  children: PropTypes.node,
  /** Adds additional class for styling */
  className: PropTypes.string,
  /** Ability to supply a different element instead of the default one for NestedListItemContent element */
  customContentTag: PropTypes.elementType,
  /** Ability to supply a different element instead of the default one for NestedListItemGraphics element */
  customGraphicTag: PropTypes.elementType,
  /** Ability to supply a different element instead of the default one for NestedListItemPrimaryText element */
  customPrimaryTextTag: PropTypes.elementType,
  /** Id value used for testing */
  dataTestId: PropTypes.string,
  /** Adds id to element */
  id: PropTypes.string,
  /** If true, item can be checked with a checkbox */
  isCheckable: PropTypes.bool,
  /** If true, item is checked */
  isChecked: PropTypes.bool,
  /** If true, children items are shown */
  isExpanded: PropTypes.bool,
  /** Item for which list item is displayed */
  item: PropTypes.shape(nestedListItemPropTypes).isRequired,
  /** Specifies the nesting level in the list which ranges from 0 to 2 */
  nestingLevel: PropTypes.number.isRequired,
  /** Callback called when item is being checked */
  onItemCheck: PropTypes.func,
  /** Callback called when item is being collapsed or expanded */
  onItemExpandCollapse: PropTypes.func.isRequired,
  /** Changes list item height */
  size: PropTypes.oneOf(Object.values(LIST_SIZES)),
};

export { NestedListItem };
