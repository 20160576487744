import React, { useState } from 'react';

import { SecondaryButton } from 'lib/button';
import { ContextMenu, MENU_HORIZONTAL_POSITIONS, MENU_VERTICAL_POSITIONS } from 'lib/context-menu';
import { IconMoreHoriz } from 'lib/icons';
import PropTypes from 'prop-types';
import styled, { css } from 'styled-components';

import { getRem } from '../../core';
import { SCREEN_SIZES } from '../constants';

const StyledContextMenu = styled(ContextMenu)`
  max-width: ${getRem('164px')};
  z-index: ${({ theme }) => theme.zIndex.pageHeaderContextMenu.value};
`;

const StyledWrapper = styled.span`
  margin-bottom: ${({ theme }) => theme.size.spacing.medium.value};
  ${({ screenSize }) =>
    screenSize === SCREEN_SIZES.SMALL &&
    css`
      margin-left: auto;
    `}
`;

const PageHeaderButtonOverflowMenu = ({
  closeMenuText = 'Close Menu',
  dataTestId = '',
  defaultOptions = undefined,
  options,
  screenSize = SCREEN_SIZES.LARGE,
  showMenuText = 'Show Menu',
  ...other
}) => {
  if (process.env.NODE_ENV === 'development') {
    console.warn(
      'Radial has deprecated this Page Header Overflow Menu Block, and it will be removed in v7. Developers will need to utilize the PageHeaderWrapper and place controls as needed.  Current Page Header components will have limited support through v6. Please visit https://svc-radial-storybook.ext.nebula.connectcdk.com/?path=/docs/components-page-header-deprecated-page-header-deprecation-info--page for more details.'
    );
  }
  const [isOpen, setIsOpen] = useState(false);

  const handleClose = () => setIsOpen(false);
  const handleOpen = () => setIsOpen(true);
  const handleSelect = () => {
    setIsOpen(false);
  };

  const hasDefaultOptions = !!defaultOptions && defaultOptions.length > 0;
  const hasDynamicOptions = !!options && options.length > 0;
  const hasOptions = hasDefaultOptions || hasDynamicOptions;

  const getOptions = () => {
    const dynamicOptions = options.map((option) => ({ id: option.id, label: option.text, onClick: option.onClick }));

    if (!hasDynamicOptions && hasDefaultOptions) {
      return defaultOptions;
    }

    return hasDefaultOptions
      ? [
          { id: 'dynamic-options', label: 'dynamic', options: dynamicOptions },
          { id: 'default-options', label: 'default', options: defaultOptions },
        ]
      : dynamicOptions;
  };

  const overflowMenuButtonContent = (
    <SecondaryButton
      data-testid={dataTestId ? `${dataTestId}-button` : undefined}
      hideText
      icon={<IconMoreHoriz />}
      onClick={isOpen ? handleClose : handleOpen}
      text={isOpen ? closeMenuText : showMenuText}
    />
  );

  return (
    <StyledWrapper screenSize={screenSize}>
      {hasOptions && (
        <StyledContextMenu
          data-testid={dataTestId}
          hideGroupLabel
          isOpen={isOpen}
          onClose={handleClose}
          onOpen={handleOpen}
          onSelect={handleSelect}
          options={getOptions()}
          positionHorizontal={
            screenSize === SCREEN_SIZES.SMALL ? MENU_HORIZONTAL_POSITIONS.LEFT : MENU_HORIZONTAL_POSITIONS.RIGHT
          }
          positionVertical={MENU_VERTICAL_POSITIONS.TOP}
          wrappedComponentContent={overflowMenuButtonContent}
          {...other}
        />
      )}
    </StyledWrapper>
  );
};

PageHeaderButtonOverflowMenu.propTypes = {
  /** Text for screen reader when context menu is being closed  */
  closeMenuText: PropTypes.node,
  /** Id value used for testing */
  dataTestId: PropTypes.string,
  /** List options provided for context menu */
  defaultOptions: PropTypes.arrayOf(
    PropTypes.shape({
      href: PropTypes.string,
      icon: PropTypes.node,
      id: PropTypes.string,
      isDisabled: PropTypes.bool,
      isSelected: PropTypes.bool,
      label: PropTypes.node.isRequired,
      onClick: PropTypes.func,
    })
  ),
  /** Button props */
  options: PropTypes.arrayOf(
    PropTypes.shape({
      /** Unique identifier for the button */
      id: PropTypes.string,
      /** Callback that is called on click */
      onClick: PropTypes.func,
      /** Will display text inside button */
      text: PropTypes.node.isRequired,
    })
  ).isRequired,
  /** Screen size for which the component has to render */
  screenSize: PropTypes.oneOf(Object.values(SCREEN_SIZES)),
  /** Text for screen reader when context menu is being opened  */
  showMenuText: PropTypes.node,
};

export { PageHeaderButtonOverflowMenu };
