/* eslint-disable @typescript-eslint/restrict-template-expressions */
import routeIdentifiers from 'fixtures/routeIdentifiers';
import { useHistory } from 'react-router-dom';
import useEnterpriseId from 'hooks/useEnterpriseId';
import { StyledLink } from './styled';
import { ParamsStoreType } from './types';

export const TransformStoreIdLink = ({
  data
}: ParamsStoreType): JSX.Element => {
  const history = useHistory();
  const enterpriseId = useEnterpriseId();
  const enterprisePath = `enterprise/${enterpriseId}`;
  const storeId = data.storeId || data.id;
  const target = `/${enterprisePath}/${
    routeIdentifiers.MANAGE_STORE_DETAILS.pathId.split('/:')[0]
  }/${storeId}`;
  return (
    <StyledLink
      data-testid={`enterprise-user-${storeId}`}
      id={storeId}
      text={storeId}
      onClick={() => history.push(target)}
    />
  );
};
