import { defineMessages } from 'react-intl';

export const messages = defineMessages({
  firstName: {
    id: 'GRID.CSVIMPORTSTEPTHREE.COLUMN.HEADER.FIRSTNAME',
    defaultMessage: 'First Name'
  },
  lastName: {
    id: 'GRID.CSVIMPORTSTEPTHREE.COLUMN.HEADER.LASTNAME',
    defaultMessage: 'Last Name'
  },
  dmsUserId: {
    id: 'GRID.CSVIMPORTSTEPTHREE.COLUMN.HEADER.DMSUSERID',
    defaultMessage: 'DMS User ID'
  },
  cNumber: {
    id: 'GRID.CSVIMPORTSTEPTHREE.COLUMN.HEADER.CNUMBER',
    defaultMessage: 'CNumber'
  },
  email: {
    id: 'GRID.CSVIMPORTSTEPTHREE.COLUMN.HEADER.EMAIL',
    defaultMessage: 'Email'
  },
  federationId: {
    id: 'GRID.CSVIMPORTSTEPTHREE.COLUMN.HEADER.FEDERATIONID',
    defaultMessage: 'Federation ID'
  },
  employeeId: {
    id: 'GRID.CSVIMPORTSTEPTHREE.COLUMN.HEADER.EMPLOYEEID',
    defaultMessage: 'Employee ID'
  },
  jobTitle: {
    id: 'GRID.CSVIMPORTSTEPTHREE.COLUMN.HEADER.JOBTITLE',
    defaultMessage: 'Job Title'
  },
  roles: {
    id: 'GRID.CSVIMPORTSTEPTHREE.COLUMN.HEADER.ROLES',
    defaultMessage: 'Roles'
  },
  stores: {
    id: 'GRID.CSVIMPORTSTEPTHREE.COLUMN.HEADER.STORES',
    defaultMessage: 'Stores'
  },
  simpleID: {
    id: 'GRID.CSVIMPORTSTEPTHREE.COLUMN.HEADER.SIMPLEID',
    defaultMessage: 'Simple ID'
  },
  emailId: {
    id: 'GRID.CSVIMPORTSTEPTHREE.COLUMN.HEADER.EMAILID',
    defaultMessage: 'Email ID'
  },
  altSystemId: {
    id: 'GRID.CSVIMPORTSTEPTHREE.COLUMN.HEADER.ALTSYSTEMID',
    defaultMessage: 'Alt System ID'
  },
  altUserID: {
    id: 'GRID.CSVIMPORTSTEPTHREE.COLUMN.HEADER.ALTUSERID',
    defaultMessage: 'Alt User ID'
  },
  primaryPhone: {
    id: 'GRID.CSVIMPORTSTEPTHREE.COLUMN.HEADER.PRIMARYPHONE',
    defaultMessage: 'Primary Phone'
  }
});
