import NewUserImportStepOne from '../../organisms/NewUserImport/StepOne/StepOne';
import NewUserImportStepTwo from '../../organisms/NewUserImport/StepTwo/StepTwo';
import NewUserImportStepThree from '../../organisms/NewUserImport/StepThree/StepThree';
import NewUserImportStepFour from '../../organisms/NewUserImport/StepFour/StepFour';
import routeIdentifiers from '../../../fixtures/routeIdentifiers';

const stepperRoutes = [{
  ...routeIdentifiers.NEW_USER_IMPORT_STEP_ONE,
  component: NewUserImportStepOne,
},
{
  ...routeIdentifiers.NEW_USER_IMPORT_STEP_TWO,
  component: NewUserImportStepTwo,
},
{
  ...routeIdentifiers.NEW_USER_IMPORT_STEP_THREE,
  component: NewUserImportStepThree,
},
{
  ...routeIdentifiers.NEW_USER_IMPORT_STEP_FOUR,
  component: NewUserImportStepFour,
}];

export default stepperRoutes;
