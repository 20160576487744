import { defineMessages } from 'react-intl';

export const messages = defineMessages({
  importListName: {
    id: 'CSVUSERIMPORT.STEP4.IMPORTLISTNAME',
    defaultMessage: 'Import List Name'
  },
  selectTemplate: {
    id: 'CSVUSERIMPORT.STEP1.LABEL.SELECTTEMPLATE',
    defaultMessage: 'Select Template'
  },
  description: {
    id: 'CSVUSERIMPORT.STEP1.DESCRIPTION',
    defaultMessage:
      'Configure the template to your needs, select the document type and the columns for import.'
  },
  assignUserSuccess: {
    id: 'CSVUSERIMPORT.STEP3.ASSIGNUSER.SUCCESS',
    defaultMessage:
      '{roles} are assigned to {selectedUserCount} selected users.'
  },
  assignUserWarning: {
    id: 'CSVUSERIMPORT.STEP3.ASSIGNUSER.WARNING',
    defaultMessage: 'Please select the users before Assignment'
  },
  reviewImportList: {
    id: 'CSVUSERIMPORT.STEP3.TABLE.HEADER.REVIEWIMPORTLIST',
    defaultMessage: 'Review Import List'
  },
  csvTemplateError: {
    id: 'CSVIMPORT.STEP2.TEMPLATE.DMSTEMPLATEERROR',
    defaultMessage:
      'Please correct the following error and re-upload the CSV file'
  },
  templateError: {
    id: 'CSVIMPORT.STEP2.TEMPLATE.DMSTEMPLATE.CSVERROR',
    defaultMessage: 'Please select a valid template'
  },
  uploadTemplate: {
    id: 'CSVIMPORT.STEP2.HEADING.UPLOADTEMPLATE',
    defaultMessage: 'Upload template'
  },
  uploadDescription: {
    id: 'CSVIMPORT.STEP2.DESCRIPTION.UPLOADTEMPLATE',
    defaultMessage:
      'Upload the filled template that was selected in the previous step.'
  },
  validationContinue: {
    id: 'CSVIMPORT.STEP2.VALIDATION.CONTINUE',
    defaultMessage:
      'CSV File data validated successfully. Continue to the next step'
  }
});
