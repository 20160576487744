/* eslint-disable react/jsx-props-no-spreading */
import StyledTableHeader from './styled';

type TableHeaderProps = {
  className?: string;
  dataTestId?: string;
  disableDownloadButton?: boolean;
  disablePrintButton?: boolean;
  downloadButtonText?: string;
  headerTitle?: JSX.Element | string;
  hideBorder?: boolean;
  hideTableTitle?: boolean;
  id?: string;
  isAlwaysExpanded?: boolean;
  isDownloadable?: boolean;
  isPrintable?: boolean;
  isSearchable?: boolean;
  onDownloadClick?: () => void;
  onPrintClick?: () => void;
  overflowButtonProps?: Record<string, string>;
  printButtonText?: string;
  searchInputName?: string;
};

const defaultHeaderProps = {
  className: '',
  dataTestId: 'table-header',
  disableDownloadButton: true,
  disablePrintButton: true,
  downloadButtonText: 'Download button',
  headerTitle: '',
  hideBorder: true,
  hideTableTitle: false,
  id: 'table-header',
  isAlwaysExpanded: false,
  isDownloadable: false,
  isPrintable: false,
  isSearchable: false,
  onDownloadClick: () => undefined,
  onPrintClick: () => undefined,
  overflowButtonProps: undefined,
  printButtonText: 'Print button',
  searchInputName: 'table-header-search-input'
};

const TableHeader = (headerProps: TableHeaderProps): JSX.Element => {
  const mergedProps = { ...defaultHeaderProps, ...headerProps };
  return <StyledTableHeader {...mergedProps} />;
};

export default TableHeader;
