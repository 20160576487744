/* eslint-disable @typescript-eslint/no-unsafe-call */
import { CELL_RENDERERS, CELL_CLASSES } from 'cdk-radial';
import { ParamsStoreType } from 'components/organisms/userMangement/UserDetails/Stores/types';
import { TransformStatus } from 'components/common/StoresTransform';
import { useIntl } from 'react-intl';
import ExpandedCellText from '../../../../components/molecules/common/ExpandableChips/ExpandedCellText';
import { messages } from './messages';

type CustomAddressType = {
  addressType: string;
  city: string;
  country: string;
  countryCode: string;
  county: number;
  postalCode: string;
  state: string;
  stateCode: string;
  streetAddress: string;
};

type CustomDMSAccountsProps = {
  data: {
    departments: {
      name: string;
    }[];
  };
  colDef: {
    field: string;
  };
  rowIndex: number;
  context: {
    setRowHeight: (rowIndex: number, divHeight: number, field: string) => void;
  };
};

type FilterCustomDMSAccountsProps = {
  data: {
    departments: {
      name: string;
    }[];
  };
};
type ParamsType = {
  data: {
    id: string;
    name: string;
    externalSystemIdentifiers: {
      cmfNumber: any;
    };
    departments: { name: string }[];
    addresses: CustomAddressType[];
    dmsInformations: Array<string>;
  };
};

type CustomCMFRenderProps = {
  data: {
    externalSystemIdentifiers: {
      cmfNumber: {
        externalSystemId: string;
        status: string;
      }[];
    };
  };
  colDef: {
    field: string;
  };
  rowIndex: number;
  context: {
    setRowHeight: (rowIndex: number, divHeight: number, field: string) => void;
  };
};

type FilterCustomCMFRenderProps = {
  data: {
    externalSystemIdentifiers: {
      cmfNumber: {
        externalSystemId: string;
        status: string;
      }[];
    };
  };
};

const CustomAddressRenderer = ({ data: { addresses } }: ParamsType): string => {
  const address = addresses.find(
    ({ addressType }) => addressType === 'Physical'
  );
  let addressString = '';
  if (
    address &&
    (address.streetAddress ||
      address.city ||
      address.stateCode ||
      address.postalCode)
  ) {
    addressString = `${address.streetAddress || ''}, ${address.city || ''}, ${
      address.stateCode || ''
    } ${address.postalCode || ''}`;
  }
  return addressString;
};

export const CustomCMFNumbersRenderer = ({
  data: { externalSystemIdentifiers },
  colDef: { field },
  rowIndex,
  context: { setRowHeight }
}: CustomCMFRenderProps): JSX.Element => {
  const cmsFnumbers: string[] = externalSystemIdentifiers?.cmfNumber?.map(
    ({ externalSystemId, status }) =>
      status === 'ACTIVE' ? externalSystemId : ''
  );
  const uniqueValues =
    cmsFnumbers &&
    cmsFnumbers?.filter((item, index) => cmsFnumbers.indexOf(item) === index);
  return (
    <ExpandedCellText
      data={uniqueValues || []}
      field={field}
      rowIndex={rowIndex}
      setRowHeight={setRowHeight}
    />
  );
};

const filterCustomCMFRenderer = ({
  data: { externalSystemIdentifiers }
}: FilterCustomCMFRenderProps): string => {
  const cmsFnumbers = externalSystemIdentifiers?.cmfNumber?.map(
    ({ externalSystemId, status }) =>
      status === 'ACTIVE' ? externalSystemId : ''
  );
  const uniqueValues =
    cmsFnumbers &&
    cmsFnumbers?.filter((item, index) => cmsFnumbers.indexOf(item) === index);
  return uniqueValues?.filter(n => n)?.join(', ') || '';
};

export const CustomDMSAccountsRenderer = ({
  data: { departments },
  colDef: { field },
  rowIndex,
  context: { setRowHeight }
}: CustomDMSAccountsProps): JSX.Element => {
  const departmentarr: string[] = departments?.map(
    department => department.name
  );

  const uniqueValues =
    departmentarr &&
    departmentarr?.filter(
      (item, index) => departmentarr.indexOf(item) === index
    );

  return (
    <ExpandedCellText
      data={uniqueValues || []}
      field={field}
      rowIndex={rowIndex}
      setRowHeight={setRowHeight}
    />
  );
};

const filterCustomDMSAccountsRenderer = ({
  data: { departments }
}: FilterCustomDMSAccountsProps): string => {
  const departmentarr: string[] = departments?.map(
    department => department.name
  );

  const uniqueValues =
    departmentarr &&
    departmentarr?.filter(
      (item, index) => departmentarr.indexOf(item) === index
    );

  return uniqueValues?.join(', ') || '';
};

const TransformStoreStatus = ({
  data: { status }
}: ParamsStoreType): JSX.Element => <TransformStatus status={status} />;

export const CustomStoreVisibiltyRenderer = ({
  data: { isVisible },
  intl
}: ParamsStoreType): string =>
  isVisible
    ? intl?.formatMessage(messages.yesVisibilityStatus)
    : intl?.formatMessage(messages.noVisibilityStatus);

const useColumns = (): Array<any> => {
  const intl = useIntl();
  const columns = [
    {
      cellClass: CELL_CLASSES.CHECKBOX,
      checkboxSelection: true,
      field: 'check',
      filter: false,
      headerCheckboxSelection: true,
      headerCheckboxSelectionFilteredOnly: true,
      headerClass: [CELL_CLASSES.CHECKBOX, CELL_CLASSES.SELECT_ALL],
      headerName: '',
      maxWidth: 55,
      minWidth: 55,
      resizable: false
    },
    {
      cellRenderer: CELL_RENDERERS.TEXT,
      field: 'id',
      headerName: intl.formatMessage(messages.storeId),
      minWidth: 100,
      sortable: true
    },
    {
      cellRenderer: CELL_RENDERERS.TEXT,
      field: 'name',
      headerName: intl.formatMessage(messages.storeName),
      cellStyle: { 'line-height': '25px' },
      minWidth: 170,
      wrapText: true,
      sortable: true
    },
    {
      cellRenderer: CustomAddressRenderer,
      cellRendererParams: (params: ParamsType): CustomAddressType[] =>
        params.data.addresses,
      valueGetter: (params: ParamsType): string | string[] =>
        CustomAddressRenderer(params),
      field: 'storeLocation',
      headerName: intl.formatMessage(messages.physicalAddress),
      cellStyle: { 'white-space': 'normal', 'line-height': '25px' },
      minWidth: 250,
      autoHeight: true,
      sortable: true
    },
    {
      cellClass: [CELL_CLASSES.NO_WRAP],
      cellRenderer: CELL_RENDERERS.TEXT,

      cellRendererParams: (params: ParamsType): any => params.data,
      valueGetter: (params: ParamsType): string | string[] =>
        filterCustomCMFRenderer(params),
      field: 'cmfNumber',
      headerName: intl.formatMessage(messages.cmfNumber),
      cellStyle: { 'white-space': 'normal', 'line-height': '25px' },
      minWidth: 450,
      maxWidth: 500,
      sortable: true,
      wrapText: true,
      autoHeight: true
    },
    {
      cellRenderer: CELL_RENDERERS.TEXT,

      cellRendererParams: (params: ParamsType): any => params.data,
      valueGetter: (params: ParamsType): string | string[] =>
        filterCustomDMSAccountsRenderer(params),
      field: 'dmsAccount',
      headerName: intl.formatMessage(messages.dmsAccounts),
      cellStyle: { 'white-space': 'normal', 'line-height': '25px' },
      sortable: true,
      wrapText: true,
      minWidth: 350,
      maxWidth: 400,
      autoHeight: true
    },
    {
      cellRenderer: CELL_RENDERERS.TEXT,
      field: 'type',
      headerName: intl.formatMessage(messages.storeType),
      cellStyle: { 'line-height': '25px' },
      minWidth: 170,
      wrapText: true,
      sortable: true
    },
    {
      cellRendererFramework: TransformStoreStatus,
      cellRendererParams: (params: ParamsStoreType): string =>
        params.data.status,
      valueGetter: (params: ParamsStoreType): string =>
        params.data.status || '',
      field: 'status',
      headerName: intl.formatMessage(messages.storeStatus),
      cellStyle: { 'line-height': '25px' },
      minWidth: 170,
      wrapText: true,
      sortable: true
    },
    {
      cellClass: [CELL_CLASSES.NO_WRAP],
      field: 'isVisible',
      cellRenderer: CustomStoreVisibiltyRenderer,
      cellRendererParams: { intl },
      valueGetter: (params: ParamsStoreType): string | string[] =>
        CustomStoreVisibiltyRenderer(params),
      headerName: intl.formatMessage(messages.storeVisibility),
      cellStyle: { 'line-height': '25px' },
      minWidth: 170,
      wrapText: true,
      sortable: true
    }
  ];
  return [columns];
};

export default useColumns;
