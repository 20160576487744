import { usePortal } from 'lib/utilities';
import PropTypes from 'prop-types';
import { createPortal } from 'react-dom';

const Portal = ({ children, id }) => {
  const target = usePortal(id);

  return createPortal(children, target);
};

Portal.propTypes = {
  /** Any content placed between component tags */
  children: PropTypes.node.isRequired,
  /** Id of element, to which content should be appended to */
  id: PropTypes.string.isRequired,
};

export { Portal };
