import TableFilterType, { OptionType } from './types/tableFilterType';

const getLabel = (selectedOptions: OptionType[], prefix: string): string => {
  let label = selectedOptions.length ? `${prefix}: ` : prefix;
  if (selectedOptions.length > 0) {
    if (selectedOptions[selectedOptions.length - 1].selectionLabel) {
      label += selectedOptions[selectedOptions.length - 1].selectionLabel;
    } else {
      label += selectedOptions[selectedOptions.length - 1].label;
    }
  }

  if (selectedOptions.length > 1) {
    label += `+${selectedOptions.length - 1}`;
  }
  return label;
};

export const filterRows = (
  node: Record<string, any>,
  selectedFilters: Record<string, OptionType[]>,
  filterSchema: TableFilterType[]
): boolean => {
  const selectedFilterKeys: Array<string> = Object.keys(selectedFilters);
  const flagArray: Array<boolean> = [];
  for (let i = 0; i < selectedFilterKeys.length; i += 1) {
    const filterKey = selectedFilterKeys[i];
    const filterDetail = filterSchema.find(
      filterObject => filterObject.uid === filterKey
    );
    if (filterDetail) {
      const {
        type: selectedFilterType,
        typecastTo,
        typecastGridDataTo
      } = filterDetail;

      const selectedFilterOptions = selectedFilterType
        ? selectedFilters[filterKey]
        : [];
      if (selectedFilterType === 'equality' && selectedFilterOptions.length) {
        let equalityFlag = false;
        for (let k = 0; k < selectedFilterOptions.length; k += 1) {
          const option = typecastTo
            ? typecastTo(selectedFilterOptions[k].value)
            : selectedFilterOptions[k].value;
          const nodeValue = typecastTo
            ? typecastTo(node.data[filterKey])
            : node.data[filterKey];
          if (option === nodeValue) {
            equalityFlag = true;
            break;
          }
        }
        flagArray.push(equalityFlag);
      }
      if (selectedFilterType === 'includes' && selectedFilterOptions.length) {
        let equalityFlag = false;
        for (let k = 0; k < selectedFilterOptions.length; k += 1) {
          const option = typecastTo
            ? typecastTo(selectedFilterOptions[k].value)
            : selectedFilterOptions[k].value;
          const nodeValue: string[] = typecastGridDataTo
            ? // eslint-disable-next-line @typescript-eslint/no-unsafe-call
              typecastGridDataTo(node.data[filterKey])
            : node.data[filterKey];
          if (nodeValue.includes(option)) {
            equalityFlag = true;
            break;
          }
        }
        flagArray.push(equalityFlag);
      }

      if (selectedFilterType === 'range' && selectedFilterOptions.length) {
        let rangeFlag = false;
        for (let j = 0; j < selectedFilterOptions.length; j += 1) {
          const { min, max } = selectedFilterOptions[j].value;
          const cellValue = typecastTo
            ? typecastTo(node.data[filterKey])
            : node.data[filterKey];
          if (cellValue >= min && cellValue <= max) {
            rangeFlag = true;
            break;
          }
        }
        flagArray.push(rangeFlag);
      }
    }
  }
  const result = flagArray.reduce((acc, curr) => acc && curr, true);
  return result;
};

export default getLabel;
