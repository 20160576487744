import styled, { css } from 'styled-components';

import { getRem, transitionAnimation } from '../../core';
import { InputIcon } from '../../input';
import { EXPAND_BEHAVIORS } from '../constants';

const SearchInputIcon = styled(InputIcon).withConfig({
  shouldForwardProp: (prop) => !['expandBehavior', 'isAlwaysExpanded', 'isFilled'].includes(prop),
})`
  ${transitionAnimation('fill, left')};
  fill: ${({ theme }) => theme.color.text.light.secondary.value};
  pointer-events: none;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  ${({ expandBehavior, theme }) =>
    expandBehavior === EXPAND_BEHAVIORS.SLIDE_LEFT
      ? css`
          left: calc(100% - ${getRem('30px')});
          right: ${theme.size.spacing.medium.value};
        `
      : css`
          left: 6px;
        `}

  ${({ isAlwaysExpanded, isFilled, theme }) =>
    (isAlwaysExpanded || isFilled) &&
    css`
      fill: ${theme.color.text.light.secondary.value};
      left: 6px;
    `}
`;

export { SearchInputIcon };
