import { cloneElement } from 'react';
import styled from 'styled-components';

import { getRem } from '../../core';

const STATUS_ICON_SIZE = getRem('16px');

const StatusIcon = styled(({ children, ...props }) => cloneElement(children, props))`
  fill: ${({ theme }) => theme.color.text.light.secondary.value};
  flex-shrink: 0;
  height: ${STATUS_ICON_SIZE};
  margin-right: ${getRem(-2)};
  width: ${STATUS_ICON_SIZE};
`;

export { StatusIcon };
