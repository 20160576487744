import React from 'react';
import PropTypes from 'prop-types';
import styled, { css } from 'styled-components';
import { transitionAnimation } from './../../core';

const StyledFlexFooterContainer = styled.div`
  ${transitionAnimation('flex-basis, max-height, min-height')};
  box-sizing: border-box;
  color: ${({ theme }) => theme.color.additional.dark.value};
  display: flex;
  flex-direction: row;
  margin-top: ${({ marginAutoOff }) => (marginAutoOff ? '0px' : 'auto')};
  padding: ${({ theme }) => theme.size.spacing.medium.value};
  position: relative;
  z-index: ${({ theme }) => theme.zIndex.globalNavigationFooter};
  ${({ isCollapsed }) =>
    isCollapsed &&
    css`
      div:nth-last-child(2) {
        padding-bottom: 8px;
      }
      align-items: center;
      flex-direction: column;
      justify-content: center;
    `}
  ${({ hasDivider }) =>
    hasDivider &&
    css`
      border-top: 1px solid ${({ theme }) => theme.color.gray[200].value};
    `}
`;

const FlexFooterContainer = ({
  children = null,
  className = '',
  dataTestId = undefined,
  hasDivider,
  isCollapsed = false,
  marginAutoOff = false,
  ...other
}) => {
  const childrenWithProps = React.Children.map(children, (child) => {
    if (React.isValidElement(child)) {
      return React.cloneElement(child, { isCollapsed: isCollapsed });
    }
    return child;
  });
  return (
    <StyledFlexFooterContainer
      data-testid={dataTestId ? `${dataTestId}-flex-footer-container` : undefined}
      {...{ className, isCollapsed, hasDivider, marginAutoOff }}
      {...other}
    >
      {childrenWithProps}
    </StyledFlexFooterContainer>
  );
};

FlexFooterContainer.propTypes = {
  /** Adds additional className to container */
  className: PropTypes.string,
  /** Displays any kind of content included between opening and closing tags footer content */
  children: PropTypes.node,
  /** Adds a top border to container to provide separation */
  hasDivider: PropTypes.bool,
  /** Id value used for testing */
  dataTestId: PropTypes.string,
  /** If true, navigation is collapsed */
  isCollapsed: PropTypes.bool,
  /** Turns off margin: auto when stacking flex foot containers */
  marginAutoOff: PropTypes.bool,
};

export { FlexFooterContainer };
