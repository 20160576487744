import React from 'react';
import PropTypes from 'prop-types';

import { AVATAR_COLORS, AVATAR_SIZES, AVATAR_VARIANTS } from '../constants';
import { AvatarIcon, AvatarImage, AvatarText, AvatarWrapper } from '../elements';

const Avatar = ({
  alt = '',
  color = null,
  icon = null,
  label,
  size = AVATAR_SIZES.STANDARD,
  src = undefined,
  srcset = '',
  variant = AVATAR_VARIANTS.TEXT,
  ...other
}) => {
  const renderAvatarContent = () => {
    switch (variant) {
      case AVATAR_VARIANTS.IMAGE:
        return <AvatarImage {...{ alt, src, srcset }} />;
      case AVATAR_VARIANTS.ICON:
        return <AvatarIcon size={size}>{icon}</AvatarIcon>;
      case AVATAR_VARIANTS.TEXT:
        return <AvatarText {...{ label, size }} />;
      default:
        return null;
    }
  };

  const avatarProps = {
    'aria-label': label,
    color,
    size,
    ...other,
  };

  return <AvatarWrapper {...avatarProps}>{renderAvatarContent()}</AvatarWrapper>;
};

Avatar.propTypes = {
  /** Accessibility measurement for verbal image description */
  alt: PropTypes.node,
  /** @ignore */
  color: PropTypes.oneOf(Object.values(AVATAR_COLORS)),
  /** Outputs icon inside the avatar. Use icon component from the library */
  icon: PropTypes.node,
  /** Username, that this avatar depicts. */
  label: PropTypes.node.isRequired,
  /** Avatar wrapper size. Icon size changes depending on wrapper size */
  size: PropTypes.oneOf(Object.values(AVATAR_SIZES)),
  /** Path to image file */
  src: PropTypes.string,
  /** Defines multiple sizes of the same image, allowing the browser to select the appropriate image source */
  srcset: PropTypes.string,
  /** Avatar variant. Values: [EMPTY, TEXT, ICON, IMAGE]. Default TEXT */
  variant: PropTypes.oneOf(Object.values(AVATAR_VARIANTS)),
};

export { Avatar };
