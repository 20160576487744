import styled from 'styled-components';

const StyledStepOne = styled.div`
    width:100%;
    .main-heading{
        margin: 0;
    }
    .description{
        margin-top: 0.6rem;
        margin-bottom:1.5rem;
    }
`;

export default StyledStepOne;
