import { defineMessages } from 'react-intl';

export const messages = defineMessages({
  dealerAdminInfo: {
    id: 'SIDENAV.DEALERADMININFO.BUTTON.LABEL',
    defaultMessage: 'Dealer Admin Information'
  },
  dealerAdminInfoDialogHeader: {
    id: 'DEALERADMININFO.DIALOG.HEADER.LABEL',
    defaultMessage: 'Dealer Admin Information'
  },
  dealerAdminInfoDialogCloseButton: {
    id: 'DEALERADMININFO.DIALOG.CLOSE.BUTTON.LABEL',
    defaultMessage: 'Close'
  },
  emptyResult: {
    id: 'ENTERPRISE.ADMINS.EMPTY.RESULT',
    defaultMessage: 'No Admins Found'
  }
});
