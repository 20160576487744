import {
  CELL_RENDERERS,
  CELL_CLASSES,
  Input,
  INPUT_SIZES,
  EmailInput
} from 'cdk-radial';
import { useState } from 'react';
import { useSelector } from 'react-redux';
import { useIntl } from 'react-intl';
import { RootState } from 'redux/store';
import ExpandableChips from '../../../components/molecules/common/ExpandableChips/ExpandableChips';
import ExpandedCellText from '../../../components/molecules/common/ExpandableChips/ExpandedCellText';
import {
  StoresType,
  RolesType,
  CellValue,
  CustomDMSAccountTypeProps,
  CustomDMSProfileTypeProps,
  ParamsType
} from './types';
import { messages } from './messages';

const CustomInputRenderer = ({
  value,
  colDef: { field },
  rowIndex,
  context: { handleFieldChange }
}: CellValue): JSX.Element => {
  const [newValue, setNewalue] = useState(value);
  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setNewalue(event.target.value);
  };
  const {
    newUserValidations: {
      step_three: { errorRows: stepThreeValidations }
    }
  } = useSelector((state: RootState) => state.slices.userImportState);

  const { hasError = false, errorMessage = '' } =
    stepThreeValidations[rowIndex] && stepThreeValidations[rowIndex][field]
      ? stepThreeValidations[rowIndex][field]
      : { hasError: false, errorMessage: '' };

  return (
    <Input
      dataTestId="input"
      id="first-name-input"
      inputDivider="left"
      label=""
      name={field}
      size={INPUT_SIZES.SMALL}
      ref={(ref: any) => {
        if (!ref) return;
        ref.onclick = (e: React.MouseEvent<HTMLInputElement>) => {
          e.stopPropagation();
          e.preventDefault();
        };

        ref.onkeyup = (e: React.KeyboardEvent<HTMLInputElement>) => {
          e.stopPropagation();
        };

        ref.onkeydown = (e: React.KeyboardEvent<HTMLInputElement>) => {
          e.stopPropagation();
        };
      }}
      onChange={handleChange}
      enableCustomValidation
      hasError={hasError}
      isRequired={field !== 'federationId'}
      errorMessage={errorMessage}
      onBlur={(event: React.ChangeEvent<HTMLInputElement>) =>
        handleFieldChange(rowIndex, field, event.target.value)
      }
      placeholder=""
      value={newValue}
    />
  );
};

const CustomEmailInputRenderer = ({
  value,
  colDef: { field },
  rowIndex,
  context: { handleFieldChange }
}: CellValue): JSX.Element => {
  const [newValue, setNewalue] = useState(value);
  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setNewalue(event.target.value);
  };

  const {
    newUserValidations: {
      step_three: { errorRows: stepThreeValidations }
    }
  } = useSelector((state: RootState) => state.slices.userImportState);

  const { hasError = false, errorMessage = '' } =
    stepThreeValidations[rowIndex] && stepThreeValidations[rowIndex][field]
      ? stepThreeValidations[rowIndex][field]
      : { hasError: false, errorMessage: '' };

  return (
    <EmailInput
      dataTestId="input"
      id="first-name-input"
      inputDivider="left"
      label=""
      name={field}
      size={INPUT_SIZES.SMALL}
      ref={(ref: any) => {
        if (!ref) return;
        ref.onclick = (e: React.MouseEvent<HTMLInputElement>) => {
          e.stopPropagation();
          e.preventDefault();
        };

        ref.onkeyup = (e: React.KeyboardEvent<HTMLInputElement>) => {
          e.stopPropagation();
        };

        ref.onkeydown = (e: React.KeyboardEvent<HTMLInputElement>) => {
          e.stopPropagation();
        };
      }}
      onChange={handleChange}
      enableCustomValidation
      hasError={hasError}
      isRequired
      errorMessage={errorMessage}
      onBlur={(event: React.ChangeEvent<HTMLInputElement>) =>
        handleFieldChange(rowIndex, field, event.target.value)
      }
      placeholder=""
      value={newValue}
    />
  );
};

export const CustomStoresRenderer = ({
  data: { stores, stores_expansion: isExpanded },
  colDef: { field },
  rowIndex,
  context: {
    setRowFieldExpansion,
    setRowHeight,
    handleFieldRemoval,
    handleRemoveAllData
  }
}: StoresType): JSX.Element => (
  <ExpandableChips
    data={stores}
    keysArray={['id']}
    isExpanded={isExpanded}
    field={field}
    rowIndex={rowIndex}
    setRowFieldExpansion={setRowFieldExpansion}
    setRowHeight={setRowHeight}
    handleFieldRemoval={handleFieldRemoval}
    handleRemoveAllData={handleRemoveAllData}
  />
);

export const CustomRolesRenderer = ({
  data: { roles, roles_expansion: isExpanded },
  colDef: { field },
  rowIndex,
  context: {
    setRowFieldExpansion,
    setRowHeight,
    handleFieldRemoval,
    handleRemoveAllData
  }
}: RolesType): JSX.Element => (
  <ExpandableChips
    data={roles}
    keysArray={['code']}
    isExpanded={isExpanded}
    field={field}
    rowIndex={rowIndex}
    setRowFieldExpansion={setRowFieldExpansion}
    setRowHeight={setRowHeight}
    handleFieldRemoval={handleFieldRemoval}
    handleRemoveAllData={handleRemoveAllData}
  />
);

const CustomDMSAccountTypeRenderer = ({
  data: { profiles },
  colDef: { field },
  rowIndex,
  context: { setRowHeight }
}: CustomDMSAccountTypeProps): JSX.Element => {
  const profileData =
    profiles && Array.isArray(profiles)
      ? Array.from(
          new Set(
            profiles.reduce(
              // eslint-disable-next-line @typescript-eslint/no-unsafe-return
              (acc: string[], curr): string[] => [...acc, ...curr.accounts],
              []
            )
          )
        )
      : '';

  return (
    <ExpandedCellText
      data={profileData || []}
      field={field}
      rowIndex={rowIndex}
      setRowHeight={setRowHeight}
    />
  );
};

export const CustomDMSProfileNameRenderer = ({
  data: { jobProfiles },
  colDef: { field },
  rowIndex,
  context: { setRowHeight }
}: CustomDMSProfileTypeProps): JSX.Element => (
  <ExpandedCellText
    data={jobProfiles || []}
    field={field}
    rowIndex={rowIndex}
    setRowHeight={setRowHeight}
  />
);

const getCnumberWithAlias = (params: ParamsType): string => {
  const { cNumber, cNumberAlias } = params?.data || {};
  return cNumber ? `${cNumber} ${cNumberAlias}`.trim() : '';
};

const useColumns = (): Array<any> => {
  const intl = useIntl();
  const columns = [
    {
      cellClass: CELL_CLASSES.CHECKBOX,
      checkboxSelection: true,
      field: 'check',
      filter: false,
      headerCheckboxSelection: true,
      headerCheckboxSelectionFilteredOnly: true,
      headerClass: [CELL_CLASSES.CHECKBOX, CELL_CLASSES.SELECT_ALL],
      headerName: '',
      maxWidth: 55,
      minWidth: 55,
      resizable: false
    },
    {
      cellRendererFramework: CustomInputRenderer,
      cellRendererParams: (params: ParamsType): string => params.data.lastName,
      valueGetter: (params: ParamsType): string => params.data.lastName || '',
      field: 'lastName',
      headerName: intl.formatMessage(messages.lastName),
      minWidth: 200,
      sortable: true,
      autoHeight: true
    },
    {
      cellRendererFramework: CustomInputRenderer,
      cellRendererParams: (params: ParamsType): string => params.data.firstName,
      valueGetter: (params: ParamsType): string => params.data.firstName || '',
      field: 'firstName',
      headerName: intl.formatMessage(messages.firstName),
      minWidth: 200,
      sortable: true
    },
    {
      cellRenderer: CELL_RENDERERS.TEXT,
      valueGetter: (params: ParamsType): string => params.data.id || '',
      field: 'id',
      headerName: intl.formatMessage(messages.dmsUserId),
      minWidth: 150,
      sortable: true
    },
    {
      cellRenderer: CELL_RENDERERS.TEXT,
      valueGetter: getCnumberWithAlias,
      field: 'cNumber',
      headerName: intl.formatMessage(messages.cNumber),
      minWidth: 150,
      cellStyle: { 'white-space': 'normal', 'line-height': '18px' },
      sortable: true
    },
    {
      cellRendererFramework: CustomEmailInputRenderer,
      cellRendererParams: (params: ParamsType): string => params.data.email,
      valueGetter: (params: ParamsType): string => params.data.email || '',
      field: 'email',
      headerName: intl.formatMessage(messages.primaryEmail),
      minWidth: 200
    },
    {
      cellRendererFramework: CustomInputRenderer,
      cellRendererParams: (params: ParamsType): string =>
        params.data.federationId,
      valueGetter: (params: ParamsType): string =>
        params.data.federationId || '',
      field: 'federationId',
      headerName: intl.formatMessage(messages.federationId),
      minWidth: 150
    },
    {
      cellRendererFramework: CustomEmailInputRenderer,
      cellRendererParams: (params: ParamsType): string => params.data.loginId,
      valueGetter: (params: ParamsType): string =>
        params.data.loginId === null
          ? params.data.email
          : params.data.loginId || '',
      field: 'loginId',
      headerName: intl.formatMessage(messages.cdkSimpleId),
      minWidth: 200
    },
    {
      cellRenderer: CELL_RENDERERS.TEXT,
      valueGetter: (params: ParamsType): string => params.data.employeeID || '',
      field: 'employeeID',
      headerName: intl.formatMessage(messages.dmsEmployeeId),
      minWidth: 150,
      sortable: true
    },
    {
      cellRendererFramework: CustomDMSProfileNameRenderer,
      cellRendererParams: (params: ParamsType): any => params.data,
      valueGetter: (params: ParamsType): string =>
        params.data.jobProfiles.map(job => job).join(', ') || '',
      field: 'jobProfiles',
      headerName: intl.formatMessage(messages.dmsProfileName),
      minWidth: 300,
      sortable: true,
      autoHeight: true,
      cellStyle: { 'white-space': 'normal' }
    },
    {
      cellRendererFramework: CustomDMSAccountTypeRenderer,
      cellRendererParams: (params: ParamsType): any => params.data,
      valueGetter: (params: ParamsType): string =>
        params?.data?.profiles && Array.isArray(params.data.profiles)
          ? Array.from(
              new Set(
                params.data.profiles.reduce(
                  (acc: string[], curr): string[] => [...acc, ...curr.accounts],
                  []
                )
              )
            ).join(', ')
          : '',
      field: 'profiles',
      headerName: intl.formatMessage(messages.dmsAccount),
      minWidth: 300,
      sortable: true,
      autoHeight: true,
      cellStyle: { 'white-space': 'normal' }
    },
    {
      cellRendererFramework: CustomRolesRenderer,
      cellRendererParams: (params: ParamsType): any => params.data,
      valueGetter: (params: ParamsType): Array<string> =>
        params.data.roles || '',
      field: 'roles',
      headerName: intl.formatMessage(messages.roles),
      cellStyle: { 'white-space': 'normal' },
      minWidth: 350,
      autoHeight: true
    },
    {
      cellRendererFramework: CustomStoresRenderer,
      cellRendererParams: (params: ParamsType): any => params.data,
      valueGetter: (params: ParamsType): Array<string> =>
        params.data.stores || '',
      field: 'stores',
      headerName: intl.formatMessage(messages.stores),
      cellStyle: { 'white-space': 'normal' },
      minWidth: 350,
      wrapText: true,
      autoHeight: true
    }
  ];
  return [columns];
};

export default useColumns;
