import { Column } from 'cdk-radial';
import StyledDescription from './styled';

type DescriptionProps = {
  labelHeading: string, labelDescription: string
};

const Description = ({ labelHeading, labelDescription }: DescriptionProps): JSX.Element => (
  <StyledDescription className="info-container">
    <Column xs={12} className="info-label">{labelHeading}</Column>
    <Column xs={12} className="info-value">{labelDescription}</Column>
  </StyledDescription>
);

export default Description;
