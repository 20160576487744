import React from 'react';

import { ICON_SIZE } from 'lib/icons';
import PropTypes from 'prop-types';
import styled, { css } from 'styled-components';

import { getRem } from './../../../core';
import { getStateStyles } from './textButtonStateStyles';
import { BaseButton } from '../../blocks';
import { BUTTON_BACKGROUND_APPEARANCES, BUTTON_SIZES } from '../../constants';

const StyledTextButton = styled(BaseButton)`
  ${({ hideText }) =>
    !hideText &&
    css`
      padding-left: ${({ theme }) => theme.size.spacing.medium.value};
      padding-right: ${({ theme }) => theme.size.spacing.medium.value};
    `}

  ${({ hideText, icon }) =>
    icon &&
    !hideText &&
    css`
      padding-left: ${getRem(6)};
    `}
`;

const TextButtonWithTrailingIcon = styled(BaseButton)`
  display: flex;
  flex-direction: row-reverse;
  gap: ${({ theme }) => theme.size.spacing.small.value};
  ${({ hideText }) =>
    !hideText &&
    css`
      padding-left: ${({ theme }) => theme.size.spacing.medium.value};
      padding-right: ${({ theme }) => theme.size.spacing.medium.value};
    `}

  ${({ hideText, icon }) =>
    icon &&
    !hideText &&
    css`
      padding-left: ${({ theme }) => theme.size.spacing.medium.value};
      padding-right: ${getRem(2)};
    `}
`;

const defaultTextButtonProps = {
  customTagElement: '',
  dataTestId: undefined,
  hideText: false,
  href: undefined,
  icon: undefined,
  iconClassName: '',
  iconSize: ICON_SIZE.DEFAULT,
  isDisabled: false,
  isLoading: false,
  loaderText: 'Loading...',
  onClick: () => {},
  onKeyDown: () => {},
  preserveClickableArea: false,
  size: BUTTON_SIZES.STANDARD,
  type: '',
};

const TextButton = React.forwardRef(
  ({ backgroundAppearance = BUTTON_BACKGROUND_APPEARANCES.LIGHT, isIconTrailing = false, ...other }, ref) => {
    const sharedTextButtonProps = {
      ...defaultTextButtonProps,
      ...other,
      ref,
      stateStyles: getStateStyles(backgroundAppearance),
    };
    return isIconTrailing ? (
      <TextButtonWithTrailingIcon {...sharedTextButtonProps} />
    ) : (
      <StyledTextButton {...sharedTextButtonProps} />
    );
  });

TextButton.propTypes = {
  /** @ignore */
  backgroundAppearance: PropTypes.oneOf(Object.values(BUTTON_BACKGROUND_APPEARANCES)),
  /** Replaces default tag: button or anchor with new value */
  customTagElement: PropTypes.string,
  /** Id value used for testing */
  dataTestId: PropTypes.string,
  /** If true, will visually hide text */
  hideText: PropTypes.bool,
  /** When URL is provided, element changes from button to hyperlink <a> */
  href: PropTypes.string,
  /** If not loading, will render specified icon before text */
  icon: PropTypes.node,
  /** Adds new class for icon element */
  iconClassName: PropTypes.string,
  /** Will set size of the icon */
  iconSize: PropTypes.oneOf(Object.values(ICON_SIZE)),
  /** Disallows user to interact with the button and adjusts appearance */
  isDisabled: PropTypes.bool,
  /** If true, will display icon after the button label */
  isIconTrailing: PropTypes.bool,
  /** If true, will display loader and adjust width to better accommodate spinner */
  isLoading: PropTypes.bool,
  /** Loader text which will be read for screen reader users */
  loaderText: PropTypes.node,
  /** Callback that is called on click */
  onClick: PropTypes.func,
  /** Callback that is called on key down */
  onKeyDown: PropTypes.func,
  /** If true, will add vertical margins to the component */
  preserveClickableArea: PropTypes.bool,
  /** Changes button height */
  size: PropTypes.oneOf(Object.values(BUTTON_SIZES)),
  /** Will display text inside button */
  text: PropTypes.node.isRequired,
  /** Default html button 'type' attribute values when button component is used */
  type: PropTypes.string,
};

export { TextButton };
