import styled from 'styled-components';
import { Button, Dialog, CardBody } from 'cdk-radial';

export const StyledButton = styled(Button)`
  display: flex;
  justify-content: center;
  align-items: center;
  max-width: 240px;
`;

export const StyledDiv = styled.div`
  display: flex;
  height: 63px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 10px;
`;

// Dialog
export const StyledDialog = styled(Dialog)`
  display: flex;
  flex-direction: column;
  width: 875px;
  min-height: 420px;
`;

// Admin Cards

export const StyledCardsContainer = styled.div`
  display: grid;
  grid-template-columns: repeat(3, 3fr);
  grid-auto-rows: 140px;
  gap: 1em;
`;

export const StyledCardBody = styled(CardBody)`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 8px;
`;

export const StyledCardItem = styled.div`
  display: flex;
  align-items: flex-start;
  gap: 12px;
`;

export const StyledEmptyContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  flex-direction: column;
`;

export const StyledEnterpriseAdminIconContainer = styled.div`
  margin-left: 20px;
  color: gray;
`;
