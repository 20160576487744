import React, { useEffect } from 'react';
import { Input } from 'cdk-radial';
import { useIntl } from 'react-intl';
import { useDispatch, useSelector } from 'react-redux';
import moment, { Moment } from 'moment';
import { TimeType } from 'utils/utility';
import { Option } from 'components/organisms/common/AutocompleteDropdown/types';
import {
  setImportDate,
  setImportTime,
  setJobName
} from 'redux/slices/userImportSlice';
import { RootState } from 'redux/store';
import StyledScheduleImport from './styled';
import StepFourTitle from '../../../molecules/NewUserImport/StepFour/StepFourTitle/StepFourTitle';
import StepFourDateInput from '../../../molecules/NewUserImport/StepFour/StepFourDateInput/StepFourDateInput';
import StepFourTimeInput from '../../../molecules/NewUserImport/StepFour/StepFourTimeInput/StepFourTimeInput';
import { messages } from './messages';

const StepFour = (): JSX.Element => {
  const intl = useIntl();
  const dispatch = useDispatch();
  const {
    importTime,
    importDate,
    jobName,
    editJobValidations: {
      step_four: {
        jobName: { isValid: jobNameValid, error: jobNameError },
        importDate: { isValid: importDateValid, error: importDateError },
        importTime: { isValid: importTimeValid, error: importTimeError }
      }
    }
  } = useSelector((state: RootState) => state.slices.userImportState);

  useEffect(() => {
    if (
      importDate &&
      importDate?.format('MM/DD/yy') !== moment().format('MM/DD/yy') &&
      (importTime?.value === 'Now' ||
        (importTime?.value !== 'Now' &&
          moment(importTime?.value, 'hh:mm').isBefore(moment())))
    ) {
      setImportTime({ label: '', value: '' });
    }
  }, [importDate, importTime]);

  const handleInputChange = (e: React.SyntheticEvent): void => {
    const target = e.target as HTMLInputElement;
    dispatch(setJobName(target.value.toString()));
  };
  const handleDateChange = (scheduledate: Moment | null) => {
    dispatch(setImportDate(scheduledate));
    dispatch(setImportTime(undefined as unknown as TimeType));
  };

  function isTimeType(option: Option): option is TimeType {
    return typeof option.label === 'string' && typeof option.value === 'string';
  }

  const handleTimeChange = (scheduletime: Option | undefined) => {
    if (scheduletime && isTimeType(scheduletime)) {
      dispatch(setImportTime(scheduletime as unknown as TimeType));
    } else {
      dispatch(setImportTime({ label: '', value: '' }));
    }
  };

  return (
    <StyledScheduleImport>
      <div>
        <div data-testid="step-four-container">
          <StepFourTitle />
          <div className="schedule-input">
            <Input
              data-testid="step-four-input"
              helperText=""
              id="input-simple"
              label={intl.formatMessage(messages.stepFourListName)}
              name="input-simple"
              errorMessage={jobNameError}
              hasError={jobNameValid === false}
              isRequired
              onChange={handleInputChange}
              placeholder=""
              value={jobName}
            />
          </div>
          <StepFourDateInput
            data-testid="step-four-date"
            handleDateChange={handleDateChange}
            date={importDate}
            importDateError={importDateError}
            importDateValid={importDateValid}
          />
          <StepFourTimeInput
            data-testid="step-four-time"
            handleTimeChange={handleTimeChange}
            hasNoNow
            date={importDate}
            time={importTime}
            importTimeError={importTimeError}
            importTimeValid={importTimeValid}
          />
        </div>
      </div>
    </StyledScheduleImport>
  );
};

export default StepFour;
