import { DatePicker } from 'cdk-radial';
import { Moment } from 'moment';
import { useIntl } from 'react-intl';
import StyledStepFourDateInput from './styled';
import { messages } from './messages';

type StepFourDateInputProps = {
  handleDateChange: (date: Moment | null) => void;
  date: Moment | null;
  importDateError: string;
  importDateValid: boolean | null;
};

const StepFourDateInput = ({
  handleDateChange,
  date,
  importDateError,
  importDateValid
}: StepFourDateInputProps): JSX.Element => {
  const intl = useIntl();
  const handleDateClear = () => {
    handleDateChange(null);
  };

  const dateInputProps = {
    helperText: intl.formatMessage(messages.selectDate),
    hasError: importDateValid === false,
    errorMessage: importDateError,
    isRequired: true,
    onInputClear: handleDateClear,
    onError: function noRefCheck() {}
  };

  return (
    <StyledStepFourDateInput>
      <div data-testid="step-four-date" className="schedule-date">
        <DatePicker
          allowDaysInPast={false}
          date={date}
          id="date-picker-simple"
          dateInputProps={dateInputProps}
          label={intl.formatMessage(messages.stepFourScheduleDate)}
          name="date-picker-simple"
          onDateChange={handleDateChange}
        />
      </div>
    </StyledStepFourDateInput>
  );
};

export default StepFourDateInput;
