import styled from 'styled-components';

const StyledAutoMapRoles = styled.div`
.ag-cell--dropdown {
    .ag-react-container > div:first-child {
          width: 375px;
    }
  }
.footer-button-group{
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
}`;

export default StyledAutoMapRoles;
