import { IntlShape } from 'react-intl';
import { ChangePasswordPayload, PasswordState } from './type';
import { messages } from './messages';

export const getErrorMessage = (
  passwordState: PasswordState,
  intl: IntlShape
): string => {
  const GENERIC_ERROR = intl.formatMessage(messages.genericError);
  const {
    hasThreeIdenticalChar,
    hasSpaces,
    hasSquentialChar,
    hasCharacter,
    hasLowerCase,
    hasNumerals,
    hasSpecialCharacter,
    hasUppperCase
  } = passwordState;

  const isPwdRequirementMet = !(
    hasCharacter &&
    hasLowerCase &&
    hasNumerals &&
    hasSpecialCharacter &&
    hasUppperCase
  );

  if (
    hasThreeIdenticalChar ||
    hasSpaces ||
    hasSquentialChar ||
    isPwdRequirementMet
  ) {
    return GENERIC_ERROR;
  }
  return '';
};

export const getChangePasswordPayload = ({
  oldPassword,
  newPassword
}: {
  oldPassword: string;
  newPassword: string;
}): ChangePasswordPayload => ({
  changePassword: {
    newPassword: {
      value: newPassword
    },
    oldPassword: {
      value: oldPassword
    }
  }
});

export const canSavePassword = (state: PasswordState): boolean => {
  const {
    hasCharacter,
    hasLowerCase,
    hasNumerals,
    hasSpecialCharacter,
    hasThreeIdenticalChar,
    hasUppperCase,
    hasSpaces,
    hasSquentialChar
  } = state;

  if (
    hasCharacter &&
    hasLowerCase &&
    hasNumerals &&
    hasSpecialCharacter &&
    hasUppperCase &&
    !hasThreeIdenticalChar &&
    !hasSquentialChar &&
    !hasSpaces
  ) {
    return true;
  }
  return false;
};

export const hasSequentialChars = (password: string): boolean => {
  for (let i = 0, prev = 0, asc = 0, desc = 0; i < password.length; i += 1) {
    const code = password[i].toLowerCase().charCodeAt(0);

    asc = prev + 1 === code ? asc + 1 : 0;
    desc = prev - 1 === code ? desc + 1 : 0;

    if (asc >= 2 || desc >= 2) return true;

    prev = code;
  }
  return false;
};
