import { defineMessages } from 'react-intl';

export const messages = defineMessages({
  assignStores: {
    id: 'COMMON.ASSIGNSTORESMODAL.ASSIGNSTORES.HEADER',
    defaultMessage: 'Assign Stores'
  },
  cancelButton: {
    id: 'COMMON.ASSIGNSTORESMODAL.CANCEL',
    defaultMessage: 'Cancel'
  },
  storesCount: {
    id: 'COMMON.ASSIGNSTORESMODAL.TABLEHEADER.STORESCOUNT',
    defaultMessage: 'Stores ({count})'
  },
  assignStoresTitle: {
    id: 'COMMON.ASSIGNSTORESMODAL.DIALOG.ASSIGNSTORES',
    defaultMessage: 'Assign Stores'
  }
});
