import styled from 'styled-components';

const StyledStepTwo = styled.div`
    width: 100%;
    height: -webkit-fill-available;
    .title {
        margin-top: 0;
    }
.title-desc {
        height: 1rem;
        font-size: 0.875rem;
        font-weight: 400;
}
`;

export default StyledStepTwo;
