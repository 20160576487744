/* eslint-disable @typescript-eslint/no-unsafe-call,
@typescript-eslint/no-unsafe-return,
@typescript-eslint/no-floating-promises */
import { GridApi } from 'ag-grid-community';
import { BUTTON_VARIANTS, Button, Dialog } from 'cdk-radial';
import ErrorToast from 'components/ui/ErrorToast';
import useColumns from 'fixtures/grid/common/assignStores/columnDefinition';
import { generateAssignStoreFilterSelectionObject } from 'helpers/tableHelper';
import { groupBy } from 'lodash';
import React, {
  MutableRefObject,
  useCallback,
  useEffect,
  useRef,
  useState
} from 'react';
import { useIntl } from 'react-intl';
import { useDispatch } from 'react-redux';
import { useGetUserStoresQuery } from 'redux/services/admin-service';
import { useGetAllStoresQuery } from 'redux/services/eis-core-service';
import { useGetCmfNumbersBulkQuery } from 'redux/services/eis-external-attributes-service';
import { StoresDetails } from 'redux/services/types/eis-core-service-types';
import { setTemplateRowHeight } from 'redux/slices/userImportSlice';
import useFilters from 'fixtures/grid/stores/filterSchema';
import { handleDownloadClick } from 'utils/utility';
import { useClient } from 'providers/ClientProvider';
import FilterHeader from '../../../templates/TableLayout/FilterHeader/FilterHeader';
import TableContainer from '../../../templates/TableLayout/TableContainer/TableContainer';
import TableContent from '../../../templates/TableLayout/TableContent/TableContent';
import TableHeader from '../../../templates/TableLayout/TableHeader/TableHeader';
import TablePaging from '../../../templates/TableLayout/TablePaging/TablePaging';
import { messages } from './messages';
import StyledAssignStores from './styled';
import {
  AssignStoresDialogProps,
  ExtendedStoreDetailsType,
  ExtendedStoresDetailsType,
  RowHeightType
} from './types';

const AssignStoresDialog: React.FC<AssignStoresDialogProps> = ({
  assignStores,
  isAssignStoresDialogOpen,
  closeAssignStoresDialog,
  context
}: AssignStoresDialogProps) => {
  const intl = useIntl();
  const [columns] = useColumns();
  const dispatch = useDispatch();
  const [filters] = useFilters();
  const containerRef = useRef() as MutableRefObject<HTMLDivElement>;
  const paginationHeight = 170;
  const headerSectionHeight = 16;

  const client = useClient();
  const { userInfo } = client;

  const {
    data: storesData,
    isFetching: isStoresFetching,

    isError: isStoresError,
    error: storesError
  } = useGetAllStoresQuery(
    {
      enterpriseId: userInfo?.fullUser?.enterprise.id || '',
      includeDepartments: true
    },
    { skip: !userInfo?.fullUser }
  );

  const {
    data: storesCMFNumbersData,
    isFetching: isStoresCMFsFetching,
    isError: isStoresCMFsError,
    error: storesCMFsError
  } = useGetCmfNumbersBulkQuery(userInfo?.fullUser?.enterprise?.id || '', {
    skip: !userInfo?.fullUser
  });

  const {
    data: userStores,
    isLoading: isStoresDataLoading,
    isError: isStoresDataError,
    error: storesDataError
  } = useGetUserStoresQuery(userInfo?.fullUser?.user?.userGuid || '');

  const filterSchema = [...filters];

  const [searchValue, setSearchValue] = useState('');
  const [filterSelections, setFilterSelections] = useState(
    generateAssignStoreFilterSelectionObject(filterSchema)
  );
  const [gridApi, setGridApi] = useState<GridApi>();
  const [currentPage, setCurrentPage] = useState(0);
  const [totalPages, setTotalPages] = useState(0);
  const [totalRows, setTotalRows] = useState(0);
  const [storesDetails, setStoresDetails] =
    useState<ExtendedStoresDetailsType>();

  const displayedRowCount: number = gridApi?.getDisplayedRowCount() || 0;

  useEffect(() => {
    if (storesData && storesCMFNumbersData) {
      const storesCMFMap = groupBy(
        storesCMFNumbersData.cmfNumber,
        'organizationId'
      );
      const storesConsolidatedData: StoresDetails[] = storesData.stores.map(
        (store: StoresDetails) => {
          const externalSystemIdentifiers: any = {};
          if (storesCMFMap[store.id]) {
            externalSystemIdentifiers.cmfNumber = storesCMFMap[store.id];
          }
          return {
            ...store,
            externalSystemIdentifiers
          };
        }
      );
      setStoresDetails({ stores: storesConsolidatedData });
    }
  }, [storesData, storesCMFNumbersData]);

  const [updatedStores, setUpdatedStores] = useState<
    ExtendedStoreDetailsType[]
  >([]);

  useEffect(() => {
    if (storesDetails) {
      const stores = storesDetails.stores?.filter(
        actualStoreData =>
          !assignStores?.find(
            StoreAssignData => StoreAssignData.storeId === actualStoreData.id
          )
      );
      setUpdatedStores(stores);
    }
  }, [storesDetails, assignStores]);

  const handleCancel = () => {
    closeAssignStoresDialog();
  };

  const handleSave = () => {
    const selectedStores = gridApi?.getSelectedRows();
    if (selectedStores && selectedStores.length) {
      context.handleAssignStores(selectedStores);
    }
    closeAssignStoresDialog();
  };

  const filteredArray: ExtendedStoreDetailsType[] = [];
  if (updatedStores && userStores) {
    updatedStores.forEach(store => {
      userStores.forEach(userStore => {
        if (userStore.storeId === store.id) {
          filteredArray.push(store);
        }
      });
    });
  }

  const setRowHeight = (rowIndex: number, rowHeight: number, field: string) => {
    dispatch(setTemplateRowHeight({ rowIndex, rowHeight, field }));
  };
  const minHeight = 80;
  const getRowHeight = useCallback(
    (params: RowHeightType) =>
      params.data.rowHeight > minHeight ? params.data.rowHeight : minHeight,
    []
  );

  const isContentLoading =
    isStoresFetching || isStoresCMFsFetching || isStoresDataLoading;

  return (
    <>
      {isStoresError && <ErrorToast dataError={storesError} />}

      {isStoresCMFsError && <ErrorToast dataError={storesCMFsError} />}

      {isStoresDataError && <ErrorToast dataError={storesDataError} />}

      {isStoresError && <ErrorToast dataError={storesError} />}

      {userInfo?.fullUser && (
        <Dialog
          dataTestId="auto-map-dialog"
          id="auto-map-Stores-dialog"
          className="auto-map-dialog"
          isOpen={isAssignStoresDialogOpen}
          onClose={closeAssignStoresDialog}
          title={intl.formatMessage(messages.assignStoresTitle)}
          style={{ width: '70vw', maxHeight: '90vw' }}
        >
          <StyledAssignStores>
            <TableContainer showBorder>
              <TableHeader
                isDownloadable
                disableDownloadButton={displayedRowCount === 0}
                onDownloadClick={(): void => handleDownloadClick(gridApi)}
                headerTitle={intl.formatMessage(messages.storesCount, {
                  count: displayedRowCount
                })}
              />
              <FilterHeader
                filterSchema={filterSchema}
                searchValue={searchValue}
                setSearchValue={setSearchValue}
                filterSelections={filterSelections}
                setFilterSelections={setFilterSelections}
              />

              <TableContent
                gridApi={gridApi}
                searchValue={searchValue}
                filterSchema={filterSchema}
                filterSelections={filterSelections}
                setGridApi={setGridApi}
                setCurrentPage={setCurrentPage}
                setTotalPages={setTotalPages}
                setTotalRows={setTotalRows}
                loading={isContentLoading}
                columns={columns}
                getRowHeight={getRowHeight}
                context={{
                  dispatch,
                  setRowHeight
                }}
                tableHeight={
                  containerRef &&
                  containerRef.current &&
                  containerRef.current.clientHeight
                    ? containerRef.current.clientHeight -
                      paginationHeight -
                      headerSectionHeight
                    : 300
                }
                data={updatedStores || []}
                rowHeight={50}
              />
              <TablePaging
                gridApi={gridApi}
                currentPage={currentPage}
                totalPages={totalPages}
                totalRows={totalRows}
              />
            </TableContainer>
            <div className="dialog-footer">
              <div className="footer-button-group">
                <Button
                  dataTestId="auto-map-dialog-cancel-button"
                  text={intl.formatMessage(messages.cancelButton)}
                  variant={BUTTON_VARIANTS.TEXT}
                  onClick={handleCancel}
                />
                <Button
                  dataTestId="auto-map-dialog-assign-button"
                  text={intl.formatMessage(messages.assignStores)}
                  variant={BUTTON_VARIANTS.TEXT}
                  onClick={handleSave}
                />
              </div>
            </div>
          </StyledAssignStores>
        </Dialog>
      )}
    </>
  );
};

export default AssignStoresDialog;
