import { useEffect, useRef } from 'react';

const useIdleTimer = (
  inactivityTime: number,
  onInactivity: () => void
): void => {
  const inactivityStartTimeRef = useRef(Date.now());

  useEffect(() => {
    const handleActivity = () => {
      inactivityStartTimeRef.current = Date.now();
    };

    const checkInactivity = () => {
      if (Date.now() - inactivityStartTimeRef.current >= inactivityTime) {
        onInactivity();
        inactivityStartTimeRef.current = Date.now();
      }
    };

    ['click', 'keypress'].forEach(event => {
      window.addEventListener(event, handleActivity);
    });

    const interval = setInterval(checkInactivity, 1000);

    return () => {
      clearInterval(interval);
      ['click', 'keypress'].forEach(event => {
        window.removeEventListener(event, handleActivity);
      });
    };
  }, [inactivityTime, onInactivity]);

  useEffect(
    () => () => {
      inactivityStartTimeRef.current = Date.now();
    },
    []
  );
};

export default useIdleTimer;
