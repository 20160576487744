import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import { ChipContent } from './ChipContent';
import { getRem } from '../../core';
import { ChipIcon, ChipIconTrailing } from '../elements';

const StyledChoiceChipContent = styled(ChipContent)`
  padding-left: ${getRem('30px')};
  padding-right: ${({ trailingIcon }) => !!trailingIcon && getRem('30px')};
  svg {
    fill: ${({ isDisabled, isSelected, theme }) =>
      (!!isDisabled && theme.color.gray[300].value) || (!!isSelected && theme.color.chip.defaultSvg.value)};
  }
`;

const ChipContentChoiceChips = React.forwardRef(
  (
    {
      disableFocus = false,
      icon = undefined,
      isDisabled = false,
      isSelected = false,
      label,
      onClick = undefined,
      trailingIcon = undefined,
      value = null,
      ...other
    },
    ref
  ) => {
    const renderIcon = () => !!icon && <ChipIcon>{icon}</ChipIcon>;
    const renderTrailingIcon = () => !!trailingIcon && <ChipIconTrailing>{trailingIcon}</ChipIconTrailing>;

    return (
      <StyledChoiceChipContent
        {...{ disableFocus, isDisabled, isSelected, label, onClick, trailingIcon, value }}
        {...other}
        ref={ref}
        renderBeforeContent={renderIcon}
        renderAfterContent={renderTrailingIcon}
      />
    );
  }
);

ChipContentChoiceChips.propTypes = {
  /** If true, chips won't be focusable via keyboard */
  disableFocus: PropTypes.bool,
  /** This is the radio button */
  icon: PropTypes.node,
  /** If true, disabled styles apply */
  isDisabled: PropTypes.bool,
  /** If true, denotes chip selection, and selected styles apply */
  isSelected: PropTypes.bool,
  /** Chip label */
  label: PropTypes.node.isRequired,
  /** Callback function which is triggered on chip click */
  onClick: PropTypes.func,
  /** Shows icon inside the button. Use icon component from the library */
  trailingIcon: PropTypes.node,
  /** Value of the chip */
  value: PropTypes.node,
};

export { ChipContentChoiceChips };
