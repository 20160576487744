import styled, { css } from 'styled-components';

import { getRem } from '../../core';

const StatusWrapper = styled.div`
  align-items: center;
  border: 2px solid;
  border-radius: ${({ theme }) => theme.size.borderRadius.small.value};
  border-color: ${({ theme }) => theme.color.additional.dark.value};
  color: ${({ theme }) => theme.color.additional.dark.value};
  box-sizing: border-box;
  display: inline-flex;
  line-height: 1;
  min-height: ${getRem('24')};
  padding: ${getRem('2')};
  ${({ isFullWidth }) =>
    isFullWidth &&
    css`
      display: flex;
      width: 100%;
    `};
`;

export { StatusWrapper };
