import { Avatar, AVATAR_SIZES } from 'cdk-radial';
import StyledAvatarText from './styled';

type AvatarTextProps = {
  label: string;
};

const AvatarText = ({ label }: AvatarTextProps): JSX.Element => (
  <StyledAvatarText>
    <Avatar label={label} size={AVATAR_SIZES.STANDARD} />
  </StyledAvatarText>
);

export default AvatarText;
