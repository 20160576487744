import { StyledPageSkeletonLoader } from './styled';
import { BlankHeader } from '../ContentHeader';

export const PageSkeletonLoader = (): JSX.Element => (
  <StyledPageSkeletonLoader>
    <div className="skeleton-sidebar" />
    <div className="skeleton-header">
      <BlankHeader title="Loading..." actionButtons={{}} />
    </div>
    <div className="skeleton-body" />
  </StyledPageSkeletonLoader>
);
