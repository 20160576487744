import { createContext, useContext, useState, useMemo, useEffect } from 'react';
import { FFContextProvider } from '@harnessio/ff-react-client-sdk';
import { PageSkeletonLoader } from 'components/common/PageSkeletonLoader/PageSkeletonLoader';
import { HarnessFlagContextType } from './types';
import { HARNESS_KEY, INDENTIFIER, NAME } from './constants';
import { useClient } from './ClientProvider';

export const HarnessFlagContext = createContext<HarnessFlagContextType>(null);

export const HarnessFlagProvider = ({
  children
}: {
  children: React.ReactNode;
}): JSX.Element => {
  const client = useClient();
  const [attributes, setAttributes] = useState<{ [key: string]: string }>({});

  useEffect(() => {
    setAttributes({
      enterprise_id: client.enterpriseId,
      user_id: client.loginId
    });
  }, [client]);

  const contextValue = useMemo(
    () => ({ setHarnessAttributes: setAttributes }),
    []
  );

  return (
    <HarnessFlagContext.Provider value={contextValue}>
      <FFContextProvider
        apiKey={HARNESS_KEY}
        target={{
          identifier: INDENTIFIER,
          name: NAME,
          attributes
        }}
        fallback={<PageSkeletonLoader />}
      >
        {children}
      </FFContextProvider>
    </HarnessFlagContext.Provider>
  );
};

export const useHarnessFlagClient = (): HarnessFlagContextType =>
  useContext(HarnessFlagContext);
