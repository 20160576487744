import React, { useRef, useState } from 'react';

import { LIST_SIZES } from 'lib/list';
import { Popup } from 'lib/popup';
import { useLatestEvent } from 'lib/utilities';
import PropTypes from 'prop-types';

import { ContextMenuContent } from './ContextMenuContent';
import { MENU_HORIZONTAL_POSITIONS, MENU_VERTICAL_POSITIONS } from '../../constants';

export const ContextMenu = ({
  ariaLabel = 'Select an option:',
  customRenderOption = undefined,
  dataTestId = undefined,
  defaultSelectedValue = undefined,
  hideGroupLabel = false,
  isOpen,
  onClose,
  onOpen,
  onSelect,
  options,
  positionHorizontal = MENU_HORIZONTAL_POSITIONS.RIGHT,
  positionVertical = MENU_VERTICAL_POSITIONS.TOP,
  size = LIST_SIZES.STANDARD,
  wrappedComponentContent,
  ...other
}) => {
  const popupRef = useRef();
  const buttonRef = useRef();
  const wrappedComponentRef = useRef();
  const [selectedOptionIndex, setSelectedOptionIndex] = useState(
    defaultSelectedValue ? options.findIndex((option) => option.value === defaultSelectedValue) : undefined
  );

  const handleSelect = (option, index) => {
    setSelectedOptionIndex(index);
    onSelect(option);
    // eslint-disable-next-line mdx/no-unused-expressions
    buttonRef.current && buttonRef.current.focus();
  };

  const handleClose = () => {
    // eslint-disable-next-line mdx/no-unused-expressions
    buttonRef.current && buttonRef.current.focus();
    onClose();
  };

  const latestEvent = useLatestEvent('mousedown', 'keydown', popupRef.current);
  const isKeyboardEventLatest = latestEvent === 'keydown';

  const clonedWrappedComponent = React.cloneElement(wrappedComponentContent, {
    ref: buttonRef,
  });

  if (process.env.NODE_ENV === 'development') {
    console.warn(
      'Radial has deprecated the Context Menu component. Context Menu will have limited support through v6, and it will be removed in v7.  Please use/migrate usage of Context Menu to the new Menu component. Visit https://svc-radial-storybook.ext.nebula.connectcdk.com/?path=/docs/components-menus--menu for more details.'
    );
  }

  return (
    <Popup
      isOpen={isOpen}
      onClose={handleClose}
      onOpen={onOpen}
      ref={popupRef}
      wrappedComponentContent={clonedWrappedComponent}
      wrappedComponentRef={wrappedComponentRef}
    >
      <ContextMenuContent
        ariaLabel={ariaLabel}
        customRenderOption={customRenderOption}
        dataTestId={dataTestId}
        hideGroupLabel={hideGroupLabel}
        isKeyboardEventLatest={isKeyboardEventLatest}
        onClose={onClose}
        onSelect={handleSelect}
        options={options}
        positionHorizontal={positionHorizontal}
        positionVertical={positionVertical}
        selectedOptionIndex={selectedOptionIndex}
        size={size}
        wrappedComponentRef={wrappedComponentRef}
        {...other}
      />
    </Popup>
  );
};

ContextMenu.propTypes = {
  /** Informs screen reader users what actions they should take */
  ariaLabel: PropTypes.node,
  /** Accepts a customised option render function */
  customRenderOption: PropTypes.func,
  /** Id value used for testing */
  dataTestId: PropTypes.string,
  /** Value of pre-selected option */
  defaultSelectedValue: PropTypes.node,
  /** If true, visually hides group label */
  hideGroupLabel: PropTypes.bool,
  /** If true, shows context menu content */
  isOpen: PropTypes.bool.isRequired,
  /** Callback that is called when context menu is being closed */
  onClose: PropTypes.func.isRequired,
  /** Callback that is called when context menu is getting opened */
  onOpen: PropTypes.func.isRequired,
  /** Callback that is called when an item is clicked in the context menu */
  onSelect: PropTypes.func.isRequired,
  /** Context menu options. For correct data structure refer to component documentation */
  options: PropTypes.arrayOf(
    PropTypes.shape({
      /** Renders icon before label */
      icon: PropTypes.node,
      /** Unique identifier for option */
      id: PropTypes.string,
      /** If true, disables option */
      isDisabled: PropTypes.bool,
      /** Option label */
      label: PropTypes.string,
    })
  ).isRequired,
  /** Specifies horizontal context menu position relative to wrapper component */
  positionHorizontal: PropTypes.oneOf(Object.values(MENU_HORIZONTAL_POSITIONS)),
  /** Specifies vertical context menu position relative to wrapper component */
  positionVertical: PropTypes.oneOf(Object.values(MENU_VERTICAL_POSITIONS)),
  /** Changes list item height */
  size: PropTypes.oneOf(Object.values(LIST_SIZES)),
  /** Sets component which shows popup menu after clicking on it */
  wrappedComponentContent: PropTypes.node.isRequired,
};
