/* eslint-disable @typescript-eslint/no-unsafe-call,
@typescript-eslint/no-floating-promises */
import { BUTTON_VARIANTS, Button, Dialog } from 'cdk-radial';
import { messages as commonMessages } from 'commonMessages';
import useColumns from 'fixtures/grid/common/assignRoles/columnDefinitions';
import { generateFilterSelectionObject } from 'helpers/tableHelper';
import React, { useEffect, useState } from 'react';
import { useIntl } from 'react-intl';
import { UserRole } from 'redux/services/admin-service/types';
import { useGetRolesQuery } from 'redux/services/authz-service';
import { RolesResponseType } from 'redux/services/authz-service/types';
import { handleDownloadClick } from 'utils/utility';
import { useClient } from 'providers/ClientProvider';
import FilterHeader from '../../../templates/TableLayout/FilterHeader/FilterHeader';
import TableContainer from '../../../templates/TableLayout/TableContainer/TableContainer';
import TableContent from '../../../templates/TableLayout/TableContent/TableContent';
import TableHeader from '../../../templates/TableLayout/TableHeader/TableHeader';
import TablePaging from '../../../templates/TableLayout/TablePaging/TablePaging';
import useFilters from '../../../../fixtures/grid/common/assignRoles/filterSchema';
import { messages } from './messages';
import StyledAssignRoles from './styled';

interface IMyProps {
  assignRoles?: UserRole[];
  isAssignRolesDialogOpen: boolean;
  closeAssignRolesDialog: () => void;
  context: { handleAssignRoles: (selectedRoles: UserRole[]) => void };
}

const AssignRolesDialog: React.FC<IMyProps> = ({
  assignRoles: assignedRoles,
  isAssignRolesDialogOpen,
  closeAssignRolesDialog,
  context
}: IMyProps) => {
  const [columns] = useColumns();
  const assignRoleColumns = columns;
  const intl = useIntl();

  const client = useClient();
  const { userInfo } = client;

  const { data: roles, isLoading: isRolesLoading } = useGetRolesQuery(
    userInfo?.fullUser?.enterprise.id || '',
    { skip: !userInfo?.fullUser }
  );

  const [updatedRoles, setUpdatedRoles] = useState<RolesResponseType[]>([]);

  useEffect(() => {
    if (roles) {
      const unassignedRoles = roles?.filter(
        actualRoleData =>
          !assignedRoles?.find(
            assignRoleData =>
              assignRoleData.roleGuid === actualRoleData.roleGuid
          )
      );
      setUpdatedRoles(unassignedRoles);
    }
  }, [roles, assignedRoles]);

  const [filterSchema] = useFilters();
  const [searchValue, setSearchValue] = useState('');
  const [filterSelections, setFilterSelections] = useState<
    Record<string, Array<string | undefined>>
  >(generateFilterSelectionObject(filterSchema));
  const [gridApi, setGridApi] = useState<any>(null);
  const [currentPage, setCurrentPage] = useState(0);
  const [totalPages, setTotalPages] = useState(0);
  const [totalRows, setTotalRows] = useState(0);

  const displayedRowCount: number =
    gridApi && gridApi.getDisplayedRowCount
      ? gridApi.getDisplayedRowCount()
      : 0;

  const handleCancel = () => {
    closeAssignRolesDialog();
  };

  const handleSave = () => {
    const selectedRoles = gridApi?.getSelectedRows();
    context.handleAssignRoles(selectedRoles as UserRole[]);
    closeAssignRolesDialog();
  };

  return (
    <>
      {userInfo?.fullUser && (
        <Dialog
          dataTestId="auto-map-dialog"
          id="auto-map-roles-dialog"
          className="auto-map-dialog"
          isOpen={isAssignRolesDialogOpen}
          onClose={closeAssignRolesDialog}
          title={intl.formatMessage(messages.assignRolesDialogTitle)}
          style={{ width: '70vw', maxHeight: '90vw' }}
        >
          <StyledAssignRoles>
            <TableContainer showBorder>
              <TableHeader
                isDownloadable
                disableDownloadButton={displayedRowCount === 0}
                onDownloadClick={(): void => handleDownloadClick(gridApi)}
                headerTitle={intl.formatMessage(
                  messages.assignRolesTableHeader,
                  { count: displayedRowCount || 0 }
                )}
              />
              <FilterHeader
                filterSchema={[]} // removing filters until RDS resolves z-index issue on a dialog
                searchValue={searchValue}
                setSearchValue={setSearchValue}
                filterSelections={[]} // removing filters until RDS resolves z-index issue on a dialog
                setFilterSelections={setFilterSelections}
              />
              <TableContent
                gridApi={gridApi}
                searchValue={searchValue}
                filterSchema={filterSchema}
                filterSelections={filterSelections}
                setGridApi={setGridApi}
                setCurrentPage={setCurrentPage}
                setTotalPages={setTotalPages}
                setTotalRows={setTotalRows}
                loading={isRolesLoading}
                columns={assignRoleColumns}
                data={updatedRoles}
                rowHeight={50}
              />
              <TablePaging
                gridApi={gridApi}
                currentPage={currentPage}
                totalPages={totalPages}
                totalRows={totalRows}
              />
            </TableContainer>
            <div className="dialog-footer">
              <div className="footer-button-group">
                <Button
                  dataTestId="auto-map-dialog-cancel-button"
                  text={intl.formatMessage(commonMessages.cancel)}
                  variant={BUTTON_VARIANTS.TEXT}
                  onClick={() => {
                    handleCancel();
                  }}
                />
                <Button
                  dataTestId="auto-map-dialog-assign-button"
                  text={intl.formatMessage(messages.assignRoles)}
                  variant={BUTTON_VARIANTS.TEXT}
                  onClick={() => {
                    handleSave();
                  }}
                />
              </div>
            </div>
          </StyledAssignRoles>
        </Dialog>
      )}
    </>
  );
};

export default AssignRolesDialog;
