import { defineMessages } from 'react-intl';

export const messages = defineMessages({
  stepThreeHeader: {
    id: 'NEWUSERIMPORT.STEP3.TABLEHEADER',
    defaultMessage: 'Review Import List'
  },
  removeUserWarning: {
    id: 'NEWUSERIMPORT.STEP3.WARNING.REMOVEUSER',
    defaultMessage: 'Please select users to remove from Import List.'
  },
  assignUserWarning: {
    id: 'NEWUSERIMPORT.STEP3.WARNING.ASSIGNUSER',
    defaultMessage: 'Please select the users before Assignment'
  },
  userImportTemplate: {
    id: 'NEWUSERIMPORT.STEP2.TEMPLATE.TITLE',
    defaultMessage: 'New Import List'
  },
  userRemovedWarning: {
    id: 'NEWUSERIMPORT.STEP3.WARNING.USERREMOVED',
    defaultMessage: '{msg} user(s) removed from import list'
  },
  assignUserSuccess: {
    id: 'NEWUSERIMPORT.STEP3.ASSIGNUSER.SUCCESS',
    defaultMessage:
      '{roles} are assigned to {selectedUserCount} selected users.'
  }
});
