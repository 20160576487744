import { defineMessages } from 'react-intl';

export const messages = defineMessages({
  assignRolesButton: {
    id: 'REVIEWIMPORTS.BUTTON.ASSIGNROLES',
    defaultMessage: 'Assign Roles'
  },
  removeUser: {
    id: 'REVIEWIMPORTS.BUTTON.REMOVEUSER',
    defaultMessage: 'Remove User'
  },
  assignStoresButton: {
    id: 'REVIEWIMPORTS.BUTTON.ASSIGNSTORES',
    defaultMessage: 'Assign Stores'
  }
});
