import React from 'react';
import PropTypes from 'prop-types';
import styled, { css } from 'styled-components';

import { ChipContent } from './ChipContent';
import { getRem } from '../../core';
import { ChipCaret, ChipIcon } from '../elements';

const StyledChipContent = styled(ChipContent).withConfig({
  shouldForwardProp: (prop) => !['hasIcon'].includes(prop),
})`
  padding-right: ${getRem('8px')};
  ${({ hasIcon }) =>
    hasIcon &&
    css`
      padding-left: ${getRem('30px')};
    `};
`;

const ChipContentWithCaret = React.forwardRef(
  (
    {
      dataTestId = '',
      disableFocus = false,
      hasDropdown = undefined,
      icon = undefined,
      isOpen = false,
      isSelected = false,
      label,
      onClick = undefined,
      value = null,
      ...other
    },
    ref
  ) => {
    const renderCaret = () =>
      !!hasDropdown && <ChipCaret data-testid={dataTestId ? `${dataTestId}-caret` : undefined} isOpen={isOpen} />;
    const renderIcon = () =>
      !!icon && <ChipIcon data-testid={dataTestId ? `${dataTestId}-icon` : undefined}>{icon}</ChipIcon>;

    return (
      <StyledChipContent
        data-testid={dataTestId ? `${dataTestId}-content` : undefined}
        hasIcon={!!icon}
        {...{ disableFocus, hasDropdown, isOpen, isSelected, label, onClick, value }}
        {...other}
        ref={ref}
        renderAfterContent={renderCaret}
        renderBeforeContent={renderIcon}
      />
    );
  }
);

ChipContentWithCaret.propTypes = {
  /** If true, chips won't be focusable via keyboard */
  disableFocus: PropTypes.bool,
  /** Shows caret inside the button. Used in select chips */
  hasDropdown: PropTypes.bool,
  /** Shows icon inside the button. Use icon component from the library */
  icon: PropTypes.node,
  /** If true, rotates caret icon. Used for select chips */
  isOpen: PropTypes.bool,
  /** Chip label */
  label: PropTypes.node.isRequired,
  /** Callback function which is triggered on chip click */
  onClick: PropTypes.func,
  /** Value of the chip */
  value: PropTypes.node,
  /** Chip is selected and disabled by default */
  isSelected: PropTypes.bool,
  /** ID for testing the node */
  dataTestId: PropTypes.string,
};

export { ChipContentWithCaret };
