import { useRef, useLayoutEffect, useState } from 'react';
import { Button, BUTTON_VARIANTS } from 'cdk-radial';
import { useIntl } from 'react-intl';
import StyledExpandableChips from './styled';
import { messages } from './messages';

type ExpandableChipsProps = {
  data: string[];
  rowIndex: number;
  field: string;
  setRowHeight: (owIndex: number, divHeight: number, field: string) => void;
};

const ExpandableCellText = ({
  data,
  rowIndex,
  field,
  setRowHeight
}: ExpandableChipsProps): JSX.Element => {
  const containerRef = useRef<HTMLDivElement>(null);
  const intl = useIntl();

  const [isExpanded, setIsExpanded] = useState<boolean>(false);

  const handleExpandCollapseClick = () => {
    setIsExpanded(!isExpanded);
  };

  useLayoutEffect(() => {
    if (data && data.length && containerRef && containerRef.current) {
      setRowHeight(rowIndex, containerRef.current.clientHeight, field);
    }
  }, [data, rowIndex, containerRef, setRowHeight, field, isExpanded]);

  return (
    <StyledExpandableChips
      data-testid="Expandablechips-container"
      ref={containerRef}
    >
      {data?.length > 1 && (
        <>
          {data[0]}
          {(isExpanded &&
            `, ${data?.filter(n => n && n !== data[0])?.join(', ')}`) ||
            ''}
          <Button
            dataTestId="copy-icon-button"
            variant={BUTTON_VARIANTS.TEXT}
            text={
              isExpanded
                ? `- ${intl.formatMessage(messages.buttonLess)}`
                : `+${data?.length - 1} ${intl.formatMessage(
                    messages.buttonMore
                  )}`
            }
            onClick={() => handleExpandCollapseClick()}
          />
        </>
      )}

      {data?.length === 1 && <p>{data?.filter(n => n)?.join(', ') || ''}</p>}
    </StyledExpandableChips>
  );
};

export default ExpandableCellText;
