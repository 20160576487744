import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import { LOADER_SIZE } from '../constants';
import {
  CircleWrapper,
  CircleTrack,
  dash,
  FilledIndeterminateCircle,
  rotate,
} from '../shared-styles/circularProgressElements';

const StyledSvg = styled.svg`
  animation: ${rotate} 1.4s linear infinite;
  position: relative;
`;

const StyledFilledCircle = styled(FilledIndeterminateCircle)`
  animation: ${dash} 1.4s ease-in-out infinite;
  stroke-linecap: round;
`;

const IndeterminateProgressCircle = ({
  className,
  dataTestId = '',
  label = 'Loading',
  size = LOADER_SIZE.LARGE,
  ...other
}) => {
  /**
   * These next values are set up against the 96 pixel svg viewBox to mimic design specs.
   */
  const strokeWidth = 9.6; // 20% of half the 96px viewBox width (or 10% of the whole width)
  const radius = 43.2; // half of 96 diameter... minus half of 9.6 strokewidth

  return (
    <CircleWrapper dataTestId={dataTestId} size={size} {...other}>
      <StyledSvg
        aria-label={label}
        className={`${className} svg`}
        height={size === LOADER_SIZE.LARGE ? 48 : 20}
        role="progressbar"
        width={size === LOADER_SIZE.LARGE ? 48 : 20}
        viewBox="0 0 96 96"
        version="1.1"
        xmlns="http://www.w3.org/2000/svg"
      >
        <CircleTrack cx="48" cy="48" r={radius} role="status" stroke-miterlimit="10" strokeWidth={strokeWidth} />
        <StyledFilledCircle cx="48" cy="48" data-testid="progress-bar-bar" r={radius} strokeWidth={strokeWidth} />
      </StyledSvg>
    </CircleWrapper>
  );
};

IndeterminateProgressCircle.propTypes = {
  /** Adds additional class name to progress circle track */
  className: PropTypes.string,
  /** Id value used for testing */
  dataTestId: PropTypes.string,
  /** Sets the Aria Label for the progress circle */
  label: PropTypes.string,
  /** Sets the size of the loader to small or large */
  size: PropTypes.oneOf(Object.values(LOADER_SIZE)),
};

export { IndeterminateProgressCircle };
