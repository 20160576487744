import styled, { css } from 'styled-components';

import { getHexToRgb, getLineHeight, getRem, msInputStyles, transitionAnimation, typographyBody1 } from './../../core';
import { InputSuffixElement } from './InputSuffixElement';
import { BORDER_POSITIONS, INPUT_SIZES } from '../constants';
import { inputStateOutlineStyles } from '../inputStateOutlineStyles';

const focusStyles = css`
  ${inputStateOutlineStyles.focus};

  & ~ ${InputSuffixElement} {
    /* No other way to apply styling */
    svg {
      fill: ${({ theme }) => theme.color.primary['500'].value};
    }
  }
`;

const isRequiredStyles = css`
  &::after {
    color: ${({ theme }) => theme.color.text.light.secondary.value};
    content: '*';
    font-size: ${getRem('17px')};
    line-height: 0;
    margin-left: ${getRem('2px')};
    position: absolute;
    right: ${({ theme }) => theme.size.spacing.small.value};
    top: ${({ theme }) => theme.size.spacing.medium.value};
    ${({ hasError, isRequired, theme }) =>
      isRequired &&
      hasError &&
      css`
        color: ${theme.color.system.negative[500].value};
      `}

    ${({ hasError, isFocused, isReadOnly, isRequired, theme }) =>
      !hasError &&
      !isReadOnly &&
      isFocused &&
      isRequired &&
      css`
        color: ${theme.color.primary['500'].value};
      `}
        ${({ isDisabled, isRequired, theme }) =>
      isRequired &&
      isDisabled &&
      css`
        color: ${theme.color.text.light.disabled.value};
      `}
  }
`;

const hideBorderColorStyles = css`
  border-left-color: ${({ hideBorder }) => hideBorder === BORDER_POSITIONS.LEFT && 'transparent'};
  border-right-color: ${({ hideBorder }) => hideBorder === BORDER_POSITIONS.RIGHT && 'transparent'};
`;

const hideBorderStyles = css`
  ${({ hideBorder, theme }) =>
    hideBorder === BORDER_POSITIONS.LEFT &&
    css`
      border-left: 1px solid transparent;
      border-radius: 0 ${theme.size.borderRadius.large.value} ${theme.size.borderRadius.large.value} 0;
    `};
  ${({ hideBorder, theme }) =>
    hideBorder === BORDER_POSITIONS.RIGHT &&
    css`
      border-radius: ${theme.size.borderRadius.large.value} 0 0 ${theme.size.borderRadius.large.value};
      border-right: none;
    `};
`;

const inputDividerStyles = css`
  ${({ hasError, inputDivider, theme }) => css`
    border-left: ${inputDivider === BORDER_POSITIONS.LEFT &&
    `1px solid rgba(${getHexToRgb(theme.color.additional.dark.value)}, 0.3)`};
    border-left-color: ${hasError && inputDivider === BORDER_POSITIONS.LEFT && theme.color.system.negative[500].value};
    border-right: ${inputDivider === BORDER_POSITIONS.RIGHT &&
    `1px solid rgba(${getHexToRgb(theme.color.additional.dark.value)}, 0.3)`};
    border-right-color: ${hasError &&
    inputDivider === BORDER_POSITIONS.RIGHT &&
    theme.color.system.negative[500].value};
  `}
`;

const sizeStyles = css`
  height: ${getRem(36)};
  ${({ size, theme }) =>
    size === INPUT_SIZES.LARGE &&
    css`
      font-size: ${theme.font.size.default.value};
      height: ${getRem(40)};
      line-height: ${getLineHeight('16px', '24px')};
      &::-webkit-input-placeholder {
        line-height: normal;
      }
    `}
  ${({ size }) =>
    size === INPUT_SIZES.SMALL &&
    css`
      height: ${getRem(32)};
      line-height: normal;
    `}
`;

// Note: BaseInput is a label since it wraps input component together with the actual label
const BaseInput = styled.label`
  align-items: center;
  box-shadow: none;
  box-sizing: border-box;
  display: flex;
  flex: 1 1 100%;
  flex-direction: column;
  outline: none;
  padding-left: ${getRem(12)};
  padding-right: ${({ theme }) => theme.size.spacing.medium.value};
  position: relative;
  width: 100%;
  ${msInputStyles}
  ${transitionAnimation('border, box-shadow')}
    ${({ theme }) => typographyBody1(theme)}
    ${inputStateOutlineStyles.default};
  ${({ isRequired }) => isRequired && isRequiredStyles}

  &::placeholder {
    color: ${({ theme }) => `rgba(${getHexToRgb(theme.color.additional.dark.value)}, 0.4)`};
  }

  &::-ms-clear,
  &::-ms-reveal {
    display: none;
  }

  ${({ hasError, isDisabled, isFocused, isReadOnly }) =>
    !isFocused &&
    !hasError &&
    !isDisabled &&
    !isReadOnly &&
    css`
      &:hover {
        ${inputStateOutlineStyles.hover};
      }
    `}
  ${({ hasError }) => hasError && inputStateOutlineStyles.error}
    ${({ hasError, isFocused }) => hasError && isFocused && inputStateOutlineStyles.errorAndFocus}
    ${({ hasError, isDisabled, isReadOnly }) => !hasError && !isDisabled && !isReadOnly && hideBorderColorStyles}
    ${hideBorderStyles}
    ${inputDividerStyles}
    ${({ isReadOnly, theme }) =>
    isReadOnly &&
    css`
      background-color: ${theme.color.state.default.disabled.value};
      border-color: transparent;
      box-shadow: none;
    `}
    ${({ isDisabled, theme }) =>
    isDisabled &&
    css`
      background-color: ${theme.color.state.default.disabled.value};
      border-color: transparent;
      cursor: not-allowed;
    `}
    ${sizeStyles}
    ${({ hasError, isDisabled, isFocused, isReadOnly }) =>
    !hasError && !isDisabled && !isReadOnly && isFocused && focusStyles}
${({ hasError, isDisabled, isFocused, isReadOnly }) =>
    !hasError && !isDisabled && !isReadOnly && isFocused && focusStyles}
`;

export { BaseInput };
