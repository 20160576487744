import { DropdownOption } from 'components/organisms/common/Dropdown/types';

export enum SectionType {
  basicDetails = 'basicDetails',
  identificationDetails = 'identificationDetails',
  passwordDetails = 'passwordDetails',
  contactDetails = 'contactDetails',
  roles = 'roles',
  stores = 'stores',
  copyDMSProfile = 'copyDMSProfile'
}

export enum FieldNameType {
  firstName = 'firstName',
  middleName = 'middleName',
  lastName = 'lastName',
  suffix = 'suffix',
  nickName = 'nickName',
  locale = 'locale',
  externallyFederated = 'externallyFederated',
  uniquePrincipalName = 'uniquePrincipalName',
  employeeId = 'employeeId',
  jobTitle = 'jobTitle',
  cdkSimpleId = 'cdkSimpleId',
  newPassword = 'newPassword',
  retypePassword = 'retypePassword',
  primaryEmail = 'primaryEmail',
  primaryPhone = 'primaryPhone',
  secondaryEmail = 'secondaryEmail',
  secondaryPhone = 'secondaryPhone',
  isCopyDMSProfileStarted = 'isCopyDMSProfileStarted'
}

export enum ComponentTypes {
  email = 'email',
  phoneNumber = 'phoneNumber',
  password = 'password'
}

export type FieldType = {
  uid: FieldNameType;
  isRequired: boolean;
  errorMessage: string;
  hasError: boolean;
  type?: ComponentTypes;
  options?: DropdownOption[];
  value: string;
};

export type CheckBoxFieldType = {
  uid: FieldNameType;
  isRequired: boolean;
  errorMessage: string;
  hasError: boolean;
  type?: ComponentTypes;
  options?: DropdownOption[];
  value: boolean | string;
};

export type FieldTypeDropDown = {
  uid: FieldNameType;
  isRequired: boolean;
  errorMessage: string;
  hasError: boolean;
  type?: ComponentTypes;
  options: DropdownOption[];
  value: { label: string; value: string };
};

type UsserDetailsType = {
  [SectionType.basicDetails]: {
    [FieldNameType.firstName]: FieldType;
    [FieldNameType.middleName]: FieldType;
    [FieldNameType.lastName]: FieldType;
    [FieldNameType.suffix]: FieldTypeDropDown;
    [FieldNameType.nickName]: FieldType;
    [FieldNameType.locale]: FieldTypeDropDown;
    [FieldNameType.externallyFederated]: CheckBoxFieldType;
    [FieldNameType.uniquePrincipalName]: FieldType;
  };
  [SectionType.identificationDetails]: {
    [FieldNameType.employeeId]: FieldType;
    [FieldNameType.jobTitle]: FieldTypeDropDown;
  };
  [SectionType.passwordDetails]: {
    [FieldNameType.cdkSimpleId]: FieldType;
    [FieldNameType.newPassword]: FieldType;
    [FieldNameType.retypePassword]: FieldType;
  };
  [SectionType.contactDetails]: {
    [FieldNameType.primaryEmail]: FieldType;
    [FieldNameType.primaryPhone]: FieldType;
    [FieldNameType.secondaryEmail]: FieldType;
    [FieldNameType.secondaryPhone]: FieldType;
  };
  [SectionType.roles]: Record<string, any>[];
  [SectionType.stores]: Record<string, any>[];
  [SectionType.copyDMSProfile]: {
    [FieldNameType.isCopyDMSProfileStarted]: FieldType;
  };
};

export type BasicDetailsProps = {
  fields: {
    [FieldNameType.firstName]: FieldType;
    [FieldNameType.middleName]: FieldType;
    [FieldNameType.lastName]: FieldType;
    [FieldNameType.suffix]: FieldTypeDropDown;
    [FieldNameType.nickName]: FieldType;
    [FieldNameType.locale]: FieldTypeDropDown;
    [FieldNameType.uniquePrincipalName]: FieldType;
  };
  federationStatus: boolean;
  handleFieldChange: (field: FieldNameType, value: string) => void;
};

export type IdentificationDetailsProps = {
  fields: {
    [FieldNameType.employeeId]: FieldType;
    [FieldNameType.jobTitle]: FieldTypeDropDown;
  };
  handleFieldChange: (field: FieldNameType, value: string) => void;
};

export type PasswordDetailsProps = {
  fields: {
    [FieldNameType.cdkSimpleId]: FieldType;
    [FieldNameType.newPassword]: FieldType;
    [FieldNameType.retypePassword]: FieldType;
  };
  handleFieldChange: (field: FieldNameType, value: string) => void;
};

export type ContactDetailsProps = {
  fields: {
    [FieldNameType.primaryEmail]: FieldType;
    [FieldNameType.primaryPhone]: FieldType;
    [FieldNameType.secondaryEmail]: FieldType;
    [FieldNameType.secondaryPhone]: FieldType;
  };
  handleFieldChange: (field: FieldNameType, value: string) => void;
};

export default UsserDetailsType;
