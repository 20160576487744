import styled from 'styled-components';

import { getHexToRgb } from './../../core';

export const DialogOverlay = styled.div`
  background-color: rgba(${({ theme }) => getHexToRgb(theme.color.additional.alwaysBlack.value)}, 0.7);
  bottom: 0;
  box-sizing: border-box;
  height: 100vh;
  left: 0;
  position: fixed;
  right: 0;
  top: 0;
  width: 100vw;
  z-index: ${({ theme }) => theme.zIndex.dialogOverlay.value};
`;
