import React from 'react';
import PropTypes from 'prop-types';

import { PageHeaderTitle, PageHeaderWrapper } from '../elements';

const PageHeaderTitleBlock = ({ title, ...other }) => {
  if (process.env.NODE_ENV === 'development') {
    console.warn(
      'Radial has deprecated this Page Header Title Block, and it will be removed in v7. Developers will need to utilize the PageHeaderWrapper and place controls as needed.  Current Page Header components will have limited support through v6. Please visit https://svc-radial-storybook.ext.nebula.connectcdk.com/?path=/docs/components-page-header-deprecated-page-header-deprecation-info--page for more details.'
    );
  }
  return (
    <PageHeaderWrapper {...other}>
      <PageHeaderTitle>{title}</PageHeaderTitle>
    </PageHeaderWrapper>
  );
};
PageHeaderTitleBlock.propTypes = {
  title: PropTypes.node.isRequired,
};

export { PageHeaderTitleBlock };
