import { useIntl } from 'react-intl';
import StyledStepFourTitle from './styled';
import { messages } from './messages';

const StepFourTitle = (): JSX.Element => {
  const intl = useIntl();
  return (
    <StyledStepFourTitle>
      <div data-testid="step-four-title">
        <h3>{intl.formatMessage(messages.stepFourScheduleTitle)}</h3>
        <p className="schedule-desc">
          {intl.formatMessage(messages.stepFourSelectDate)}
        </p>
      </div>
    </StyledStepFourTitle>
  );
};

export default StepFourTitle;
