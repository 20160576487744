import { defineMessages } from 'react-intl';

export const messages = defineMessages({
  dragTemplateToUpload: {
    id: 'CSVUSERIMPORT.STEP2.TEMPLATEUPLOAD.DRAGFILE',
    defaultMessage: 'Drag files here, or '
  },
  browseTemplate: {
    id: 'CSVUSERIMPORT.STEP2.TEMPLATEUPLOAD.BROWSE',
    defaultMessage: 'browse'
  }
});
