import styled from 'styled-components';

const StyledStepTwo = styled.div`
    width:100%;
    .main-heading{
        margin: 0;
    }
    
    .description{
        margin-top: 0.6rem;
        margin-bottom:1.5rem;
    }

    .custom-toast{
        width: 52%;
        margin-top: 1rem;
    }

    error-p {
        margin: 0;
    }
`;

export default StyledStepTwo;
