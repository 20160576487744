import { useIntl } from 'react-intl';
import { CELL_RENDERERS, CELL_CLASSES } from 'cdk-radial';
import moment from 'moment';
import { messages } from './messages';

type CustomDateRenderProps = {
  data: {
    createdDate: string;
  };
};
const useColumns = (): Array<any> => {
  const intl = useIntl();
  const columns = [
    {
      cellClass: CELL_CLASSES.CHECKBOX,
      checkboxSelection: true,
      field: 'check',
      filter: false,
      headerCheckboxSelection: true,
      headerCheckboxSelectionFilteredOnly: true,
      headerClass: [CELL_CLASSES.CHECKBOX, CELL_CLASSES.SELECT_ALL],
      headerName: '',
      maxWidth: 55,
      minWidth: 55,
      resizable: false
    },
    {
      cellRenderer: CELL_RENDERERS.TEXT,
      field: 'code',
      headerName: intl.formatMessage(messages.roleName),
      minWidth: 150,
      cellStyle: { 'white-space': 'normal', 'line-height': '25px' },
      wrapText: true,
      sortable: true
    },
    {
      cellRenderer: CELL_RENDERERS.TEXT,
      field: 'userCount',
      headerName: intl.formatMessage(messages.userCount),
      minWidth: 150,
      sortable: true
    },
    {
      cellRenderer: (data: CustomDateRenderProps): string =>
        `${moment(data.data.createdDate).format('DD/MM/YYYY')}`,
      field: 'createdDate',
      headerName: intl.formatMessage(messages.dateCreated),
      minWidth: 150,
      sortable: true
    },
    {
      cellRenderer: CELL_RENDERERS.TEXT,
      field: 'createdBy',
      headerName: intl.formatMessage(messages.createdBy),
      minWidth: 150,
      cellStyle: { 'white-space': 'normal', 'line-height': '25px' },
      wrapText: true,
      sortable: true
    }
  ];
  return [columns];
};

export default useColumns;
