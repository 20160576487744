import React from 'react';

import { BUTTON_BACKGROUND_APPEARANCES, TextButton } from 'lib/button';
import { focusOutlinesStyles } from 'lib/core';
import { IconChevronRight } from 'lib/icons';
import PropTypes from 'prop-types';
import styled, { css } from 'styled-components';

import { getRem } from './../../core';
import { footerButtonStyles } from '../shared-styles';

const StyledToggleButton = styled(TextButton)`
  ${footerButtonStyles};
  background-color: ${({ theme }) => theme.color.navigationContextSwitcher.enabled.value};
  margin-left: auto;
  margin-top: auto;
  width: ${getRem(36)};
  ${({ isCollapsed }) =>
    !isCollapsed &&
    css`
      svg {
        transform: rotate(180deg);
      }
    `}

  &&& {
    :focus-visible {
      ${focusOutlinesStyles.dark};
    }
  }
`;

const ToggleButton = ({
  collapsedToggleLabel = 'Expand navigation',
  expandedToggleLabel = 'Collapse navigation',
  isCollapsed = false,
  onCollapse = undefined,
  onExpand = undefined,
  ...other
}) => {
  const buttonText = isCollapsed ? collapsedToggleLabel : expandedToggleLabel;
  const handleToggle = isCollapsed ? onExpand : onCollapse;

  return (
    <StyledToggleButton
      backgroundAppearance={BUTTON_BACKGROUND_APPEARANCES.DARK}
      hideText
      icon={<IconChevronRight />}
      isCollapsed={isCollapsed}
      onClick={handleToggle}
      text={buttonText}
      {...other}
    />
  );
};

ToggleButton.propTypes = {
  /** Text which is shown for screen readers when navigation is collapsed */
  collapsedToggleLabel: PropTypes.node,
  /** Text which is shown for screen readers when navigation is expanded */
  expandedToggleLabel: PropTypes.node,
  /** If true, navigation is collapsed */
  isCollapsed: PropTypes.bool,
  /** Callback function which is called when navigation is being collapsed */
  onCollapse: PropTypes.func,
  /** Callback function which is called when navigation is being expanded */
  onExpand: PropTypes.func,
};

export { ToggleButton };
