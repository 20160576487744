import styled from 'styled-components';

const StyledStepOneTitle = styled.div`
.subTitle {
    width: 50rem;
    height: 2.8rem;
    font-size: 14px;
    font-weight: 400;
    line-height: 1.4rem; 
}
`;

export default StyledStepOneTitle;
