import React from 'react';
// import { getRem } from 'lib/core';
import PropTypes from 'prop-types';
import styled, { css } from 'styled-components';

const StyledSimpleTableWrapper = styled.div`
  ${({ theme }) => css`
    box-sizing: border-box;
    color: ${({ theme }) => theme.color.text.light.primary.value};
    width: 100%;
    overflow: auto;
  `};
`;

const SimpleTableWrapper = ({ children, className, ...other }) => (
  <StyledSimpleTableWrapper {...{ children, className }} {...other} />
);

SimpleTableWrapper.propTypes = {
  /** Displays any kind of content included between opening and closing tags  */
  children: PropTypes.node.isRequired,
  /** Adds additional className prop to override styling */
  className: PropTypes.string,
};

export { SimpleTableWrapper };
