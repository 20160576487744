import { IconAdmin, withTooltip, THEMES } from 'cdk-radial';
import { useIntl } from 'react-intl';
import { useState } from 'react';
import { messages } from './messages';
import {
  StyledButton,
  StyledDiv,
  StyledEnterpriseAdminIconContainer
} from './styled';
import EnterpriseAdminsDialog from './components/EnterpriseAdminsDialog';
import { EnterpriseAdminProps } from './types';

const EnterpriseAdmin = ({
  isCollapsed
}: EnterpriseAdminProps): JSX.Element => {
  const intl = useIntl();
  const saveButton = intl.formatMessage(messages.dealerAdminInfo);
  const [openDialog, setOpenDialog] = useState(false);
  const handleButtonClick = () => {
    setOpenDialog(true);
  };

  const HelpToolTipProps = {
    id: 'tooltip-id-1',
    text: intl.formatMessage(messages.dealerAdminInfo),
    theme: THEMES.DARK,
    delay: 100
  };

  const ToolTipDealerAdminButton = (props: any) => (
    <StyledButton
      data-testid="enterpise-admin-button"
      icon={<IconAdmin />}
      text={saveButton}
      onClick={handleButtonClick}
      variant="secondary"
      // eslint-disable-next-line react/jsx-props-no-spreading
      {...props}
    />
  );

  // eslint-disable-next-line @typescript-eslint/no-unsafe-call
  const ToolTipDealerAdminButtonWrap = withTooltip(
    ToolTipDealerAdminButton,
    HelpToolTipProps
  );

  return (
    <>
      {isCollapsed ? (
        <StyledEnterpriseAdminIconContainer>
          <IconAdmin onClick={handleButtonClick} iconColor="gray" />
        </StyledEnterpriseAdminIconContainer>
      ) : (
        <StyledDiv>
          <ToolTipDealerAdminButtonWrap />
        </StyledDiv>
      )}
      {openDialog && (
        <EnterpriseAdminsDialog
          handleClose={() => {
            setOpenDialog(false);
          }}
        />
      )}
    </>
  );
};

export default EnterpriseAdmin;
