/* eslint-disable array-callback-return */
/* eslint-disable @typescript-eslint/no-unsafe-call */
/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import { includes } from 'lodash';
import {
  StorePermissionsType,
  TransformedUserPermissions,
  ManageEnterprise
} from './types/storePermissionType';

const getPermissions = (
  permissionsData: TransformedUserPermissions | undefined
) => {
  let permission = {};
  if (permissionsData) {
    const permissionsAssignedToUser =
      permissionsData?.allPermissionsAssigedToUser;

    const hasPermission = (code: string) =>
      includes(permissionsAssignedToUser, code);

    const enterprisePermission: unknown = permissionsData?.MNGENTERPISESPERM;
    const updateStorePermission =
      enterprisePermission && hasPermission('UPDATESTORE');

    const updateIdentifiersPermission: unknown =
      hasPermission('MNGSTRALTIDENTIFIERSPERM') &&
      hasPermission('UPDATESTRALTIDENTIFIERS');

    const storeMFAPersmission: boolean =
      hasPermission('MNGINDIVIDUALSTOREMFA') && hasPermission('UPDATESTOREMFA');

    const manageUsersPersmission: unknown = hasPermission('MNGUSR');
    const manageEmailNotifyPersmission: unknown = hasPermission(
      'VIEWEMAILSENTSTATUS'
    );
    const manageEmailResendPersmission: unknown = hasPermission('RESENDEMAIL');
    const manageDownloadUserList: unknown = hasPermission(
      'EXPORTACTIVITYHISTORY'
    );

    const dmsUserLinkPermission: unknown = hasPermission('DMSUSRLNK');
    const userDeletePermission: unknown =
      manageUsersPersmission && hasPermission('DELUSER');
    const userCopyPermissios: unknown =
      manageUsersPersmission && hasPermission('EDITPROFILE');
    const userViewPermissios: unknown =
      manageUsersPersmission && hasPermission('VIEWPROFILE');
    const dmsAccessPermission: unknown = hasPermission('DMSACCESS');
    const dmsCopyUserPermission = hasPermission('DMS_COPY_USER');
    const userLinkUserIdPermission: unknown = hasPermission('LNKUSERALTID');
    const userEditProfilePermission: unknown = hasPermission('EDITPROFILE');
    const userResetPasswordPermission: unknown = hasPermission('RESETPASSWORD');
    const mergeUserPermission: unknown = hasPermission('MRGUSR');
    const createRolePermission: unknown = hasPermission('MNGROL');
    const viewAllRolesPermission: unknown = hasPermission('CFGRLDTL');
    const updateRolesPermission: unknown = hasPermission('CFGROL');

    const assignStoreDepartmentPermission: boolean =
      hasPermission('STOREDEPARTMENTASSOCIATIONPERM') &&
      hasPermission('SDASSIGN');

    const unAssignStoreDepartmentPermission: boolean =
      hasPermission('STOREDEPARTMENTASSOCIATIONPERM') &&
      hasPermission('SDUNASSIGN');

    const hasServiceUsersPermission: unknown =
      permissionsData?.MNGSVRCUSER || permissionsData?.VIEWSVRCUSER;

    const updateDeptIdentifiersPermission: boolean = hasPermission(
      'MNGDEPTALTIDENTIFIERSPERM'
    );

    const hasUpdateDeptPermission: unknown = hasPermission('MNGENTERPISESPERM');

    const packageVisibilityPermission: boolean =
      hasPermission('VIEWPCKGINSTNC');

    const manageHirachyPermission: boolean = hasPermission(
      'MNGENTHIERARCHYPERM'
    );

    let viewNewGroupButtonPermission = false;

    const manageEnterpriseHirarchy: any | boolean =
      (manageHirachyPermission &&
        permissionsData?.MNGENTHIERARCHYPERM?.rules) ||
      false;

    if (!manageEnterpriseHirarchy) {
      viewNewGroupButtonPermission = false;
    } else {
      manageEnterpriseHirarchy?.map((a: ManageEnterprise) => {
        if (a.resource.code === 'CREATEENTHIERARCHY') {
          viewNewGroupButtonPermission = true;
        }
      });
    }

    const manageEnterPrisePermission = hasPermission('MNGENTERPRISEPERM');
    const bulkUnassignUsersPermission = hasPermission('BULKUSERUNASSIGNMENT');

    const enterpriseAdminVisibilityPermission =
      manageEnterPrisePermission && hasPermission('READSTORES');

    permission = {
      isEnterprisePermission: enterprisePermission,
      isUserDeletePermission: userDeletePermission,
      isUserCopyPermission: userCopyPermissios,
      isUserViewPermission: userViewPermissios,
      isDMSUserLinkHasPermission: dmsUserLinkPermission,
      storeUpdatePermission: updateStorePermission,
      indentifierPermission: updateIdentifiersPermission,
      storeMFA: storeMFAPersmission,
      manageUsers: manageUsersPersmission,
      assignDepartmentsPermission: assignStoreDepartmentPermission,
      unAssignDepartmentsPermission: unAssignStoreDepartmentPermission,
      updateDeptIdentifiersPermission,
      hasUpdateDeptPermission,
      manageEmailNotify: manageEmailNotifyPersmission,
      isEmailResend: manageEmailResendPersmission,
      isUserListDownload: manageDownloadUserList,
      isDMSAccessPermission: dmsAccessPermission,
      hasDMSCopyPermisssion: dmsCopyUserPermission,
      isUserLInkIDPermission: userLinkUserIdPermission,
      isUserEditablePermission: userEditProfilePermission,
      isUserHasResetPasswordPermission: userResetPasswordPermission,
      isMergeUSersPermission: mergeUserPermission,
      packageVisibilityPermission,
      isCreateRolePermission: createRolePermission,
      isAllRolesPageViewPermission: viewAllRolesPermission,
      isUpdateRolesPermission: updateRolesPermission,
      hasAddGroupingPermission: viewNewGroupButtonPermission,
      hasGroupPermission: manageHirachyPermission,
      isEnterpriseAdmin: enterpriseAdminVisibilityPermission,
      isServiceUsersPermission: hasServiceUsersPermission,
      hasBulkUnassignUsersPermission: bulkUnassignUsersPermission
    };
  }
  return permission as StorePermissionsType;
};

export const getStorePermission = (
  permissions: TransformedUserPermissions,
  storeId: string
) => {
  const { allPermissionsAssigedToUser } = permissions;
  const manageEnterPrisePermission = includes(
    allPermissionsAssigedToUser,
    'MNGENTERPRISEPERM'
  );
  const readStorespermission = includes(
    allPermissionsAssigedToUser,
    'READSTORES'
  );
  const stores = permissions?.MNGENTERPRISEPERM?.storeIds as string[];

  const isStoreHasReadStorespermission = includes(stores, storeId);

  const isEnterpriseAdmin =
    manageEnterPrisePermission &&
    readStorespermission &&
    isStoreHasReadStorespermission;

  return {
    isEnterpriseAdmin
  };
};

export default getPermissions;
