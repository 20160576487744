import { defineMessages } from 'react-intl';

export const messages = defineMessages({
  permissionErrorMessage: {
    id: 'GLOBAL.PERMISSION.ERROR.MESSAGE',
    defaultMessage:
      'You do not have the required permission to perform this action.Please request for the required permission from your administrator.'
  },

  unknownErrorMessage: {
    id: 'GLOBAL.UNKNOWN.ERROR.MESSAGE',
    defaultMessage: 'Unknown error occurred. Please try again in sometime'
  },

  errorMessage: {
    id: 'GLOBAL.ERROR.MESSAGE.OCCURED',
    defaultMessage: '{errMsg}'
  },
  loading: {
    id: 'GLOBAL.LOADING.MESSAGE',
    defaultMessage: 'Loading...'
  },
  successfulMessage: {
    id: 'GLOBAL.SUCCESS.MESSAGE.OCCURED',
    defaultMessage: '{successMessage}'
  },
  defaultSuccessfulMessage: {
    id: 'GLOBAL.DEFAULT.SUCCESS.MESSAGE',
    defaultMessage: 'Successful'
  },
  cancel: {
    id: 'GLOBAL.CANCEL',
    defaultMessage: 'Cancel'
  },
  saveChanges: {
    id: 'GLOBAL.BUTTON.TITLE.SAVECHANGES',
    defaultMessage: 'Save Changes'
  },
  confirmDailogDescription: {
    id: 'GLOBAL.WARNING.DAILOG.DESCRIPTION',
    defaultMessage: `Are you sure you want to cancel?`
  },
  sessionTimeoutWarningMessage: {
    id: 'GLOBAL.SESSIONTIMEOUT.MESSAGE',
    defaultMessage: `You’re being timed out due to inactivity. Please choose to stay signed in or to log out. Otherwise, you will be logged out automatically.`
  },
  sessionTimeoutWarningTitle: {
    id: 'GLOBAL.SESSIONTIMEOUT.TITLE',
    defaultMessage: `Session Timeout`
  },
  staySignedInButton: {
    id: 'GLOBAL.BUTTON.TEXT',
    defaultMessage: 'Stay Logged In ({timeLeft})'
  },
  save: {
    id: 'GLOBAL.SAVE',
    defaultMessage: 'Save'
  },
  delete: {
    id: 'GLOBAL.DELETE',
    defaultMessage: 'Delete'
  },
  logout: {
    id: 'GLOBAL.BUTTON.LOGOUT.LABEL',
    defaultMessage: `Log out`
  },
  goBack: {
    id: 'GLOBAL.GOBACK',
    defaultMessage: 'Go Back'
  },
  copyUser: {
    id: 'GLOBAL.COPY.USER',
    defaultMessage: 'Copy User'
  }
});
