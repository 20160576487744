import { useHistory } from 'react-router-dom';
import { Security } from '@okta/okta-react';
import { OktaAuth, toRelativeUrl } from '@okta/okta-auth-js';

export const oktaAuth = new OktaAuth({
  clientId: process.env.REACT_APP_SSO_CLIENT_ID,
  issuer: process.env.REACT_APP_SSO_ISSUER,
  authorizeUrl: process.env.REACT_APP_SSO_AUTHORIZE_URL,
  redirectUri: process.env.REACT_APP_SSO_REDIRECT_URI,
  tokenUrl: process.env.REACT_APP_SSO_TOKEN_URL,
  scopes: ['openid', 'profile', 'email'],
  pkce: true
});

export const SecurityProvider = ({
  children
}: {
  children: React.ReactNode;
}): JSX.Element => {
  const history = useHistory();

  const restoreOriginalUri = (_oktaAuth: OktaAuth, originalUri: string) => {
    if (history) {
      history?.replace(
        toRelativeUrl(originalUri || '/', window.location.origin)
      );
    } else {
      window.history.pushState({}, '', `${originalUri}`);
      window.history.go();
    }
  };

  return (
    <Security oktaAuth={oktaAuth} restoreOriginalUri={restoreOriginalUri}>
      {children}
    </Security>
  );
};

export default SecurityProvider;
