/* eslint-disable @typescript-eslint/no-unsafe-member-access */
/* eslint-disable @typescript-eslint/no-unsafe-assignment */
/* eslint-disable @typescript-eslint/no-unsafe-call,
  @typescript-eslint/no-unsafe-return */
import { GridApi, RowNode } from 'ag-grid-community';
import { TOAST_VARIANTS } from 'cdk-radial';
import ErrorToast from 'components/ui/ErrorToast';
import useColumns from 'fixtures/grid/EditImportJobStpeThree/columnDefinition';
import schema from 'fixtures/grid/ReviewImport/fitlerSchema';
import {
  generateFilterSelectionObject,
  getEmptyTableMessage
} from 'helpers/tableHelper';
import moment from 'moment';
import {
  MutableRefObject,
  useCallback,
  useEffect,
  useRef,
  useState
} from 'react';
import { useIntl } from 'react-intl';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import { UserRole, UserStore } from 'redux/services/admin-service/types';
import { useGetImportJobDetailsByJobIdQuery } from 'redux/services/user-migration-service';
import { setGlobalToast } from 'redux/slices/commonSlice';
import {
  handleDataRemoval,
  setContentHeader,
  setImportDate,
  setImportTime,
  setJobName,
  setSelectedRows,
  setTemplatDataRowField,
  setTemplatDataRows,
  setTemplateFieldExpansion,
  setTemplateRolesAndStores,
  setTemplateRowHeight
} from 'redux/slices/userImportSlice';
import { RootState } from 'redux/store';
import { handleDownloadClick } from 'utils/utility';
import { useClient } from 'providers/ClientProvider';
import FilterHeader from '../../../templates/TableLayout/FilterHeader/FilterHeader';
import TableContainer from '../../../templates/TableLayout/TableContainer/TableContainer';
import TableContent from '../../../templates/TableLayout/TableContent/TableContent';
import TableHeader from '../../../templates/TableLayout/TableHeader/TableHeader';
import TablePaging from '../../../templates/TableLayout/TablePaging/TablePaging';
import ReviewImportHeader from '../../ReviewImports/ReviewImportHeader/ReviewImportHeader';
import AssignRolesDialog from '../../common/AssignRolesModal/AssignRolesDialog';
import AssignStoresDialog from '../../common/AssignStoresModal/AssignStoresDialog';
import { messages } from './messages';
import { StyledStepThree, StyledStepThreeFilterContainer } from './styled';

type SelectedRowsType = {
  id: string;
};

type RowHeightType = {
  data: {
    rowHeight: number;
  };
};

const StepThree = (): JSX.Element => {
  const intl = useIntl();
  const [columns] = useColumns();
  const [searchValue, setSearchValue] = useState('');
  const [gridApi, setGridApi] = useState<GridApi>();
  const [currentPage, setCurrentPage] = useState(0);
  const [totalPages, setTotalPages] = useState(0);
  const [totalRows, setTotalRows] = useState(0);
  const [isAssignRolesDialogOpen, setIsAssignRolesDialogOpen] =
    useState<boolean>(false);
  const [isAssignStoresDialogOpen, setIsAssignStoresDialogOpen] =
    useState<boolean>(false);

  const displayedRowCount =
    gridApi && gridApi.getDisplayedRowCount
      ? gridApi.getDisplayedRowCount()
      : 0;
  const dispatch = useDispatch();

  const { selectedRows } = useSelector(
    (state: RootState) => state.slices.userImportState
  );

  const containerRef = useRef() as MutableRefObject<HTMLDivElement>;
  const paginationHeight = 147;

  const client = useClient();
  const { userInfo } = client;

  const handleFieldChange = (
    rowIndex: number,
    field: string,
    value: UserRole[] | UserStore[]
  ) => {
    dispatch(setTemplatDataRowField({ rowIndex, field, value }));
  };
  const { pathname } = useLocation();
  const jobType = pathname.split('/')[5];
  const jobId = pathname.split('/').pop() || '';
  const {
    data: scheduleJobEdit,
    isSuccess: isscheduleJobLoadSuccess,
    isLoading: isscheduleJobEditLoading,
    isError: isscheduleJobLoadError,
    error: scheduleJobLoadError
  } = useGetImportJobDetailsByJobIdQuery(
    {
      jobId,
      enterpriseId: userInfo?.fullUser.enterprise.id || ''
    },
    { skip: !jobId || jobType !== 'dms' }
  );
  const rowData = selectedRows || [];
  useEffect(() => {
    if (gridApi && selectedRows) {
      gridApi.setRowData(selectedRows);
      gridApi.resetRowHeights();
    }
  }, [selectedRows, gridApi]);
  useEffect(() => {
    if (isscheduleJobLoadSuccess && scheduleJobEdit && !selectedRows) {
      const scheduledJobDMSUsersDetails = scheduleJobEdit.users;

      dispatch(setContentHeader(scheduleJobEdit?.jobDataInfo.scheduleJobName));
      dispatch(setJobName(scheduleJobEdit?.jobDataInfo.scheduleJobName));
      dispatch(
        setImportDate(moment(scheduleJobEdit?.jobDataInfo.scheduleDate))
      );

      const importTime = moment(
        scheduleJobEdit?.jobDataInfo.scheduleDate
      ).format('hh:mm A');
      dispatch(setImportTime({ label: importTime, value: importTime }));
      dispatch(setSelectedRows(scheduledJobDMSUsersDetails));
    }
  }, [scheduleJobEdit, isscheduleJobLoadSuccess, dispatch, selectedRows]);
  const [filterSelections, setFilterSelections] = useState<
    Record<string, Array<string | undefined>>
  >(generateFilterSelectionObject(schema));

  const minHeight = 80;
  const getRowHeight = useCallback(
    (params: RowHeightType) =>
      params.data.rowHeight > minHeight ? params.data.rowHeight : minHeight,
    []
  );

  const onRemoveFromImportList = () => {
    const selectedRemRows = gridApi?.getSelectedRows() as SelectedRowsType[];
    const selectedRowIndices: Record<string, boolean> = selectedRemRows?.reduce(
      (acc, curr) => ({ ...acc, [curr.id]: true }),
      {}
    );

    const filteredRows = rowData.filter(({ id }) => !selectedRowIndices[id]);

    dispatch(setTemplatDataRows(filteredRows));
  };
  const getRowNodeId = (row: Record<string, string>) => row.id;

  const onAssignRolesClick = () => {
    setIsAssignRolesDialogOpen(!isAssignRolesDialogOpen);
  };
  const onAssignStoresClick = () => {
    setIsAssignStoresDialogOpen(!isAssignStoresDialogOpen);
  };

  const handleAssignment = (
    selectedItems: UserRole[] | UserStore[],
    field: string,
    keyToAccess: string
  ) => {
    if (selectedRows && gridApi && selectedItems?.length) {
      const selectedNodes: RowNode[] = gridApi.getSelectedNodes();
      gridApi.getSelectedNodes().forEach((row: RowNode) => {
        const { rowIndex } = row;
        dispatch(
          setTemplateRolesAndStores({
            rowIndex,
            field,
            value: selectedItems,
            keyToAccess
          })
        );
      });
      const rolesText: string[] = selectedItems.map(
        i => i.code || i.id
      ) as string[];
      const selectedNodesLength: number = selectedNodes.length;
      dispatch(
        setGlobalToast({
          content: selectedNodesLength
            ? intl.formatMessage(messages.assignUserSuccess, {
                roles: rolesText.toString(),
                selectedUserCount: selectedNodesLength
              })
            : intl.formatMessage(messages.assignUserWarning),
          variant: selectedNodesLength
            ? TOAST_VARIANTS.POSITIVE
            : TOAST_VARIANTS.NEGATIVE
        })
      );
    }
  };

  const setRowFieldExpansion = (
    rowIndex: number,
    field: string,
    isCollapsed: boolean
  ) => {
    dispatch(
      setTemplateFieldExpansion({ rowIndex, field, value: isCollapsed })
    );
  };

  const setRowHeight = (rowIndex: number, rowHeight: number, field: string) => {
    dispatch(setTemplateRowHeight({ rowIndex, rowHeight, field }));
  };

  const handleFieldRemoval = (
    fieldIndex: number,
    rowIndex: number,
    field: string
  ) => {
    dispatch(handleDataRemoval({ fieldIndex, rowIndex, field }));
  };

  return (
    <>
      {isscheduleJobLoadError && (
        <ErrorToast dataError={scheduleJobLoadError} />
      )}

      {userInfo?.fullUser && (
        <StyledStepThree ref={containerRef}>
          <TableContainer showBorder>
            <TableHeader
              isDownloadable
              disableDownloadButton={displayedRowCount === 0}
              onDownloadClick={() => handleDownloadClick(gridApi)}
              headerTitle={intl.formatMessage(messages.tableHeader)}
            />
            <StyledStepThreeFilterContainer>
              <FilterHeader
                filterSchema={schema}
                searchValue={searchValue}
                setSearchValue={setSearchValue}
                filterSelections={filterSelections}
                setFilterSelections={setFilterSelections}
              />
              <ReviewImportHeader
                data-testid="review-header"
                onRemoveFromImportList={onRemoveFromImportList}
                onAssignRoles={onAssignRolesClick}
                onAssignStores={onAssignStoresClick}
              />
            </StyledStepThreeFilterContainer>
            <TableContent
              gridApi={gridApi}
              searchValue={searchValue}
              tableHeight={
                containerRef &&
                containerRef.current &&
                containerRef.current.clientHeight
                  ? containerRef.current.clientHeight - paginationHeight
                  : 300
              }
              filterSelections={filterSelections}
              setGridApi={setGridApi}
              setCurrentPage={setCurrentPage}
              setTotalPages={setTotalPages}
              setTotalRows={setTotalRows}
              immutableData
              loading={isscheduleJobEditLoading}
              columns={columns}
              getRowNodeId={getRowNodeId}
              getRowHeight={getRowHeight}
              context={{
                handleFieldChange,
                setRowFieldExpansion,
                setRowHeight,
                handleFieldRemoval
              }}
              filterSchema={schema}
              overlayNoRowsTemplateMessage={getEmptyTableMessage(
                searchValue,
                intl
              )}
            />
            <TablePaging
              gridApi={gridApi}
              currentPage={currentPage}
              totalPages={totalPages}
              totalRows={totalRows}
            />
          </TableContainer>
          {isAssignRolesDialogOpen && (
            <AssignRolesDialog
              isAssignRolesDialogOpen={isAssignRolesDialogOpen}
              closeAssignRolesDialog={() => setIsAssignRolesDialogOpen(false)}
              context={{
                handleAssignRoles: (selectedRoles: UserRole[]) =>
                  handleAssignment(selectedRoles, 'roles', 'code')
              }}
            />
          )}

          {isAssignStoresDialogOpen && (
            <AssignStoresDialog
              isAssignStoresDialogOpen={isAssignStoresDialogOpen}
              closeAssignStoresDialog={() => setIsAssignStoresDialogOpen(false)}
              context={{
                handleAssignStores: (selectedStores: UserStore[]) =>
                  handleAssignment(selectedStores, 'stores', 'id')
              }}
            />
          )}
        </StyledStepThree>
      )}
    </>
  );
};

export default StepThree;
