import { FetchBaseQueryError } from '@reduxjs/toolkit/query';
import { TOAST_VARIANTS } from 'cdk-radial';
import { setGlobalToast } from 'redux/slices/commonSlice';

export function isFetchBaseQueryError(
  error: unknown
): error is FetchBaseQueryError {
  return typeof error === 'object' && error != null && 'status' in error;
}

export function isErrorWithMessage(
  error: unknown
): error is { message: string } {
  return (
    typeof error === 'object' &&
    error != null &&
    'message' in error &&
    typeof (error as any).message === 'string'
  );
}

export const displayErrorToast = (
  content: string | JSX.Element,
  dispatch: (action: any) => void
): void => {
  const variant = TOAST_VARIANTS.NEGATIVE;
  dispatch(
    setGlobalToast({
      content,
      variant
    })
  );
};
