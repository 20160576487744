import styled from 'styled-components';

const StyledStepOneRoles = styled.div`
  .rolesDesc {
    width: 45rem;
    font-size: 14px;
    font-weight: 400;
    line-height: 1.4rem;
  }
`;

export default StyledStepOneRoles;
