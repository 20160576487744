import { useEffect, useRef } from 'react';
import { useIntl } from 'react-intl';
import { useOktaAuth } from '@okta/okta-react';
import { BUTTON_VARIANTS } from 'cdk-radial';
import { formatTime } from 'utils/utility';
import { messages as commonMessages } from 'commonMessages';
import { StyledSessionTimeoutDialog } from './styled';
import { ExtendedSessionObject, SessionTimeoutDialogProps } from './types';

export const SessionTimeoutDialog = ({
  title,
  description,
  countdownDuration,
  primaryButtonText,
  secondaryButtonText,
  handleStayIn,
  handleLogout,
  hideCloseButton
}: SessionTimeoutDialogProps): JSX.Element => {
  const intl = useIntl();
  const countdownRef = useRef(countdownDuration);
  const buttonText = useRef(`${secondaryButtonText}`);
  const oktaHook = useOktaAuth();

  const BUTTON_PROPS = [
    {
      id: 'dialog-2-action-1',
      onClick: handleLogout,
      text: primaryButtonText,
      variant: 'text'
    },
    {
      id: 'dialog-2-action-2',
      onClick: handleStayIn,
      text: buttonText.current,
      variant: BUTTON_VARIANTS.PRIMARY
    }
  ];

  const updateCountdown = async () => {
    const button = document.getElementById('dialog-2-action-2');
    if (button && oktaHook.authState?.isAuthenticated) {
      try {
        const session =
          (await oktaHook.oktaAuth.session.get()) as ExtendedSessionObject;
        const currentTime = Math.floor(Date.now() / 1000);

        const sessionExpiresAt = session?.expiresAt
          ? new Date(session.expiresAt).getTime() / 1000
          : 0;
        const timeLeft = sessionExpiresAt - currentTime;

        if (timeLeft > 0 && timeLeft <= countdownDuration) {
          countdownRef.current = timeLeft;
        }
      } catch (error) {
        console.error('Error fetching session info:', error);
      }
      const buttonLabel = intl.formatMessage(
        commonMessages.staySignedInButton,
        {
          timeLeft: formatTime(countdownRef.current)
        }
      );
      button.textContent = buttonLabel;
    }
  };

  useEffect(() => {
    const countdownTimer = setInterval(() => {
      countdownRef.current -= 1;
      updateCountdown();

      if (countdownRef.current <= 0) {
        clearInterval(countdownTimer);
        handleLogout();
      }
    }, 1000);

    return () => {
      if (countdownTimer) clearInterval(countdownTimer);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <StyledSessionTimeoutDialog
      dataTestId="session-timeout-dialog"
      disableOverlayClicked={false}
      id="session-timeout-dialog"
      isOpen
      onClose={handleLogout}
      title={title}
      buttonsProps={BUTTON_PROPS}
      hideCloseButton={hideCloseButton}
    >
      {description}
    </StyledSessionTimeoutDialog>
  );
};
