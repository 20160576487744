import { useEffect } from 'react';

const useAccessTokenValidator = (): void => {
  const isTokenExpired = (expirationTime: number): boolean => {
    const currentTime = Math.floor(Date.now() / 1000);
    return currentTime > expirationTime;
  };

  useEffect(() => {
    const unparsedObject = localStorage.getItem('okta-token-storage');
    const tokenExpiry =
      unparsedObject && JSON.parse(unparsedObject)?.accessToken?.expiresAt;
    if (tokenExpiry && isTokenExpired(tokenExpiry)) {
      localStorage.clear();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
};

export default useAccessTokenValidator;
