export const INPUT_SIZES = {
  LARGE: 'large',
  SMALL: 'small',
  STANDARD: 'standard',
};

export const INPUT_ICON_POSITIONS = {
  LEADING: 'leading',
  TRAILING: 'trailing',
  LEADING_AND_TRAILING: 'leading_and_trailing',
};

export const INPUT_TEXT_POSITIONS = {
  LEFT: 'left',
  CENTER: 'center',
  JUSTIFY: 'justify',
  RIGHT: 'right',
};

export const BORDER_POSITIONS = {
  LEFT: 'left',
  NONE: 'none',
  RIGHT: 'right',
};

export const DIVIDER_POSITIONS = {
  LEFT: 'left',
  NONE: 'none',
  RIGHT: 'right',
};

export const PREFIX_MAX_CHARACTERS_ALLOWED = 1;

export const SUFFIX_MAX_CHARACTERS_ALLOWED = 5;

const INPUT_ICON_SIZE_IN_PIXELS = 24;

export const INPUT_PADDING_WITH_SUFFIX_OR_PREFIX_IN_PIXELS = INPUT_ICON_SIZE_IN_PIXELS + 20;
export const INLINE_INPUT_PADDING_WITH_SUFFIX_OR_PREFIX_IN_PIXELS = 34;

export const INLINE_INPUT_LABEL_MAX_WIDTH_DEFAULT = '100px';
