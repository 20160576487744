/* eslint-disable react/jsx-props-no-spreading */
import { useOktaAuth } from '@okta/okta-react';
import { Switch } from 'cdk-radial';
import { useIntl } from 'react-intl';
import { useAnalytics } from 'dms-analytics';
import { AMP_EVENT_TYPES } from 'constants/index';
import { OldNewAppSwitcherProps } from 'components/molecules/common/Header/ShowCounter/type';
import { messages } from '../messages';
import {
  StyledModernAppSwitcherContainer,
  StyledSwitchToggleContainer,
  StyledSwitchWrapper
} from '../styled';

const OldNewCAMSwitcher = ({
  userEnterpriseId
}: OldNewAppSwitcherProps): JSX.Element => {
  const intl = useIntl();
  const { authState } = useOktaAuth();
  const { trackEvent } = useAnalytics();

  const hasLoggedInAsCDKUser = (): boolean => {
    const loggedInUser =
      (authState?.accessToken?.claims.cdksid as string) || '';
    return loggedInUser?.toLowerCase().includes('cdk.com');
  };

  const handleTrackEvent = (
    event: string,
    properties: Record<string, unknown>
  ) => {
    if (!hasLoggedInAsCDKUser()) {
      trackEvent(event, properties);
    }
  };

  const handleSwitchClick = () => {
    const oldAppUrl = process.env.REACT_APP_OLD_APP_URL;
    if (oldAppUrl && userEnterpriseId) {
      // launchmode=navigate-back is used in deleting sessionStorage in old app
      const redirectUrl = `${oldAppUrl}/enterprise/${userEnterpriseId}?launchmode=navigate-back`;
      window.open(redirectUrl, '_self');
      handleTrackEvent(AMP_EVENT_TYPES.SELECT_INTERFACE, {
        'Legacy Or Modern': 'Legacy'
      });
      localStorage.setItem('modernCAMAmplitude', 'false');
    }
  };

  return (
    <StyledModernAppSwitcherContainer data-testid="Top-header-actions-container">
      <StyledSwitchWrapper>
        <Switch
          dataTestId="switch"
          id="new Common Admin Toggle"
          labelPosition="before"
          label={intl.formatMessage(messages.newCommonAdminHeader)}
          checked
          className="toggle-switch"
          name="new Common Admin Toggle"
          onChange={handleSwitchClick}
        />
      </StyledSwitchWrapper>
      <StyledSwitchToggleContainer>
        {intl.formatMessage(messages.appSwitcherToggleMessage)}
      </StyledSwitchToggleContainer>
    </StyledModernAppSwitcherContainer>
  );
};

export default OldNewCAMSwitcher;
