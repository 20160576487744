import { defineMessages } from 'react-intl';

export const messages = defineMessages({
  assignUserSuccess: {
    id: 'EDITUSERIMPORT.STEP3.ASSIGNUSER.SUCCESS',
    defaultMessage:
      '{roles} are assigned to {selectedUserCount} selected users.'
  },
  assignUserWarning: {
    id: 'EDITUSERIMPORT.STEP3.ASSIGNUSER.WARNING',
    defaultMessage: 'Please select the users before Assignment'
  },
  tableHeader: {
    id: 'EDIUSERIMPORT.STEP3.TABLE.HEADER',
    defaultMessage: 'Review Scheduled Job Details'
  }
});
