import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import { PageHeaderTitleButtons } from '../blocks';
import { SCREEN_SIZES } from '../constants';

const StyledPageHeaderContent = styled.div`
  background-color: ${({ theme }) => theme.color.additional.light.value};
  border-bottom: 1px solid ${({ theme }) => theme.color.gray[200].value};
  display: flex;
`;

const PageHeaderTitleWithButtons = ({ buttons, children, ...other }) => {
  if (process.env.NODE_ENV === 'development') {
    console.warn(
      'Radial has deprecated the Page Header Title with Buttons component, and it will be removed in v7. Developers will need to utilize the PageHeaderWrapper and place controls as needed.  Current Page Header components will have limited support through v6. Please visit https://svc-radial-storybook.ext.nebula.connectcdk.com/?path=/docs/components-page-header-deprecated-page-header-deprecation-info--page for more details.'
    );
  }
  return (
    <StyledPageHeaderContent {...other}>
      {children}
      <PageHeaderTitleButtons
        overflowMenuButtonProps={buttons.overflowMenu}
        primaryButtonProps={buttons.primary}
        secondaryButtonProps={buttons.secondary}
      />
    </StyledPageHeaderContent>
  );
};

PageHeaderTitleWithButtons.propTypes = {
  /** Overflow menu button properties  */
  buttons: PropTypes.shape({
    /** Overflow menu button properties  */
    overflowMenu: PropTypes.shape({
      /** Text for screen reader when context menu is being closed  */
      closeMenuText: PropTypes.node,
      /** Id value used for testing */
      dataTestId: PropTypes.string,
      /** Specifies default options */
      defaultOptions: PropTypes.arrayOf(
        PropTypes.shape({
          /** Specifies the URL of the option */
          href: PropTypes.string,
          /** Will render specified icon */
          icon: PropTypes.node,
          /** Specifies option id */
          id: PropTypes.string,
          /** If sets option state to disabled */
          isDisabled: PropTypes.bool,
          /** If sets option state to selected */
          isSelected: PropTypes.bool,
          /** Specifies the option label */
          label: PropTypes.node.isRequired,
          /** Callback to be called when option is clicked */
          onClick: PropTypes.func,
        })
      ),
      /** Specifies option properties */
      options: PropTypes.arrayOf(
        PropTypes.shape({
          /** Unique identifier for the button */
          id: PropTypes.string,
          /** Callback that is called on click */
          onClick: PropTypes.func,
          /** Will display text inside button */
          text: PropTypes.node.isRequired,
        })
      ).isRequired,
      /** Screen size for which the component has to render */
      screenSize: PropTypes.oneOf(Object.values(SCREEN_SIZES)),
      /** Text for screen reader when context menu is being opened  */
      showMenuText: PropTypes.node,
    }),
    /** Secondary Button */
    secondary: PropTypes.shape({
      /** Function to be when Secondary Button is clicked */
      onClick: PropTypes.func.isRequired,
      /** Secondary Button Text */
      text: PropTypes.node.isRequired,
    }),
    /** Primary Button */
    primary: PropTypes.shape({
      /** Function to be when Primary Button is clicked */
      onClick: PropTypes.func.isRequired,
      /** Primary Button Text */
      text: PropTypes.node.isRequired,
    }),
  }).isRequired,
  /** PageHeaderTitle content which gets wrapped inside PageHeaderTitleWithButtons */
  children: PropTypes.node.isRequired,
};

export { PageHeaderTitleWithButtons };
