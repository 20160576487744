import { defineMessages } from 'react-intl';

export const messages = defineMessages({
  dmsProfile: {
    id: 'GRID.NEWUSERIMPORT.STEP2.TABLE.FILTER.DMSPROFILE',
    defaultMessage: 'DMS Profile'
  },
  dmsAccounts: {
    id: 'GRID.NEWUSERIMPORT.STEP2.TABLE.FILTER.PROFILES',
    defaultMessage: 'DMS Account'
  },
  stores: {
    id: 'GRID.NEWUSERIMPORT.STEP2.TABLE.FILTER.STORES',
    defaultMessage: 'Stores'
  },
  cnumber: {
    id: 'GRID.NEWUSERIMPORT.STEP2.TABLE.FILTER.CNUMBER',
    defaultMessage: 'CNumber'
  },
  lastLogin: {
    id: 'GRID.NEWUSERIMPORT.STEP2.TABLE.FILTER.LASTLOGIN',
    defaultMessage: 'Last login'
  },
  currentWeek: {
    id: 'GRID.NEWUSERIMPORT.STEP2.TABLE.FILTER.CURRENTWEEK',
    defaultMessage: 'Current Week'
  },
  previousWeek: {
    id: 'GRID.NEWUSERIMPORT.STEP2.TABLE.FILTER.PREVIOUSWEEK',
    defaultMessage: 'Previous Week'
  },
  currentMonth: {
    id: 'GRID.NEWUSERIMPORT.STEP2.TABLE.FILTER.CURRENTMONTH',
    defaultMessage: 'Current Month'
  },
  previousMonth: {
    id: 'GRID.NEWUSERIMPORT.STEP2.TABLE.FILTER.PREVIOUSMONTH',
    defaultMessage: 'Previous Month'
  },
  past6Months: {
    id: 'GRID.NEWUSERIMPORT.STEP2.TABLE.FILTER.PAST6MONTHS',
    defaultMessage: 'Past 6 Months'
  },
  pastYear: {
    id: 'GRID.NEWUSERIMPORT.STEP2.TABLE.FILTER.PASTYEAR',
    defaultMessage: 'Past Year'
  },
  email: {
    id: 'GRID.NEWUSERIMPORT.STEP2.COLUMN.HEADER.EMAIL',
    defaultMessage: 'Email'
  },
  cNumber: {
    id: 'GRID.NEWUSERIMPORT.STEP2.COLUMN.HEADER.CNUMBER',
    defaultMessage: 'CNumber'
  },
  dmsJobProfile: {
    id: 'GRID.NEWUSERIMPORT.STEP2.COLUMN.HEADER.DMSJOBPROFILE',
    defaultMessage: 'DMS Job Profile'
  },
  dmsAccount: {
    id: 'GRID.NEWUSERIMPORT.STEP2.COLUMN.HEADER.DMSACCOUNT',
    defaultMessage: 'DMS Account'
  },
  lastLoginColumn: {
    id: 'GRID.NEWUSERIMPORT.STEP2.COLUMN.HEADER.LASTLOGIN',
    defaultMessage: 'Last login'
  },
  dmsUserId: {
    id: 'GRID.NEWUSERIMPORT.STEP2.COLUMN.HEADER.DMSUSERID',
    defaultMessage: 'DMS User ID'
  },
  lastName: {
    id: 'GRID.NEWUSERIMPORT.STEP2.COLUMN.HEADER.LASTNAME',
    defaultMessage: 'Last Name'
  },
  firstName: {
    id: 'GRID.NEWUSERIMPORT.STEP2.COLUMN.HEADER.FIRSTNAME',
    defaultMessage: 'First Name'
  }
});
