import { defineMessages } from 'react-intl';

export const messages = defineMessages({
  storeId: {
    id: 'GRID.COMMON.ASSIGNSTORES.COLUMN.DEFINITIONS.STOREID',
    defaultMessage: 'Store ID'
  },
  storeName: {
    id: 'GRID.COMMON.ASSIGNSTORES.DIALOG.HEADER.STORENAME',
    defaultMessage: 'Store Name'
  },
  physicalAddress: {
    id: 'GRID.COMMON.ASSIGNSTORES.COLUMN.DEFINITIONS.PHYSICALADDRESS',
    defaultMessage: 'Physical Address'
  },
  cmfNumber: {
    id: 'GRID.COMMON.ASSIGNSTORES.COLUMN.DEFINITIONS.CMFNUMBER',
    defaultMessage: 'CMF Number'
  },
  dmsAccounts: {
    id: 'GRID.COMMON.ASSIGNSTORES.COLUMN.DEFINITIONS.DMSACCOUNTS',
    defaultMessage: 'DMS Accounts'
  },
  storeStatus: {
    id: 'GRID.COMMON.ASSIGNSTORES.COLUMN.DEFINITIONS.STORESTATUS',
    defaultMessage: 'Store Status'
  },
  storeType: {
    id: 'GRID.COMMON.ASSIGNSTORES.COLUMN.DEFINITIONS.STORETYPE',
    defaultMessage: 'Store Type'
  },
  storeVisibility: {
    id: 'GRID.COMMON.ASSIGNSTORES.COLUMN.DEFINITIONS.VISIBILITY',
    defaultMessage: 'Visible'
  },
  yesVisibilityStatus: {
    id: 'USERMANAGEMENT.STORES.STORESCOLUMNDEFINITIONS.VISIBILITY.YES',
    defaultMessage: 'Yes'
  },
  noVisibilityStatus: {
    id: 'USERMANAGEMENT.STORES.STORESCOLUMNDEFINITIONS.VISIBILITY.NO',
    defaultMessage: 'No'
  }
});
