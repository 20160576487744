import React, { useReducer, useState } from 'react';
import { useIntl } from 'react-intl';
import {
  BUTTON_VARIANTS,
  PasswordInput,
  IconCheckCircle,
  IconClose,
  TOAST_VARIANTS,
  TOAST_POSITIONS,
  Toast
} from 'cdk-radial';
import { messages } from './messages';
import {
  StyledDialogContent,
  StyledDialogLeftContent,
  StyledDialogRightContent,
  StyledPasswordErrorContainer,
  StyledPasswordRequirement,
  StyledPasswordRequirementContainer,
  StyledRequirementContent,
  StyledResetPasswordDialog,
  StyledRightContentTitle,
  StyledVerticalLine
} from './styled';
import {
  PasswordActionType,
  PasswordErrorActionType,
  ResetPasswordProps
} from './type';
import { initialState, passwordReducer } from './passwordReducer';
import {
  LOWERCASE_REGEX,
  NUMERALS_REGEX,
  SPECIAL_CHAR_REGEX,
  THREE_IDENTICAL_REGEX,
  TRAILING_SPACES_REGEX,
  UPPERCASE_REGEX
} from './constants';
import {
  canSavePassword,
  getChangePasswordPayload,
  getErrorMessage,
  hasSequentialChars
} from './utils';

export const ResetPassword = ({
  passwordError,
  handleSaveExit,
  handleCloseExit,
  handleClose
}: ResetPasswordProps): JSX.Element => {
  const intl = useIntl();

  const [state, dispatch] = useReducer(passwordReducer, initialState);
  const [currentPassword, setCurrentPassword] = useState<string>('');
  const [confirmPassword, setConfirmPassword] = useState<string>('');
  const [isConfirmPasswordMatch, setConfirmPasswordMatch] =
    useState<boolean>(false);

  const handleCurrentPassword = ({
    target: { value }
  }: React.ChangeEvent<HTMLInputElement>) => {
    setCurrentPassword(value);
  };

  const handleConfirmPassword = ({
    target: { value }
  }: React.ChangeEvent<HTMLInputElement>) => {
    setConfirmPassword(value);
  };

  const handlePassword = ({
    target: { value }
  }: React.ChangeEvent<HTMLInputElement>) => {
    dispatch({ type: PasswordActionType.PASSWORD, payload: value });
    dispatch({ type: PasswordErrorActionType.RESET, error: true });

    if (RegExp(UPPERCASE_REGEX).test(value)) {
      dispatch({ type: PasswordErrorActionType.HAS_UPPER_CASE, error: true });
    }
    if (RegExp(LOWERCASE_REGEX).test(value)) {
      dispatch({ type: PasswordErrorActionType.HAS_LOWER_CASE, error: true });
    }
    if (RegExp(NUMERALS_REGEX).test(value)) {
      dispatch({ type: PasswordErrorActionType.HAS_NUMERALS, error: true });
    }
    if (RegExp(SPECIAL_CHAR_REGEX).test(value)) {
      dispatch({
        type: PasswordErrorActionType.HAS_SPECIAL_CHARACTER,
        error: true
      });
    }
    if (value.length >= 8 && value.length < 32) {
      dispatch({ type: PasswordErrorActionType.HAS_CHARACTER, error: true });
    }
    if (RegExp(THREE_IDENTICAL_REGEX).test(value)) {
      dispatch({
        type: PasswordErrorActionType.HAS_THREE_IDENTICAL_CHAR,
        error: true
      });
    }
    if (RegExp(TRAILING_SPACES_REGEX).test(value)) {
      dispatch({
        type: PasswordErrorActionType.HAS_SPACES,
        error: true
      });
    }
    if (hasSequentialChars(value)) {
      dispatch({
        type: PasswordErrorActionType.HAS_SQUENTIAL_CHAR,
        error: true
      });
    }
  };

  const handleOnBlurPassword = () => {
    const validationMessage = getErrorMessage(state, intl);
    dispatch({
      type: PasswordActionType.VALIDATION_MESSAGE,
      payload: validationMessage
    });
  };

  const handleSave = () => {
    if (state.password !== confirmPassword) {
      setConfirmPasswordMatch(true);
      return;
    }
    const payload = getChangePasswordPayload({
      oldPassword: currentPassword,
      newPassword: confirmPassword
    });
    handleSaveExit(payload);
  };

  const canEnableSave =
    canSavePassword(state) && currentPassword && confirmPassword;

  const BUTTON_PROPS = [
    {
      id: 'dialog-2-action-1',
      onClick: handleCloseExit,
      text: intl.formatMessage(messages.cancel),
      variant: 'text'
    },
    {
      id: 'dialog-2-action-1',
      onClick: handleSave,
      text: intl.formatMessage(messages.save),
      variant: BUTTON_VARIANTS.PRIMARY,
      isDisabled: !canEnableSave
    }
  ];
  const TITLE = intl.formatMessage(messages.resetPasswordTitle);
  const PASSWORD_REQUIREMENTS = [
    {
      hasPassed: state.hasCharacter,
      message: intl.formatMessage(messages.eightCharacter)
    },
    {
      hasPassed: state.hasLowerCase,
      message: intl.formatMessage(messages.lowerCase)
    },
    {
      hasPassed: state.hasUppperCase,
      message: intl.formatMessage(messages.upperCase)
    },
    {
      hasPassed: state.hasNumerals,
      message: intl.formatMessage(messages.numerals)
    },
    {
      hasPassed: state.hasSpecialCharacter,
      message: intl.formatMessage(messages.specialCharacters)
    }
  ];
  const PASSWORD_ERRORS = [
    {
      hasError: state.hasSpaces,
      message: intl.formatMessage(messages.hasSpacesErrorWarning)
    },
    {
      hasError: state.hasThreeIdenticalChar,
      message: intl.formatMessage(messages.hasThreeIdenticalCharErrorWarning)
    },
    {
      hasError: state.hasSquentialChar,
      message: intl.formatMessage(messages.hasThreeSquentialCharErrorWarning)
    }
  ];

  const canShowToast = !!passwordError;

  return (
    <StyledResetPasswordDialog
      dataTestId="dialog-reset-password"
      disableOverlayClicked={false}
      id="dialog-reset-password"
      isOpen
      onClose={() => handleClose(false)}
      title={TITLE}
      buttonsProps={BUTTON_PROPS}
    >
      {canShowToast && (
        <Toast
          position={TOAST_POSITIONS.INLINE}
          variant={TOAST_VARIANTS.NEGATIVE}
          content={passwordError}
          id="password-error-toast"
          data-testid="password-error-toast"
        />
      )}
      <StyledDialogContent>
        <StyledDialogLeftContent>
          <PasswordInput
            isRequired
            dataTestId="password-input"
            errorMessage={intl.formatMessage(messages.currentPasswordError)}
            id="current-password-input"
            label={intl.formatMessage(messages.currentPasswordLabel)}
            name="password-input"
            onChange={handleCurrentPassword}
            value={currentPassword}
          />
          <PasswordInput
            isRequired
            dataTestId="new-password-input"
            enableCustomValidation
            errorMessage={state.validationMessage}
            hasError={!!state.validationMessage}
            id="new-password-input"
            label={intl.formatMessage(messages.newPasswordLabel)}
            name="new-password-input"
            onChange={handlePassword}
            onBlur={handleOnBlurPassword}
            value={state.password}
          />
          <PasswordInput
            isRequired
            dataTestId="confirm-password-input"
            enableCustomValidation
            errorMessage={intl.formatMessage(messages.confirmPasswordError)}
            hasError={isConfirmPasswordMatch}
            id="confirm-password-input"
            label={intl.formatMessage(messages.confirmPasswordLabel)}
            name="confirm-password-input"
            onChange={handleConfirmPassword}
            value={confirmPassword}
          />
        </StyledDialogLeftContent>
        <StyledVerticalLine height={canShowToast ? '78%' : ''} />
        <StyledDialogRightContent>
          <StyledRightContentTitle type="body-1">
            {intl.formatMessage(messages.passwordMustContain)}
          </StyledRightContentTitle>
          <StyledPasswordRequirementContainer>
            {PASSWORD_REQUIREMENTS.map(passRequirement => (
              <StyledPasswordRequirement key={passRequirement.message}>
                <IconCheckCircle
                  height={16}
                  width={16}
                  className={passRequirement.hasPassed ? 'success' : 'error'}
                />
                <StyledRequirementContent type="caption">
                  {passRequirement.message}
                </StyledRequirementContent>
              </StyledPasswordRequirement>
            ))}
          </StyledPasswordRequirementContainer>
          <StyledRightContentTitle
            type="body-1"
            className="reset-password-title"
          >
            {intl.formatMessage(messages.passwordMustNotContain)}
          </StyledRightContentTitle>

          <StyledPasswordErrorContainer>
            {PASSWORD_ERRORS.map(error => (
              <StyledPasswordRequirement key={error.message.replace(' ', '')}>
                <IconClose
                  height={16}
                  width={16}
                  className={error.hasError ? 'error' : 'info'}
                />
                <StyledRequirementContent type="caption">
                  {error.message}
                </StyledRequirementContent>
              </StyledPasswordRequirement>
            ))}
          </StyledPasswordErrorContainer>
        </StyledDialogRightContent>
      </StyledDialogContent>
    </StyledResetPasswordDialog>
  );
};
