/* eslint-disable @typescript-eslint/no-unsafe-call ,
@typescript-eslint/restrict-template-expressions,
@typescript-eslint/no-unsafe-return */

import { AutocompleteDropdown } from 'components/organisms/common/AutocompleteDropdown/AutocompleteDropdown';
import { Option } from 'components/organisms/common/AutocompleteDropdown/types';
import ErrorToast from 'components/ui/ErrorToast';
import { useEffect, useState } from 'react';
import { useIntl } from 'react-intl';
import { useDispatch, useSelector } from 'react-redux';
import { useGetCNumbersQuery } from 'redux/services/eis-external-attributes-service';
import { useGetDMSUsersQuery } from 'redux/services/identity-service';
import { SelectedCNumber } from 'redux/services/admin-service/types';
import {
  setContentHeader,
  setSelectedCNumber
} from 'redux/slices/userImportSlice';
import { RootState } from 'redux/store';
import { useClient } from 'providers/ClientProvider';
import StepOneRoles from '../../../molecules/NewUserImport/StepOne/StepOneRoles/StepOneRoles';
import StepOneTitle from '../../../molecules/NewUserImport/StepOne/StepOneTitle/StepOneTitle';
import StyledStepOne from './styled';

import AutoMapRolesDialog from './AutoMapRolesDialog';
import { messages } from './messages';
import { DmsQueryProps } from './types';

const StepOne = (): JSX.Element => {
  const dispatch = useDispatch();

  const intl = useIntl();
  const client = useClient();
  const { userInfo } = client;

  const {
    data: cNumbers = [],
    isLoading: isCNumbersQueryLoading,
    isError: isCNumbersQueryError,
    error: cNumbersQueryError
  } = useGetCNumbersQuery(userInfo?.fullUser.enterprise.id || '', {
    skip: !userInfo?.fullUser
  });

  useEffect(() => {
    const navTitle = intl.formatMessage(messages.importFromDMSTitle);
    dispatch(setContentHeader(navTitle));
  }, [dispatch, intl]);

  const {
    selectedCNumber,
    newUserValidations: {
      step_one: {
        selectedCNumber: { isValid, error }
      }
    }
  } = useSelector((state: RootState) => state.slices.userImportState);

  const {
    data: dmsUsers,
    isSuccess: isJobProfileSuccess,
    isLoading: isJobProfileLoading,
    isFetching,
    refetch
  }: DmsQueryProps = useGetDMSUsersQuery(
    {
      enterpriseId: userInfo?.fullUser.enterprise.id || '',
      cnumber:
        selectedCNumber?.value === 'All' ? '' : selectedCNumber?.value || ''
    },
    { skip: !selectedCNumber }
  );

  const [isAutoMapRolesDilaogOpen, setIsAutoMapRolesDilaogOpen] =
    useState<boolean>(false);
  const [isCnumberSelect, setIsCnumberSelect] = useState<boolean>(false);
  const [optionValue, setOptionValue] = useState<string>('');
  const [cNumberDetails, setCNumberDetails] = useState<SelectedCNumber[]>([]);

  useEffect(() => {
    if (
      (selectedCNumber?.value !== '' && !isCnumberSelect) ||
      (isCnumberSelect && optionValue === selectedCNumber?.value)
    ) {
      refetch();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedCNumber]);

  useEffect(() => {
    const updatedCNumberAliasDetails = cNumbers
      .map(cNum => ({
        ...cNum,
        label: `${cNum.label} ${cNum.alias}`.trim()
      }))
      .sort((a, b) => {
        if (a.value === 'All') return -1;
        if (b.value === 'All') return 1;

        const aliasA = a.alias || '';
        const aliasB = b.alias || '';
        const valueA = a.value || '';
        const valueB = b.value || '';

        if (aliasA && aliasB) return aliasA.localeCompare(aliasB);
        if (aliasA) return -1;
        if (aliasB) return 1;

        return valueB.localeCompare(valueA);
      });

    setCNumberDetails(updatedCNumberAliasDetails);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isCNumbersQueryLoading]);

  const saveNumber = (selectedValue?: SelectedCNumber | Option) => {
    if (selectedValue) {
      setIsCnumberSelect(true);
      setOptionValue(selectedValue?.value);
      if (selectedValue?.value === selectedCNumber?.value) {
        refetch();
      }
      dispatch(setSelectedCNumber(selectedValue as unknown as SelectedCNumber));
    }
  };

  const onAutoMapRolesClick = () => {
    setIsAutoMapRolesDilaogOpen(!isAutoMapRolesDilaogOpen);
  };

  return (
    <>
      {isCNumbersQueryError && <ErrorToast dataError={cNumbersQueryError} />}

      {userInfo?.fullUser && (
        <StyledStepOne>
          <div data-testid="step-one-container">
            <StepOneTitle />
            <div data-testid="step-one-select" className="selectCNumber">
              <div>
                <AutocompleteDropdown
                  id="input-simple-stepOne"
                  dataTestId="stepOne-dropdown"
                  options={cNumberDetails}
                  value={selectedCNumber || { label: '', value: '', alias: '' }}
                  onChange={saveNumber}
                  isLoading={false}
                  minChars={3}
                  inputProps={{
                    label: intl.formatMessage(messages.selectCNumber),
                    placeholder: intl.formatMessage(messages.selectValue),
                    helperText: intl.formatMessage(messages.selectValue)
                  }}
                  enableCustomValidation
                  errorMessage={error}
                  hasError={isValid === false}
                />
              </div>
            </div>
            <h3>{intl.formatMessage(messages.automapRolesButton)}</h3>
            <StepOneRoles
              onAutoMapRolesClick={onAutoMapRolesClick}
              isAutoMapDisabled={!selectedCNumber || !isJobProfileSuccess}
              isAutoMapLoading={isJobProfileLoading || isFetching}
            />
            <AutoMapRolesDialog
              isAutoMapRolesDilaogOpen={isAutoMapRolesDilaogOpen}
              closeAutoMapRolesDialog={() => setIsAutoMapRolesDilaogOpen(false)}
              dmsUsers={dmsUsers}
            />
          </div>
        </StyledStepOne>
      )}
    </>
  );
};

export default StepOne;
