import { createGlobalStyle } from 'styled-components';

const GlobalStyle = createGlobalStyle`

body {
  overflow: hidden;
}

.show-table-border{
  border: 1px solid #b9bbbe;
  border-radius: 0.5rem;
}
.show-table-height {
  height:80% !important;
}

div[data-testid$="dialog-content"]{
  overflow-x: visible;
  overflow-y: visible;
}

::-webkit-scrollbar {
  -webkit-appearance: none;
  width: 8px;
  height: 8px;
}
::-webkit-scrollbar-thumb {
  border-radius: 4px;
  background-color: rgba(0,0,0,.5);
  box-shadow: 0 0 1px rgba(255,255,255,.5);
}
`;

export default GlobalStyle;
