import { useOktaAuth } from '@okta/okta-react';
import { BUTTON_VARIANTS, EmptyStatesIllustration, Heading } from 'cdk-radial';
import { useIntl } from 'react-intl';
import { useGetAdminsByStoreIdWithPermissionQuery } from 'redux/services/admin-service';
import Loading from 'components/organisms/common/Loading';
import ErrorToast from 'components/ui/ErrorToast';
import { messages } from '../messages';
import EnterpriseAdminCard from './EnterpriseAdminCard';
import {
  StyledCardsContainer,
  StyledDialog,
  StyledEmptyContainer
} from '../styled';
import { EnterpriseAdminDialogProps } from '../types';

const EnterpriseAdminDialog = ({
  handleClose
}: EnterpriseAdminDialogProps): JSX.Element => {
  const intl = useIntl();
  const { authState } = useOktaAuth();
  const loginId = (authState?.accessToken?.claims.cdksid as string) || '';
  const isCdkUser = loginId?.toLowerCase().includes('cdk.com');
  const skipQuery = isCdkUser && loginId === '';

  const { data, isLoading, isSuccess, isError, error } =
    useGetAdminsByStoreIdWithPermissionQuery(loginId, {
      skip: skipQuery
    });

  const firstFiveUsers = data?.users?.slice(
    0,
    Math.min(5, data?.users?.length)
  );

  return (
    <StyledDialog
      dataTestId="enterprise-admins-dialog"
      id="enterprise-admins-dialog"
      buttonsProps={[
        {
          id: 'enterprise-admins-dialog-close-button',
          onClick: handleClose,
          text: intl.formatMessage(messages.dealerAdminInfoDialogCloseButton),
          variant: BUTTON_VARIANTS.TEXT
        }
      ]}
      isOpen
      onClose={handleClose}
      title={intl.formatMessage(messages.dealerAdminInfoDialogHeader)}
    >
      {isLoading && <Loading />}
      {isSuccess && (
        <>
          {Array.isArray(data?.users) && data?.users?.length > 0 && (
            <StyledCardsContainer>
              {firstFiveUsers?.map(user => (
                <EnterpriseAdminCard key={user.loginId} admin={user} />
              ))}
            </StyledCardsContainer>
          )}

          {Array.isArray(data?.users) && data?.users?.length < 1 && (
            <StyledEmptyContainer data-testid="enterprise-admin-dialog-empty-conatiner">
              <EmptyStatesIllustration size={400} />
              <Heading headingType="subtitle-3" level={1}>
                {intl.formatMessage(messages.emptyResult)}
              </Heading>
            </StyledEmptyContainer>
          )}
        </>
      )}
      {isError && <ErrorToast dataError={error} />}
    </StyledDialog>
  );
};

export default EnterpriseAdminDialog;
