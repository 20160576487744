import { css } from 'styled-components';

import { focusOutlinesStyles } from './../../../core';
import { BUTTON_BACKGROUND_APPEARANCES } from '../../constants';
import { getButtonContentElementsColor } from '../../shared-styles';

export const textButtonStateStyles = {
  default: {
    active: css`
      background-color: ${({ theme }) => theme.color.button.textButton.background.active.value};
      ${({ theme }) => getButtonContentElementsColor(theme.color.button.textButton.text.active.value)};
    `,
    default: css`
      background-color: ${({ theme }) => theme.color.button.textButton.background.enabled.value};
      ${({ theme }) => getButtonContentElementsColor(theme.color.button.textButton.text.normal.value)};
    `,
    disabled: css`
      background-color: ${({ theme }) => theme.color.button.textButton.background.disabled.value};
      ${({ theme }) => getButtonContentElementsColor(theme.color.button.textButton.text.disabled.value)};
    `,
    focus: css`
      background-color: ${({ theme }) => theme.color.button.textButton.background.hover.value};
      ${({ theme }) => getButtonContentElementsColor(theme.color.button.textButton.text.hover.value)};
      ${focusOutlinesStyles.primary};
    `,
    hover: css`
      background-color: ${({ theme }) => theme.color.button.textButton.background.hover.value};
      ${({ theme }) => getButtonContentElementsColor(theme.color.button.textButton.text.hover.value)};
    `,
  },
  defaultOnPrimaryBackground: {
    active: css`
      background-color: ${({ theme }) => theme.color.state.dark.pressed.value};
    `,
    default: css`
      ${({ theme }) => getButtonContentElementsColor(theme.color.additional.light.value)};
    `,
    disabled: css`
      ${({ theme }) => getButtonContentElementsColor(theme.color.text.dark.disabled.value)};
    `,
    focus: css`
      background-color: ${({ theme }) => theme.color.state.dark.focus.value};
      ${focusOutlinesStyles.light};
    `,
    hover: css`
      background-color: ${({ theme }) => theme.color.state.dark.hover.value};
    `,
  },
};

export const getStateStyles = (backgroundAppearance) => {
  return backgroundAppearance === BUTTON_BACKGROUND_APPEARANCES.LIGHT
    ? textButtonStateStyles.default
    : textButtonStateStyles.defaultOnPrimaryBackground;
};
