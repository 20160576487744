import React from 'react';
import PropTypes from 'prop-types';
import styled, { css } from 'styled-components';

import { HEADING_LEVELS, HEADING_TYPES } from '../constants';
import { headingVariantStyles } from '../variant-styles';

const StyledHeading = styled.h1`
  ${({ variant }) =>
    variant &&
    css`
      ${headingVariantStyles[variant]};
    `}
`;

const Heading = ({ children, headingType = HEADING_TYPES.DISPLAY_1, level = HEADING_LEVELS.LEVEL_1, ...other }) => {
  const Tag = `h${Math.min(level, 6)}`;

  return (
    <StyledHeading as={Tag} variant={headingType} {...other}>
      {children}
    </StyledHeading>
  );
};

Heading.propTypes = {
  /** Adds any content between tags */
  children: PropTypes.node,
  /** Sets the heading styles. */
  headingType: PropTypes.oneOf(Object.values(HEADING_TYPES)),
  /** Sets heading tag level (depth) and applies style. Values: [1, 2, 3, 4, 5, 6] */
  level: PropTypes.oneOf(Object.values(HEADING_LEVELS)),
};

export { Heading };
