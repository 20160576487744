import React, { useRef, useState } from 'react';

import { IconEvent } from 'lib/icons';
import { isFocused as isElementFocused, KEY_VALUES, useErrorValidation, useKeyDownEventListener } from 'lib/utilities';
import PropTypes from 'prop-types';

import { BORDER_POSITIONS, DIVIDER_POSITIONS, Input, INPUT_ICON_POSITIONS, INPUT_SIZES } from './../../../input';
import { isDateValid } from './validation';
import { DATE_FORMATS, DEFAULT_MAXIMUM_YEAR, DEFAULT_MINIMUM_YEAR } from '../../constants';
import { DATE_INPUT_ERRORS } from '../../errors';
import { ReactDateInputMask } from '../ReactDateInputMask';

const DateInput = React.forwardRef(
  (
    {
      customTag = undefined,
      dataTestId = undefined,
      dateFormat = DATE_FORMATS.MM_DD_YYYY,
      enableCustomValidation = false,
      errorMessage = '',
      hasError = false,
      helperText = '',
      hideBorder = BORDER_POSITIONS.NONE,
      inputDivider = DIVIDER_POSITIONS.NONE,
      inputMode = 'numeric',
      isDisabled = false,
      isFocused = false,
      isReadOnly = false,
      isRequired = false,
      maximumYear = DEFAULT_MAXIMUM_YEAR,
      minimumYear = DEFAULT_MINIMUM_YEAR,
      onBlur = () => {},
      onChange,
      onError = () => {},
      onFocus = () => {},
      prefixText = '',
      size = INPUT_SIZES.STANDARD,
      value,
      ...other
    },
    ref
  ) => {
    const inputRef = useRef();

    const [editedSinceFocus, setEditedSinceFocus] = useState(false);

    const isDateInvalidError = !editedSinceFocus && !isDateValid(dateFormat, value, minimumYear, maximumYear);

    const errors = [isDateInvalidError && DATE_INPUT_ERRORS.INVALID_DATE].filter(Boolean);

    const { onChildError: onInputError, updatedHasError } = useErrorValidation(errors, {
      enableCustomValidation,
      hasError,
      onError,
    });

    useKeyDownEventListener(
      KEY_VALUES.TAB,
      () => {
        // setTimeout is necessary to wait for the currently focused item to update
        setTimeout(() => {
          const isInputFocused = isElementFocused(inputRef.current);

          if (isInputFocused) {
            inputRef.current.select();
          }
        }, 0);
      },
      document
    );

    const handleChange = (...args) => {
      setEditedSinceFocus(true);
      onChange(...args);
    };

    const handleBlur = (event) => {
      onBlur(event);
      setEditedSinceFocus(false);
    };

    const handleFocus = (event) => {
      setEditedSinceFocus(false);
      onFocus(event);
    };

    const reactInputMaskProps = {
      dateFormat,
      inputRef: (element) => {
        inputRef.current = element;
        if (ref) {
          // eslint-disable-next-line no-param-reassign
          ref.current = element;
        }
      },
    };

    const Tag = customTag || Input;

    return (
      <Tag
        as={ReactDateInputMask}
        dataTestId={dataTestId}
        dateFormat={dateFormat}
        enableCustomValidation
        errorMessage={errorMessage}
        hasError={updatedHasError}
        helperText={helperText}
        hideBorder={hideBorder}
        icon={<IconEvent />}
        iconPosition={INPUT_ICON_POSITIONS.LEADING}
        inputDivider={inputDivider}
        inputMode={inputMode}
        isDisabled={isDisabled}
        isFocused={isFocused}
        isReadOnly={isReadOnly}
        isRequired={isRequired}
        onBlur={handleBlur}
        onChange={handleChange}
        onError={onInputError}
        onFocus={handleFocus}
        prefixText={prefixText}
        size={size}
        value={value}
        {...other}
        {...reactInputMaskProps}
      />
    );
  }
);

DateInput.propTypes = {
  /** NOTE THAT EVERYTHING ELSE IS ALSO APPLICABLE WHAT YOU CAN FIND IN INPUT COMPONENT */
  /** Ability to supply a different input element instead of the default one */
  customTag: PropTypes.elementType,
  /** Id value used for testing */
  dataTestId: PropTypes.string,
  /** Defines in what format date input should format value */
  dateFormat: PropTypes.oneOf(Object.values(DATE_FORMATS)),
  /** If true, custom validation is being enabled instead of built in component validation */
  enableCustomValidation: PropTypes.bool,
  /** Message to be displayed when input is in error state */
  errorMessage: PropTypes.node,
  /** If true, input is in error state */
  hasError: PropTypes.bool,
  /** Text to be displayed as a helper text near the input field */
  helperText: PropTypes.node,
  /** Defines which side of input container border should be hidden */
  hideBorder: PropTypes.oneOf(Object.values(BORDER_POSITIONS)),
  /** Identifier of the input component */
  id: PropTypes.string.isRequired,
  /** Defines the side of vertical divider */
  inputDivider: PropTypes.oneOf(Object.values(DIVIDER_POSITIONS)),
  /** Sets global attribute for inputMode to determine mobile input mode */
  inputMode: PropTypes.string,
  /** If true, input is disabled and value of it cannot be edited */
  isDisabled: PropTypes.bool,
  /** If true, visually applies focused input styles */
  isFocused: PropTypes.bool,
  /** If true, input is in read only state, value cannot be edited */
  isReadOnly: PropTypes.bool,
  /** If true, isRequired asterisk will be shown */
  isRequired: PropTypes.bool,
  /** Label of the input field */
  label: PropTypes.node.isRequired,
  /** Maximum valid year available for date input date value */
  maximumYear: PropTypes.number,
  /** Minimum valid year available for date input date value */
  minimumYear: PropTypes.number,
  /** Name of the input */
  name: PropTypes.string.isRequired,
  /** Callback that is called when Date Input has been blurred */
  onBlur: PropTypes.func,
  /** Callback to be called when input's value is being changed by user interaction */
  onChange: PropTypes.func.isRequired,
  /** Callback to be called when Date Input's validation fails */
  onError: PropTypes.func,
  /** Callback that is called when Date Input has been focused */
  onFocus: PropTypes.func,
  /** String to be displayed before the input value. Prefix text should be 1 character. */
  prefixText: PropTypes.node,
  /** Set the size of the input */
  size: PropTypes.oneOf(Object.values(INPUT_SIZES)),
  /** String to be displayed after the input value. Text should not contain more than 5 characters, to prevent text being cut off */
  suffixText: PropTypes.node,
  /** Value of the date input */
  value: PropTypes.string.isRequired,
};

export { DateInput };
