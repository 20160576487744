import styled, { css, keyframes } from 'styled-components';
import { LOADER_SIZE } from '../constants';

export const CircleWrapper = styled.div`
  margin: 0;
  padding: 0;
  position: relative;
  ${({ size }) =>
    size === LOADER_SIZE.LARGE &&
    css`
      height: ${({ theme }) => theme.size.progressCircle.large.original.value + 'px'};
      width: ${({ theme }) => theme.size.progressCircle.large.original.value + 'px'};
    `};
  ${({ size }) =>
    size === LOADER_SIZE.SMALL &&
    css`
      height: ${({ theme }) => theme.size.progressCircle.small.original.value + 'px'};
      width: ${({ theme }) => theme.size.progressCircle.small.original.value + 'px'};
    `};
`;

export const CircleTrack = styled.circle`
  fill: transparent;
  stroke: ${({ theme }) => theme.color.progressTrack.background.value};
`;

export const FilledCircle = styled(CircleTrack)`
  stroke: ${({ theme }) => theme.color.primary[500].value};
  transform: rotate(-90deg);
  transform-origin: 50% 50%;
`;

export const FilledIndeterminateCircle = styled(CircleTrack)`
  stroke: ${({ theme }) => theme.color.primary[500].value};
  transform: rotate(-40deg);
  transform-origin: 50% 50%;
`;

export const dash = keyframes`
  0%{
    stroke-dasharray: 10,262;
    stroke-dashoffset: 0;
  }
  50%{
    stroke-dasharray: 200,272;
    stroke-dashoffset: -70;
  }
  100%{
    stroke-dasharray: 200,265;
    stroke-dashoffset: -272;
  }
`;

export const rotate = keyframes`
  100%{
    transform: rotate(360deg);
  }  
`;
