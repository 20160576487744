/* eslint-disable react/jsx-props-no-spreading */
import { IconButton, IconHelp, withTooltip, THEMES } from 'cdk-radial';
import { FormattedMessage } from 'react-intl';
import { WalkMe } from '../type';

const HelpButton = (props: unknown): JSX.Element => {
  const handleHelp = () => {
    const { WalkMePlayerAPI } = window as unknown as WalkMe;
    if (WalkMePlayerAPI) {
      WalkMePlayerAPI?.toggleMenu();
    }
  };

  return (
    <IconButton
      backgroundAppearance="light"
      icon={
        <IconHelp
          focusable={false}
          height={24}
          role="presentation"
          viewBox="0 0 24 24"
          width="24"
        />
      }
      iconColor="gray"
      iconSize={24}
      onClick={handleHelp}
      data-testid="Top-header-help-button"
      text="Help Button"
      {...props}
    />
  );
};

const toolTipProps = {
  id: 'tooltip-id-1',
  text: (
    <FormattedMessage
      id="COMMON.HEADER.TOPBAR.OPTION.HELP"
      defaultMessage="Help"
    />
  ),
  theme: THEMES.DARK,
  delay: 100
};

// eslint-disable-next-line @typescript-eslint/no-unsafe-call
export const HelpButtonWithToolTip = withTooltip(HelpButton, toolTipProps);
