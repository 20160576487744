import { useIntl } from 'react-intl';
import { Counter } from './type';
import { messages } from './messages';

export const ShowCounter = ({
  hours,
  minutes,
  seconds
}: Counter): JSX.Element => {
  const intl = useIntl();
  return (
    <div className="show-counter">
      <span className="counter-item">
        {intl.formatMessage(messages.impersonationTimer)}
      </span>
      <span className="counter-item">{hours}hrs</span>
      <span className="counter-item">{minutes}mins</span>
      <span className="counter-item">{seconds}sec</span>
    </div>
  );
};
