import { useSelector, useDispatch } from 'react-redux';
import { useIntl } from 'react-intl';
import { RootState } from 'redux/store';
import { setTemplateType } from 'redux/slices/csvImportSlice';
import useTemplateConfig from 'fixtures/csvTemplatesConfig';
import StyledStepOne from './styled';
import TemplateSelectCard from '../../../molecules/csvUserImport/StepOne/TemplateSelectCard/TemplateSelectCard';
import { messages } from '../messages';

const StepOne = (): JSX.Element => {
  const intl = useIntl();
  const dispatch = useDispatch();
  const { templateType } = useSelector(
    (state: RootState) => state.slices.csvImportState
  );

  const handleRadioChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    dispatch(setTemplateType(event.target.value));
  };
  const [templatesConfig] = useTemplateConfig();
  return (
    <StyledStepOne>
      <h4 className="main-heading">
        {intl.formatMessage(messages.selectTemplate)}
      </h4>
      <p className="description">{intl.formatMessage(messages.description)}</p>
      {templatesConfig.map(
        ({
          uid,
          mainHeading,
          mainDescription,
          columnNamesDesc,
          TemplateRule,
          downloadSampleFile
        }) => (
          <TemplateSelectCard
            uid={uid}
            key={uid}
            mainHeading={mainHeading}
            mainDescription={mainDescription}
            columnNamesDesc={columnNamesDesc}
            TemplateRule={TemplateRule}
            downloadSampleFile={downloadSampleFile}
            handleRadioChange={handleRadioChange}
            radioValue={templateType}
          />
        )
      )}
    </StyledStepOne>
  );
};

export default StepOne;
