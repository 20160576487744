import { defineMessages } from 'react-intl';

export const messages = defineMessages({
  confirmImpersonation: {
    id: 'COMMON.HEADER.REVOKEIMPERSONATION.CONFIRM.',
    defaultMessage: 'Are you sure you want to revoke impersonation ?'
  },
  dialogRevokeButton: {
    id: 'COMMON.HEADER.REVOKEIMPERSONATION.DIALOG.REVOKE.BUTTON',
    defaultMessage: 'Revoke'
  },
  revokeImpersonationDialogTitle: {
    id: 'COMMON.HEADER.REVOKEIMPERSONATION.DIALOG.TITLE',
    defaultMessage: 'Revoke Impersonation'
  }
});
