import { defineMessages } from 'react-intl';

export const messages = defineMessages({
  seeLess: {
    id: 'COMMON.EXPANDABLETEXT.BUTTON.SEELESS',
    defaultMessage: 'see less'
  },
  seeMore: {
    id: 'COMMON.EXPANDABLETEXT.BUTTON.SEEMORE',
    defaultMessage: 'see more'
  },
  buttonLess: {
    id: 'COMMON.EXPANDABLETEXT.BUTTON.LESS',
    defaultMessage: 'Less'
  },
  buttonMore: {
    id: 'COMMON.EXPANDABLETEXT.BUTTON.MORE',
    defaultMessage: 'More'
  },
  removeAll: {
    id: 'COMMON.EXPANDABLE.CHIP.REMOVEALL',
    defaultMessage: 'remove all'
  }
});
