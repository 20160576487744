import styled from 'styled-components';

const StyledStepFourDateInput = styled.div`
.schedule-date{
    width: 16rem;
    height: 3.4rem;
    padding-top: 1.7rem;
    padding-bottom: 1.7rem;
}
`;

export default StyledStepFourDateInput;
