/* eslint-disable @typescript-eslint/no-unsafe-return */

import styled from 'styled-components';
import { FlexFooterContainer } from 'cdk-radial';

const StyledFlexFooterContainer = styled(FlexFooterContainer)`
  border-top: 1px solid ${({ theme }) => theme.color.gray[200].value};
`;

export default StyledFlexFooterContainer;
