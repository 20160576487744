import React from 'react';

import { AVATAR_COLORS, AVATAR_SIZES, AVATAR_VARIANTS } from 'lib/avatar';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import {
  ChipContent,
  ChipContentChoiceChips,
  ChipContentWithAvatar,
  ChipContentWithCaret,
  ChipContentWithCheckbox,
  ChipContentWithIcon,
  ChipContentWithLoader,
  StyledAvatarWrapper,
} from '../../blocks';
import { CHIP_BACKGROUND_APPEARANCES } from '../../constants';
import { ChipRemoveButton, ChipWrapper } from '../../elements';

const StyledChipContentWithAvatar = styled(ChipContentWithAvatar)`
  ${StyledAvatarWrapper} {
    background-color: ${({ theme }) => theme.color.primary[600].value};
  }
`;

const Chip = React.forwardRef(
  (
    {
      avatarProps = {},
      backgroundAppearance = CHIP_BACKGROUND_APPEARANCES.LIGHT,
      className = '',
      dataTestId = undefined,
      hasCheckbox = undefined,
      hasDropdown = undefined,
      icon = undefined,
      isChoiceChip = false,
      isDisabled = false,
      isLoading = false,
      isOpen = false,
      isSelected = undefined,
      label,
      onRemove = undefined,
      removeButtonLabel = 'Remove',
      renderBeforeContent = undefined,
      trailingIcon = undefined,
      ...other
    },
    ref
  ) => {
    const renderChipContent = () => {
      const showAvatar = Object.entries(avatarProps).length > 0;
      const showChipWithIcon = !!icon && !isChoiceChip;

      if (isLoading) {
        return (
          <ChipContentWithLoader
            isLoading
            withRemoveButton={!!onRemove}
            {...{
              backgroundAppearance,
              dataTestId,
              isDisabled,
              isSelected,
              label,
              removeButtonLabel,
              renderBeforeContent,
            }}
            {...other}
            ref={ref}
          />
        );
      }

      if (showAvatar && !isSelected) {
        return (
          <ChipContentWithAvatar
            withRemoveButton={!!onRemove}
            {...{
              avatarProps,
              backgroundAppearance,
              dataTestId,
              isDisabled,
              isSelected,
              label,
              removeButtonLabel,
              renderBeforeContent,
            }}
            {...other}
            ref={ref}
          />
        );
      }

      if (showAvatar && isSelected) {
        return (
          <StyledChipContentWithAvatar
            withRemoveButton={!!onRemove}
            {...{
              avatarProps,
              backgroundAppearance,
              dataTestId,
              isDisabled,
              isSelected,
              label,
              removeButtonLabel,
              renderBeforeContent,
            }}
            {...other}
            ref={ref}
          />
        );
      }

      if (hasDropdown) {
        return (
          <ChipContentWithCaret
            {...{
              backgroundAppearance,
              dataTestId,
              hasDropdown,
              icon,
              isOpen,
              isDisabled,
              isSelected,
              label,
              removeButtonLabel,
              renderBeforeContent,
            }}
            {...other}
            ref={ref}
          />
        );
      }

      if (showChipWithIcon) {
        return (
          <ChipContentWithIcon
            withRemoveButton={!!onRemove}
            isSeected={isSelected}
            {...{
              backgroundAppearance,
              dataTestId,
              icon,
              isDisabled,
              isSelected,
              label,
              removeButtonLabel,
              renderBeforeContent,
            }}
            {...other}
            ref={ref}
          />
        );
      }

      if (isChoiceChip) {
        return (
          <ChipContentChoiceChips
            {...{
              backgroundAppearance,
              dataTestId,
              icon,
              isDisabled,
              isSelected,
              label,
              removeButtonLabel,
              renderBeforeContent,
              trailingIcon,
            }}
            {...other}
            ref={ref}
          />
        );
      }

      if (hasCheckbox) {
        return (
          <ChipContentWithCheckbox
            data-testid={dataTestId}
            withRemoveButton={!!onRemove}
            {...{
              backgroundAppearance,
              hasCheckbox,
              isDisabled,
              isSelected,
              label,
              removeButtonLabel,
              renderBeforeContent,
            }}
            {...other}
            ref={ref}
          />
        );
      }

      return (
        <ChipContent
          data-testid={dataTestId}
          withRemoveButton={!!onRemove}
          {...{
            backgroundAppearance,
            dataTestId,
            isDisabled,
            isSelected,
            label,
            removeButtonLabel,
            renderBeforeContent,
          }}
          {...other}
          ref={ref}
        />
      );
    };

    return (
      <ChipWrapper className={className} data-testid={dataTestId ? `${dataTestId}-wrapper` : undefined}>
        {renderChipContent()}
        {!!onRemove && (
          <ChipRemoveButton
            data-testid={dataTestId ? `${dataTestId}-remove-button` : undefined}
            {...{ isSelected, onRemove }}
          />
        )}
      </ChipWrapper>
    );
  }
);

Chip.propTypes = {
  /** Object of properties, which are applied to avatar component */
  avatarProps: PropTypes.shape({
    /** Accessibility measurement for verbal image description */
    alt: PropTypes.node,
    /** Sets background color of avatar component */
    color: PropTypes.oneOf(Object.values(AVATAR_COLORS)),
    /** Outputs icon inside the avatar. Use icon component from the library */
    icon: PropTypes.node,
    /** Username, that this avatar depicts */
    label: PropTypes.node,
    /** Avatar wrapper size. Icon size changes depending on wrapper size */
    size: PropTypes.oneOf(Object.values(AVATAR_SIZES)),
    /** Path to image file */
    src: PropTypes.string,
    /** Defines multiple sizes of the same image, allowing the browser to select the appropriate image source */
    srcset: PropTypes.string,
    /** Avatar variant. Values: [EMPTY, TEXT, ICON, IMAGE]. Default TEXT */
    variant: PropTypes.oneOf(Object.values(AVATAR_VARIANTS)),
  }),
  /** @ignore */
  backgroundAppearance: PropTypes.oneOf(Object.values(CHIP_BACKGROUND_APPEARANCES)),
  /** Adds className to main wrapper */
  className: PropTypes.string,
  /** Id value used for testing */
  dataTestId: PropTypes.string,
  /** If true, chips won't be focusable via keyboard */
  disableFocus: PropTypes.bool,
  /** Shows checkbox inside the button. Used for filter chips */
  hasCheckbox: PropTypes.bool,
  /** Shows dropdown caret inside the button. Used for select chips */
  hasDropdown: PropTypes.bool,
  /** Shows icon inside the button. Use icon component from the library */
  icon: PropTypes.node,
  /** If true, denotes a choice chip usage */
  isChoiceChip: PropTypes.bool,
  /** If true, disabled properties pass through */
  isDisabled: PropTypes.bool,
  /** If true, changes button content into loader icon with loader text */
  isLoading: PropTypes.bool,
  /** If true, rotates caret icon. Used for select chips */
  isOpen: PropTypes.bool,
  /** If true, renders chip option as selected */
  isSelected: PropTypes.bool,
  /** Chip label */
  label: PropTypes.node.isRequired,
  /** Chip label suffix content */
  labelSuffix: PropTypes.node,
  /** Provided information is rendered as loader text */
  loaderText: PropTypes.node,
  /** Callback function which is triggered on chip click */
  onClick: PropTypes.func,
  /** Callback function for remove button */
  onRemove: PropTypes.func,
  /** Text which is read for screen reader users, when remove button is focused */
  removeButtonLabel: PropTypes.node,
  /** Renders custom content before main content group */
  renderBeforeContent: PropTypes.func,
  /** Shows trailing icon inside the button when applicable. Use icon component from the library */
  trailingIcon: PropTypes.node,
};

export { Chip };
