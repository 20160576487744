export type DropdownMenuOption = {
  label: string;
  value: string;
};

export enum SectionType {
  basicDetails = 'basicDetails',
  identificationDetails = 'identificationDetails',
  loginDetails = 'loginDetails',
  contactDetails = 'contactDetails',
  roles = 'roles',
  stores = 'stores',
  alternateIdentifiers = 'alternateIdentifiers',
  enterpriseDetails = 'enterpriseDetails',
  securityQuestionsStatus = 'securityQuestionsStatus'
}

export enum FieldNameType {
  firstName = 'firstName',
  middleName = 'middleName',
  lastName = 'lastName',
  suffix = 'suffix',
  nickName = 'nickName',
  locale = 'locale',
  employeeId = 'employeeId',
  jobTitle = 'jobTitle',
  cdkSimpleId = 'cdkSimpleId',
  newPassword = 'newPassword',
  retypePassword = 'retypePassword',
  primaryEmail = 'primaryEmail',
  primaryPhone = 'primaryPhone',
  secondaryEmail = 'secondaryEmail',
  secondaryPhone = 'secondaryPhone',
  externallyFederated = 'externallyFederated',
  uniquePrincipalName = 'uniquePrincipalName',
  accountStatus = 'accountStatus',
  systemId = 'systemId',
  alternateUserId = 'alternateUserId',
  systemType = 'systemType',
  communicationEdgeId = 'communicationEdgeId',
  enterpriseId = 'enterpriseId',
  enterpriseName = 'enterpriseName',
  userGuid = 'userGuid',
  alternateIdentifiers = 'alternateIdentifiers',
  mfaEnabled = 'mfaEnabled',
  idpName = 'idpName',
  securityQuestionsStatus = 'securityQuestionsStatus'
}

export enum ComponentTypes {
  email = 'email',
  phoneNumber = 'phoneNumber',
  password = 'password'
}

export type FieldType = {
  uid: FieldNameType;
  gUid?: string;
  isRequired: boolean;
  errorMessage: string;
  hasError: boolean;
  type?: ComponentTypes;
  options?: DropdownMenuOption[];
  value: string;
};

export type AlternateId = {
  alternateUserId: string;
  systemId: string;
  systemType: string;
};

type UserDetailsType = {
  [SectionType.basicDetails]: {
    [FieldNameType.firstName]: FieldType;
    [FieldNameType.middleName]: FieldType;
    [FieldNameType.lastName]: FieldType;
    [FieldNameType.suffix]: FieldType;
    [FieldNameType.nickName]: FieldType;
    [FieldNameType.locale]: FieldType;
    [FieldNameType.externallyFederated]: boolean;
    [FieldNameType.uniquePrincipalName]: FieldType;
    [FieldNameType.accountStatus]: string;
    [FieldNameType.systemId]: FieldType;
    [FieldNameType.alternateUserId]: FieldType;
    [FieldNameType.systemType]: FieldType;
    [FieldNameType.communicationEdgeId]: FieldType;
    [FieldNameType.userGuid]: FieldType;
    [FieldNameType.mfaEnabled]: boolean;
    [FieldNameType.securityQuestionsStatus]: boolean;
  };
  [SectionType.identificationDetails]: {
    [FieldNameType.employeeId]: FieldType;
    [FieldNameType.jobTitle]: FieldType;
    [FieldNameType.enterpriseId]: FieldType;
    [FieldNameType.enterpriseName]: FieldType;
  };
  [SectionType.loginDetails]: {
    [FieldNameType.cdkSimpleId]: FieldType;
    [FieldNameType.newPassword]: FieldType;
    [FieldNameType.retypePassword]: FieldType;
  };
  [SectionType.contactDetails]: {
    [FieldNameType.primaryEmail]: FieldType;
    [FieldNameType.primaryPhone]: FieldType;
    [FieldNameType.secondaryEmail]: FieldType;
    [FieldNameType.secondaryPhone]: FieldType;
  };
  [SectionType.enterpriseDetails]: {
    [FieldNameType.enterpriseId]: FieldType;
    [FieldNameType.enterpriseName]: FieldType;
    [FieldNameType.idpName]: FieldType;
  };
  [SectionType.roles]: Record<string, any>[];
  [SectionType.stores]: Record<string, any>[];
  [SectionType.alternateIdentifiers]: Array<AlternateId>;
};

export type BasicDetailsProps = {
  fields: {
    [FieldNameType.firstName]: FieldType;
    [FieldNameType.middleName]: FieldType;
    [FieldNameType.lastName]: FieldType;
    [FieldNameType.suffix]: FieldType;
    [FieldNameType.nickName]: FieldType;
    [FieldNameType.locale]: FieldType;
    [FieldNameType.accountStatus]: boolean;
  };
  handleFieldChange: (field: FieldNameType, value: string) => void;
};

export type IdentificationDetailsProps = {
  fields: {
    [FieldNameType.employeeId]: FieldType;
    [FieldNameType.jobTitle]: FieldType;
    [FieldNameType.enterpriseId]: FieldType;
    [FieldNameType.enterpriseName]: FieldType;
  };
  handleFieldChange: (field: FieldNameType, value: string) => void;
};

export type PasswordDetailsProps = {
  fields: {
    [FieldNameType.cdkSimpleId]: FieldType;
    [FieldNameType.newPassword]: FieldType;
    [FieldNameType.retypePassword]: FieldType;
  };
  handleFieldChange: (field: FieldNameType, value: string) => void;
};

export type ContactDetailsProps = {
  fields: {
    [FieldNameType.primaryEmail]: FieldType;
    [FieldNameType.primaryPhone]: FieldType;
    [FieldNameType.secondaryEmail]: FieldType;
    [FieldNameType.secondaryPhone]: FieldType;
  };
  handleFieldChange: (field: FieldNameType, value: string) => void;
};

export default UserDetailsType;
