const apiEndPoints = {
  getStoreDetails: '/stores',
  getEnterpriseDetails: '/enterprises',
  getDepartmentsDetailsByIds: (enterpriseId: string): string =>
    `/enterprises/${enterpriseId}/departments`,
  getDepartmentDetails: '/departments',
  getEntities: `/eis-search/search`
};

export default apiEndPoints;
