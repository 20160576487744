import styled from 'styled-components';

const StyledScheduleImport = styled.div`
    display: block;
    .step-four-container{
        margin-top: -1rem;
    }
    .schedule-input{
        width: 24.5rem;
        height: 2.5rem;
        margin-bottom: 0.5rem;
    }
    .schedule-buttons{
        padding-top: 4.2rem;
        display: flex;
    }
`;

export default StyledScheduleImport;
