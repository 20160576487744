import { IntlProvider } from 'react-intl';
import { ThemeProvider } from 'styled-components';
import { theme } from 'cdk-radial';
import { CookiesProvider, useCookies } from 'react-cookie';
import { RadialTheme } from '../types';
import { loadMessages } from '../i18n';
import { SecurityProvider } from './SecurityProvider';
import GlobalStyle from '../components/templates/commonAppLayout/GloablStyles';
import { AppShell } from './AppShell';

const defaultLocale = 'en';

function AppProvider(): JSX.Element {
  const [cookies] = useCookies(['locale']) || 'en';
  const cookieLocale = cookies.locale;
  const messages = loadMessages(cookieLocale);

  return (
    <ThemeProvider theme={theme as RadialTheme}>
      <CookiesProvider>
        <IntlProvider
          locale={cookieLocale}
          defaultLocale={defaultLocale}
          messages={messages}
        >
          <GlobalStyle />
          <div className="App">
            <SecurityProvider>
              <AppShell />
            </SecurityProvider>
          </div>
        </IntlProvider>
      </CookiesProvider>
    </ThemeProvider>
  );
}

export default AppProvider;
