import styled from 'styled-components';

const StyledTemplateSelectCard = styled.div`
    padding: 0.5rem;
    border: 1px solid #C4C5C8;
    border-radius: 0.5rem;
    margin-top: 1rem;
    .radio-container-column{
        max-width: 3rem;
    }
    .main-heading{
        margin: 0;
        margin-top: 0.5rem;
        font-weight: bold;
    }
    .main-description{
        font-size: 0.875rem;
        margin-top: 0.7rem;
    }
    .column-desc{
        margin-top: 1.5rem;
        margin-bottom: 1rem;
        font-size: 0.875rem;
        padding: 0.7rem;
        padding-left: 1rem;
        border-radius: 0.5rem;
        font-weight: 500;
        background: #F3F3F3;
    }

    .custom-btns{
        margin-right: 1rem;
    }
    .btn-container{
        display:flex;
    }
    .custom-anchor{
        text-decoration: none;
    }
`;

export default StyledTemplateSelectCard;
