import React from 'react';
import { components } from 'react-select';
import styled, { css } from 'styled-components';

import { getRem } from '../../core';
import { IconArrowDropDown } from '../../icons';

import { DROPDOWN_VARIANTS } from '../constants';

const StyledIndicator = styled(components.DropdownIndicator)`
  box-sizing: border-box;
  display: flex;
  padding-left: 0;
  padding-right: ${({ theme }) => theme.size.spacing.medium.value};
  ${({ isNoOutlineVariant }) =>
    isNoOutlineVariant &&
    css`
      padding: 0 0 ${getRem('2px')};
    `}
`;

const StyledIndicatorIcon = styled(IconArrowDropDown).withConfig({
  shouldForwardProp: (prop) => !['isOpen'].includes(prop),
})`
  display: flex;
  fill: ${({ theme }) => theme.color.text.light.secondary.value};
  flex-shrink: 0;
  transform: ${({ isOpen }) => isOpen && 'rotate(180deg)'};
`;

/* eslint-disable react/prop-types */
export const DropdownIndicatorComponent = ({ dataTestId, dropdownIndicatorProps, variant }) => {
  const { selectProps } = dropdownIndicatorProps;
  const { menuIsOpen } = selectProps;
  /* eslint-enable */

  const isNoOutlineVariant = variant === DROPDOWN_VARIANTS.NO_OUTLINE;

  return (
    <StyledIndicator isNoOutlineVariant={isNoOutlineVariant} {...dropdownIndicatorProps}>
      <StyledIndicatorIcon data-testid={dataTestId ? `${dataTestId}-caret` : undefined} isOpen={menuIsOpen} />
    </StyledIndicator>
  );
};
