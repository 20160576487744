import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import { getRem } from './../../core';
import { BaseInputPrefixSuffix } from './BaseInputPrefixSuffix';
import { PREFIX_MAX_CHARACTERS_ALLOWED } from '../constants';

const StyledInputPrefix = styled(BaseInputPrefixSuffix)`
  padding-right: ${getRem('2px')};
`;

const InputPrefix = ({ id, isDisabled = false, text, ...other }) => {
  const formattedText = text.substring(0, PREFIX_MAX_CHARACTERS_ALLOWED);

  return (
    <StyledInputPrefix {...{ id, isDisabled }} {...other}>
      {formattedText}
    </StyledInputPrefix>
  );
};

InputPrefix.propTypes = {
  /** Unique identifier. Necessary to have for linking with input */
  id: PropTypes.string.isRequired,
  /** If true, adjusts label styling */
  isDisabled: PropTypes.bool,
  /** Displays provided text string up to 1 character */
  text: PropTypes.node.isRequired,
};

export { InputPrefix };
