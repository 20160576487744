import { defineMessages } from 'react-intl';

export const messages = defineMessages({
  storeStatusOpen: {
    id: 'STORE.STATUS.OPEN',
    defaultMessage: 'Open'
  },
  storeStatusPreOpen: {
    id: 'STORE.STATUS.PRE_OPEN',
    defaultMessage: 'Pre-Open'
  },
  storeStatusSold: {
    id: 'STORE.STATUS.SOLD',
    defaultMessage: 'Sold'
  },
  storeStatusClosed: {
    id: 'STORE.STATUS.CLOSED',
    defaultMessage: 'Closed'
  },
  storeStatusInactive: {
    id: 'STORE.STATUS.INACTIVE',
    defaultMessage: 'Inactive'
  },
  storeStatusDuplicate: {
    id: 'STORE.STATUS.DUPLICATE',
    defaultMessage: 'Duplicate'
  },
  storeStatusOutOfBusiness: {
    id: 'STORE.STATUS.OUT_OF_BUSINESS',
    defaultMessage: 'Out Of Business'
  },
  departmentStatusArchived: {
    id: 'DEPARTMENT.STATUS.ARCHIVED',
    defaultMessage: 'Archived'
  },
  yes: {
    id: 'STORE.VISIBILITY.STATUS.YES',
    defaultMessage: 'Yes'
  },
  no: {
    id: 'STORE.VISIBILITY.STATUS.NO',
    defaultMessage: 'No'
  }
});
