import React from 'react';
import PropTypes from 'prop-types';
import styled, { css } from 'styled-components';

import { CONTENT_TYPES } from '../constants';
import { contentVariantStyles } from '../variant-styles';

const StyledContent = styled.p`
  ${({ variant }) =>
    variant &&
    css`
      ${contentVariantStyles[variant]};
    `}
`;

const Content = ({ children, tag = 'div', type = CONTENT_TYPES.BODY_1, ...other }) => (
  <StyledContent as={tag} variant={type} {...other}>
    {children}
  </StyledContent>
);

Content.propTypes = {
  /** Adds any content between tags */
  children: PropTypes.node,
  /** Sets the content tag */
  tag: PropTypes.elementType,
  /** Sets the content styles. */
  type: PropTypes.oneOf(Object.values(CONTENT_TYPES)),
};

export { Content };
