import React, { useRef } from 'react';
import PropTypes from 'prop-types';
import styled, { css } from 'styled-components';

import { getRem, truncateText, typographySubtitle1, visuallyHidden } from '../../core';

const StyledTableHeaderTitle = styled.span`
  ${({ theme }) => typographySubtitle1(theme)};
  ${truncateText};
  color: ${({ theme }) => theme.color.text.light.primary.value};
  padding: ${getRem(6)} ${getRem(16)} ${getRem(6)} 0;
  transform: translateX(0); /* Necessary for Edge browser to work properly */
  ${({ isHidden }) =>
    isHidden &&
    css`
      ${visuallyHidden};
    `}
`;

const TableHeaderTitle = ({ customTag = '', hideTitle = false, title = '' }) => {
  const titleRef = useRef();

  return (
    <StyledTableHeaderTitle ref={titleRef} tabIndex="0" as={customTag} isHidden={hideTitle}>
      {title}
    </StyledTableHeaderTitle>
  );
};

TableHeaderTitle.propTypes = {
  /** Ability to supply a different element instead of the default one.
   * Proper heading element (`h1`, `h2`, ...) must be provided according to page structure */
  customTag: PropTypes.elementType,
  /** If true, table title is visually hidden */
  hideTitle: PropTypes.bool,
  /** Sets a table title */
  title: PropTypes.node,
};

export { TableHeaderTitle };
