import React from 'react';
import PropTypes from 'prop-types';
import styled, { css } from 'styled-components';

import { BREAKPOINTS } from './../../core';

const widthInPercentile = (noOfColumns) => parseFloat(((noOfColumns / 12) * 100).toFixed(4));

const xSmallStyles = css`
  flex-basis: ${({ xs }) => widthInPercentile(xs)}%;
  flex-grow: 0;
  max-width: ${({ xs }) => widthInPercentile(xs)}%;
`;

const smallStyles = css`
  @media ${BREAKPOINTS.S} {
    flex-basis: ${({ s }) => widthInPercentile(s)}%;
    flex-grow: 0;
    max-width: ${({ s }) => widthInPercentile(s)}%;
  }
`;

const mediumStyles = css`
  @media ${BREAKPOINTS.M} {
    flex-basis: ${({ m }) => widthInPercentile(m)}%;
    flex-grow: 0;
    max-width: ${({ m }) => widthInPercentile(m)}%;
  }
`;

const largeStyles = css`
  @media ${BREAKPOINTS.L} {
    flex-basis: ${({ l }) => widthInPercentile(l)}%;
    flex-grow: 0;
    max-width: ${({ l }) => widthInPercentile(l)}%;
  }
`;

const xLargeStyles = css`
  @media ${BREAKPOINTS.XL} {
    flex-basis: ${({ xl }) => widthInPercentile(xl)}%;
    flex-grow: 0;
    max-width: ${({ xl }) => widthInPercentile(xl)}%;
  }
`;

const StyledColumn = styled.div`
  flex-basis: 0;
  flex-grow: 1;
  max-width: 100%;
  ${({ xs }) => xs && xSmallStyles}
  ${({ s }) => s && smallStyles}
    ${({ m }) => m && mediumStyles}
    ${({ l }) => l && largeStyles}
    ${({ xl }) => xl && xLargeStyles}
`;

const Column = ({ children = undefined, l = false, m = false, s = false, xl = false, xs = false, ...other }) => {
  return (
    <StyledColumn xs={xs} s={s} m={m} l={l} xl={xl} {...other}>
      {children}
    </StyledColumn>
  );
};

Column.propTypes = {
  /** Displays any kind of content included between opening and closing tags */
  children: PropTypes.node,
  /* Defines the number of blocks the component is going to use.
    It's applied for the `l` breakpoint and wider screens if not overridden. */
  l: PropTypes.oneOfType([PropTypes.oneOf([1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12]), PropTypes.bool]),
  /* Defines the number of blocks the component is going to use.
    It's applied for the `m` breakpoint and wider screens if not overridden. */
  m: PropTypes.oneOfType([PropTypes.oneOf([1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12]), PropTypes.bool]),
  /* Defines the number of blocks the component is going to use.
    It's applied for the `s` breakpoint and wider screens if not overridden. */
  s: PropTypes.oneOfType([PropTypes.oneOf([1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12]), PropTypes.bool]),
  /* Defines the number of blocks the component is going to use.
    It's applied for the `xl` breakpoint and wider screens if not overridden. */
  xl: PropTypes.oneOfType([PropTypes.oneOf([1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12]), PropTypes.bool]),
  /* Defines the number of blocks the component is going to use.
    It's applied for the `xs` breakpoint and wider screens if not overridden. */
  xs: PropTypes.oneOfType([PropTypes.oneOf([1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12]), PropTypes.bool]),
};

export { Column };
