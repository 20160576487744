import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

const StyledAvatarImage = styled.img`
  height: inherit;
  object-fit: cover;
  width: inherit;
`;

// TODO: decide if alt should be a string or a node. If it's a node, change the default to undefined. If it's a string, change the propType. -PaU
const AvatarImage = ({ alt = '', src = undefined, srcset = '', ...other }) => (
  <StyledAvatarImage {...{ alt, src, srcset }} data-testid="avatar-image" {...other} />
);

AvatarImage.propTypes = {
  /** Accessibility measurement for verbal image description */
  alt: PropTypes.node,
  /** Path to image file */
  src: PropTypes.string,
  /** Defines multiple sizes of the same image, allowing the browser to select the appropriate image source */
  srcset: PropTypes.string,
};

export { AvatarImage };
