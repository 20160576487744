import { defineMessages } from 'react-intl';

export const messages = defineMessages({
  cancel: {
    id: 'BUTTON.TITLE.CANCEL',
    defaultMessage: 'Cancel'
  },
  save: {
    id: 'BUTTON.TITLE.SAVE',
    defaultMessage: 'Save'
  },
  resetPasswordTitle: {
    id: 'DIALOG.RESETPASSWORD.TITLE',
    defaultMessage: 'Reset Password'
  },
  passwordMustNotContain: {
    id: 'DIALOG.RESETPASSWORD.PASSWORDMUSTNOTCONTAIN',
    defaultMessage: 'Password must not contain'
  },
  passwordMustContain: {
    id: 'DIALOG.RESETPASSWORD.PASSWORDMUSTCONTAIN',
    defaultMessage: 'Password must contain'
  },
  currentPasswordLabel: {
    id: 'DIALOG.RESETPASSWORD.CURRENTPASSWORD.LABEL',
    defaultMessage: 'Current Password'
  },
  currentPasswordError: {
    id: 'DIALOG.RESETPASSWORD.CURRENTPASSWORD.ERROR',
    defaultMessage: 'Password Required'
  },
  newPasswordLabel: {
    id: 'DIALOG.RESETPASSWORD.NEWPASSWORD.LABEL',
    defaultMessage: 'New Password'
  },
  confirmPasswordLabel: {
    id: 'DIALOG.RESETPASSWORD.CONFIRMPASSWORD.LABEL',
    defaultMessage: 'Confirm Password'
  },
  confirmPasswordError: {
    id: 'DIALOG.RESETPASSWORD.CONFIRMPASSWORD.ERROR',
    defaultMessage: 'Confirm Password doest not match with above password'
  },
  passwordErrorMessage: {
    id: 'DIALOG.RESETPASSWORD.PASSWORDERROR.MESSAGE',
    defaultMessage: 'Password must contain'
  },
  hasThreeIdenticalCharErrorWarning: {
    id: 'DIALOG.RESETPASSWORD.HASTHREEIDENTICALCHAR.ERROR.WARNING',
    defaultMessage: 'Three identical characters in a row'
  },
  hasSpacesErrorWarning: {
    id: 'DIALOG.RESETPASSWORD.HASSPACESERRORWARNING',
    defaultMessage: 'Spaces in the begining or end'
  },
  hasThreeSquentialCharErrorWarning: {
    id: 'DIALOG.RESETPASSWORD.HASTHREESQUENTIALCHAR.ERROR.WARNING',
    defaultMessage: 'Three squential character (ascending or descending)'
  },
  eightCharacter: {
    id: 'DIALOG.RESETPASSWORD.EIGHTCHARACTER',
    defaultMessage: '8-32 Characters'
  },
  lowerCase: {
    id: 'DIALOG.RESETPASSWORD.LOWERCASE',
    defaultMessage: 'Lower Case'
  },
  upperCase: {
    id: 'DIALOG.RESETPASSWORD.UPPERCASE',
    defaultMessage: 'Upper Case'
  },
  numerals: {
    id: 'DIALOG.RESETPASSWORD.NUMERALS',
    defaultMessage: 'Numerals'
  },
  specialCharacters: {
    id: 'DIALOG.RESETPASSWORD.SPECIALCHARACTERS',
    defaultMessage: 'Special Characters'
  },
  genericError: {
    id: 'DIALOG.RESETPASSWORD.GENERICERROR',
    defaultMessage: 'Password does not match requirements.'
  }
});
