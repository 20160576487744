import styled from 'styled-components';

import { truncateText, typographyBody1 } from '../../../core';

const ListItemSubtext = styled.span`
  ${truncateText};
  ${({ theme }) => typographyBody1(theme)};
  box-sizing: border-box;
  color: ${({ theme }) => theme.color.text.light.secondary.value};
  pointer-events: none;
  width: 100%;
`;

export { ListItemSubtext };
