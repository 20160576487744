import React, { forwardRef, useCallback, useEffect, useRef, useState } from 'react';

import { Portal } from 'lib/portal';
import { useEventListener } from 'lib/utilities';

import { TOOLTIP_HORIZONTAL_ALIGNMENTS, TOOLTIP_VERTICAL_ALIGNMENTS } from '../constants';
import { Tooltip } from '../elements';
import { tooltipPositioningProps } from '../propTypes';

const windowLeftOffset = 0;
const windowTopOffset = 0;

const withTooltip = (Component, tooltipProps) => {
  const WithTooltip = forwardRef(
    (
      {
        horizontalAlignment = TOOLTIP_HORIZONTAL_ALIGNMENTS.LEFT,
        marginAroundElement = 8,
        verticalAlignment = TOOLTIP_VERTICAL_ALIGNMENTS.TOP,
        ...other
      },
      ref
    ) => {
      const { id, styles, text } = tooltipProps;
      const tooltipStyling = styles || {};

      const isWindow = window !== 'undefined';
      const isDocumentReady = document !== 'undefined';
      const isInitialVerticalPositionMiddle = verticalAlignment === TOOLTIP_VERTICAL_ALIGNMENTS.MIDDLE;
      const isInitialHorizontalPositionMiddle = horizontalAlignment === TOOLTIP_HORIZONTAL_ALIGNMENTS.MIDDLE;

      const componentContainerRef = useRef();
      const tooltipRef = useRef();

      const [isVisible, setIsVisible] = useState(false);
      const [showTooltip, setShowTooltip] = useState(true);
      const [tooltipText, setTooltipText] = useState(text);
      const [windowScrollOffset, setWindowScrollOffset] = useState({});
      const [tooltipHorizontalOffset, setTooltipHorizontalOffset] = useState({ left: 0 });
      const [tooltipVerticalOffset, setTooltipVerticalOffset] = useState({ top: 0 });

      const documentSize = isDocumentReady && document.documentElement;
      const documentHeight = documentSize.clientHeight;
      const documentWidth = documentSize.clientWidth;

      const getPositionValue = useCallback((value) => (isVisible ? value : 0), [isVisible]);
      const setFullWidthTooltip = useCallback(
        () =>
          setTooltipHorizontalOffset({
            left: getPositionValue(marginAroundElement),
            right: getPositionValue(marginAroundElement),
          }),
        [getPositionValue, marginAroundElement]
      );

      useEventListener('scroll', () => {
        if (isWindow) {
          setWindowScrollOffset({
            x: window.pageXOffset,
            y: window.pageYOffset,
          });
        }
      });

      useEffect(() => {
        // Component dimensions
        const componentDimensions =
          !!componentContainerRef.current && componentContainerRef.current.getBoundingClientRect();
        const componentBottom = componentDimensions.bottom;
        const componentHeight = componentDimensions.height;
        const componentLeft = componentDimensions.left;
        const componentTop = componentDimensions.top;
        const componentRight = componentDimensions.right;
        const componentWidth = componentDimensions.width;
        const halfComponentHeight = componentHeight / 2;

        // Tooltip dimensions
        const tooltipDimensions = !!tooltipRef.current && tooltipRef.current.getBoundingClientRect();
        const tooltipHeight = tooltipDimensions.height;
        const tooltipTop = tooltipDimensions.top;
        const tooltipWidth = tooltipDimensions.width;
        const halfTooltipHeight = tooltipHeight / 2;

        // Intersections
        // Bottom
        const isTooltipIntersectingWindowBottom =
          documentHeight < componentBottom + marginAroundElement + tooltipHeight;
        // Top
        const isTooltipFitableBetweenComponentAndWindowTop = tooltipHeight < componentTop;
        const isTooltipIntersectingWindowTop = tooltipTop < windowTopOffset;
        const canTooltipBeDisplayedTop =
          !isTooltipIntersectingWindowTop && isTooltipFitableBetweenComponentAndWindowTop;
        const isMiddleVerticalTooltipIntersectingWindowTop = halfTooltipHeight - halfComponentHeight > componentTop;
        const isMiddleVerticalTooltipIntersectingWindowBottom =
          halfTooltipHeight - halfComponentHeight > documentHeight - componentBottom;
        // Left
        const isTooltipIntersectingWindowLeft = componentRight - marginAroundElement - tooltipWidth < windowLeftOffset;
        const isTooltipFitableBetweenComponentAndWindowLeft = tooltipWidth + marginAroundElement < componentLeft;

        // Right
        const isTooltipIntersectingWindowRight = componentLeft + tooltipWidth + marginAroundElement > documentWidth;
        const isTooltipFitableBetweenComponentAndWindowRight =
          tooltipWidth + marginAroundElement < documentWidth - componentRight;

        // Tooltip alignments
        // Vertical
        const alignBottom = `${componentBottom + marginAroundElement}px`;
        const alignTop = `${componentTop - tooltipHeight - marginAroundElement}px`;
        const alignMiddleVertical = `${componentTop - tooltipHeight / 2 + componentHeight / 2}px`;

        // Horizontal
        const alignLeft = `${componentLeft}px`;
        const alignMiddleVerticalLeftHorizontal = `${componentLeft - tooltipWidth - marginAroundElement}px`;
        const alignRight = `${documentWidth - componentRight}px`; // Must be set as right: alignRight
        const alignMiddleVerticalRightHorizontal = `${componentRight + marginAroundElement}px`;
        const alignMiddleHorizonal = `${componentLeft - tooltipWidth / 2 + componentWidth / 2}px`;

        // Position handlers
        // Vertical
        const handlePositionTop = () => {
          if (canTooltipBeDisplayedTop) {
            setTooltipVerticalOffset({ top: getPositionValue(alignTop) });
          } else {
            setTooltipVerticalOffset({ top: getPositionValue(alignBottom) });
          }
        };

        const handlePositionBottom = () => {
          if (!isTooltipIntersectingWindowBottom) {
            setTooltipVerticalOffset({ top: getPositionValue(alignBottom) });
          } else {
            setTooltipVerticalOffset({ top: getPositionValue(alignTop) });
          }
        };

        const handlePositionMiddle = () => {
          if (isMiddleVerticalTooltipIntersectingWindowTop) {
            setTooltipVerticalOffset({ top: getPositionValue(alignBottom) });
          } else if (isMiddleVerticalTooltipIntersectingWindowBottom) {
            setTooltipVerticalOffset({ top: getPositionValue(alignTop) });
          } else if (
            !isInitialHorizontalPositionMiddle &&
            (isTooltipFitableBetweenComponentAndWindowLeft || isTooltipFitableBetweenComponentAndWindowRight)
          ) {
            setTooltipVerticalOffset({ top: getPositionValue(alignMiddleVertical) });
          } else {
            handlePositionTop();
          }
        };

        // Horizontal
        const handlePositionLeft = () => {
          if (!isTooltipIntersectingWindowRight) {
            setTooltipHorizontalOffset({ left: getPositionValue(alignLeft) });
          } else if (isTooltipIntersectingWindowRight && !isTooltipIntersectingWindowLeft) {
            setTooltipHorizontalOffset({ right: getPositionValue(alignRight) });
          } else {
            setFullWidthTooltip();
          }
        };

        const handlePositionRight = () => {
          if (!isTooltipIntersectingWindowLeft) {
            setTooltipHorizontalOffset({ right: getPositionValue(alignRight) });
          } else if (isTooltipIntersectingWindowLeft && !isTooltipIntersectingWindowRight) {
            setTooltipHorizontalOffset({ left: getPositionValue(alignLeft) });
          } else {
            setFullWidthTooltip();
          }
        };

        const handlePositionMiddleVerticalLeftHorizontal = () => {
          if (
            isTooltipFitableBetweenComponentAndWindowLeft &&
            !isMiddleVerticalTooltipIntersectingWindowTop &&
            !isMiddleVerticalTooltipIntersectingWindowBottom
          ) {
            setTooltipHorizontalOffset({ left: getPositionValue(alignMiddleVerticalLeftHorizontal) });
          } else if (
            isTooltipFitableBetweenComponentAndWindowRight &&
            !isMiddleVerticalTooltipIntersectingWindowTop &&
            !isMiddleVerticalTooltipIntersectingWindowBottom
          ) {
            setTooltipHorizontalOffset({ left: getPositionValue(alignMiddleVerticalRightHorizontal) });
          } else if (!isTooltipIntersectingWindowLeft) {
            setTooltipHorizontalOffset({ right: getPositionValue(alignRight) });
          } else if (!isTooltipIntersectingWindowTop || !isTooltipIntersectingWindowBottom) {
            setTooltipHorizontalOffset({ left: getPositionValue(alignLeft) });
          } else {
            setFullWidthTooltip();
          }
        };

        const handlePositionMiddleHorizontal = () => {
          if (!isTooltipIntersectingWindowLeft && !isTooltipIntersectingWindowRight) {
            setTooltipHorizontalOffset({ left: getPositionValue(alignMiddleHorizonal) });
          } else if (isTooltipIntersectingWindowLeft && !isTooltipIntersectingWindowRight) {
            setTooltipHorizontalOffset({ left: getPositionValue(alignLeft) });
          } else if (isTooltipIntersectingWindowRight && !isTooltipIntersectingWindowLeft) {
            setTooltipHorizontalOffset({ right: getPositionValue(alignRight) });
          } else {
            setFullWidthTooltip();
          }
        };

        const handlePositionMiddleVerticalRightHorizontal = () => {
          if (
            isTooltipFitableBetweenComponentAndWindowRight &&
            !isMiddleVerticalTooltipIntersectingWindowTop &&
            !isMiddleVerticalTooltipIntersectingWindowBottom
          ) {
            setTooltipHorizontalOffset({ left: getPositionValue(alignMiddleVerticalRightHorizontal) });
          } else if (
            isTooltipFitableBetweenComponentAndWindowLeft &&
            !isMiddleVerticalTooltipIntersectingWindowTop &&
            !isMiddleVerticalTooltipIntersectingWindowBottom
          ) {
            setTooltipHorizontalOffset({ left: getPositionValue(alignMiddleVerticalLeftHorizontal) });
          } else if (!isTooltipIntersectingWindowRight) {
            setTooltipHorizontalOffset({ left: getPositionValue(alignLeft) });
          } else if (!isTooltipIntersectingWindowTop || !isTooltipIntersectingWindowBottom) {
            setTooltipHorizontalOffset({ right: getPositionValue(alignRight) });
          } else {
            setFullWidthTooltip();
          }
        };

        // Vertical alignment handling
        switch (verticalAlignment) {
          case TOOLTIP_VERTICAL_ALIGNMENTS.BOTTOM:
            handlePositionBottom();
            break;
          case TOOLTIP_VERTICAL_ALIGNMENTS.MIDDLE:
            handlePositionMiddle();
            break;
          default:
            handlePositionTop();
        }

        // Horizontal alignment handling
        switch (horizontalAlignment) {
          case TOOLTIP_HORIZONTAL_ALIGNMENTS.MIDDLE:
            handlePositionMiddleHorizontal();
            break;
          case TOOLTIP_HORIZONTAL_ALIGNMENTS.RIGHT:
            if (isInitialVerticalPositionMiddle) {
              handlePositionMiddleVerticalRightHorizontal();
            } else {
              handlePositionRight();
            }
            break;
          default:
            if (isInitialVerticalPositionMiddle) {
              handlePositionMiddleVerticalLeftHorizontal();
            } else {
              handlePositionLeft();
            }
        }
      }, [
        documentHeight,
        documentWidth,
        getPositionValue,
        horizontalAlignment,
        isInitialHorizontalPositionMiddle,
        isInitialVerticalPositionMiddle,
        marginAroundElement,
        setFullWidthTooltip,
        verticalAlignment,
        windowScrollOffset,
      ]);

      const handleOnMouseOver = () => {
        if (showTooltip) setIsVisible(true);
      };

      const handleOnFocus = () => {
        if (showTooltip) setIsVisible(true);
      };

      const handleOnMouseOut = () => {
        if (showTooltip) setIsVisible(false);
      };

      const handleOnBlur = () => {
        if (showTooltip) setIsVisible(false);
      };

      return (
        <>
          <div ref={componentContainerRef}>
            <Component
              aria-describedby={id}
              onBlur={handleOnBlur}
              onFocus={handleOnFocus}
              onMouseOut={handleOnMouseOut}
              onMouseOver={handleOnMouseOver}
              ref={ref}
              setTooltipText={setTooltipText}
              showTooltip={setShowTooltip}
              {...other}
            />
          </div>
          {showTooltip && isVisible && (
            <Portal id={`tooltip-portal-${id}`}>
              <Tooltip
                {...tooltipProps}
                ref={tooltipRef}
                style={{
                  ...tooltipStyling,
                  ...tooltipHorizontalOffset,
                  ...tooltipVerticalOffset,
                }}
                text={tooltipText}
              />
            </Portal>
          )}
        </>
      );
    }
  );

  WithTooltip.propTypes = tooltipPositioningProps;

  return WithTooltip;
};

export { withTooltip };
