import { defineMessages } from 'react-intl';

export const messages = defineMessages({
  firstName: {
    id: 'GRID.EDITIMPORTSTEPTHREE.COLUMN.HEADER.FIRSTNAME',
    defaultMessage: 'First Name'
  },
  lastName: {
    id: 'GRID.EDITIMPORTSTEPTHREE.COLUMN.HEADER.LASTNAME',
    defaultMessage: 'Last Name'
  },
  dmsUserId: {
    id: 'GRID.EDITIMPORTSTEPTHREE.COLUMN.HEADER.DMSUSERID',
    defaultMessage: 'DMS User ID'
  },
  cNumber: {
    id: 'GRID.EDITIMPORTSTEPTHREE.COLUMN.HEADER.CNUMBER',
    defaultMessage: 'CNumber'
  },
  primaryEmail: {
    id: 'GRID.EDITIMPORTSTEPTHREE.COLUMN.HEADER.EMAIL',
    defaultMessage: 'Primary Email'
  },
  federationId: {
    id: 'GRID.EDITIMPORTSTEPTHREE.COLUMN.HEADER.FEDERATIONID',
    defaultMessage: 'Federation ID'
  },
  employeeId: {
    id: 'GRID.EDITIMPORTSTEPTHREE.COLUMN.HEADER.EMPLOYEEID',
    defaultMessage: 'DMS Employee ID'
  },
  jobProfile: {
    id: 'GRID.EDITIMPORTSTEPTHREE.COLUMN.HEADER.JOBTITLE',
    defaultMessage: 'DMS Job Profile'
  },
  cdkSimpleID: {
    id: 'GRID.EDITIMPORTSTEPTHREE.COLUMN.HEADER.SIMPLEID',
    defaultMessage: 'CDK Simple ID'
  },
  dmsAccount: {
    id: 'GRID.EDITIMPORTSTEPTHREE.COLUMN.HEADER.DMSACCOUNT',
    defaultMessage: 'DMS Account'
  },
  roles: {
    id: 'GRID.EDITIMPORTSTEPTHREE.COLUMN.HEADER.ROLES',
    defaultMessage: 'Roles'
  },
  stores: {
    id: 'GRID.EDITIMPORTSTEPTHREE.COLUMN.HEADER.STORES',
    defaultMessage: 'Stores'
  }
});
