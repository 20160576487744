import moment, { Moment } from 'moment';
import { useIntl } from 'react-intl';
import { AutocompleteDropdown } from 'components/organisms/common/AutocompleteDropdown/AutocompleteDropdown';
import { Option } from 'components/organisms/common/AutocompleteDropdown/types';
import StyledStepFourTimeInput from './styled';
import scheduleTimeItems from '../../../../../fixtures/scheduleTimeItems';
import { messages } from './messages';

type StepFourTimeInputProps = {
  handleTimeChange: (time?: Option) => void;
  time: Option | null;
  date: Moment | null;
  // eslint-disable-next-line react/require-default-props
  hasNoNow?: boolean;
  importTimeError: string;
  importTimeValid: boolean | null;
};

const getTimeOptions = (
  timeOptions: Record<string, string>[],
  selectedDate: Moment | null,
  hasNoNow: boolean
) => {
  if (
    selectedDate &&
    selectedDate.format('MM/DD/yy') === moment().format('MM/DD/yy')
  ) {
    if (hasNoNow) {
      return [
        ...timeOptions.filter(timeOption =>
          moment().isBefore(moment(timeOption.value, 'hh:mm A'))
        )
      ];
    }
    return [
      {
        label: 'Now',
        value: 'Now'
      },
      ...timeOptions.filter(timeOption =>
        moment().isBefore(moment(timeOption.value, 'hh:mm A'))
      )
    ];
  }
  return timeOptions;
};

const StepFourTimeInput = ({
  date,
  handleTimeChange,
  time,
  hasNoNow = false,
  importTimeError,
  importTimeValid
}: StepFourTimeInputProps): JSX.Element => {
  const intl = useIntl();

  return (
    <StyledStepFourTimeInput>
      <div data-testid="step-four-time" className="schedule-time">
        <AutocompleteDropdown
          id="input-simple-stepFour"
          dataTestId="stepFour-dropdown"
          options={
            getTimeOptions(
              scheduleTimeItems,
              date,
              hasNoNow
            ) as unknown as Option[]
          }
          selectedDate={date}
          value={time}
          onChange={handleTimeChange}
          isLoading={false}
          minChars={3}
          enableCustomValidation
          errorMessage={importTimeError}
          hasError={importTimeValid === false}
          inputProps={{
            label: intl.formatMessage(messages.stepFourScheduleTime),
            placeholder: intl.formatMessage(messages.stepFourPlaceholder),
            helperText: intl.formatMessage(messages.stepFourTimeHelper)
          }}
        />
      </div>
    </StyledStepFourTimeInput>
  );
};

export default StepFourTimeInput;
