import styled from 'styled-components';
import { Dialog, Content } from 'cdk-radial';

export const StyledResetPasswordDialog = styled(Dialog)`
  min-width: 50vw;
  height: 50vh;
  div[data-testid$='dialog-content'] {
    height: 100%;
  }
  #password-error-toast {
    margin-bottom: 0.5rem;
  }
`;

export const StyledDialogContent = styled.div`
  display: grid;
  height: 100%;
  grid-template-columns: 50% 4px 50%;
`;

export const StyledDialogLeftContent = styled.div`
  display: grid;
  row-gap: 0.5rem;
  align-content: start;
  margin-right: 0.5rem;
`;

type StyledVerticalLineProps = {
  height?: string;
};
export const StyledVerticalLine = styled.div<StyledVerticalLineProps>`
  width: 0px;
  height: ${props => props.height || '100%'};
  border: 1px inset;
`;

export const StyledDialogRightContent = styled.div`
  margin-left: 0.5rem;
  .reset-password-title {
    margin-top: 24px;
  }
`;

export const StyledRightContentTitle = styled(Content)`
  font-weight: bold;
  margin: 0px 8px 8px 0px;
`;

export const StyledPasswordRequirementContainer = styled.div`
  display: grid;
  row-gap: 0.5rem;
  grid-template-columns: auto auto;
`;

export const StyledPasswordRequirement = styled.div`
  display: flex;
  .success {
    fill: green;
  }
`;

export const StyledRequirementContent = styled(Content)`
  margin-left: 8px;
`;

export const StyledPasswordErrorContainer = styled.div`
  display: grid;
  row-gap: 0.5rem;
  .error {
    fill: red;
  }
`;
