import { KEY_VALUES } from './keyValues';
import { useEventListener } from './useEventListener';

export const useTabOutEventListener = (ref, callback) => {
  const handleKeyUp = (event) => {
    const isTabPressed = event.key === KEY_VALUES.TAB;
    const isTargetOutside = ref.current && !ref.current.contains(event.target);

    if (isTabPressed && isTargetOutside && callback) {
      callback();
    }
  };

  useEventListener('keyup', handleKeyUp);
};
