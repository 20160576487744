import { useState } from 'react';
import { Button, BUTTON_VARIANTS, Content } from 'cdk-radial';
import { useIntl } from 'react-intl';
import StyledExpandableChips from './styled';
import { ExpandableListProps } from './types';
import { messages } from './messages';

export const ExpandableList = ({
  data,
  sliceBy
}: ExpandableListProps): JSX.Element => {
  const intl = useIntl();
  const [isExpanded, setIsExpanded] = useState<boolean>(false);

  const handleClick = () => setIsExpanded(prevState => !prevState);

  const getButtonText = () => {
    const moreText = intl.formatMessage(messages.moreText, {
      count: data?.length - sliceBy
    });
    const lessText = intl.formatMessage(messages.lessText);
    return isExpanded ? lessText : moreText;
  };

  const renderItems = (items: string[]) =>
    items.map((d, index) => (
      <Content key={d} data-testid={`Expandablechips-item-${index}`}>
        {`${d}${index !== items.length - 1 ? ',' : ''}`}
      </Content>
    ));

  const shouldShowButton = data?.length > sliceBy;

  return (
    <StyledExpandableChips data-testid="Expandablechips-container">
      {shouldShowButton ? (
        <>
          {renderItems(isExpanded ? data : data.slice(0, sliceBy))}
          <Button
            dataTestId="copy-icon-button"
            variant={BUTTON_VARIANTS.TEXT}
            text={getButtonText()}
            onClick={handleClick}
          />
        </>
      ) : (
        renderItems(data)
      )}
    </StyledExpandableChips>
  );
};

export default ExpandableList;
