import React from 'react';

import { getStateStyles } from 'lib/context-menu/contextMenuItemStateStyles';
import { focusOutlineColors, getRem } from 'lib/core';
import { IconCheck } from 'lib/icons';
import { ListItemPrimaryText, ListItemText, ListItem, ListItemGraphic, ListItemCheckbox } from 'lib/list';
import PropTypes from 'prop-types';
import styled, { css } from 'styled-components';

const StyledIconCheck = styled(IconCheck)`
  ${({ theme }) => css`
    align-self: flex-start;
    fill: ${theme.color.primary[500].value};
    flex-shrink: 0;
    height: 100%;
    padding-left: ${theme.size.spacing.medium.value};
    width: ${getRem(18)};
  `}
`;

const StyledListItemPrimaryText = styled(ListItemPrimaryText)`
  color: ${({ isDisabled, theme }) => isDisabled && theme.color.text.light.disabled.value};
`;

const StyledListItemCheckbox = styled(ListItemCheckbox)`
  margin-left: ${getRem(2)};
  padding-right: 0;
`;

const StyledListItem = styled(ListItem)`
  overflow: visible;
  ${({ isSelected, showCheckbox }) => css`
    ${!showCheckbox && `padding-right: ${isSelected ? getRem(6) : getRem(38)}`};
    ${showCheckbox ? getStateStyles(false).default : getStateStyles(isSelected).default};
  `}

  ${({ isDisabled, isKeyboardFocused, isSelected, showCheckbox, theme }) =>
    !isDisabled &&
    css`
      cursor: pointer;
      &:hover {
        ${/* sc-selector */ ListItemCheckbox}::after {
          background-color: ${isSelected
            ? theme.color.state.primary.hover.value
            : theme.color.state.default.hover.value};
        }
        ${!showCheckbox && getStateStyles(isSelected).hover}
      }

      &:focus {
        ${/* sc-selector */ ListItemCheckbox}::after {
          background-color: ${isSelected
            ? theme.color.state.primary.focus.value
            : theme.color.state.default.focus.value};
          ${isKeyboardFocused &&
          css`
            box-shadow: 0 0 0 4px
              ${isSelected ? focusOutlineColors.getPrimary(theme) : focusOutlineColors.getDark(theme)};
          `}
        }
        ${!showCheckbox && isKeyboardFocused && getStateStyles(isSelected).focus}
        &:not(:hover) {
          ${/* sc-selector */ ListItemCheckbox}::after {
            ${!isKeyboardFocused &&
            css`
              background-color: transparent;
            `}
          }
        }
      }

      &:active {
        ${/* sc-selector */ ListItemCheckbox}::after {
          background-color: ${isSelected
            ? theme.color.state.primary.pressed.value
            : theme.color.state.default.pressed.value};
          box-shadow: none;
        }
        ${!showCheckbox && getStateStyles(isSelected).active}
      }
    `}

  ${({ isDisabled }) =>
    isDisabled &&
    css`
      cursor: not-allowed;
    `}
`;

const MenuListItem = React.forwardRef(
  (
    {
      children,
      className,
      customListItemTag = undefined,
      dataTestId = '',
      graphicContent = undefined,
      hideSelectedItemCheckmark = false,
      indeterminate = false,
      isDisabled = false,
      isKeyboardFocused = false,
      isSelected = false,
      onClick = undefined,
      onSelect = undefined,
      showCheckbox = false,
      showDivider = false,
      tabIndex = 0,
    },
    ref
  ) => {
    const handleClick = () => {
      onClick();
      if (onSelect) {
        onSelect(ref);
      }
    };
    return (
      <StyledListItem
        onClick={handleClick}
        hideStateVisibility={showCheckbox}
        data-testid={dataTestId}
        {...{
          className,
          customListItemTag,
          isDisabled,
          isKeyboardFocused,
          isSelected,
          showCheckbox,
          showDivider,
          tabIndex,
        }}
        ref={ref}
      >
        {showCheckbox && (
          <StyledListItemCheckbox
            data-testid={dataTestId ? `${dataTestId}-checkbox` : undefined}
            checked={isSelected}
            {...{ indeterminate, isDisabled, isKeyboardFocused }}
          />
        )}
        {graphicContent && (
          <ListItemGraphic data-testid={dataTestId ? `${dataTestId}-graphic` : undefined} isDisabled={isDisabled}>
            {graphicContent}
          </ListItemGraphic>
        )}
        <ListItemText data-testid={dataTestId ? `${dataTestId}-text` : undefined}>
          <StyledListItemPrimaryText
            data-testid={dataTestId ? `${dataTestId}-primarytext` : undefined}
            isDisabled={isDisabled}
          >
            {children}
          </StyledListItemPrimaryText>
        </ListItemText>
        {isSelected && !showCheckbox && !hideSelectedItemCheckmark && <StyledIconCheck />}
      </StyledListItem>
    );
  }
);

MenuListItem.propTypes = {
  /** Flag to show menu item as selected */
  isSelected: PropTypes.bool,
  /** Node/element to show inside menu item */
  children: PropTypes.node.isRequired,
  /** Adds new class to Menu */
  className: PropTypes.string,
  /** Callback function to trigger on click */
  onClick: PropTypes.func,
  /** Tab index attribute to enable keyboard focus */
  tabIndex: PropTypes.number,
  /** Flag to enable keyboard styles on menu items */
  isKeyboardFocused: PropTypes.bool,
  /** Disable filter to disable menu item */
  isDisabled: PropTypes.bool,
  /** Ability to supply a different element instead of the default one for ListItem element */
  customListItemTag: PropTypes.elementType,
  /** Accepts Avatar / Icon / Image inside menu item  */
  graphicContent: PropTypes.node,
  /** Enables Checkbox in menu item */
  showCheckbox: PropTypes.bool,
  /** Enables divider between menu items */
  showDivider: PropTypes.bool,
  /** Id value used for testing */
  dataTestId: PropTypes.string,
  /** Changes the icon and sets checkbox indeterminate parameter state */
  indeterminate: PropTypes.bool,
  /** callback function which triggers when selected is passed */
  onSelect: PropTypes.func,
  /** Hide selected Icon */
  hideSelectedItemCheckmark: PropTypes.bool,
};

export { MenuListItem };
