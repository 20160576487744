import { Chip } from 'cdk-radial';
import { useEffect, useRef, useState } from 'react';
import {
  StyledSingleSelectFilter,
  StyledMenu,
  StyledMenuListItem
} from './styled';
import { OptionType } from '../../../../helpers/types/tableFilterType';
import getLabel from '../../../../helpers/filterHeaderHelper';

type SingleSelectFilterProps = {
  options: OptionType[];
  selectedOptions: OptionType[];
  setSelectedOptions: (options: OptionType[]) => void;
  prefix: string;
};

// Do not connect this directy to store
const SingleSelectFilter = ({
  options,
  selectedOptions,
  setSelectedOptions,
  prefix
}: SingleSelectFilterProps): JSX.Element => {
  const [filterDropDown, setFilterDropDown] = useState(true);
  const targetRef = useRef();

  useEffect(() => {
    if (!filterDropDown) {
      setFilterDropDown(true);
    }
  }, [filterDropDown]);

  const handleSelect = (option: OptionType) => {
    setSelectedOptions([option]);
    setFilterDropDown(false);
  };

  const handleClearSelect = () => {
    setFilterDropDown(false);
    setSelectedOptions([]);
  };
  return (
    <StyledSingleSelectFilter data-testid="single-select-container">
      <Chip
        label={getLabel(selectedOptions, prefix)}
        ref={targetRef}
        isSelected={selectedOptions?.length}
        onClick={() => undefined}
        data-testid="chip"
        onRemove={
          selectedOptions.length ? () => handleClearSelect() : undefined
        }
        hasDropdown={selectedOptions?.length === 0}
      />
      {filterDropDown && (
        <StyledMenu labelRef={targetRef} isAuto={false} data-testid="menu">
          {options.map(option => (
            <StyledMenuListItem
              key={option.value}
              isSelected={
                selectedOptions?.length > 0 &&
                selectedOptions[0].label === option.label
              }
              onClick={() => handleSelect(option)}
              isDisabled={option.disabled}
            >
              {option.label}
            </StyledMenuListItem>
          ))}
        </StyledMenu>
      )}
    </StyledSingleSelectFilter>
  );
};

export default SingleSelectFilter;
