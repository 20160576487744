import React, { useEffect } from 'react';
import moment, { Moment } from 'moment';
import { Input } from 'cdk-radial';
import { useIntl } from 'react-intl';
import { useDispatch, useSelector } from 'react-redux';
import { TimeType } from 'utils/utility';
import {
  setContentHeader,
  setJobName,
  setImportTime,
  setImportDate
} from 'redux/slices/userImportSlice';
import { Option } from 'components/organisms/common/AutocompleteDropdown/types';
import { RootState } from 'redux/store';
import StyledScheduleImport from './styled';
import StepFourTitle from '../../../molecules/NewUserImport/StepFour/StepFourTitle/StepFourTitle';
import StepFourDateInput from '../../../molecules/NewUserImport/StepFour/StepFourDateInput/StepFourDateInput';
import StepFourTimeInput from '../../../molecules/NewUserImport/StepFour/StepFourTimeInput/StepFourTimeInput';
import { messages } from './messages';

const StepFour = (): JSX.Element => {
  const dispatch = useDispatch();
  const intl = useIntl();

  const {
    importTime,
    importDate,
    jobName,
    newUserValidations: {
      step_four: {
        jobName: { isValid: jobNameValid, error: jobNameError },
        importDate: { isValid: importDateValid, error: importDateError },
        importTime: { isValid: importTimeValid, error: importTimeError }
      }
    }
  } = useSelector((state: RootState) => state.slices.userImportState);

  useEffect(() => {
    const navTitle = intl.formatMessage(messages.title);
    dispatch(setContentHeader(navTitle));
  }, [dispatch, intl]);

  useEffect(() => {
    if (
      importDate &&
      importDate?.format('MM/DD/yy') !== moment().format('MM/DD/yy') &&
      (importTime?.value === 'Now' ||
        (importTime?.value !== 'Now' &&
          moment(importTime?.value, 'hh:mm A').isBefore(moment())))
    ) {
      dispatch(
        setImportTime({
          label: '',
          value: ''
        })
      );
    }
  }, [importDate, dispatch, importTime]);

  const handleInputChange = (e: React.SyntheticEvent): void => {
    const target = e.target as HTMLInputElement;
    dispatch(setJobName(target.value.toString()));
  };
  const handleDateChange = (scheduledate: Moment | null) => {
    dispatch(setImportDate(scheduledate));
  };

  function isTimeType(option: Option): option is TimeType {
    return typeof option.label === 'string' && typeof option.value === 'string';
  }

  const handleTimeChange = (scheduletime: Option | undefined) => {
    if (scheduletime && isTimeType(scheduletime)) {
      dispatch(setImportTime(scheduletime as unknown as TimeType));
    } else {
      dispatch(setImportTime({ label: '', value: '' }));
    }
  };

  return (
    <>
      <StyledScheduleImport>
        <div>
          <div
            data-testid="step-four-container"
            className="step-four-container"
          >
            <StepFourTitle />
            <div className="schedule-input">
              <Input
                helperText=""
                id="input-simple"
                label={intl.formatMessage(messages.importName)}
                data-testid="input-simple"
                name="input-simple"
                enableCustomValidation
                errorMessage={jobNameError}
                hasError={jobNameValid === false}
                isRequired
                onChange={handleInputChange}
                placeholder=""
                value={jobName}
              />
            </div>
            <StepFourDateInput
              handleDateChange={handleDateChange}
              date={importDate}
              importDateError={importDateError}
              importDateValid={importDateValid}
            />
            <StepFourTimeInput
              handleTimeChange={handleTimeChange}
              date={importDate}
              time={importTime}
              importTimeError={importTimeError}
              importTimeValid={importTimeValid}
            />
          </div>
        </div>
      </StyledScheduleImport>
    </>
  );
};

export default StepFour;
