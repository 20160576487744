import React from 'react';
import { Avatar, AVATAR_COLORS, AVATAR_SIZES, AVATAR_VARIANTS } from 'lib/avatar';
import { TOOLTIP_HORIZONTAL_ALIGNMENTS, TOOLTIP_VERTICAL_ALIGNMENTS } from 'lib/tooltip';
import { THEMES } from 'lib/utilities';
import PropTypes from 'prop-types';

import { LIST_SIZES, LIST_ITEM_DIVIDER_TYPES } from '../../constants';
import {
  ListItem,
  ListItemGraphic,
  ListItemMetadata,
  ListItemPrimaryText,
  ListItemSubtext,
  ListItemText,
  ListWrapper,
} from '../elements';

const StandardList = ({
  className = undefined,
  customListItemPrimaryTextTag = undefined,
  customListItemTag = undefined,
  dataTestId = undefined,
  dividerType = LIST_ITEM_DIVIDER_TYPES.FULL,
  items,
  showDivider = false,
  size = LIST_SIZES.STANDARD,
  ...other
}) => {
  const hasItems = !!items && items.length;
  const isAnyItemInteractable = hasItems && items.some((item) => (item.href || item.onClick) !== undefined);
  const avatarSize = size === LIST_SIZES.STANDARD ? AVATAR_SIZES.RESPONSIVE : AVATAR_SIZES.STANDARD;

  if (hasItems) {
    return (
      <ListWrapper as={isAnyItemInteractable && 'div'} className={className} data-testid={dataTestId} {...other}>
        {items.map((item, index) => (
          <ListItem
            className={className ? `${className}-button` : undefined}
            data-testid={dataTestId ? `${dataTestId}-item-button-${index}` : undefined}
            hasAvatar={!!item.avatarProps}
            hasIcon={!!item.icon}
            href={item.href}
            id={item.id}
            isDisabled={item.isDisabled}
            isMultiLine={!!item.subtext}
            key={item.id || index}
            onClick={item.onClick}
            isLastItem={items.length - 1 === index}
            {...{ customListItemTag, dividerType, showDivider, size }}
            {...item}
          >
            {!!item.avatarProps && (
              <ListItemGraphic>
                <Avatar
                  className={className ? `${className}-avatar` : undefined}
                  data-testid={dataTestId ? `${dataTestId}-item-avatar-${index}` : undefined}
                  id={`avatar-${item.id}`}
                  size={avatarSize}
                  {...item.avatarProps}
                />
              </ListItemGraphic>
            )}
            {!!item.icon && (
              <ListItemGraphic
                className={className ? `${className}-icon` : undefined}
                data-testid={dataTestId ? `${dataTestId}-item-icon-${index}` : undefined}
                id={`icon-${item.id}`}
                isDisabled={item.isDisabled}
              >
                {item.icon}
              </ListItemGraphic>
            )}
            <ListItemText
              className={className ? `${className}-text` : undefined}
              data-testid={dataTestId ? `${dataTestId}-item-text-${index}` : undefined}
              id={`text-${item.id}`}
            >
              <ListItemPrimaryText
                as={customListItemPrimaryTextTag}
                className={className ? `${className}-primarytext` : undefined}
                data-testid={dataTestId ? `${dataTestId}-item-primarytext-${index}` : undefined}
                id={`primarytext-${item.id}`}
                isDisabled={item.isDisabled}
                size={size}
              >
                {item.text}
              </ListItemPrimaryText>
              <ListItemSubtext
                className={className ? `${className}-subtext` : undefined}
                data-testid={dataTestId ? `${dataTestId}-item-subtext-${index}` : undefined}
                id={`subtext-${item.id}`}
              >
                {item.subtext}
              </ListItemSubtext>
            </ListItemText>
            {!!item.metadata && (
              <ListItemMetadata
                className={className ? `${className}-metadata` : undefined}
                id={`metadata-${item.id}`}
                size={size}
              >
                {item.metadata}
              </ListItemMetadata>
            )}
          </ListItem>
        ))}
      </ListWrapper>
    );
  }

  return null;
};

StandardList.propTypes = {
  /** Adds additional class for styling */
  className: PropTypes.string,
  /** Ability to supply a different element instead of the default one for ListItemPrimaryText element */
  customListItemPrimaryTextTag: PropTypes.elementType,
  /** Ability to supply a different element instead of the default one for ListItem element */
  customListItemTag: PropTypes.elementType,
  /** Id value used for testing */
  dataTestId: PropTypes.string,
  /** Changes divider length */
  dividerType: PropTypes.oneOf(Object.values(LIST_ITEM_DIVIDER_TYPES)),
  /** List data which gets rendered */
  items: PropTypes.arrayOf(
    PropTypes.shape({
      /** Renders avatar as a first element inside list item */
      avatarProps: PropTypes.shape({
        /** Accessibility measurement for verbal image description */
        alt: PropTypes.node,
        /** Sets background color of avatar component */
        color: PropTypes.oneOf(Object.values(AVATAR_COLORS)),
        /** Outputs icon inside the avatar. Use icon component from the library */
        icon: PropTypes.node,
        /** If true, disables item interactions */
        isDisabled: PropTypes.bool,
        /** Username, that this avatar depicts. */
        label: PropTypes.node.isRequired,
        /** Avatar wrapper size. Icon size changes depending on wrapper size */
        size: PropTypes.oneOf(Object.values(AVATAR_SIZES)),
        /** Path to image file */
        src: PropTypes.string,
        /** Defines multiple sizes of the same image */
        srcset: PropTypes.string,
        /** Avatar variant. Values: [EMPTY, TEXT, ICON, IMAGE]. Default TEXT */
        variant: PropTypes.oneOf(Object.values(AVATAR_VARIANTS)),
      }),
      /** Custom anchor Element */
      customAnchorElement: PropTypes.func,
      /** Sets hyperlink for anchor tag */
      href: PropTypes.string,
      /** Renders icon as a first element inside list item */
      icon: PropTypes.node,
      /** Unique single list item identifier */
      id: PropTypes.string,
      /** Renders meta information after main text group */
      metadata: PropTypes.node,
      /** Callback function for item */
      onClick: PropTypes.func,
      /** Custom anchor Element */
      renderCustomAnchorElement: PropTypes.func,
      /** Secondary line of text */
      subtext: PropTypes.node,
      /** Main list item content */
      text: PropTypes.node.isRequired,
      /** Display a tooltip */
      tooltip: PropTypes.shape({
        /** Dynamically show/hide tooltip */
        showTooltip: PropTypes.bool,
        /** Dynamically change tooltip text */
        setTooltipText: PropTypes.string,
        /** Tooltip positioning props */
        positioningProps: PropTypes.shape({
          /** Aligns tooltip horizontally by wrapped component */
          horizontalAlignment: PropTypes.oneOf(Object.values(TOOLTIP_HORIZONTAL_ALIGNMENTS)),
          /** Distance between element and tooltip */
          marginAroundElement: PropTypes.number,
          /** Aligns tooltip vertically by wrapped component */
          verticalAlignment: PropTypes.oneOf(Object.values(TOOLTIP_VERTICAL_ALIGNMENTS)),
        }),
        /** Tooltip props */
        tooltipProps: PropTypes.shape({
          /** Unique identifier of the component */
          id: PropTypes.string.isRequired,
          /** Inline styles applied to main component wrapper */
          style: PropTypes.shape({}),
          /** Component content */
          text: PropTypes.node.isRequired,
          /** Changes style depending on theme */
          theme: PropTypes.oneOf(Object.values(THEMES)),
        }),
      }),
    })
  ).isRequired,
  /** If true, displays divider after each item, if there are more than one */
  showDivider: PropTypes.bool,
  /** Changes list item height */
  size: PropTypes.oneOf(Object.values(LIST_SIZES)),
};

export { StandardList };
