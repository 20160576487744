/* eslint-disable @typescript-eslint/no-unsafe-call */
import { useState, useEffect } from 'react';
import StyledErrorTable from 'components/molecules/csvUserImport/StepTwo/ErrorTable/styled/index';
import TableContent from 'components/templates/TableLayout/TableContent/TableContent';
import TablePaging from 'components/templates/TableLayout/TablePaging/TablePaging';
import errorConfig from 'fixtures/grid/errorTable/columnDefinitions';
import TableContainer from 'components/templates/TableLayout/TableContainer/TableContainer';
import { CSV_TEMPLATE } from 'components/molecules/csvUserImport/StepTwo/ErrorTable/constant';
import { ErrorTablePropsType } from 'components/molecules/csvUserImport/StepTwo/ErrorTable/types';

const ErrorTable = ({ rows, uid }: ErrorTablePropsType): JSX.Element => {
  const [gridApi, setGridApi] = useState<any>(null);
  const [data, setData] = useState<any>([]);
  const [currentPage, setCurrentPage] = useState(0);
  const [totalPages, setTotalPages] = useState(0);
  const [totalRows, setTotalRows] = useState(0);

  useEffect(() => {
    if (rows && rows.length) {
      setData(rows);
    }
  }, [rows]);

  const { useDmsColumns, useNondmscolumns, useBulkUnassignUsersColumns } =
    errorConfig;

  const [dmsColumns] = useDmsColumns();
  const [nonDMSColumns] = useNondmscolumns();
  const [bulkUnassignUsersColumns] = useBulkUnassignUsersColumns();

  let columns;
  if (uid === CSV_TEMPLATE.DMS) {
    columns = dmsColumns;
  } else if (uid === CSV_TEMPLATE.BULK_UNASSIGN_USERS) {
    columns = bulkUnassignUsersColumns;
  } else {
    columns = nonDMSColumns;
  }

  return (
    <StyledErrorTable>
      <TableContainer showBorder>
        <TableContent
          gridApi={gridApi}
          searchValue=""
          filterSchema={[]}
          filterSelections={{}}
          setGridApi={setGridApi}
          setCurrentPage={setCurrentPage}
          setTotalPages={setTotalPages}
          setTotalRows={setTotalRows}
          tableHeight={190}
          columns={columns}
          data={data}
        />
        <TablePaging
          gridApi={gridApi}
          currentPage={currentPage}
          totalPages={totalPages}
          totalRows={totalRows}
        />
      </TableContainer>
    </StyledErrorTable>
  );
};

export default ErrorTable;
