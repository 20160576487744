import {
  INPUT_SIZES,
  SearchInput,
  IconFileDownload,
  IconButton
} from 'cdk-radial';
import { useIntl } from 'react-intl';
import { messages } from 'components/templates/messages';
import MultiSelectFilter from '../../../molecules/common/MultiSelectFilter/MultiSelectFilter';
import SingleSelectFilter from '../../../molecules/common/SingleSelectFilter/SingleSelectFilter';
import { OptionType } from '../../../../helpers/types/tableFilterType';
import { FilterHeaderProps } from './types';
import StyledFilterHeader from './styled';

const FilterHeader = ({
  filterSchema,
  searchValue,
  filterSelections,
  setSearchValue,
  setFilterSelections,
  isDownloadable,
  onDownloadClick,
  disableDownloadButton,
  isMenuListAuto
}: FilterHeaderProps): JSX.Element => {
  const intl = useIntl();
  const handleSearchChange = (value: string) => {
    setSearchValue(value);
  };

  const handleSelectChange = (options: any, uid: string) => {
    const newSelections = { ...filterSelections, [uid]: options };
    setFilterSelections(newSelections);
  };

  return (
    <StyledFilterHeader data-testid="filter-header">
      <SearchInput
        dataTestId="customize-search-input"
        className="customize-search-input"
        clearButtonLabel="clearButtonLabel"
        id="search-input-expanded"
        isAlwaysExpanded
        name="search-input-expanded"
        onChange={handleSearchChange}
        placeholder={intl.formatMessage(messages.searchPlaceholder)}
        size={INPUT_SIZES.STANDARD}
        value={searchValue}
      />
      {filterSchema.map((filterObject, index) =>
        filterObject.multi ? (
          <div
            key={filterObject.uid}
            data-testid={`customize-multi-select-${index}`}
            className="customize-multi-select"
          >
            <MultiSelectFilter
              data-testid="multi-select"
              options={filterObject.options}
              prefix={filterObject.label}
              setSelectedOptions={(options: OptionType[]) =>
                handleSelectChange(options, filterObject.uid)
              }
              selectedOptions={filterSelections[filterObject.uid]}
              isAuto={isMenuListAuto}
            />
          </div>
        ) : (
          <div
            key={filterObject.uid}
            data-testid={`customize-multi-select-${index}`}
            className="customize-multi-select"
          >
            <SingleSelectFilter
              data-testid="multi"
              options={filterObject.options}
              prefix={filterObject.label}
              setSelectedOptions={(options: OptionType[]) =>
                handleSelectChange(options, filterObject.uid)
              }
              selectedOptions={filterSelections[filterObject.uid]}
            />
          </div>
        )
      )}

      {isDownloadable && (
        <IconButton
          className="customize-download-icon"
          dataTestId="icon-button"
          icon={<IconFileDownload />}
          onClick={onDownloadClick}
          isDisabled={disableDownloadButton}
        />
      )}
    </StyledFilterHeader>
  );
};
export default FilterHeader;
