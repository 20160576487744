import React, { useCallback, useEffect } from 'react';

import { BUTTON_VARIANTS } from 'lib/button';
import { useShareForwardedRef } from 'lib/utilities';
import PropTypes from 'prop-types';

import { ToastIconContainer } from '../blocks';
import { MAX_ALLOWED_ACTIONS, TOAST_POSITIONS, TOAST_VARIANTS, TOAST_VISIBILITY_DURATIONS } from '../constants';
import { ToastActions, ToastBody, ToastButton, ToastContent, ToastWrapper } from '../elements';
import { useSwipe } from '../utilities';

const useToastVisibility = ({ id, onVisibilityDurationEnd, ref, visibilityDuration }) => {
  const handleDismiss = useCallback(
    (event) => {
      onVisibilityDurationEnd(event, id);
    },
    [id, onVisibilityDurationEnd]
  );

  useEffect(() => {
    const visibilityTimer =
      visibilityDuration !== TOAST_VISIBILITY_DURATIONS.INFINITE
        ? setTimeout(() => handleDismiss(), visibilityDuration)
        : null;

    return () => clearTimeout(visibilityTimer);
  }, [handleDismiss, visibilityDuration]);

  const swipeStyles = useSwipe(ref, onVisibilityDurationEnd, id);

  return {
    swipeStyles,
  };
};

const Toast = React.forwardRef(
  (
    {
      actions = undefined,
      className,
      content,
      id,
      onVisibilityDurationEnd = () => {},
      position = TOAST_POSITIONS.INLINE,
      style = {},
      variant = TOAST_VARIANTS.NEUTRAL,
      visibilityDuration = TOAST_VISIBILITY_DURATIONS.SEVEN_SECONDS,
      ...other
    },
    ref
  ) => {
    const actionsCount = (actions && actions.length) || 0;
    const hasActions = actionsCount > 0;
    const hasMultipleActions = actionsCount > 1;

    const toastRef = useShareForwardedRef(ref);

    const { swipeStyles } = useToastVisibility({
      id,
      onVisibilityDurationEnd,
      ref: toastRef,
      visibilityDuration,
    });

    const renderToastActions = () => (
      <ToastActions withMultipleActions={hasMultipleActions}>
        {actions.slice(0, MAX_ALLOWED_ACTIONS).map((actionProps) => {
          const { onClick, text, variant: buttonVariant, ...otherActionProps } = actionProps;

          const buttonAppearance = buttonVariant || BUTTON_VARIANTS.TEXT;

          const handleClick = (event) => {
            onClick(event, id);
          };

          return (
            <ToastButton
              aria-controls={id}
              className={className ? `toast-button-${className}-${text.replace(/\s/g, '')}` : undefined}
              key={text}
              onClick={handleClick}
              preserveClickableArea
              text={text}
              variant={buttonAppearance}
              withMultipleActions={hasMultipleActions}
              {...otherActionProps}
            />
          );
        })}
      </ToastActions>
    );

    return (
      <ToastWrapper
        className={`${className} toast-wrapper-${className}`}
        style={{ ...style, ...swipeStyles }}
        {...{ id, position, variant }}
        {...other}
        ref={toastRef}
      >
        <ToastIconContainer className={`toast-icon-${className}`} variant={variant} />
        <ToastBody className={`toast-body-${className}`} variant={variant} withMultipleActions={hasMultipleActions}>
          <ToastContent className={`toast-content-${className}`} withMultipleActions={hasMultipleActions}>
            {content}
          </ToastContent>
          {hasActions && renderToastActions()}
        </ToastBody>
      </ToastWrapper>
    );
  }
);

Toast.propTypes = {
  /** Array of action elements. Action element contains Button component properties.
   * A maximum of 2 actions is allowed. */
  actions: PropTypes.arrayOf(
    PropTypes.shape({
      text: PropTypes.string.isRequired,
      onClick: PropTypes.func.isRequired,
      /** Changes button style depending on variant */
      variant: PropTypes.oneOf([
        BUTTON_VARIANTS.ICON,
        BUTTON_VARIANTS.PRIMARY,
        BUTTON_VARIANTS.SECONDARY,
        BUTTON_VARIANTS.TEXT,
      ]),
      icon: PropTypes.node,
    })
  ),
  /** Adds classname for additional styling */
  className: PropTypes.string,
  /** The content of the component */
  content: PropTypes.node.isRequired,
  /** Unique id for component */
  id: PropTypes.string.isRequired,
  /** Toast visibility handler when visibility duration ends */
  onVisibilityDurationEnd: PropTypes.func,
  /** Sets component position accordingly to specified value */
  position: PropTypes.oneOf(Object.values(TOAST_POSITIONS)),
  /** Inline styles applied to main component wrapper */
  style: PropTypes.shape({}),
  /** Changes toast style depending on variant. */
  variant: PropTypes.oneOf(Object.values(TOAST_VARIANTS)),
  /** Duration of time for how long component will be visible */
  visibilityDuration: PropTypes.oneOf(Object.values(TOAST_VISIBILITY_DURATIONS)),
};

export { Toast };
