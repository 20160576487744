import React from 'react';
import PropTypes from 'prop-types';
import styled, { css } from 'styled-components';

import { BREAKPOINTS, getRem } from '../../core';
import { AVATAR_COLORS, AVATAR_SIZES, AVATAR_WRAPPER_SIZES } from '../constants';

const StyledAvatarWrapper = styled.div.withConfig({
  shouldForwardProp: (prop) => !['color'].includes(prop),
})`
  align-items: center;
  background-color: ${({ color, theme }) =>
    color ? theme.color.avatar.background.value : theme.color.avatar.background.value};
  border-radius: ${({ theme }) => theme.size.borderRadius.full.value};
  box-sizing: border-box;
  color: ${({ theme }) => theme.color.additional.alwaysWhite.value};
  display: inline-flex;
  height: ${({ size }) => getRem(AVATAR_WRAPPER_SIZES[size])};
  justify-content: center;
  margin: 0;
  overflow: hidden;
  text-align: center;
  width: ${({ size }) => getRem(AVATAR_WRAPPER_SIZES[size])};
  ${({ size }) =>
    size === AVATAR_SIZES.RESPONSIVE &&
    css`
      height: ${getRem(AVATAR_WRAPPER_SIZES[AVATAR_SIZES.STANDARD])};
      width: ${getRem(AVATAR_WRAPPER_SIZES[AVATAR_SIZES.STANDARD])};

      @media ${BREAKPOINTS.L} {
        height: ${getRem(AVATAR_WRAPPER_SIZES[AVATAR_SIZES.RESPONSIVE])};
        width: ${getRem(AVATAR_WRAPPER_SIZES[AVATAR_SIZES.RESPONSIVE])};
      }
    `};
`;

const AvatarWrapper = ({ color = AVATAR_COLORS.COLOR_500, size = AVATAR_SIZES.STANDARD, ...props }) => {
  return <StyledAvatarWrapper {...{ color, size }} {...props} />;
};

AvatarWrapper.propTypes = {
  /** @ignore */
  color: PropTypes.oneOf(Object.values(AVATAR_COLORS)),
  /** Avatar wrapper size. Icon size changes depending on wrapper size */
  size: PropTypes.oneOf(Object.values(AVATAR_SIZES)),
};

export { AvatarWrapper };
