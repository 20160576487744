import { defineMessages } from 'react-intl';

export const messages = defineMessages({
  selectDate: {
    id: 'NEWUSERIMPORT.STEP4.DATESELECT',
    defaultMessage: 'Select a date'
  },
  stepFourScheduleDate: {
    id: 'NEWUSERIMPORT.STEP4.SCHEDULEDATE',
    defaultMessage: 'Schedule Date'
  }
});
