import { defineMessages } from 'react-intl';

export const messages = defineMessages({
  firstName: {
    id: 'GRID.REVIEWIMPORT.COLUMN.HEADER.FIRSTNAME',
    defaultMessage: 'First Name'
  },
  lastName: {
    id: 'GRID.REVIEWIMPORT.COLUMN.HEADER.LASTNAME',
    defaultMessage: 'Last Name'
  },
  dmsUserId: {
    id: 'GRID.REVIEWIMPORT.COLUMN.HEADER.DMSUSERID',
    defaultMessage: 'DMS User ID'
  },
  cNumber: {
    id: 'GRID.REVIEWIMPORT.COLUMN.HEADER.TABLE.FILTER.CNUMBER',
    defaultMessage: 'CNumber'
  },
  primaryEmail: {
    id: 'GRID.REVIEWIMPORT.COLUMN.HEADER.PRIMARYEMAIL',
    defaultMessage: 'Primary Email'
  },
  federationId: {
    id: 'GRID.REVIEWIMPORT.COLUMN.HEADER.FEDERATIONID',
    defaultMessage: 'Federation ID'
  },
  cdkSimpleId: {
    id: 'GRID.REVIEWIMPORT.COLUMN.HEADER.LABEL.CDKSIMPLEID',
    defaultMessage: 'CDK Simple ID'
  },
  dmsEmployeeId: {
    id: 'GRID.REVIEWIMPORT.COLUMN.HEADER.DMSEMPLOYEEID',
    defaultMessage: 'DMS Employee ID'
  },
  dmsProfileName: {
    id: 'GRID.REVIEWIMPORT.COLUMN.HEADER.DMSPROFILENAME',
    defaultMessage: 'DMS Profile Name'
  },
  dmsAccount: {
    id: 'GRID.REVIEWIMPORT.COLUMN.HEADER.DMSACCOUNT',
    defaultMessage: 'DMS Account'
  },
  roles: {
    id: 'GRID.REVIEWIMPORT.COLUMN.HEADER.MENUITEM.ROLES',
    defaultMessage: 'Roles'
  },
  stores: {
    id: 'GRID.REVIEWIMPORT.COLUMN.HEADER.MENUITEM.STORES',
    defaultMessage: 'Stores'
  }
});
