import { IconCheck } from 'lib/icons';
import styled from 'styled-components';

import { getRem, transitionAnimation } from './../../core';

const ICON_SIZE = getRem(18);

const ChipCheckboxIcon = styled(IconCheck)`
  ${transitionAnimation('background-color')};
  background-color: transparent;
  border: 2px solid ${({ theme }) => theme.color.gray[500].value};
  border-radius: ${({ theme }) => theme.size.borderRadius.extraSmall.value};
  box-sizing: border-box;
  fill: transparent;
  height: ${ICON_SIZE};
  left: ${getRem('14px')};
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  width: ${ICON_SIZE};

  && {
    position: absolute;
  }
`;

export { ChipCheckboxIcon };
