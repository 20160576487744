import StyledTableContainer from './styled';
import { TableContainerProps } from './types';

const tableContainerHeight = (tableHeight: number, showBorder: boolean) => {
  let className: string;
  className = showBorder ? 'show-table-border ' : '';
  className += tableHeight && tableHeight < 100 ? ' show-table-height' : '';
  return className;
};

const TableContainer = ({
  children,
  showBorder = false,
  tableHeight = 100
}: TableContainerProps): JSX.Element => (
  <StyledTableContainer
    data-testid="table-container"
    tableLayout="normal"
    className={tableContainerHeight(tableHeight, showBorder)}
  >
    {children}
  </StyledTableContainer>
);
export default TableContainer;
