import { AgGridColumnProps } from 'ag-grid-react';
import { CELL_RENDERERS, CELL_CLASSES } from 'cdk-radial';
import { useIntl } from 'react-intl';
import { messages } from './messages';

type ParamsType = {
  data: {
    jobProfiles: string[];
    profiles: { id: string; accounts: string[] }[];
    cNumber: string;
    cNumberAlias: string;
  };
};

function monthToComparableNumber(date: string) {
  if (date === undefined || date === null || date.length !== 10) {
    return null;
  }
  const yearNumber = date.substring(6, 10);
  const monthNumber = date.substring(3, 5);
  const dayNumber = date.substring(0, 2);
  const result = `${
    // eslint-disable-next-line @typescript-eslint/restrict-plus-operands
    Number(yearNumber) * 10000 + Number(monthNumber) * 100 + dayNumber
  }`;
  return result;
}
function createDateComparator(date1: string, date2: string): any {
  const date1Number = monthToComparableNumber(date1);
  const date2Number = monthToComparableNumber(date2);
  if (date1Number === null && date2Number === null) {
    return 0;
  }
  if (date1Number === null) {
    return -1;
  }
  if (date2Number === null) {
    return 1;
  }
  return Number(date1Number) - Number(date2Number);
}

const getDMSAccounts = (params: ParamsType): string =>
  params?.data?.profiles && Array.isArray(params.data.profiles)
    ? Array.from(
        new Set(
          params.data.profiles.reduce(
            (acc: string[], curr): string[] => [...acc, ...curr.accounts],
            []
          )
        )
      ).join(', ')
    : '';

const getCnumberWithAlias = (params: ParamsType): string =>
  `${params?.data?.cNumber} ${params?.data?.cNumberAlias}`.trim();

const useColumns = (): AgGridColumnProps[] => {
  const intl = useIntl();
  const columns = [
    {
      cellClass: CELL_CLASSES.CHECKBOX,
      checkboxSelection: true,
      field: 'check',
      filter: false,
      headerCheckboxSelection: true,
      headerCheckboxSelectionFilteredOnly: true,
      headerClass: [CELL_CLASSES.CHECKBOX, CELL_CLASSES.SELECT_ALL],
      headerName: '',
      maxWidth: 55,
      minWidth: 55,
      resizable: false
    },
    {
      cellRenderer: CELL_RENDERERS.TEXT,
      field: 'lastName',
      headerName: intl.formatMessage(messages.lastName),
      minWidth: 250,
      sortable: true
    },
    {
      cellRenderer: CELL_RENDERERS.TEXT,
      field: 'firstName',
      headerName: intl.formatMessage(messages.firstName),
      minWidth: 250,
      sortable: true
    },
    {
      cellRenderer: CELL_RENDERERS.TEXT,
      field: 'id',
      headerName: intl.formatMessage(messages.dmsUserId),
      minWidth: 150,
      sortable: true
    },
    {
      cellRenderer: CELL_RENDERERS.TEXT,
      field: 'log_date',
      comparator: createDateComparator,
      headerName: intl.formatMessage(messages.lastLoginColumn),
      minWidth: 150,
      sortable: true
    },
    {
      cellRenderer: CELL_RENDERERS.TEXT,
      valueGetter: getDMSAccounts,
      field: 'profiles',
      headerName: intl.formatMessage(messages.dmsAccount),
      minWidth: 300,
      sortable: true,
      cellStyle: { 'white-space': 'normal', 'line-height': '25px' },
      autoHeight: true
    },
    {
      cellRenderer: CELL_RENDERERS.TEXT,
      valueGetter: (params: ParamsType): string =>
        params?.data?.jobProfiles.join(', ') || '',
      field: 'jobProfiles',
      headerName: intl.formatMessage(messages.dmsJobProfile),
      minWidth: 300,
      sortable: true,
      cellStyle: { 'white-space': 'normal', 'line-height': '25px' },
      autoHeight: true
    },
    {
      cellRenderer: CELL_RENDERERS.TEXT,
      field: 'cNumber',
      valueGetter: getCnumberWithAlias,
      headerName: intl.formatMessage(messages.cNumber),
      minWidth: 150,
      cellStyle: { 'white-space': 'normal', 'line-height': '18px' },
      sortable: true
    },
    {
      cellRenderer: CELL_RENDERERS.TEXT,
      field: 'email',
      headerName: intl.formatMessage(messages.email),
      minWidth: 250,
      sortable: true
    }
  ];
  return columns;
};
export default useColumns;
