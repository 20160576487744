import React from 'react';
import { IconCheck, IconRemove } from 'lib/icons';
import PropTypes from 'prop-types';
import styled, { css } from 'styled-components';

import { BREAKPOINTS, centerContent, getRem, transitionAnimation } from './../../core';
import { CHECKBOX_SIZES } from '../constants';

const CHECKBOX_HIGHLIGHT_SIZES = {
  [CHECKBOX_SIZES.LARGE]: '40px',
  [CHECKBOX_SIZES.SMALL]: '32px',
  [CHECKBOX_SIZES.STANDARD]: '36px',
};

const INDICATOR_SIZE = getRem('18px');
const ICON_SIZE = getRem('24px');

const StyledCheckboxIndicator = styled.span`
  background-color: ${({ theme }) => theme.color.additional.light.value};
  border: 2px solid ${({ theme }) => theme.color.gray[700].value};
  border-radius: ${({ theme }) => theme.size.borderRadius.extraSmall.value};
  box-sizing: border-box;
  display: inline-flex;
  flex-shrink: 0;
  height: ${INDICATOR_SIZE};
  margin: 0 ${getRem('15px')};
  position: relative;
  width: ${INDICATOR_SIZE};

  &::after {
    ${centerContent}
    ${transitionAnimation('background-color')}
        border-radius: ${({ theme }) => theme.size.borderRadius.full.value};
    content: '';
    display: inline-block;
    height: ${getRem(CHECKBOX_HIGHLIGHT_SIZES[CHECKBOX_SIZES.LARGE])};
    width: ${getRem(CHECKBOX_HIGHLIGHT_SIZES[CHECKBOX_SIZES.LARGE])};

    @media ${BREAKPOINTS.L} {
      height: ${({ size }) => getRem(CHECKBOX_HIGHLIGHT_SIZES[size])};
      width: ${({ size }) => getRem(CHECKBOX_HIGHLIGHT_SIZES[size])};
    }
  }

  ${({ checked, theme }) =>
    checked &&
    css`
      background-color: ${theme.color.primary[500].value};
      border-color: ${theme.color.primary[500].value};
    `};
  ${({ checked, isDisabled, theme }) =>
    isDisabled &&
    css`
      background-color: ${checked && theme.color.gray[300].value};
      border-color: ${theme.color.gray[300].value};
    `};
`;

const StyledIcon = styled(IconCheck).withConfig({
  shouldForwardProp: (prop) => !['indeterminate'].includes(prop),
})`
  ${centerContent}
  ${transitionAnimation('opacity')}
    fill: ${({ theme }) => theme.color.additional.light.value};
  height: ${({ indeterminate }) => (indeterminate ? ICON_SIZE : INDICATOR_SIZE)};
  opacity: ${({ checked }) => (checked ? 1 : 0)};
  width: ${({ indeterminate }) => (indeterminate ? ICON_SIZE : INDICATOR_SIZE)};
`;

const CheckboxIndicator = ({
  checked = false,
  indeterminate = false,
  isDisabled = false,
  size = CHECKBOX_SIZES.STANDARD,
  ...other
}) => (
  <StyledCheckboxIndicator checked={checked} isDisabled={isDisabled} size={size} {...other}>
    <StyledIcon as={indeterminate && IconRemove} checked={checked} indeterminate={indeterminate} />
  </StyledCheckboxIndicator>
);

CheckboxIndicator.propTypes = {
  /** The current value of checkbox - checked or unchecked */
  checked: PropTypes.bool,
  /** Changes the icon and sets checkbox indeterminate parameter state */
  indeterminate: PropTypes.bool,
  /** Disables to change the value of checkbox and shows it visually disabled */
  isDisabled: PropTypes.bool,
  /** Indicator clickable area size */
  size: PropTypes.oneOf(Object.values(CHECKBOX_SIZES)),
};

export { CheckboxIndicator, StyledCheckboxIndicator };
