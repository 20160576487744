import { createSlice, PayloadAction } from '@reduxjs/toolkit';

type CommonState = {
  actionButtons: Record<string, any>;
  toast: {
    toastContent: string | JSX.Element;
    toastVariant: string;
    showToast: boolean;
    autoHide: boolean;
  };
  isImpersonationActiveForCurrentEnterprise: boolean;
};

const initialState: CommonState = {
  actionButtons: {},
  toast: {
    toastContent: ' ',
    toastVariant: '',
    showToast: false,
    autoHide: true
  },
  isImpersonationActiveForCurrentEnterprise: false
};

type ToastActionType = {
  variant: string;
  content: string | JSX.Element;
  autoHide?: boolean;
};

export const commonSlice = createSlice({
  name: 'commonState',

  initialState,
  reducers: {
    setActionButtons: (state, action: PayloadAction<Record<string, any>>) => {
      state.actionButtons = action.payload;
    },
    setGlobalToast: (
      state,
      {
        payload: { content, variant, autoHide = true }
      }: PayloadAction<ToastActionType>
    ) => {
      state.toast.toastContent = content;
      state.toast.toastVariant = variant;
      state.toast.showToast = true;
      state.toast.autoHide = autoHide;
    },
    resetGlobalToast: state => {
      state.toast.toastContent = '';
      state.toast.toastVariant = '';
      state.toast.showToast = false;
      state.toast.autoHide = true;
    },
    setImpersonation: (state, action: PayloadAction<boolean>) => {
      state.isImpersonationActiveForCurrentEnterprise = action.payload;
    }
  }
});

export const {
  setActionButtons,
  setGlobalToast,
  resetGlobalToast,
  setImpersonation
} = commonSlice.actions;

export default commonSlice.reducer;
