import { css } from 'styled-components';

import { focusOutlineColors, getHexToRgb, getRem } from './../../core';

export const unselectedStyles = {
  active: css`
    background-color: ${({ theme }) => theme.color.state.dark.pressed.value};
  `,
  disabled: css`
    background-color: transparent;
    cursor: not-allowed;
  `,
  focus: css`
    background-color: ${({ theme }) => theme.color.state.dark.focus.value};
    box-shadow: inset 0 0 0 4px ${({ theme }) => focusOutlineColors.getDark(theme, 0.5)};
  `,
  hover: css`
    background-color: ${({ theme }) => theme.color.state.dark.hover.value};
  `,
};

const selectedStyles = {
  active: css`
    background-color: ${({ theme }) => theme.color.state.dark.pressed.value};
  `,
  focus: css`
    background-color: ${({ theme }) => theme.color.state.dark.focus.value};
    box-shadow: inset 0 0 0 4px ${({ theme }) => focusOutlineColors.getDark(theme, 0.6)};
  `,
  hover: css`
    background-color: ${({ theme }) => theme.color.state.dark.hover.value};
  `,
};

export const dropdownButtonStateStyles = {
  active: css`
    background-color: ${({ theme }) => `rgba(${getHexToRgb(theme.color.additional.dark.value)}, 0.3)`};

    @-moz-document url-prefix('') {
      padding-inline-start: ${getRem(6)};
    }
  `,
  focus: css`
    background-color: ${({ theme }) => theme.color.state.light.focus.value};
    box-shadow: 0 0 0 4px ${({ theme }) => focusOutlineColors.getDark(theme)};
  `,
  hover: css`
    background-color: ${({ theme }) => `rgba(${getHexToRgb(theme.color.additional.dark.value)}, 0.15)`};
  `,
};

export const getStateStyles = (isSelected) => (isSelected ? selectedStyles : unselectedStyles);
