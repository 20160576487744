import { useOktaAuth } from '@okta/okta-react';
import { TransformedUserPermissions } from 'helpers/types/storePermissionType';
import {
  createContext,
  useContext,
  useState,
  useMemo,
  useEffect,
  useCallback
} from 'react';
import { useGetPermissionsQuery } from 'redux/services/authz-service';
import { useGetAuthenticationQuery } from 'redux/services/identity-service';
import { FullUser } from 'redux/services/identity-service/types';

type ClientContextType = {
  userInfo: FullUser;
  permissions: TransformedUserPermissions;
  loginId: string;
  enterpriseId: string;
  refetchAuthenticationQuery: () => void;
  refetchPermissionsQuery: () => void;
  setEnterprise: (enterpriseId: string) => void;
};

export type IClient = ClientContextType;

export const ClientContext = createContext<IClient>({} as ClientContextType);

const defaultUser = {} as FullUser;
const defaultPemissions = {} as TransformedUserPermissions;

export const ClientProvider = ({
  children
}: {
  children: React.ReactNode;
}): JSX.Element => {
  const [userInfo, setUserInfo] = useState<FullUser>(defaultUser);
  const [permissions, setPermissions] =
    useState<TransformedUserPermissions>(defaultPemissions);
  const [selectedEnterpriseId, setSelectedEnterpriseId] = useState<string>('');

  const { authState } = useOktaAuth();
  const token = authState?.accessToken?.accessToken;

  const {
    data: userData = defaultUser,
    isSuccess,
    refetch: refetchAuthenticationQuery
  } = useGetAuthenticationQuery(undefined, { skip: !token });

  const {
    data: permissionsData = defaultPemissions,
    refetch: refetchPermissionsQuery
  } = useGetPermissionsQuery(
    {
      loginId: userData?.fullUser?.user?.loginId || '',
      enterpriseId:
        selectedEnterpriseId || userData?.fullUser?.enterprise?.selfId || ''
    },
    { skip: !userData?.fullUser }
  );

  /* Used to get permissions for current enterpriseId 
  Used in - Reports.tsx
  */
  const setEnterprise = useCallback((enterpriseId: string) => {
    setSelectedEnterpriseId(enterpriseId);
  }, []);

  useEffect(() => {
    setUserInfo(userData);
  }, [userData, isSuccess]);

  useEffect(() => {
    setPermissions(permissionsData);
  }, [permissionsData]);

  const contextValue = useMemo(
    () => ({
      userInfo,
      permissions,
      loginId: userInfo?.fullUser?.user?.loginId || '',
      enterpriseId: userInfo?.fullUser?.enterprise?.selfId || '',
      refetchAuthenticationQuery,
      refetchPermissionsQuery,
      setEnterprise
    }),
    [
      userInfo,
      permissions,
      refetchAuthenticationQuery,
      refetchPermissionsQuery,
      setEnterprise
    ]
  );

  return (
    <ClientContext.Provider value={contextValue}>
      {children}
    </ClientContext.Provider>
  );
};

export const useClient = (): IClient => useContext(ClientContext);
