import React from 'react';
import PropTypes from 'prop-types';

import { StatusLabel, StatusWrapper } from '../elements';

const BaseIndicator = React.forwardRef(
  (
    {
      ariaLabel = 'Status Indicator',
      enableStateStyles,
      isFullWidth = undefined,
      label,
      renderBeforeContent = undefined,
      ...other
    },
    ref
  ) => {
    if (enableStateStyles) {
      other = { as: 'button', ...other };
    }
    return (
      <StatusWrapper isFullWidth={isFullWidth} {...other}>
        {!!renderBeforeContent && renderBeforeContent()}
        <StatusLabel aria-label={ariaLabel}>{label}</StatusLabel>
      </StatusWrapper>
    );
  }
);

BaseIndicator.propTypes = {
  /** Informs screen reader users what actions they should take */
  ariaLabel: PropTypes.node,
  /** Enables hover, focus, active, disabled states on status indicator */
  enableStateStyles: PropTypes.bool,
  /** If true, status indicator takes full width of container */
  isFullWidth: PropTypes.bool,
  /** Status indicator label */
  label: PropTypes.node.isRequired,
  /** Renders custom content before main content group */
  renderBeforeContent: PropTypes.func,
};

export { BaseIndicator };
