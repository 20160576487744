import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import { getRem } from '../../core';
import { TOAST_VARIANTS, TOAST_VARIANT_ICONS } from '../constants';
import { ToastIcon } from '../elements';

const StyledToastIconContainer = styled.div.withConfig({
  shouldForwardProp: (prop) => !['variant'].includes(prop),
})`
  align-items: flex-start;
  background-color: ${({ theme, variant }) => theme.color.system[variant][500].value};
  border-bottom-left-radius: ${({ theme }) => theme.size.borderRadius.large.value};
  border: 1px solid ${({ theme, variant }) => theme.color.system[variant][500].value};
  border-top-left-radius: ${({ theme }) => theme.size.borderRadius.large.value};
  box-sizing: border-box;
  display: flex;
  flex-shrink: 0;
  justify-content: center;
  padding: ${({ theme }) => `${getRem('14px')} ${theme.size.spacing.small.value}`};
  width: ${getRem('32px')};
`;

const ToastIconContainer = ({ variant = TOAST_VARIANTS.NEUTRAL, ...other }) => {
  const Icon = TOAST_VARIANT_ICONS[variant];

  return (
    <StyledToastIconContainer variant={variant} {...other}>
      <ToastIcon>
        <Icon />
      </ToastIcon>
    </StyledToastIconContainer>
  );
};

ToastIconContainer.propTypes = {
  /** Changes style depending on variant. Default 'Neutral' */
  variant: PropTypes.oneOf(Object.values(TOAST_VARIANTS)),
};

export { ToastIconContainer };
