import React from 'react';
import PropTypes from 'prop-types';
import styled, { css } from 'styled-components';

import { TableHeaderButtons } from './TableHeaderButtons';
import { getRem } from '../../core';
import { TableHeaderTitle } from '../elements';

const StyledTableHeader = styled.div`
  background-color: ${({ theme }) => theme.color.additional.light.value};
  ${({ hideBorder }) =>
    !hideBorder &&
    css`
      border: 1px solid ${({ theme }) => theme.color.gray[200].value};
      border-bottom: none;
      border-radius: ${({ theme }) => getRem(theme.size.borderRadius.large.value)}
        ${({ theme }) => getRem(theme.size.borderRadius.large.value)} 0 0;
    `}
  display: flex;
  flex: 0 0 auto;
  padding: ${getRem(8)} ${getRem(16)};
  transition: all ${({ theme }) => theme.transition.duration.value};

  @media print {
    border: none;

    &__buttons {
      display: none;
    }
  }
`;

const TableHeader = ({
  customTag = undefined,
  dataTestId = '',
  disableDownloadButton = false,
  disablePrintButton = false,
  downloadButtonText = '',
  headerTitle = '',
  hideBorder = false,
  hideTableTitle = false,
  id,
  isAlwaysExpanded = false,
  isDownloadable = true,
  isPrintable = true,
  isSearchable = true,
  onDownloadClick = undefined,
  onPrintClick = undefined,
  onSearchInputChange = undefined,
  overflowButtonProps = undefined,
  printButtonText = '',
  searchInputName,
  searchInputValue = '',
  searchPlaceholder = '',
  ...other
}) => {
  return (
    <StyledTableHeader data-testid={dataTestId} hideBorder={hideBorder} {...other}>
      <TableHeaderTitle customTag={customTag} hideTitle={hideTableTitle} title={headerTitle} />
      <TableHeaderButtons
        {...{
          disableDownloadButton,
          disablePrintButton,
          downloadButtonText,
          id,
          isAlwaysExpanded,
          isDownloadable,
          isPrintable,
          isSearchable,
          onDownloadClick,
          onPrintClick,
          onSearchInputChange,
          overflowButtonProps,
          printButtonText,
          searchInputName,
          searchInputValue,
          searchPlaceholder,
        }}
      />
    </StyledTableHeader>
  );
};

TableHeader.propTypes = {
  /** Ability to supply a different element instead of the default one.
   * Proper heading element (`h1`, `h2`, ...) must be provided according to page structure */
  customTag: PropTypes.elementType,
  /** Id value used for testing */
  dataTestId: PropTypes.string,
  /** If true, disables download button */
  disableDownloadButton: PropTypes.bool,
  /** If true, disables print button */
  disablePrintButton: PropTypes.bool,
  /** Sets text for table header download button */
  downloadButtonText: PropTypes.node,
  /** Sets a table title */
  headerTitle: PropTypes.node,
  /** If true, hides table border */
  hideBorder: PropTypes.bool,
  /** If true, table title is visually hidden */
  hideTableTitle: PropTypes.bool,
  /** Identifier of the table component */
  id: PropTypes.string.isRequired,
  /** If true, search input is always expanded */
  isAlwaysExpanded: PropTypes.bool,
  /** If true, table data can be downloaded */
  isDownloadable: PropTypes.bool,
  /** If true, table data can be printed */
  isPrintable: PropTypes.bool,
  /** If true, quick filter can be used to search table data */
  isSearchable: PropTypes.bool,
  /** Props for overflow button */
  overflowButtonProps: PropTypes.shape({
    /** Id value used for testing */
    dataTestId: PropTypes.string,
    /** Context menu options. For correct data structure refer to component documentation */
    contextMenuOptions: PropTypes.arrayOf(
      PropTypes.shape({
        /** Renders icon before label */
        icon: PropTypes.node,
        /** Unique identifier for option */
        id: PropTypes.string,
        /** If true, disables option */
        isDisabled: PropTypes.bool,
        /** Option label */
        label: PropTypes.string,
      })
    ),
    /** Sets text for overflow button */
    text: PropTypes.node,
  }),
  /** Callback function which is called on download button click */
  onDownloadClick: PropTypes.func,
  /** Callback function which is called on print button click */
  onPrintClick: PropTypes.func,
  /** Callback function which is called on search input value change */
  onSearchInputChange: PropTypes.func,
  /** Sets text for table header print button */
  printButtonText: PropTypes.node,
  /** Sets name for table header search input */
  searchInputName: PropTypes.string.isRequired,
  /** Sets initial search input value */
  searchInputValue: PropTypes.string,
  /** Sets a placeholder for table header search input */
  searchPlaceholder: PropTypes.node,
};

export { TableHeader };
