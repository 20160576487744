import StyledTableContentWrapper from './styled';

type TableContentWrapperProps = {
  children: React.ReactNode;
  ref: HTMLElement;
  dataTestId: string;
};

const TableContentWrapper = ({
  children,
  ref,
  dataTestId = ''
}: TableContentWrapperProps): JSX.Element => (
  <StyledTableContentWrapper data-testid={dataTestId} ref={ref}>
    {children}
  </StyledTableContentWrapper>
);
export default TableContentWrapper;
