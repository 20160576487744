import styled from 'styled-components';

const StyledExpandableChips = styled.div`
  margin: 0.2rem;
  .expand-chips-container {
    background: #e8e8e9;
    margin: 0.2rem;
    padding: 0.2rem;
    line-height: normal;
    align-items: center;
    display: inline-flex;
  }
  .see-text {
    cursor: pointer;
    color: #006cfa;
    display: inline-flex;
  }
`;

export default StyledExpandableChips;
