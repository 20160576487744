import { useIntl } from 'react-intl';
import TableFilterType from '../../../helpers/types/tableFilterType';
import { CustomStoreStatusRenderer } from './columnDefinitions';
import { messages } from './messages';

const useFilters = (): TableFilterType[][] => {
  const intl = useIntl();

  const filters: TableFilterType[] = [
    {
      label: intl.formatMessage(messages.storeStatus),
      uid: 'status',
      typecastTo: (value: string) => value.toLowerCase(),
      type: 'equality',
      multi: true,
      options: [
        {
          id: 'open',
          label: <CustomStoreStatusRenderer status="open" />,
          value: 'open',
          selectionLabel: intl.formatMessage(messages.open),
          disabled: false,
          showDivider: false
        },
        {
          id: 'pre-open',
          label: <CustomStoreStatusRenderer status="pre-open" />,
          value: 'pre-open',
          selectionLabel: intl.formatMessage(messages.preOpen),
          disabled: false,
          showDivider: false
        },
        {
          id: 'closed',
          label: <CustomStoreStatusRenderer status="closed" />,
          value: 'closed',
          selectionLabel: intl.formatMessage(messages.closed),
          disabled: false,
          showDivider: false
        },
        {
          id: 'inactive',
          label: <CustomStoreStatusRenderer status="inactive" />,
          value: 'inactive',
          selectionLabel: intl.formatMessage(messages.inactive),
          disabled: false,
          showDivider: false
        },
        {
          id: 'sold',
          label: <CustomStoreStatusRenderer status="sold" />,
          value: 'sold',
          selectionLabel: intl.formatMessage(messages.sold),
          disabled: false,
          showDivider: false
        },
        {
          id: 'duplicate',
          label: <CustomStoreStatusRenderer status="duplicate" />,
          value: 'duplicate',
          selectionLabel: intl.formatMessage(messages.duplicate),
          disabled: false,
          showDivider: false
        },
        {
          id: 'out_of_business',
          label: <CustomStoreStatusRenderer status="out_of_business" />,
          value: 'out_of_business',
          selectionLabel: intl.formatMessage(messages.outOfBusiness),
          disabled: false,
          showDivider: false
        },
        {
          id: 'archived',
          label: <CustomStoreStatusRenderer status="archived" />,
          value: 'archived',
          selectionLabel: intl.formatMessage(messages.archived),
          disabled: false,
          showDivider: false
        }
      ]
    },
    {
      label: intl.formatMessage(messages.visibility),
      uid: 'isVisible',
      typecastTo: null,
      type: 'equality',
      multi: false,
      options: [
        {
          id: 'isVisible',
          label: intl.formatMessage(messages.yesVisibilityStatus),
          value: true,
          selectionLabel: intl.formatMessage(messages.yesVisibilityStatus),
          disabled: false,
          showDivider: false
        },
        {
          id: 'isVisible',
          label: intl.formatMessage(messages.noVisibilityStatus),
          value: false,
          selectionLabel: intl.formatMessage(messages.noVisibilityStatus),
          disabled: false,
          showDivider: false
        }
      ]
    }
  ];
  return [filters];
};

export default useFilters;
