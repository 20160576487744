import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { ICON_SIZE } from './constants';

const StyledSvg = styled.svg`
  fill: ${({ theme }) => theme.color.additional.dark.value};
`;

const createIconWithPath = (paths) => {
  const CreateIconWithPath = React.forwardRef(
    (
      {
        ariaLabel = '',
        className = '',
        focusable = false,
        height = ICON_SIZE.DEFAULT,
        role = 'presentation',
        viewBox = '0 0 24 24',
        width = ICON_SIZE.DEFAULT,
        ...other
      },
      ref
    ) => {
      const ariaAttrs = {};

      if (ariaLabel) {
        ariaAttrs['aria-label'] = ariaLabel;
      }

      return (
        <StyledSvg
          xmlns="http://www.w3.org/2000/svg"
          tabIndex={focusable ? 0 : undefined}
          {...{ className, focusable, height, role, viewBox, width }}
          {...ariaAttrs}
          {...other}
          ref={ref}
        >
          {paths.map((path) => (
            <path key={path.d} {...path} />
          ))}
        </StyledSvg>
      );
    }
  );

  CreateIconWithPath.propTypes = {
    /** Adds aria label for icon */
    ariaLabel: PropTypes.node,
    /** Adds classname to the icon */
    className: PropTypes.string,
    /** Sets if icon is focusable when using tab */
    focusable: PropTypes.bool,
    /** Sets the height of the icon */
    height: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    /** Sets the role of the icon */
    role: PropTypes.string,
    /** Sets icon viewbox */
    viewBox: PropTypes.string,
    /** Sets the width of the icon */
    width: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  };

  return CreateIconWithPath;
};

export { createIconWithPath };
