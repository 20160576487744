/* eslint-disable @typescript-eslint/no-unsafe-return */
/* eslint-disable @typescript-eslint/restrict-template-expressions */

import { createApi } from '@reduxjs/toolkit/query/react';
import apiEndPoints from '../../../fixtures/apiEndPoints';
import baseQuery from '../../../utils/interceptedFetchBaseQuery';
import { JobDetailQueryType } from '../types/identity-service-types';
import {
  DmsUsersResponse,
  GetNonDmsImportJobResponsetype,
  RoleType
} from '../types/user-import-service-type';
import { ScheduleNonDmsBody } from '../identity-service/types';

const userMigrationServiceBasePath: string =
  process.env.REACT_APP_UMS_NEBULA_API_BASE_URL || '';
const identityServiceBasePath: string =
  process.env.REACT_APP_IDENTITY_NEBULA_API_BASE_URL || '';
const adminServiceBasePath: string =
  process.env.REACT_APP_ADMIN_NEBULA_API_BASE_URL || '';

export const userImportApi = createApi({
  reducerPath: 'userImport',
  baseQuery,
  tagTypes: ['userImportJobs', 'getEnterpriseCDKAccessDetails'],
  endpoints: builder => ({
    getNonDmsImportJob: builder.query<GetNonDmsImportJobResponsetype, string>({
      query: (jobId: string) =>
        `${userMigrationServiceBasePath}${apiEndPoints.getNonDmsUserImportJob}${jobId}`
    }),

    postScheduleNonDmsImport: builder.mutation<void, ScheduleNonDmsBody>({
      query: ({ enterpriseId, body }) => ({
        url: `${userMigrationServiceBasePath}${apiEndPoints.postNonDmsUsersImport(
          enterpriseId
        )}`,
        method: 'POST',
        body
      }),
      invalidatesTags: ['userImportJobs']
    }),

    getImportJobDetailsByJobId: builder.query<
      JobDetailQueryType,
      Record<string, string>
    >({
      queryFn: async (
        { jobId, enterpriseId },
        _queryApi,
        _extraOptions,
        fetchWithBQ
      ) => {
        const getImportJobDetails = fetchWithBQ(
          `${identityServiceBasePath}${apiEndPoints.getUserImportJobs}?jobId=${jobId}&enterpriseId=${enterpriseId}`
        );
        const roles = fetchWithBQ(
          `${adminServiceBasePath}${apiEndPoints.getAllRoles(enterpriseId)}`
        );
        const rawResponse = await Promise.allSettled([
          getImportJobDetails,
          roles
        ]);
        const response = rawResponse.filter(
          res => res.status === 'fulfilled'
        ) as [
          PromiseFulfilledResult<DmsUsersResponse>,
          PromiseFulfilledResult<DmsUsersResponse>
        ];

        const rolesObject: Record<string, string> = {};

        if (response[1].value.data && Array.isArray(response[1].value.data)) {
          response[1].value.data.forEach((role: RoleType) => {
            rolesObject[role.roleGuid] = role.code;
          });
        }

        const users: Record<string, any>[] = [];
        if (
          response[0].value.data &&
          Array.isArray(response[0].value.data?.dmsUsers)
        ) {
          response[0].value.data.dmsUsers.forEach(dmsUser => {
            users.push({
              ...dmsUser,
              roles:
                dmsUser.roles?.map(userRoleGuid =>
                  typeof userRoleGuid === 'string'
                    ? {
                        code: rolesObject[userRoleGuid],
                        roleGuid: userRoleGuid
                      }
                    : {}
                ) || [],
              stores: dmsUser.stores?.map(storeName => ({ id: storeName }))
            });
          });
        }
        let jobDataInfo: any = {};
        if (response[0].value.data) {
          jobDataInfo = response[0].value.data;
          jobDataInfo.dmsUser = undefined;
        }

        return {
          data: {
            users,
            rolesObject,
            importJobData: response[1].value.data,
            jobDataInfo
          }
        };
      }
    })
  })
});

export const {
  useGetNonDmsImportJobQuery,

  usePostScheduleNonDmsImportMutation,

  useGetImportJobDetailsByJobIdQuery
} = userImportApi;
