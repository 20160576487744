import { Dialog, Button, BUTTON_VARIANTS } from 'cdk-radial';
import { useIntl } from 'react-intl';
import { messages as commonMessages } from 'commonMessages';
import StyledRevokeImpersonationDialog from './styled';
import { messages } from './messages';

interface DialogProps {
  isConfirmDialogOpen: boolean;
  setIsConfirmDialogOpen: (isOpen: boolean) => void;
  handleSave: () => void;
  enterpriseId: string;
}

const RevokeImpersonationDialog = ({
  isConfirmDialogOpen,
  setIsConfirmDialogOpen,
  handleSave,
  enterpriseId
}: DialogProps): JSX.Element => {
  const intl = useIntl();
  return (
    <Dialog
      dataTestId="revoke-impersonation-dialog"
      id="revoke-impersonation-dialog"
      className="revoke-impersonation-dialog"
      isOpen={isConfirmDialogOpen}
      onClose={() => setIsConfirmDialogOpen(false)}
      title={intl.formatMessage(messages.revokeImpersonationDialogTitle)}
    >
      <StyledRevokeImpersonationDialog>
        <div className="revoke-impersonation-text">
          {intl.formatMessage(messages.confirmImpersonation, {
            enterpriseId: enterpriseId || ''
          })}
        </div>
        <div className="dialog-footer">
          <div className="footer-button-group">
            <Button
              dataTestId="cancel-btn"
              text={intl.formatMessage(commonMessages.cancel)}
              variant={BUTTON_VARIANTS.TEXT}
              onClick={() => setIsConfirmDialogOpen(false)}
            />
            &nbsp;&nbsp;&nbsp;
            <Button
              dataTestId="save-btn"
              text={intl.formatMessage(messages.dialogRevokeButton)}
              variant={BUTTON_VARIANTS.PRIMARY}
              onClick={() => handleSave()}
            />
          </div>
        </div>
      </StyledRevokeImpersonationDialog>
    </Dialog>
  );
};

export default RevokeImpersonationDialog;
