/* eslint-disable @typescript-eslint/no-unsafe-call */
import FileSaver from 'file-saver';
import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';

function downloadFile(blob: Blob, reportType: string, extenstion = 'xlsx') {
  const filename = `${reportType}_${new Date()
    .toJSON()
    .slice(0, 16)
    .replace(':', '-')}.${extenstion}`;
  // eslint-disable-next-line @typescript-eslint/no-unsafe-call
  FileSaver.saveAs(blob, filename);
  return { data: { filename } };
}

export const reportsDownloadServiceAPI = createApi({
  reducerPath: 'reportsDownloadService',
  baseQuery: fetchBaseQuery({
    baseUrl: ''
  }),
  endpoints: builder => ({
    downloadStoreAssignmentReport: builder.query<any, string>({
      query: (url: string) => ({
        url,
        method: 'GET',
        responseHandler: async response => {
          if (response.ok) {
            const blob = await response.blob();
            downloadFile(blob, 'Store_Assignment_Report');
          }
          return { error: { response } };
        }
      })
    }),
    downloadRoleAssignmentReport: builder.query<any, string>({
      query: (url: string) => ({
        url,
        method: 'GET',
        responseHandler: async response => {
          if (response.ok) {
            const blob = await response.blob();
            downloadFile(blob, 'Role_Assignment_Report');
          }
          return { error: { response } };
        }
      })
    }),
    downloadUserListReport: builder.query<any, string>({
      query: (url: string) => ({
        url,
        method: 'GET',
        responseHandler: async response => {
          if (response.ok) {
            const blob = await response.blob();
            downloadFile(blob, 'User_List_Report');
          }
          return { error: { response } };
        }
      })
    })
  })
});

export const {
  useLazyDownloadStoreAssignmentReportQuery,
  useLazyDownloadRoleAssignmentReportQuery,
  useLazyDownloadUserListReportQuery
} = reportsDownloadServiceAPI;
