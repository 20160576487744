import styled from 'styled-components';
import {
  SearchInput,
  Button,
  SimpleTableWrapper,
  MenuListItem,
  Content,
  TableContainer,
  Input,
  Toast,
  Heading,
  Dialog,
  Menu
} from 'cdk-radial';

export const StyledMoveStores = styled.div`
  margin: 1rem;
`;

export const StyledSearchInput = styled(SearchInput)`
  width: 60%;
  margin-top: 0.5rem;
`;

export const StyledEmptyContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  flex-direction: column;
`;

export const StyledActionButton = styled(Button)`
border: 'none',
cursor: 'pointer'
`;
export const ActionButtonsContainer = styled.div`
  display: flex;
  gap: 10px;
`;
export const StyledSimpleTableWrapper = styled(SimpleTableWrapper)`
  margin-top: 1rem;
  .move-stores-table {
    height: 27vh;
    overflow-y: auto;
    display: block;
  }
`;
export const StyledSimpleDialogTableWrapper = styled.div`
  margin-top: 1rem;
  display: block;
`;
export const StyledTableContainer = styled(TableContainer)`
  overflow-y: auto;
`;

export const StyleMoveStoreSearchInput = styled(SearchInput)`
  width: 60%;
  margin-top: 0.5rem;
`;
export const StyleMoveStoreInput = styled(Input)`
  width: 50%;
  margin-top: 0.5rem;
`;

export const StyledCheckboxContainer = styled.div`
  margin-top: 30px;
`;

export const FlexContainer = styled.div`
  display: flex;
  justify-content: space-between;
  margin-right: 15px;
  align-items: center;
`;

export const StyledMoveButton = styled(Button)`
  margin-left: 5px;
`;

export const Container = styled.div`
  margin-top: 20px;
`;

export const SelectedStores = styled.div`
  margin-top: 20px;
`;

export const InfoBox = styled.div`
  background-color: #f0f0f0;
  padding: 10px;
  height: 50px;
`;
export const StyledContainer = styled.div`
  margin-top: 5px;
  margin-left: 16px;
`;

export const StyledHeadingContainer = styled.h1`
  margin-left: 10px;
`;
export const StyledDepartmentDialog = styled(Dialog)`
  width: 80vw;
  max-height: 90vh;
`;

export const StyledSimpleMoveStoreDialogTableWrapper = styled(
  SimpleTableWrapper
)`
  margin-top: 1rem;
  .move-dialog-store-table {
    height: 20vh;
    overflow-y: auto;
    display: block;
  }
`;
export const StyledMenuListItemAutoComplete = styled(MenuListItem)`
  pointer-events: all !important;
`;

export const StyledHeading = styled(Heading)`
  margin-top: 1rem;
`;

export const StyledNoSelectedStore = styled.div`
  width: 100%;
  height: 50px;
  border-radius: 8px;
  background: #f3f3f4;
`;
export const StyledContent = styled(Content)`
  padding: 1rem;
`;

export const StyledTableContent = styled.div`
  max-height: 45vh;
  overflow-y: auto;
`;
export const StyledToast = styled(Toast)`
  padding-bottom: 1rem;
`;
export const StyledIdentifierDialog = styled(Dialog)`
  width: 80vw;
  max-height: 90vh;
`;
export const StyledMenu = styled(Menu)`
  z-index: 51;
  width: 60%;
`;
