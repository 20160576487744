import { defineMessages } from 'react-intl';

export const messages = defineMessages({
  roleName: {
    id: 'GRID.COMMON.ASSIGNROLES.COLUMN.DEFINITION.ROLENAME',
    defaultMessage: 'Role Name'
  },
  userCount: {
    id: 'GRID.COMMON.ASSIGNROLES.COLUMN.DEFINITION.USERCOUNT',
    defaultMessage: 'User Count'
  },
  dateCreated: {
    id: 'GRID.COMMON.ASSIGNROLES.COLUMN.DEFINITION.DATECREATED',
    defaultMessage: 'Date Created'
  },
  createdBy: {
    id: 'GRID.COMMON.ASSIGNROLES.COLUMN.DEFINITION.CREATEDBY',
    defaultMessage: 'Created By'
  },
  oneto10Users: {
    id: 'GRID.COMMON.ASSIGNROLES.FILTERSCHEMA.1TO10USERS',
    defaultMessage: '1 - 10 users'
  },
  tenTo50Users: {
    id: 'GRID.COMMON.ASSIGNROLES.FILTERSCHEMA.10TO50USERS',
    defaultMessage: '10 - 50 users'
  },
  fiftyTo100Users: {
    id: 'GRID.COMMON.ASSIGNROLES.FILTERSCHEMA.50TO100USERS',
    defaultMessage: '50 - 100 users'
  },
  hundredTo300Users: {
    id: 'GRID.COMMON.ASSIGNROLES.FILTERSCHEMA.100TO300USERS',
    defaultMessage: '100 - 300 users'
  },
  plus300Users: {
    id: 'GRID.COMMON.ASSIGNROLES.FILTERSCHEMA.300PLUSUSERS',
    defaultMessage: '300+ users'
  },
  currentWeek: {
    id: 'GRID.COMMON.ASSIGNROLES.FILTERSCHEMA.CURRENTWEEK',
    defaultMessage: 'Current Week'
  },
  previousWeek: {
    id: 'GRID.COMMON.ASSIGNROLES.FILTERSCHEMA.PREVIOUSWEEK',
    defaultMessage: 'Previous Week'
  },
  currentMonth: {
    id: 'GRID.COMMON.ASSIGNROLES.FILTERSCHEMA.CURRENTMONTH',
    defaultMessage: 'Current Month'
  },
  previousMonth: {
    id: 'GRID.COMMON.ASSIGNROLES.FILTERSCHEMA.PREVIOUSMONTH',
    defaultMessage: 'Previous Month'
  },
  past6Months: {
    id: 'GRID.COMMON.ASSIGNROLES.FILTERSCHEMA.PAST6MONTHS',
    defaultMessage: 'Past 6 Months'
  },
  pastYear: {
    id: 'GRID.COMMON.ASSIGNROLES.FILTERSCHEMA.PASTYEAR',
    defaultMessage: 'Past Year'
  }
});
