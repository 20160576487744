import React, { useEffect, useRef, useState } from 'react';
import 'react-dates/initialize';

import { CUSTOM_ARIA_LABELS, SHORTCUT_CHIPS_POSITIONS, useCustomPhrases } from 'lib/core';
import { DATE_FORMATS, DateInput, getIsDateInInvalidRange, isDateValid } from 'lib/date-input';
import {
  isFocused,
  CODE_VALUES,
  KEY_VALUES,
  useErrorValidation,
  useKeyDownEventListener,
  useKeyCodeEventListener,
  useShareForwardedRef,
  useWindowSize,
} from 'lib/utilities';
import moment from 'moment';
import PropTypes from 'prop-types';
import { DayPickerSingleDateController } from 'react-dates';
import { DayPickerPhrases } from 'react-dates/lib/defaultPhrases';
import momentPropTypes from 'react-moment-proptypes';

import { DEFAULT_MAXIMUM_YEAR, DEFAULT_MINIMUM_YEAR } from '../constants';
import { DatePickerContainer, ShortcutChip, ShortcutContainer } from '../elements';
import { DATE_PICKER_ERROR_TYPES } from '../errors';
import {
  getToday,
  getTomorrow,
  getYesterday,
  isAnyDateOutsideRange,
  isCalendarDayFocused,
  isDateBlocked,
  isDateEmpty,
  isDateInPast,
  isDateToday,
  isSelectedDateBlocked,
  isSelectedDateInPastInvalid,
} from '../utils';
import { isMobileScreen } from './../../core';
import { INPUT_SIZES } from './../../input';

const DatePicker = React.forwardRef(
  (
    {
      allowDaysInPast = false,
      ariaLabels = {
        jumpToNextMonth: CUSTOM_ARIA_LABELS.NEXT_MONTH,
        jumpToPrevMonth: CUSTOM_ARIA_LABELS.PREVIOUS_MONTH,
      },
      blockedDateRanges = [],
      className = '',
      dataTestId = undefined,
      date = undefined,
      dateFormat = DATE_FORMATS.MM_DD_YYYY,
      dateInputProps = {},
      dateInputRef = undefined,
      defaultIsOpen = false,
      highlightToday = false,
      id,
      label,
      maximumYear = DEFAULT_MAXIMUM_YEAR,
      minimumYear = DEFAULT_MINIMUM_YEAR,
      name,
      numberOfMonths = 2,
      onClose = () => {},
      onDateChange,
      onOpen = () => {},
      shortcutChipsPosition = SHORTCUT_CHIPS_POSITIONS.NONE,
      shortcutLabels = { today: 'Today', tomorrow: 'Tomorrow', yesterday: 'Yesterday' },
      onNumpadEnterKeypress = () => {},
      ...other
    },
    ref
  ) => {
    const innerDateInputRef = useShareForwardedRef(dateInputRef);
    const datePickerRef = useShareForwardedRef(ref);
    const lastShortcutChipRef = useRef();

    const [calendarHasClosed, setCalendarHasClosed] = useState(false);

    const [dateInputValue, setDateInputValue] = useState('');
    const [isCalendarOpen, setIsCalendarOpen] = useState(defaultIsOpen);
    const [isCalendarFocused, setIsCalendarFocused] = useState(false);
    const [isNavNextFocused, setIsNavNextFocused] = useState(false);
    const [isNavPrevFocused, setIsNavPrevFocused] = useState(false);

    const windowSize = useWindowSize();
    const numberOfMonthsShown = isMobileScreen(windowSize.width) ? 1 : numberOfMonths;

    const customPhrases = useCustomPhrases(ariaLabels, DayPickerPhrases);

    const shortcutChipsPositioning = isMobileScreen(windowSize.width)
      ? SHORTCUT_CHIPS_POSITIONS.BOTTOM
      : shortcutChipsPosition;

    const isEditable = () => {
      const { isDisabled, isReadOnly } = dateInputProps;
      return !isDisabled && !isReadOnly;
    };

    const shouldRenderCalendarInfo = shortcutChipsPosition !== SHORTCUT_CHIPS_POSITIONS.NONE && isEditable();

    const handleFocusNavNext = () => {
      setIsNavPrevFocused(false);
      setIsNavNextFocused(true);
    };

    const handleFocusNavPrev = () => {
      setIsNavPrevFocused(true);
      setIsNavNextFocused(false);
    };

    const openCalendar = () => {
      setIsCalendarOpen(true);
      setIsCalendarFocused(false);
      onOpen();
    };

    const closeCalendar = () => {
      setIsCalendarOpen(false);
      setIsCalendarFocused(false);
      setCalendarHasClosed(true);
      onClose();
    };

    const closeCalendarAfterTimeout = () => {
      setTimeout(() => {
        closeCalendar();
      }, 0);
    };

    const moveFocusToInput = () => {
      innerDateInputRef.current.focus();
    };

    const preventScroll = (event) => {
      event.preventDefault();
    };

    const handleDateInputChange = (event) => {
      const { value } = event.target;

      const isEmpty = isDateEmpty(value);
      const isValid =
        isDateValid(dateFormat, value, minimumYear, maximumYear) &&
        !getIsDateInInvalidRange(value, dateFormat, minimumYear, maximumYear);

      const newDateValue = isValid && !isEmpty ? moment(value, dateFormat) : undefined;

      onDateChange(newDateValue);
      setDateInputValue(value);
    };
    const handleCalendarDayClick = (momentValue) => {
      if (isEditable()) {
        onDateChange(momentValue);
        moveFocusToInput();

        // closeCalendar is getting called after timeout because of an issue in IE11:
        // Calendar close happens earlier than date input focus
        closeCalendarAfterTimeout();
      } else {
        closeCalendar();
      }
    };

    const handleOutsideClick = (event) => {
      const elementClicked = event.target;

      const clickedOnDateInput = innerDateInputRef.current.contains(elementClicked);

      if (!clickedOnDateInput) {
        closeCalendar();
      }
    };

    const isBottom = isMobileScreen(windowSize.width) || shortcutChipsPosition === SHORTCUT_CHIPS_POSITIONS.BOTTOM;
    const isRight = !isMobileScreen(windowSize.width) && shortcutChipsPosition === SHORTCUT_CHIPS_POSITIONS.RIGHT;

    const renderShortcuts = () => {
      const shortcutChipsProps = [
        { label: shortcutLabels.today, onClick: () => handleCalendarDayClick(getToday()) },
        { label: shortcutLabels.tomorrow, onClick: () => handleCalendarDayClick(getTomorrow()) },
      ];

      if (allowDaysInPast) {
        shortcutChipsProps.push({
          label: shortcutLabels.yesterday,
          onClick: () => handleCalendarDayClick(getYesterday()),
        });
      }

      return (
        <ShortcutContainer isBottom={isBottom} isRight={isRight}>
          {shortcutChipsProps.map((chipProps, i) => (
            <ShortcutChip
              data-testid={dataTestId ? `${dataTestId}-shortcut-chip-${chipProps.label}` : undefined}
              key={chipProps.label}
              isBottom={isBottom}
              isRight={isRight}
              ref={i === shortcutChipsProps.length - 1 ? lastShortcutChipRef : undefined}
              {...chipProps}
            />
          ))}
        </ShortcutContainer>
      );
    };

    const dateInputErrors = [
      isSelectedDateBlocked(date, blockedDateRanges) && DATE_PICKER_ERROR_TYPES.DATE_BLOCKED,
      isSelectedDateInPastInvalid(date, allowDaysInPast) && DATE_PICKER_ERROR_TYPES.INVALID_DATE_IN_PAST,
      getIsDateInInvalidRange(dateInputValue, dateFormat, minimumYear, maximumYear) &&
        DATE_PICKER_ERROR_TYPES.INVALID_DATE_IN_RANGE,
    ].filter(Boolean);

    const { onChildError: onDateInputError, updatedHasError } = useErrorValidation(dateInputErrors, dateInputProps);

    const handleClear = () => {
      // reset all internal state
      setDateInputValue('');
      setCalendarHasClosed(false);
      setIsCalendarFocused(false);

      dateInputProps.onInputClear();
    };

    useKeyDownEventListener(
      KEY_VALUES.ARROW_DOWN,
      (event) => {
        preventScroll(event);

        if (isCalendarOpen) {
          setIsCalendarFocused(true);
        } else {
          openCalendar();
        }
      },
      datePickerRef.current
    );

    useKeyCodeEventListener(
      CODE_VALUES.ENTER,
      () => {
        if (!isCalendarOpen && !isMobileScreen(windowSize.width)) {
          openCalendar();
        }
      },
      datePickerRef.current
    );

    useKeyCodeEventListener(
      CODE_VALUES.NUMPAD_ENTER,
      (event) => {
        const isDateInputFocused = isFocused(innerDateInputRef.current);
        onNumpadEnterKeypress();
        if (isDateInputFocused) {
          closeCalendar();
        }
      },
      datePickerRef.current
    );

    useKeyDownEventListener(
      KEY_VALUES.ESCAPE,
      () => {
        moveFocusToInput();
        closeCalendar();
      },
      datePickerRef.current
    );

    useKeyDownEventListener(
      KEY_VALUES.SPACE,
      (event) => {
        const isNavigationIconFocused = isNavNextFocused || isNavPrevFocused;

        if (isNavigationIconFocused) {
          preventScroll(event);
          setIsNavPrevFocused(false);
          setIsNavNextFocused(false);
        }

        if (!isCalendarOpen) {
          openCalendar();
        }
      },
      datePickerRef.current
    );

    useKeyDownEventListener(
      KEY_VALUES.TAB,
      (event) => {
        const shortcutChipsAreShown = shortcutChipsPosition !== SHORTCUT_CHIPS_POSITIONS.NONE;

        const isShiftKeyPressed = event.shiftKey;
        const isDateInputFocused = isFocused(innerDateInputRef.current);
        const isDayFocused = isCalendarDayFocused(datePickerRef);
        const isLastShortcutChipFocused = shortcutChipsAreShown && isFocused(lastShortcutChipRef.current);

        if (isDateInputFocused) {
          closeCalendar();
          return;
        }

        if (isNavPrevFocused) {
          handleFocusNavNext();
          return;
        }

        if (isNavNextFocused) {
          if (isShiftKeyPressed) {
            handleFocusNavPrev();
            return;
          }

          setIsNavNextFocused(false);
          return;
        }

        if (isDayFocused) {
          if (isShiftKeyPressed) {
            handleFocusNavNext();
            return;
          }

          if (shortcutChipsAreShown) {
            return;
          }

          moveFocusToInput();
          closeCalendar();
          return;
        }

        if (isLastShortcutChipFocused) {
          if (isShiftKeyPressed) {
            return;
          }

          moveFocusToInput();
          closeCalendar();
        }
      },
      datePickerRef.current
    );

    /**
     * NOTE: the next useEffect block differentiates between null and undefined.
     * If undefined, we don't want to do anything (someone is typing)
     * If null, then we want to reset the picker (external clear functionality)
     * If neither (which means a valid date in this case), we want to set the date.
     * This was done to allow for external clearing of the date pickers.
     **/
    useEffect(() => {
      if (date === undefined) {
        return;
      }
      if (date === null) {
        setDateInputValue('');
        return;
      }
      setDateInputValue(date.format(dateFormat));
      closeCalendar();
    }, [date, dateFormat]);

    useEffect(() => {
      if (dateInputProps.isDisabled && isCalendarOpen) setIsCalendarOpen(false);
    }, [dateInputProps.isDisabled, isCalendarOpen]);

    return (
      <DatePickerContainer
        data-testid={dataTestId}
        id={id}
        ref={datePickerRef}
        className={className}
        size={dateInputProps.size}
      >
        <DateInput
          {...dateInputProps}
          autoComplete="off"
          dateFormat={dateFormat}
          dataTestId={dataTestId ? `${dataTestId}-date-input` : undefined}
          enableCustomValidation
          hasError={isCalendarOpen ? updatedHasError && calendarHasClosed : updatedHasError}
          id={`${id}-date-input`}
          label={label}
          name={name}
          onChange={handleDateInputChange}
          onError={onDateInputError}
          onFocus={openCalendar}
          ref={innerDateInputRef}
          value={dateInputValue}
          onInputClear={handleClear}
        />
        {isCalendarOpen && (
          <DayPickerSingleDateController
            calendarInfoPosition={
              shortcutChipsPosition !== SHORTCUT_CHIPS_POSITIONS.NONE ? shortcutChipsPositioning : undefined
            }
            date={date}
            daySize={40}
            focused={isCalendarOpen}
            hideKeyboardShortcutsPanel
            horizontalMonthPadding={6}
            isDayBlocked={isDateBlocked(blockedDateRanges)}
            isDayHighlighted={highlightToday ? isDateToday : undefined}
            isFocused={isCalendarFocused}
            isOutsideRange={allowDaysInPast ? isAnyDateOutsideRange : isDateInPast}
            numberOfMonths={numberOfMonthsShown}
            onDateChange={handleCalendarDayClick}
            onOutsideClick={handleOutsideClick}
            phrases={customPhrases}
            renderCalendarInfo={shouldRenderCalendarInfo ? renderShortcuts : undefined}
            onPrevMonthClick={handleFocusNavPrev}
            onNextMonthClick={handleFocusNavNext}
            {...other}
          />
        )}
      </DatePickerContainer>
    );
  }
);

DatePicker.propTypes = {
  /** If true, it is allowed to pick days in past */
  allowDaysInPast: PropTypes.bool,
  /** Sets text that will be displayed for aria-labels required for screen readers */
  ariaLabels: PropTypes.shape({
    /** Text displayed for next month navigation arrow */
    jumpToNextMonth: PropTypes.node.isRequired,
    /** Text displayed for previous month navigation arrow */
    jumpToPrevMonth: PropTypes.node.isRequired,
  }),
  /** An array of date ranges that should be blocked for user to select. */
  blockedDateRanges: PropTypes.arrayOf(
    PropTypes.shape({
      /** Last day inclusive of blocked date range. */
      endDate: PropTypes.instanceOf(moment),
      /** First day inclusive of blocked date range. */
      startDate: PropTypes.instanceOf(moment),
    })
  ),
  /** Adds additional class for datePicker */
  className: PropTypes.string,
  /** Id value used for testing */
  dataTestId: PropTypes.string,
  /** Value of datepicker date. It's type is a moment object. Set it to null to reset the picker */
  date: momentPropTypes.momentObj,
  /** Defines in what format date input should format value (e.g. YYYY/MM/DD, MM/DD/YYYY, DD/MM/YYYY) */
  dateFormat: PropTypes.oneOf(Object.values(DATE_FORMATS)),
  /** Props that are supplied to DateInput used in this component. */
  dateInputProps: PropTypes.shape({
    /** Message to be displayed when date input is in error state */
    errorMessage: PropTypes.node,
    /** When true, input is in error state */
    hasError: PropTypes.bool,
    /** Text to be displayed as a helper text near the input field */
    helperText: PropTypes.node,
    /** If true, input is disabled and value of it cannot be edited */
    isDisabled: PropTypes.bool,
    /** If true, input is in read only state, value cannot be edited */
    isReadOnly: PropTypes.bool,
    /** If true, isRequired asterisk will be shown */
    isRequired: PropTypes.bool,
    /** Callback that is called when Date Input has been blurred */
    onBlur: PropTypes.func,
    /** Callback that is called when Date Input errors change */
    onError: PropTypes.func,
    /** Callback that is called when date input is cleared */
    onInputClear: PropTypes.func,
    /** Set the size of the input */
    size: PropTypes.oneOf(Object.values(INPUT_SIZES)),
  }),
  /** Access DOM element of date input field */
  dateInputRef: PropTypes.oneOfType([
    PropTypes.func,
    PropTypes.shape({ current: PropTypes.instanceOf(HTMLInputElement) }),
  ]),
  /** If true, date picker is open on initial render */
  defaultIsOpen: PropTypes.bool,
  /** If true, today on the calendar is highlighted */
  highlightToday: PropTypes.bool,
  /** Unique identifier to identify date picker component */
  id: PropTypes.string.isRequired,
  /** Label of the date input field */
  label: PropTypes.node.isRequired,
  /** Maximum valid year available for date picker date value */
  maximumYear: PropTypes.number,
  /** Minimum valid year available for date picker date value */
  minimumYear: PropTypes.number,
  /** Name that is supplied to DateInput component */
  name: PropTypes.string.isRequired,
  /** Defines how many months should be shown on calendar */
  numberOfMonths: PropTypes.number,
  /** Callback that is called when date picker should close */
  onClose: PropTypes.func,
  /** Callback that is called when the "Enter" key on the number pad is pressed. */
  onNumpadEnterKeypress: PropTypes.func,
  /** Callback that is called when date picker value changes.
   * It takes one argument: new date value of type PropTypes.instanceOf(moment) */
  onDateChange: PropTypes.func.isRequired,
  /** Callback that is called when date picker should open */
  onOpen: PropTypes.func,
  /** Sets where shortcut chips should be rendered */
  shortcutChipsPosition: PropTypes.oneOf(Object.values(SHORTCUT_CHIPS_POSITIONS)),
  /** Sets text that will be displayed for today, tomorrow and yesterday shortcuts */
  shortcutLabels: PropTypes.shape({
    /** Text displayed for today shortcut */
    today: PropTypes.node.isRequired,
    /** Text displayed for tomorrow shortcut */
    tomorrow: PropTypes.node.isRequired,
    /** Text displayed for yesterday shortcut */
    yesterday: PropTypes.node.isRequired,
  }),
};

export { DatePicker };
