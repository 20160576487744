import fr from './compiled/fr-CA.json';
import en from './compiled/en-US.json';

const loadMessages = (locale: string): Record<string, string> => {
  switch (locale) {
    case 'fr':
      return fr;
    default:
      return en;
  }
};

export default loadMessages;
