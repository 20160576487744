import { configureStore, combineReducers } from '@reduxjs/toolkit';
import { authzServiceApi } from './services/authz-service';
import { userImportApi } from './services/user-migration-service';
import { eisHierarchyAPI } from './services/eis-hierarchy-service';
import { enterpriseInfoServiceAPI } from './services/enterpriseInfo-service';
import { packageEnablementApi } from './services/pis-service';
import { eisExternalAttributesServiceAPI } from './services/eis-external-attributes-service';
import { eisCoreServiceAPI } from './services/eis-core-service';
import { reportsDownloadServiceAPI } from './services/reports-download-service';
import userImportReducer from './slices/userImportSlice';
import csvImportReducer from './slices/csvImportSlice';
import userManagementReducer from './slices/userManagementSlice';
import unassignDepartmentReducer from './slices/storeDepartmentSlice';
import rolesReducer from './slices/roleSlice';
import userviceUserSliceReducer from './slices/serviceUserSlice';
import userRoleSliceReducer from './slices/userRoleSlice';
import allEnterprisesSliceReducer from './slices/allEnterprisesSlice';
import commonReducer from './slices/commonSlice';
import { identityServiceAPI } from './services/identity-service';
import { adminServiceAPI } from './services/admin-service';
import { lookerServiceAPI } from './services/looker-service';

const combinedSlices = combineReducers({
  commonState: commonReducer,
  userImportState: userImportReducer,
  csvImportState: csvImportReducer,
  userManagementState: userManagementReducer,
  unassignDepartmentState: unassignDepartmentReducer,
  rolesState: rolesReducer,
  serviceUserState: userviceUserSliceReducer,
  userRoleState: userRoleSliceReducer,
  allEnterprisesState: allEnterprisesSliceReducer
});

export const reducer = {
  [authzServiceApi.reducerPath]: authzServiceApi.reducer,
  [userImportApi.reducerPath]: userImportApi.reducer,
  [eisHierarchyAPI.reducerPath]: eisHierarchyAPI.reducer,
  [enterpriseInfoServiceAPI.reducerPath]: enterpriseInfoServiceAPI.reducer,
  [packageEnablementApi.reducerPath]: packageEnablementApi.reducer,
  [eisCoreServiceAPI.reducerPath]: eisCoreServiceAPI.reducer,
  [eisExternalAttributesServiceAPI.reducerPath]:
    eisExternalAttributesServiceAPI.reducer,
  [reportsDownloadServiceAPI.reducerPath]: reportsDownloadServiceAPI.reducer,
  [identityServiceAPI.reducerPath]: identityServiceAPI.reducer,
  [adminServiceAPI.reducerPath]: adminServiceAPI.reducer,
  [lookerServiceAPI.reducerPath]: lookerServiceAPI.reducer,
  slices: combinedSlices
};

const store = configureStore({
  reducer,
  middleware: getDefaultMiddleware =>
    getDefaultMiddleware({ serializableCheck: false }).concat(
      authzServiceApi.middleware,
      userImportApi.middleware,
      eisHierarchyAPI.middleware,
      enterpriseInfoServiceAPI.middleware,
      packageEnablementApi.middleware,
      eisCoreServiceAPI.middleware,
      eisExternalAttributesServiceAPI.middleware,
      reportsDownloadServiceAPI.middleware,
      identityServiceAPI.middleware,
      adminServiceAPI.middleware
    )
});

export default store;

export type RootState = ReturnType<typeof store.getState>;

export type AppDispatch = typeof store.dispatch;
