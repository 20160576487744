import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import { getRem } from './../../core';
import { ChipContent } from './ChipContent';
import { ChipCheckboxIcon } from '../elements';

const StyledChipContent = styled(ChipContent)`
  padding-left: ${getRem('38px')};
`;

const ChipContentWithCheckbox = React.forwardRef(
  (
    {
      disableFocus = false,
      hasCheckbox = undefined,
      isSelected = false,
      label,
      onClick = undefined,
      value = null,
      ...other
    },
    ref
  ) => {
    const renderIcon = () => !!hasCheckbox && <ChipCheckboxIcon />;

    return (
      <StyledChipContent
        {...{ disableFocus, hasCheckbox, isSelected, label, onClick, value }}
        {...other}
        ref={ref}
        renderBeforeContent={renderIcon}
      />
    );
  }
);

ChipContentWithCheckbox.propTypes = {
  /** If true, chips won't be focusable via keyboard */
  disableFocus: PropTypes.bool,
  /** Shows checkbox inside the button. Used in filter chips */
  hasCheckbox: PropTypes.bool,
  /** If true, renders chip option as selected */
  isSelected: PropTypes.bool,
  /** Chip label */
  label: PropTypes.node.isRequired,
  /** Callback function which is triggered on chip click */
  onClick: PropTypes.func,
  /** Value of the chip */
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
};

export { ChipContentWithCheckbox };
