import PropTypes from 'prop-types';
import styled from 'styled-components';

import { getRem, wordBreak } from '../../core';

const ToastContent = styled.div.withConfig({
  shouldForwardProp: (prop) => !['withMultipleActions'].includes(prop),
})`
  ${wordBreak}
  box-sizing: border-box;
  display: flex;
  flex: 1 1 auto;
  padding: ${({ theme }) => `${theme.size.spacing.large.value} ${getRem('14px')}`};
  padding-bottom: ${({ theme, withMultipleActions = false }) =>
    withMultipleActions ? 0 : theme.size.spacing.large.value};
  touch-action: none;
`;

ToastContent.propTypes = {
  /** Any content inserted between component tags */
  children: PropTypes.node.isRequired,
  /** If true sets padding-bottom to 0 */
  withMultipleActions: PropTypes.bool,
};

export { ToastContent };
