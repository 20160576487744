import React from 'react';
import { ICON_SIZE } from 'lib/icons';
import PropTypes from 'prop-types';
import styled, { css } from 'styled-components';

import { ButtonIcon, ButtonLoader, ButtonText } from '../elements';

const StyledButtonContentIcon = styled(ButtonIcon).withConfig({
  shouldForwardProp: (prop) => !['isTextVisuallyHidden'].includes(prop),
})`
  ${({ isTextVisuallyHidden }) =>
    !isTextVisuallyHidden &&
    css`
      margin-right: ${({ theme }) => theme.size.spacing.small.value};
    `}
`;

const ButtonContent = ({
  dataTestId = undefined,
  hideText = false,
  icon = undefined,
  iconClassName = '',
  iconSize = ICON_SIZE.DEFAULTf,
  isLoading = false,
  loaderText = 'Loading...',
  text,
}) => {
  if (isLoading) {
    return <ButtonLoader label={loaderText} data-testid={dataTestId ? `${dataTestId}-loader` : undefined} />;
  }

  return (
    <>
      {!!icon && (
        <StyledButtonContentIcon
          data-testid={dataTestId ? `${dataTestId}-icon` : undefined}
          className={iconClassName}
          iconSize={iconSize}
          isTextVisuallyHidden={hideText}
        >
          {icon}
        </StyledButtonContentIcon>
      )}
      {!!text && (
        <ButtonText data-testid={dataTestId ? `${dataTestId}-text` : undefined} isHidden={hideText}>
          {text}
        </ButtonText>
      )}
    </>
  );
};

ButtonContent.propTypes = {
  /** Id value used for testing */
  dataTestId: PropTypes.string,
  /** If true, will visually hide text */
  hideText: PropTypes.bool,
  /** If not loading, will render specified icon before text. Use icon component from the library */
  icon: PropTypes.node,
  /** Adds new class for icon element */
  iconClassName: PropTypes.string,
  /** Will set size of the icon */
  iconSize: PropTypes.oneOf(Object.values(ICON_SIZE)),
  /** If true, will display loader */
  isLoading: PropTypes.bool,
  /** Loader text which will be read for screen reader users */
  loaderText: PropTypes.node,
  /** Will display text inside button */
  text: PropTypes.node.isRequired,
};

export { ButtonContent, StyledButtonContentIcon };
