import { focusOutlineColors } from 'lib/core';
import { css } from 'styled-components';

export const menuSwitcherButtonStateStyles = {
  active: css`
    background-color: ${({ theme }) => theme.color.gray[300].value};
  `,
  focus: css`
    background-color: ${({ theme }) => theme.color.gray[200].value};
    box-shadow: 0 0 0 4px ${({ theme }) => focusOutlineColors.getDark(theme)};
  `,
  hover: css`
    background-color: ${({ theme }) => theme.color.gray[200].value};
  `,
};
