import React, { cloneElement, useEffect } from 'react';
import { useShareForwardedRef } from 'lib/utilities';
import PropTypes from 'prop-types';
import styled, { css } from 'styled-components';

import { BREAKPOINTS, getRem, visuallyHidden } from './../../core';
import { CHECKBOX_LABEL_POSITIONS, CHECKBOX_SIZES } from '../constants';

const CHECKBOX_WRAPPER_SIZES = {
  [CHECKBOX_SIZES.LARGE]: '48px',
  [CHECKBOX_SIZES.SMALL]: '32px',
  [CHECKBOX_SIZES.STANDARD]: '40px',
};

const beforeLabelStyles = css`
  padding-right: ${getRem('15px')};
`;

const StyledCheckboxWrapper = styled.label`
  align-items: center;
  box-sizing: border-box;
  cursor: ${({ isDisabled }) => (isDisabled ? 'not-allowed' : 'pointer')};
  display: inline-flex;
  min-height: ${getRem(CHECKBOX_WRAPPER_SIZES[CHECKBOX_SIZES.LARGE])};
  min-width: 0;
  width: 100%;

  @media ${BREAKPOINTS.L} {
    min-height: ${({ size }) => getRem(CHECKBOX_WRAPPER_SIZES[size])};
    padding-right: ${({ hideLabel }) => !hideLabel && getRem('32px')};
    width: auto;
  }
`;

const StyledCheckboxInput = styled.input`
  ${visuallyHidden}
`;

const StyledCheckboxIndicator = styled(({ renderIndicator, ...props }) => cloneElement(renderIndicator(), props))`
  order: ${({ labelPosition }) => (labelPosition === CHECKBOX_LABEL_POSITIONS.BEFORE ? 1 : 0)};

  && {
    margin: ${({ hideLabel, labelPosition, theme }) =>
      labelPosition === CHECKBOX_LABEL_POSITIONS.BEFORE && !hideLabel && `0 ${theme.size.spacing.medium.value}`};

    @media ${BREAKPOINTS.L} {
      margin: ${({ hideLabel, labelPosition }) => labelPosition === CHECKBOX_LABEL_POSITIONS.BEFORE && !hideLabel && 0};
    }
  }
`;

const StyledCheckboxLabel = styled(({ renderLabel, ...props }) => cloneElement(renderLabel(), props))`
  order: ${({ labelPosition }) => (labelPosition === CHECKBOX_LABEL_POSITIONS.BEFORE ? 0 : 1)};

  && {
    ${({ labelPosition }) => labelPosition === CHECKBOX_LABEL_POSITIONS.BEFORE && beforeLabelStyles};
  }
`;

const BaseCheckbox = React.forwardRef(
  (
    {
      checked,
      dataTestId = undefined,
      hideLabel = false,
      id = '',
      indeterminate = false,
      isDisabled = false,
      label = '',
      labelPosition = CHECKBOX_LABEL_POSITIONS.AFTER,
      name,
      onChange,
      renderIndicator,
      renderLabel,
      size = CHECKBOX_SIZES.STANDARD,
      truncateLabel = false,
      ...other
    },
    ref
  ) => {
    const checkboxInputRef = useShareForwardedRef(ref);

    useEffect(() => {
      checkboxInputRef.current.indeterminate = indeterminate;
    }, [checkboxInputRef, indeterminate]);

    return (
      <StyledCheckboxWrapper
        data-testid={dataTestId}
        hideLabel={hideLabel || !label}
        htmlFor={id || name}
        isDisabled={isDisabled}
        size={size}
        truncateLabel={truncateLabel}
        {...other}
      >
        <StyledCheckboxInput
          checked={checked}
          data-testid={dataTestId ? `${dataTestId}-input` : undefined}
          disabled={isDisabled}
          id={id || name}
          name={name}
          onChange={onChange}
          ref={checkboxInputRef}
          tabIndex={-1}
          type="checkbox"
        />
        <StyledCheckboxIndicator
          checked={checked}
          data-testid={dataTestId ? `${dataTestId}-indicator` : undefined}
          hideLabel={hideLabel}
          indeterminate={indeterminate}
          isDisabled={isDisabled}
          labelPosition={labelPosition}
          renderIndicator={renderIndicator}
          size={size}
        />
        {!!label && (
          <StyledCheckboxLabel
            data-testid={dataTestId ? `${dataTestId}-label` : undefined}
            hideLabel={hideLabel}
            label={label}
            labelPosition={labelPosition}
            renderLabel={renderLabel}
            size={size}
            truncateLabel={truncateLabel}
          />
        )}
      </StyledCheckboxWrapper>
    );
  }
);

BaseCheckbox.propTypes = {
  /** The current value of checkbox - checked or unchecked */
  checked: PropTypes.bool.isRequired,
  /** Provides test id for testing libraries */
  dataTestId: PropTypes.string,
  /** Visually hides checkbox label */
  hideLabel: PropTypes.bool,
  /** Identifier for this checkbox */
  id: PropTypes.string,
  /** Changes the icon and sets checkbox indeterminate parameter state */
  indeterminate: PropTypes.bool,
  /** Disables to change the value of checkbox and shows it visually disabled */
  isDisabled: PropTypes.bool,
  /** Checkbox label */
  label: PropTypes.node,
  /** Places label before or after indicator */
  labelPosition: PropTypes.oneOf(Object.values(CHECKBOX_LABEL_POSITIONS)),
  /** Name of the checkbox */
  name: PropTypes.string.isRequired,
  /** Callback that is called when interacting with checkbox */
  onChange: PropTypes.func.isRequired,
  /** Renders custom element inside a component. It should represent the default checkbox indicator */
  renderIndicator: PropTypes.func.isRequired,
  /** Renders custom element inside a component. Should be used for providing label appearance */
  renderLabel: PropTypes.func.isRequired,
  /** Checkbox size */
  size: PropTypes.oneOf(Object.values(CHECKBOX_SIZES)),
  /** If true, truncates the checkbox label */
  truncateLabel: PropTypes.bool,
};

export { BaseCheckbox, StyledCheckboxWrapper };
