import { defineMessages } from 'react-intl';

export const messages = defineMessages({
  title: {
    id: 'NEWUSERIMPORT.STEP4.TEMPLATE.TITLE',
    defaultMessage: 'New Import List'
  },
  importName: {
    id: 'NEWUSERIMPORT.STEP4.LIST.NAME',
    defaultMessage: 'Import List Name'
  }
});
