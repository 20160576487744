import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import { getRem } from './../../core';
import { ChipContent } from './ChipContent';
import { ChipIcon } from '../elements';

const StyledChipContent = styled(ChipContent)`
  padding-left: ${getRem('30px')};
`;

const ChipContentWithIcon = React.forwardRef(
  ({ disableFocus = false, icon = undefined, label, onClick = undefined, value = null, ...other }, ref) => {
    const renderIcon = () => !!icon && <ChipIcon>{icon}</ChipIcon>;

    return (
      <StyledChipContent
        {...{ disableFocus, icon, label, onClick, value }}
        {...other}
        renderBeforeContent={renderIcon}
        ref={ref}
      />
    );
  }
);

ChipContentWithIcon.propTypes = {
  /** If true, chips won't be focusable via keyboard */
  disableFocus: PropTypes.bool,
  /** Shows icon inside the button. Use icon component from the library */
  icon: PropTypes.node,
  /** Chip label */
  label: PropTypes.node.isRequired,
  /** Callback function which is triggered on chip click */
  onClick: PropTypes.func,
  /** Value of the chip */
  value: PropTypes.node,
};

export { ChipContentWithIcon };
