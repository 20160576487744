import styled from 'styled-components';
import { Row } from 'cdk-radial';

const StyledDescription = styled(Row)`
  padding-top: 0px !important;
  &:last-of-type {
    padding-bottom: 0px !important;
  }

  .info-label {
    color: black;
    font-size: 0.875rem;
    padding-top: 0.3rem;
  }

  .info-label:first-of-type {
    padding-top: 0;
  }

  .info-value {
    color: black;
    opacity: 60%;
    padding-top: 0.2rem;
  }
`;

export default StyledDescription;
