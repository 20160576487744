import React from 'react';

import { ICON_SIZE } from 'lib/icons';
import { KEY_VALUES, preventDefaultBehavior } from 'lib/utilities';
import PropTypes from 'prop-types';
import styled, { css } from 'styled-components';

import { ElementStateWrapper, getRem, transitionAnimation, typographyButton } from './../../core';
import { ButtonContent } from './ButtonContent';
import { BUTTON_SIZES } from '../constants';
import { NormalizedButton } from '../elements';

const getButtonSizeStyles = (size, margin) => css`
  height: ${size};
  min-height: ${size};
  min-width: ${size};
  ${!!margin &&
  css`
    margin-bottom: ${margin};
    margin-top: ${margin};
  `}
`;

const getButtonSize = (theme, size, preserveClickableArea) => {
  switch (size) {
    case BUTTON_SIZES.SMALL:
      return getButtonSizeStyles(getRem(32), preserveClickableArea ? theme.size.spacing.medium.value : null);
    case BUTTON_SIZES.LARGE:
      return getButtonSizeStyles(getRem(40), preserveClickableArea ? theme.size.spacing.small.value : null);
    default:
      return getButtonSizeStyles(getRem(36), preserveClickableArea ? getRem(6) : null);
  }
};

const StyledBaseButton = styled(NormalizedButton)`
  ${({ theme }) => typographyButton(theme)}
  ${transitionAnimation('background-color, box-shadow, color, transform')}
    align-items: center;
  border-radius: ${({ theme }) => getRem(theme.size.borderRadius.large.value)};
  box-shadow: none;
  cursor: ${({ isDisabled }) => (isDisabled ? 'not-allowed' : 'pointer')};
  display: inline-flex;
  justify-content: center;
  margin: 0;
  padding: 0 ${getRem(12)};
  position: relative;
  text-decoration: none;
  ${({ preserveClickableArea, size, theme }) => getButtonSize(theme, size, preserveClickableArea)}

  &::after {
    ${transitionAnimation('background-color, border-color')}
    border-radius: ${({ theme }) => getRem(theme.size.borderRadius.large.value)};
    box-sizing: border-box;
    content: '';
    display: block;
    height: 100%;
    left: 0;
    position: absolute;
    top: 0;
    width: 100%;
    z-index: ${({ theme }) => theme.zIndex.buttonOverlay.value};
  }

  ${({ isLoading }) =>
    isLoading &&
    css`
      cursor: progress;
      min-width: ${getRem(68)};
    `}

  ${({ hasIcon, theme }) =>
    hasIcon &&
    css`
      padding-left: ${theme.size.spacing.medium.value};
    `}

    ${({ showOnlyIcon }) =>
    showOnlyIcon &&
    css`
      padding-left: 0;
      padding-right: 0;
    `}
`;

const BaseButton = React.forwardRef(
  (
    {
      customTagElement = '',
      dataTestId = undefined,
      hideText = false,
      href = undefined,
      icon = undefined,
      iconClassName = '',
      iconSize = ICON_SIZE.DEFAULT,
      isDisabled = false,
      isLoading = false,
      loaderText = 'Loading...',
      onClick = () => {},
      onKeyDown = () => {},
      preserveClickableArea = false,
      size = BUTTON_SIZES.STANDARD,
      stateStyles = {
        active: [],
        default: [],
        disabled: [],
        focus: [],
        hover: [],
      },
      text,
      type = '',
      ...other
    },
    ref
  ) => {
    const isLink = !!href;
    const defaultTag = isLink ? 'a' : 'button';
    const Tag = customTagElement || defaultTag;

    const handleClick = (event) => {
      if (isDisabled) {
        event.preventDefault();
      } else {
        onClick(event);
      }
    };

    const handleKeyDown = (event) => {
      const isSpaceClicked = event.key === KEY_VALUES.SPACE;
      const shouldOpen = isLink && isSpaceClicked && !isDisabled;

      if (shouldOpen) {
        preventDefaultBehavior(event);
        event.target.click();
      }

      onKeyDown(event);
    };

    const attributes = { onKeyDown: handleKeyDown, ...other };

    if (!customTagElement) {
      if (isLink) {
        attributes.href = !isDisabled ? href : undefined;
        attributes['aria-disabled'] = isDisabled;
        attributes.onClick = isDisabled ? (event) => event.preventDefault() : undefined;
      } else {
        attributes.disabled = isDisabled;
        attributes.type = type || 'button';
        attributes.onClick = handleClick;
      }
    }

    return (
      <ElementStateWrapper isDisabled={isDisabled} stateStyles={stateStyles} {...attributes}>
        <StyledBaseButton
          as={Tag}
          data-testid={dataTestId}
          hasIcon={!!icon}
          isDisabled={isDisabled}
          isLoading={isLoading}
          onClick={handleClick}
          preserveClickableArea={preserveClickableArea}
          ref={ref}
          showOnlyIcon={!!icon && hideText}
          size={size}
        >
          <ButtonContent
            dataTestId={dataTestId}
            hideText={hideText}
            icon={icon}
            iconClassName={iconClassName}
            iconSize={iconSize}
            isLoading={isLoading}
            loaderText={loaderText}
            text={text}
          />
        </StyledBaseButton>
      </ElementStateWrapper>
    );
  }
);

BaseButton.propTypes = {
  /** Replaces default tag: button or anchor with new value */
  customTagElement: PropTypes.string,
  /** Id value used for testing */
  dataTestId: PropTypes.string,
  /** If true, will visually hide text */
  hideText: PropTypes.bool,
  /** When URL is provided, element changes from button to hyperlink <a> */
  href: PropTypes.string,
  /** If not loading, will render specified icon before text */
  icon: PropTypes.node,
  /** Adds new class for icon element */
  iconClassName: PropTypes.string,
  /** Will set size of the icon */
  iconSize: PropTypes.oneOf(Object.values(ICON_SIZE)),
  /** Disallows user to interact with the button */
  isDisabled: PropTypes.bool,
  /** If true, will display loader and adjust width to better accommodate spinner */
  isLoading: PropTypes.bool,
  /** Loader text which will be read for screen reader users */
  loaderText: PropTypes.node,
  /** Callback that is called on click */
  onClick: PropTypes.func,
  /** Callback that is called on key down */
  onKeyDown: PropTypes.func,
  /** If true, will add vertical margins to the component */
  preserveClickableArea: PropTypes.bool,
  /** Changes button height */
  size: PropTypes.oneOf(Object.values(BUTTON_SIZES)),
  /** Specifies element state styles */
  stateStyles: PropTypes.shape({
    /** Specifies :active pseudo state styles */
    active: PropTypes.array.isRequired,
    /** Specifies default styles that are always applied */
    default: PropTypes.array.isRequired,
    /** Specifies styles when isDisabled is true */
    disabled: PropTypes.array.isRequired,
    /** Specifies :focus pseudo state styles */
    focus: PropTypes.array.isRequired,
    /** Specifies :hover pseudo state styles */
    hover: PropTypes.array.isRequired,
  }),
  /** Will display text inside button */
  text: PropTypes.node.isRequired,
  /** Default html button 'type' attribute values when button component is used */
  type: PropTypes.string,
};

export { BaseButton };
