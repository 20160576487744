import { Profile, Store } from './type';

export const getProfileAccounts = (profiles: Profile[]): string[] =>
  profiles?.reduce(
    (acc, curr): string[] => [...acc, ...curr.accounts],
    [] as string[]
  );

export const getStores = (stores: Store[]): string[] =>
  stores?.map(store => store.id);
