import { createSlice } from '@reduxjs/toolkit';
import type { PayloadAction } from '@reduxjs/toolkit';

import { Permissions, Resources } from './types/userManagementSliceTypes';

export const serviceUserSlice = createSlice({
  name: 'serviceUserSilce',
  initialState: {
    permissions: [] as Permissions[],
    editUserPermissions: [] as Permissions[],
    permissionsBeforeEdit: [] as Permissions[]
  },
  reducers: {
    addSelectedPermission: (state, action: PayloadAction<Permissions>) => {
      state.permissions.push(action.payload);
    },
    removeSelectedPermission: (state, action: PayloadAction<Permissions>) => {
      const { permissionId } = action.payload;
      const arr = state.permissions.filter(
        item => item.permissionId !== permissionId
      );
      state.permissions = arr;
    },
    addSelectedResource: (state, action: PayloadAction<Resources>) => {
      const { permissionId, resourceId } = action.payload;
      state?.permissions
        ?.find(item => item?.permissionId === permissionId)
        ?.resources?.push(resourceId);
    },
    removeSelectedReource: (state, action: PayloadAction<Resources>) => {
      const { permissionId, resourceId } = action.payload;
      const resources = state.permissions.find(
        item => item.permissionId === permissionId
      )?.resources;
      const resourceIdIndex = state.permissions
        .find(item => item.permissionId === permissionId)
        ?.resources?.indexOf(resourceId);

      if (
        resources &&
        resources?.length > 0 &&
        resourceIdIndex &&
        resourceIdIndex >= 0
      ) {
        state.permissions
          .find(item => item.permissionId === permissionId)
          ?.resources?.splice(resourceIdIndex, 1);
      } else {
        state.permissions
          .find(item => item.permissionId === permissionId)
          ?.resources?.splice(0, 1);
      }
    },
    editUserAddSelectedPermission: (
      state,
      action: PayloadAction<Permissions>
    ) => {
      state.editUserPermissions.push(action.payload);
    },
    editUserAddSelectedPermissionBeforeEdit: (
      state,
      action: PayloadAction<Permissions>
    ) => {
      state.permissionsBeforeEdit.push(action.payload);
    },
    editUserRemoveSelectedPermission: (
      state,
      action: PayloadAction<Permissions>
    ) => {
      const { permissionId } = action.payload;
      const arr = state.editUserPermissions.filter(
        item => item.permissionId !== permissionId
      );
      state.editUserPermissions = arr;
    },
    editUserAddSelectedResource: (state, action: PayloadAction<Resources>) => {
      const { permissionId, resourceId } = action.payload;
      state?.editUserPermissions
        ?.find(item => item?.permissionId === permissionId)
        ?.resources?.push(resourceId);
    },
    editUserRemoveSelectedReource: (
      state,
      action: PayloadAction<Resources>
    ) => {
      const { permissionId, resourceId } = action.payload;
      const resources = state.editUserPermissions.find(
        item => item.permissionId === permissionId
      )?.resources;
      const resourceIdIndex = state.editUserPermissions
        .find(item => item.permissionId === permissionId)
        ?.resources?.indexOf(resourceId);

      if (
        resources &&
        resources?.length > 0 &&
        resourceIdIndex &&
        resourceIdIndex >= 0
      ) {
        state.editUserPermissions
          .find(item => item.permissionId === permissionId)
          ?.resources?.splice(resourceIdIndex, 1);
      } else {
        state.editUserPermissions
          .find(item => item.permissionId === permissionId)
          ?.resources?.splice(0, 1);
      }
    },
    editUserRemoveAllPermissions: state => {
      state.editUserPermissions = [];
      state.permissionsBeforeEdit = [];
      state.permissions = [];
    }
  }
});

export const {
  addSelectedPermission,
  removeSelectedPermission,
  addSelectedResource,
  removeSelectedReource,
  editUserAddSelectedPermission,
  editUserAddSelectedPermissionBeforeEdit,
  editUserRemoveSelectedPermission,
  editUserAddSelectedResource,
  editUserRemoveSelectedReource,
  editUserRemoveAllPermissions
} = serviceUserSlice.actions;

export default serviceUserSlice.reducer;
