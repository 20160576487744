import { defineMessages } from 'react-intl';

export const messages = defineMessages({
  stepOneSelectCnumber: {
    id: 'NEWUSERIMPORT.STEP1.TITLE.SELECTCNUMBER',
    defaultMessage: 'Select CNumber'
  },
  stepOneNote: {
    id: 'NEWUSERIMPORT.STEP1.NOTE',
    defaultMessage:
      'Note: The “All” option might slow down the processing time or timeout depending on the number of users.Selecting individual CNumbers is recommended'
  }
});
