import { defineMessages } from 'react-intl';

export const messages = defineMessages({
  removeUser: {
    id: 'CSVUSERIMPORT.STEP3.REMOVEUSER',
    defaultMessage: 'Remove User'
  },
  assignRoles: {
    id: 'CSVUSERIMPORT.STEP3.ASSIGNROLES',
    defaultMessage: 'Assign Roles'
  },
  assignStores: {
    id: 'CSVUSERIMPORT.STEP3.ASSIGNSTORES',
    defaultMessage: 'Assign Stores'
  }
});
