import { Suspense } from 'react';
import { Switch } from 'react-router-dom';
import routesConfig from './config';
import RouteGeneratorComponent from './RouteGeneratorComponent';

const RoutesComponent = (): JSX.Element => (
  <>
    <Suspense fallback={<div>Loading...</div>}>
      <Switch>
        <RouteGeneratorComponent routesConfig={routesConfig} />
      </Switch>
    </Suspense>
  </>
);

export default RoutesComponent;
