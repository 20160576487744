import React from 'react';
import PropTypes from 'prop-types';
import styled, { css } from 'styled-components';

import {
  BREAKPOINTS,
  getRem,
  truncateText,
  typographyBody1,
  typographyBody2,
  visuallyHidden,
  wordBreak,
} from './../../core';
import { CHECKBOX_SIZES } from '../constants';

const StyledCheckboxLabel = styled.span`
  ${({ theme }) => typographyBody2(theme)}
  align-self: center;
  display: inline-flex;
  line-height: ${getRem('20px')};
  pointer-events: none;

  @media ${BREAKPOINTS.L} {
    ${({ size, theme }) => size !== CHECKBOX_SIZES.LARGE && typographyBody1(theme)}
    line-height: ${getRem('20px')};
  }

  ${({ size, theme }) =>
    size === CHECKBOX_SIZES.LARGE &&
    css`
      ${typographyBody2(theme)}
      line-height: ${getRem('20px')};
    `}

  ${({ hideLabel }) => hideLabel && visuallyHidden}
    ${({ truncateLabel }) =>
    truncateLabel
      ? css`
          ${truncateText}
          display: block;
        `
      : css`
          ${wordBreak}
          display: block;
          width: 100%;
        `};
`;

const CheckboxLabel = ({
  hideLabel = false,
  label = '',
  size = CHECKBOX_SIZES.STANDARD,
  truncateLabel = false,
  ...other
}) => (
  <StyledCheckboxLabel hideLabel={hideLabel} size={size} truncateLabel={truncateLabel} {...other}>
    {label}
  </StyledCheckboxLabel>
);

CheckboxLabel.propTypes = {
  /** Visually hides checkbox label */
  hideLabel: PropTypes.bool,
  /** Checkbox label */
  label: PropTypes.node,
  /** Checkbox size */
  size: PropTypes.oneOf(Object.values(CHECKBOX_SIZES)),
  /** If true, truncates the label */
  truncateLabel: PropTypes.bool,
};

export { CheckboxLabel };
