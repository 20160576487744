import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Input } from 'cdk-radial';
import { useIntl } from 'react-intl';
import moment, { Moment } from 'moment';
import { TimeType } from 'utils/utility';
import { Option } from 'components/organisms/common/AutocompleteDropdown/types';
import {
  setJobName,
  setImportDate,
  setImportTime
} from 'redux/slices/csvImportSlice';
import { RootState } from 'redux/store';
import StyledStepFour from './styled';
import StepFourTitle from '../../../molecules/NewUserImport/StepFour/StepFourTitle/StepFourTitle';
import StepFourDateInput from '../../../molecules/NewUserImport/StepFour/StepFourDateInput/StepFourDateInput';
import StepFourTimeInput from '../../../molecules/NewUserImport/StepFour/StepFourTimeInput/StepFourTimeInput';
import { messages } from '../messages';

const StepFour = (): JSX.Element => {
  const intl = useIntl();
  const dispatch = useDispatch();

  const {
    jobName,
    importDate,
    importTime,
    csvValidations: {
      step_four: {
        jobName: { isValid: jobNameValid, error: jobNameError },
        importDate: { isValid: importDateValid, error: importDateError },
        importTime: { isValid: importTimeValid, error: importTimeError }
      }
    }
  } = useSelector((state: RootState) => state.slices.csvImportState);

  useEffect(() => {
    if (
      importDate &&
      importDate?.format('MM/DD/yy') !== moment().format('MM/DD/yy') &&
      (importTime?.value === 'Now' ||
        (importTime?.value !== 'Now' &&
          moment(importTime?.value, 'hh:mm A').isBefore(moment())))
    ) {
      dispatch(
        setImportTime({
          label: '',
          value: ''
        })
      );
    }
  }, [importDate, importTime, dispatch]);

  const handleInputChange = (e: React.SyntheticEvent): void => {
    const target = e.target as HTMLInputElement;
    dispatch(setJobName(target.value.toString()));
  };
  const handleDateChange = (scheduledate: Moment | null) => {
    dispatch(setImportDate(scheduledate));
  };

  function isTimeType(option: Option): option is TimeType {
    return typeof option.label === 'string' && typeof option.value === 'string';
  }

  const handleTimeChange = (scheduletime: Option | undefined) => {
    if (scheduletime && isTimeType(scheduletime)) {
      dispatch(setImportTime(scheduletime as unknown as TimeType));
    } else {
      dispatch(setImportTime({ label: '', value: '' }));
    }
  };

  return (
    <StyledStepFour>
      <div>
        <div data-testid="step-four-container">
          <StepFourTitle />
          <div className="schedule-input">
            <Input
              helperText=""
              id="input-simple"
              data-testid="input-simple"
              label={intl.formatMessage(messages.importListName)}
              name="input-simple"
              isRequired
              enableCustomValidation
              errorMessage={jobNameError}
              hasError={jobNameValid === false}
              onChange={handleInputChange}
              placeholder=""
              value={jobName}
            />
          </div>
          <StepFourDateInput
            handleDateChange={handleDateChange}
            date={importDate}
            importDateError={importDateError}
            importDateValid={importDateValid}
          />

          <StepFourTimeInput
            handleTimeChange={handleTimeChange}
            date={importDate}
            time={importTime}
            importTimeError={importTimeError}
            importTimeValid={importTimeValid}
          />
        </div>
      </div>
    </StyledStepFour>
  );
};

export default StepFour;
