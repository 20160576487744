import React, { useEffect, useRef, useState } from 'react';
import { customScrollbar } from 'lib/core';
import { useEventListener } from 'lib/utilities';
import PropTypes from 'prop-types';
import styled, { css } from 'styled-components';

import { getHexToRgb, getRem, typographyBody1, wordBreak } from './../../core';

const fullScreenStyles = css`
  max-height: none;
  padding: 0;
`;

const StyledDialogContent = styled.div.withConfig({
  shouldForwardProp: (prop) => !['hasExtraPadding', 'isFullScreen', 'isScrollable'].includes(prop),
})`
  ${({ theme }) => typographyBody1(theme)}
  ${wordBreak}

    box-sizing: border-box;
  overflow-x: hidden;
  overflow-y: auto;
  padding: ${getRem('12px')} ${({ theme }) => theme.size.spacing.large.value} 0;

  ${({ hasExtraPadding, theme }) =>
    hasExtraPadding &&
    css`
      padding-top: ${theme.size.spacing.large.value};
    `}

  ${({ isScrollable, theme }) =>
    isScrollable &&
    css`
      ${customScrollbar};
      border-bottom: 1px solid rgba(${getHexToRgb(theme.color.additional.dark.value)}, 0.3);
    `}

    &:last-child {
    padding-bottom: ${getRem('12px')};
  }
  ${({ isFullScreen }) => isFullScreen && fullScreenStyles}
`;

const DialogContent = ({ children, dataTestId = undefined, hasExtraPadding = false, ...other }) => {
  const [isScrollable, setIsScrollable] = useState(false);

  const contentRef = useRef(null);

  const handleContentScrollOverflow = () => {
    if (contentRef && contentRef.current) {
      const contentElement = contentRef.current;
      const contentDimensions = contentElement.getBoundingClientRect();
      const contentDimensionsHeight = Math.ceil(contentDimensions.height);

      setIsScrollable(contentElement.scrollHeight > contentDimensionsHeight);
    }
  };

  useEffect(() => {
    setTimeout(() => handleContentScrollOverflow());
  }, [children]);

  useEventListener('resize', () => handleContentScrollOverflow());

  return (
    <StyledDialogContent
      data-testid={dataTestId ? `${dataTestId}-dialog-content` : undefined}
      hasExtraPadding={hasExtraPadding}
      isScrollable={isScrollable}
      ref={contentRef}
      {...other}
    >
      {children}
    </StyledDialogContent>
  );
};

DialogContent.propTypes = {
  /** Any content inserted between component tags */
  children: PropTypes.node.isRequired,
  /** Id value used for testing */
  dataTestId: PropTypes.string,
  /** If true, adds extra padding-top style for the component */
  hasExtraPadding: PropTypes.bool,
};

export { DialogContent };
