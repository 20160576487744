import styled from 'styled-components';

const ChipWrapper = styled.div`
  box-sizing: border-box;
  color: ${({ theme }) => theme.color.additional.dark.value};
  display: inline-flex;
  min-width: 0;
  position: relative;
`;

export { ChipWrapper };
