import styled from 'styled-components';

const StyledTemplateUpload = styled.div`
    .pre-drag-text{
        font-size: 1.7rem;
        color: #80B5FC;
    }
    .post-drag-text{
        font-weight: 500;
        font-size: 1.7rem;
        color: #006CFA;
    }

    .info-row{
        width: 30%;
    }

    .icon-column {
        background: #151b250d;
        padding: 0.5rem;
        border-radius: 0.5rem;
        margin-right: 0.5rem;
    }

    .icon-column, .cancel-column{
        display: flex;
        justify-content: space-around;
        align-items: flex-end;
    }

    .close-container{
        cursor: pointer;
    }
`;

export default StyledTemplateUpload;
