/* These values map to event.key **/
export const KEY_VALUES = {
  ARROW_DOWN: 'ArrowDown',
  ARROW_LEFT: 'ArrowLeft',
  ARROW_RIGHT: 'ArrowRight',
  ARROW_UP: 'ArrowUp',
  BACKSPACE: 'Backspace',
  DELETE: 'Delete',
  END: 'End',
  ENTER: 'Enter',
  ESCAPE: 'Escape',
  HOME: 'Home',
  SPACE: ' ',
  SUBTRACT: '-',
  TAB: 'Tab',
};

/* These values map to event.code **/
export const CODE_VALUES = {
  ARROW_DOWN: 'ArrowDown',
  ARROW_LEFT: 'ArrowLeft',
  ARROW_RIGHT: 'ArrowRight',
  ARROW_UP: 'ArrowUp',
  BACKSPACE: 'Backspace',
  DELETE: 'Delete',
  END: 'End',
  ENTER: 'Enter',
  ESCAPE: 'Escape',
  HOME: 'Home',
  SPACE: 'Space',
  SUBTRACT: 'Minus',
  TAB: 'Tab',
  NUMPAD_ENTER: 'NumpadEnter', // Only works with event.code.
};

// leaving this here jusssst in case consuming applications use these exported constants
export const KEY_CODES = {
  ARROW_DOWN: 40,
  ARROW_LEFT: 37,
  ARROW_RIGHT: 39,
  ARROW_UP: 38,
  BACKSPACE: 8,
  DELETE: 46,
  END: 35,
  ENTER: 13,
  ESCAPE: 27,
  HOME: 36,
  SPACE: 32,
  SUBTRACT: 109,
  TAB: 9,
};
