import { IconAdmin, IconPhone, IconMail, CardWrapper } from 'cdk-radial';
import { StyledCardItem, StyledCardBody } from '../styled';
import { EnterpriseAdminCardProps } from '../types';

const EnterpriseAdminCard = ({
  admin: { firstName, lastName, primaryEmail, primaryPhone }
}: EnterpriseAdminCardProps): JSX.Element => (
  <CardWrapper>
    <StyledCardBody>
      <StyledCardItem>
        <IconAdmin height={24} width={24} />
        {firstName} {lastName}
      </StyledCardItem>

      {primaryEmail && (
        <StyledCardItem>
          <IconMail height={24} width={24} />
          {primaryEmail}
        </StyledCardItem>
      )}

      {primaryPhone && (
        <StyledCardItem>
          <IconPhone height={24} width={24} />
          {primaryPhone}
        </StyledCardItem>
      )}
    </StyledCardBody>
  </CardWrapper>
);

export default EnterpriseAdminCard;
